<template>
    <v-app>
        <Modal v-if="modalShown" />
        <navigation :show-to-admin="true" />
        <Toolbar />
        <v-main>
            <v-container class="layout" fluid>
                <aside v-if="hasAside" class="layout__aside">
                    <router-view name="aside"></router-view>
                </aside>
                <main class="layout__main">
                    <IncomingMessages />
                    <router-view></router-view>
                </main>
            </v-container>
        </v-main>
    </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import Toolbar from '@/layout/Toolbar.vue';
import Navigation from '@/layout/Navigation.vue';
import Modal from '@/modals/Modal.component.vue';
import IncomingMessages from '@/messaging/IncomingMessages.component.vue';
import { mapState } from 'vuex';

export default Vue.extend({
    name: 'App',
    components: { Toolbar, Navigation, Modal, IncomingMessages },
    computed: {
        ...mapState(['modalShown']),
        hasAside(): boolean {
            // Check if "aside" route is used
            return Boolean(this.$route?.matched?.[1]?.components?.aside);
        }
    }
});
</script>

<style lang="scss">
@import './styles/styles';
</style>
