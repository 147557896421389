import {Setoid} from '@utils/functional.util';
import {GrowerMember} from '@/grower/grower.domain';
import {FarmCombineInfo} from '@/farmCombine/farmCombine.domain';
import {FarmBaleServiceVehicleInfo, FarmBinInfo} from '@/farmBin/farmBin.domain';
import {FarmBalerInfo} from '@/farmBaler/farmBaler.domain';
import {FarmUserInfo} from '@/farmUser/farmUser.domain';
import {BoundaryJSON} from 'shared-frontend/dist/boundary/boundary.jsonParsers.utils';
import {FarmSpfhInfo} from '@/farmSpfh/farmSpfh.domain';

export type FarmTeamId = string;

export interface FarmMgr {
    farmMgrId: string;
    farmMgrName: string;
    farmMgrFirstName: string;
    farmMgrLastName: string;
}

export interface UpdateFarmTeam {
    teamName: string;
    growerName: string;
    farmName: string;
    year: number;
    fieldName: string;
    cropName: string;
    operatorName: string;
}

export interface FarmTeamSummary {
    teamId: string;
    farmMgrId: string;
    teamName: string;
    year: number;
    growerName: string;
    farmName: string;
    fieldName: string;
    cropName: string;
    operatorName: string;
    startedOn: string;
    stoppedOn: string;
    ownedBy: FarmMgr;
    linkedTo: GrowerMember;
}

export interface FarmTeamDetail extends FarmTeamSummary {
    combines: FarmCombineInfo;
    balers: FarmBalerInfo;
    bins: FarmBinInfo;
    baleServiceVehicles: FarmBaleServiceVehicleInfo;
    users: FarmUserInfo;
    spfhs: FarmSpfhInfo;
    boundary: BoundaryJSON | null;
}

export const eqFarmTeam: Setoid<FarmTeamSummary> = {
    equals: (x: FarmTeamSummary, y: FarmTeamSummary) => x?.teamId === y?.teamId
};

// FarmTeam events
// ================

export enum FarmTeamSseEvents {
    FARM_TEAM_EVENT = 'farmTeamEvent'
}

export enum FarmTeamEventTypes {
    FARM_TEAM_CREATED = 'farmTeamCreated',
    FARM_TEAM_UPDATED = 'farmTeamUpdated',
    FARM_TEAM_DELETED = 'farmTeamDeleted',
    FARM_TEAM_LINKED = 'farmTeamLinked',
    FARM_TEAM_UNLINKED = 'farmTeamUnlinked'
}

interface FarmTeamEventBase {
    type: FarmTeamEventTypes;
    farmTeamId: string;
    farmMgrId: string;
    eventTime: string;
}

export interface FarmTeamCreatedEvent extends FarmTeamEventBase {
    type: FarmTeamEventTypes.FARM_TEAM_CREATED;
    farmTeam: FarmTeamSummary;
}

export interface FarmTeamUpdatedEvent extends FarmTeamEventBase {
    type: FarmTeamEventTypes.FARM_TEAM_UPDATED;
    farmTeam: FarmTeamSummary;
}

export interface FarmTeamDeletedEvent extends FarmTeamEventBase {
    type: FarmTeamEventTypes.FARM_TEAM_DELETED;
}

export interface FarmTeamLinkedEvent extends FarmTeamEventBase {
    type: FarmTeamEventTypes.FARM_TEAM_LINKED;
    linkedTo: GrowerMember;
}

export interface FarmTeamUnlinkedEvent extends FarmTeamEventBase {
    type: FarmTeamEventTypes.FARM_TEAM_UNLINKED;
    unlinkedFrom: GrowerMember;
}

export type FarmTeamEvent =
    | FarmTeamCreatedEvent
    | FarmTeamUpdatedEvent
    | FarmTeamDeletedEvent
    | FarmTeamLinkedEvent
    | FarmTeamUnlinkedEvent;


export interface FilteredTeam extends FarmTeamSummary{
    boundary: BoundaryJSON
}

