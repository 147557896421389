<template>
    <div class="incomingMessages">
        <div v-for="(message, i) in messages">
            <MessageComponent :key="message.id" :message="message" />
        </div>
    </div>
</template>

<script>
import MessageComponent from '@/messaging/Message.component.vue';
import MessageService from '@/messaging/message.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export default {
    name: 'IncomingMessages',
    components: {
        MessageComponent
    },
    data() {
        return {
            messages: [],
            destroy$: new Subject()
        };
    },
    mounted() {
        MessageService.init();
        MessageService.recentMessages$()
            .pipe(takeUntil(this.destroy$))
            .subscribe((messages) => (this.messages = messages));
    },
    beforeDestroy() {
        this.destroy$.next();
    }
};
</script>
