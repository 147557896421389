<template>
  <fragment>
    <v-card class="dashboard__card">
      <v-card-title :style="{ backgroundColor: brandColors.SECONDARY }">
        <span>{{ translations.DASHBOARD.WORKFORCE_GROWERS }}</span>
        <InfoIcon :text="translations.DASHBOARD.WORKFORCE_GROWERS_INFO"/>
      </v-card-title>
      <v-card-text class="dashboard__textContainer">
        <ul v-if="growers$ && growers$.length" class="dashboard__list">
          <li v-for="grower in growers$" :key="grower.growerName" class="dashboard__item mb-3">
            <v-icon color="primary" class="dashboard__icon ml-2">{{ icons.user }}</v-icon>
            <div class="dashboard__titleSubtitleContainer">
              <span class="dashboard__text dashboard__text-title">{{ getName(grower) }}</span>
              <span v-if="isLinked(grower)" class="dashboard__text dashboard__text-subtitle">{{
                  grower.linkedTo.email
                }}</span>
            </div>
            <span class="dashboard__actions">
                                    <v-btn v-if="isLinked(grower)" icon @click="edit(grower)">
                                        <v-icon>{{ icons.edit }}</v-icon>
                                    </v-btn>
                                    <v-btn v-if="!isLinked(grower)" icon @click="link(grower)">
                                        <v-icon>{{ icons.link }}</v-icon>
                                    </v-btn>
                                    <v-btn v-if="isLinked(grower)" icon @click="unlink(grower)">
                                        <v-icon color="red">{{ icons.unlink }}</v-icon>
                                    </v-btn>
                                </span>
          </li>
        </ul>
        <template v-else-if="growers$ === null">
          <v-progress-circular color="primary" indeterminate/>
        </template>
        <template v-else>
          <p style="font-weight: bold">
            {{ translations.DASHBOARD.NO_GROWERS.PARAGRAPH_1 }}
          </p>
          <p>{{ translations.DASHBOARD.NO_GROWERS.PARAGRAPH_2 }}</p>
        </template>
      </v-card-text>
    </v-card>
  </fragment>
</template>

<script lang="ts">
import Vue from 'vue';
import {mapState} from 'vuex';
import InfoIcon from '@components/InfoIcon.component.vue';
import MapIcon from '@/map/icons/MapIcon.icon.vue';
import {Icons, icons} from '@utils/icons.utils';
import {Brand, BrandingService} from 'shared-frontend';
import {State} from '@/store';
import GrowerService from './grower.service';
import {Grower} from '@/grower/grower.domain';
import ModalService from '@/modals/modal.service';
import {ModalTypeEnum} from '@/modals/modalType.enum';
import WorkforceService from '@/workforce/workforce.service';

export default Vue.extend({
  name: 'Growers',
  components: {
    InfoIcon,
    MapIcon
  },
  mounted() {
    GrowerService.fetchGrowers();
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    icons(): Icons {
      return icons;
    },
    brandColors(): any {
      return BrandingService.getColors(process.env.VUE_APP_BRAND as Brand);
    }
  },
  methods: {
    getName({linkedTo, growerName}: Grower): string {
      const { firstName, lastName} = linkedTo || {};
      return (firstName || lastName) ? `${firstName ?? ''} ${lastName ?? ''}` : growerName;
    },
    isLinked(grower: Grower): boolean {
      return Boolean(grower?.linkedTo);
    },
    edit(grower: Grower): void {
      ModalService.showModal(ModalTypeEnum.LINK_EDIT_GROWER, {link: false, grower});
    },
    link(grower: Grower): void {
      ModalService.showModal(ModalTypeEnum.LINK_EDIT_GROWER, {link: true, grower});
    },
    unlink(grower: Grower): void {
      ModalService.showModal(ModalTypeEnum.CONFIRM_UNLINK_GROWER, {grower, onConfirm: () => GrowerService.unLinkGrower(grower.growerName)});
    }
  },
  subscriptions() {
    return {
      growers$: GrowerService.growers$
    };
  }
});
</script>