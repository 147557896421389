<template>
  <v-app>
    <Toolbar
        :show-farm-team-picker="false"
        :show-settings="false"/>
    <navigation
        :show-nav-items="false"
        :show-admin-items="true"
        :show-to-portal="true"/>
    <v-main>
      <v-container class="layout" fluid>
        <aside v-if="hasAside" class="layout__aside">
          <router-view name="aside"></router-view>
        </aside>
        <main class="layout__main">
          <v-progress-circular
              v-if="isAdmin === null"
              color="primary"
              indeterminate
          />

          <div v-if="!isAdmin">
            {{ translations.ADMIN.FORBIDDEN }}
          </div>
          <div v-else>

            <Title :title="this.translations.ADMIN.THIRD_PARTY.TITLE"/>

              <v-snackbar
                  v-model="showSuccessSnackbar"
                  color="success"
                  absolute
                  style="transform: translateY(-70vh)"
                  timeout="3000"
              >
                The values are updated!

                <template v-slot:action="{ attrs }">
                  <v-btn
                      color="pink"
                      text
                      v-bind="attrs"
                      @click="showSuccessSnackbar = false"
                  >
                    X
                  </v-btn>
                </template>
              </v-snackbar>

              <v-snackbar
                  v-model="showErrorSnackbar"
                  color="error"
                  absolute
                  style="transform: translateY(-70vh)"
                  timeout="3000"
              >
                Something went wrong! Data was not stored!

                <template v-slot:action="{ attrs }">
                  <v-btn
                      color="pink"
                      text
                      v-bind="attrs"
                      @click="showErrorSnackbar = false"
                  >
                    X
                  </v-btn>
                </template>
              </v-snackbar>
              <v-form ref="form" v-model="isFormValid">
                <v-row>
                  <v-col>
                    <v-card>
                      <v-card-title>General properties</v-card-title>
                      <v-card-text>
                        <v-text-field :rules="formRules.name" v-model="thirdParty.name" outlined label="Name"/>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>

                    <v-card>
                      <v-card-title>
                        Allowed data entities
                        <add-data-provider v-if="availableDataProviders.length>0" @submit="handleAddDataProvider" :available-data-providers="availableDataProviders"/>
                      </v-card-title>
                      <v-spacer/>

                      <v-card-text>

                        <v-card :key="entity.provider" v-for="entity in this.sortedAllowedDataProviders">
                          <v-card-title>
                            {{ entity.provider }}
                            <v-spacer/>
                            <remove-data-provider @submit="handleRemoveCredential(entity.provider)"/>
                          </v-card-title>

                          <v-card-text>
                            <v-row>
                              <v-col :cols="2">
                                <v-checkbox label="Restricted to period" v-model="entity.accessPeriodConfiguration.limitedAccess" @click="changePeriodLimitedAccess(entity)"/>
                              </v-col>
                              <v-col v-if="entity.accessPeriodConfiguration.limitedAccess" :cols="5">
                                <date-input :label="translations.APDT_ADVICE.FORMS.SOWING.SOWING_TIME"  />
                                <date-input v-model="entity.accessPeriodConfiguration.periods[0].from" :rules="formRules.from(entity)"  label="Begin"/>
                              </v-col>
                              <v-col v-if="entity.accessPeriodConfiguration.limitedAccess" :cols="5">
                                <date-input v-model="entity.accessPeriodConfiguration.periods[0].to" :rules="formRules.to(entity)"  label="End"/>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col :cols="2">
                                <v-checkbox label="Restricted to farmers" v-model="entity.farmManagerAccessConfiguration.limitedAccess" @click="changeFarmerLimitedAccess(entity)"/>
                              </v-col>
                              <v-col v-if="entity.farmManagerAccessConfiguration.limitedAccess" :cols="10">
                                <v-autocomplete v-model="entity.farmManagerAccessConfiguration.farmers" :items="allConsentFarmers"
                                                :rules="formRules.validateFarmers(entity)"
                                                item-text="username"
                                                item-value="id"
                                                chips deletable-chips clearable multiple/>
                              </v-col>
                            </v-row>

                          </v-card-text>
                        </v-card>

                      </v-card-text>
                    </v-card>

                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-title>
                      Authentication
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                          v-model="thirdParty.apiKey"
                          label="Token" outlined
                          :type="showToken ? 'text' : 'password'"
                          readonly>
                        <template slot="append">
                          <v-icon @click="showToken = !showToken" v-if="showToken">{{ icons.eye }}</v-icon>
                          <v-icon @click="showToken = !showToken" v-if="!showToken">{{ icons.eyeSlash }}</v-icon>
                          <refresh-token @submit="refreshToken"></refresh-token>
                        </template>
                      </v-text-field>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

            <v-spacer/>
            <v-footer style="position:sticky;bottom: 0;">
              <v-container>
                <v-row>
                  <v-spacer/>
                  <save-third-party :is-form-valid="isFormValid" @submit="handleSubmit"/>
                </v-row>
              </v-container>
            </v-footer>

          </div>
        </main>
      </v-container>

    </v-main>
  </v-app>
</template>

<script lang="ts">

import Vue from "vue";
import Navigation from "@/layout/Navigation.vue";
import Toolbar from "@/layout/Toolbar.vue";
import Title from "@/components/Title.component.vue";
import { getConsentFarmers, getThirdPartyDataProviders, getThirdPartyDetails, updateThirdParty, updateThirdPartyKey } from "@/thirdPartyManagement/thirdParty.service";
import { icons, Icons } from "@utils/icons.utils";
import { AccessPeriod, AccessPeriodConfiguration, FarmManagerAccessConfiguration, ThirdParty, ThirdPartyCustomerAllowDataProvider } from "@/thirdPartyManagement/thirdParty.domain";
import DateInput from "@/components/DateInput.component.vue";
import AddDataProvider from "@/thirdPartyManagement/AddDataProvider.vue";
import RefreshToken from "@/thirdPartyManagement/RefreshToken.vue";
import RemoveDataProvider from "@/thirdPartyManagement/RemoveDataProvider.vue";
import { mapState } from "vuex";
import { State } from "@/store";
import SaveThirdParty from "@/thirdPartyManagement/SaveThirdParty.vue";
import authenticationService from "@services/authentication.service";


export default Vue.extend({
  name: "ThirdPartyManagementDetails",
  components: {SaveThirdParty, RefreshToken, RemoveDataProvider, AddDataProvider, DateInput, Navigation, Toolbar, Title},
  props: {},
  data() {
    return {
      isAdmin: false,
      isFormValid: false,
      thirdParty: new ThirdParty({}),
      allDataProviders: [],
      allConsentFarmers: [],
      showToken: false,
      showSuccessSnackbar: false,
      showErrorSnackbar: false,
      formRules: {
        name: [
          v => !!v || (this as any).translations.GENERAL.REQUIRED,
        ],
        validateFarmers(entity) {
          return [v => !(entity.farmManagerAccessConfiguration.limitedAccess && (entity.farmManagerAccessConfiguration.farmers == null || entity.farmManagerAccessConfiguration.farmers.length == 0)) || "Required"]
        },
        from(entity) {
          return [v => (entity.accessPeriodConfiguration.periods[0].from <= entity.accessPeriodConfiguration.periods[0].to) || "Begin should not be after end"]
        },
        to(entity) {
          return [v => (entity.accessPeriodConfiguration.periods[0].from <= entity.accessPeriodConfiguration.periods[0].to) || "End cannot be before begin"]
        }
      }
    };
  },
  async created() {
    this.isAdmin = await authenticationService.isAdmin();
  },
  mounted() {
    this.fetchInit();
    getThirdPartyDataProviders()
        .then((arrayDataProviders) => {
          arrayDataProviders.forEach(d => this.allDataProviders.push(d))
        });
    getConsentFarmers()
        .then((arrayFarmers) => {
          arrayFarmers.forEach(f => this.allConsentFarmers.push(f))
        })
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    icons(): Icons {
      return icons;
    },
    availableDataProviders(): Array<string> {
      if (!!this.thirdParty.allowDataProviders) {
        const existingDataProviders: Array<string> = this.thirdParty.allowDataProviders.map((a: ThirdPartyCustomerAllowDataProvider) => a.provider)
        const unsortedAvailableDP = this.allDataProviders.filter((allEntry) => !existingDataProviders.includes(allEntry));
        return unsortedAvailableDP.sort((a, b) => {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          } else {
            return 0;
          }
        })
      } else {
        return []
      }
    },
    sortedAllowedDataProviders(): Array<ThirdPartyCustomerAllowDataProvider> {
      if (!!this.thirdParty.allowDataProviders) {
        return this.thirdParty.allowDataProviders.sort((a, b) => {
          if (a.provider < b.provider) {
            return -1;
          } else if (a.provider > b.provider) {
            return 1;
          } else {
            return 0;
          }
        })
      } else {
        return []
      }
    }
  },
  methods: {
    fetchInit: async function () {
      await getThirdPartyDetails(this.$route.params.id)
          .then((t) => {
            this.thirdParty = t
          });
    },
    handleSubmit() {
      updateThirdParty(this.thirdParty)
          .then(updatedData => this.showSuccessSnackbar = true)
          .catch(reason => this.showErrorSnackbar = true)
    },
    refreshToken: function () {
      updateThirdPartyKey(this.thirdParty).then(value => this.thirdParty.apiKey = value)
    },
    changePeriodLimitedAccess(entity: ThirdPartyCustomerAllowDataProvider) {
      if (entity.accessPeriodConfiguration.limitedAccess) {
        entity.accessPeriodConfiguration.periods = [new AccessPeriod({})];
      } else {
        entity.accessPeriodConfiguration.periods = []
      }
    },
    changeFarmerLimitedAccess(entity: ThirdPartyCustomerAllowDataProvider) {
      entity.farmManagerAccessConfiguration.farmers = []
    },
    handleAddDataProvider(dataProvider) {
      this.thirdParty.allowDataProviders.push(
          new ThirdPartyCustomerAllowDataProvider(
              {
                provider: dataProvider,
                accessPeriodConfiguration: new AccessPeriodConfiguration({limitedAccess: false, periods: []}),
                farmManagerAccessConfiguration: new FarmManagerAccessConfiguration({limitedAccess: false, farmers: []})
              }
          )
      );
    },
    handleRemoveCredential(dataProvider) {
      this.thirdParty.allowDataProviders = this.thirdParty.allowDataProviders.filter(obj => obj.provider !== dataProvider);
    }
  },
  watch: {
    thirdParty: {
      handler: function (val, oldVal) {
        (this.$refs.form as any).validate()
      },
      deep: true
    }
  }
});
</script>

<style scoped>
::v-deep .v-main__wrap {
  display: flex;
  flex-direction: column;
}
</style>
