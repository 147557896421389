import {MacAddress} from "shared-frontend";

export class LogsUploadProgress {
    public startedOn: Date | null;
    public status: UploadStatus
    public canStartNewUpload: boolean;
    public failureMessage: string | null;

    constructor(startedOn: Date, status: UploadStatus, canStartNewUpload: boolean, failureMessage) {
        this.startedOn = startedOn;
        this.status = status;
        this.canStartNewUpload = canStartNewUpload;
        this.failureMessage = failureMessage;
    }
}

export enum UploadStatus {
    NEVER_REQUESTED = 'NEVER_REQUESTED',
    REQUESTED = 'REQUESTED',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED'
}


export interface MacAddressInfo {
    macAddress: MacAddress;
    serialNumber: string | null;
}
