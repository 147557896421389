<template>
  <div>
    <div
      v-if="info"
      class="info-container"
      :class="{ today: info.date === selectedDate }"
    >
      <div class="timestamp" :class="{ extendedTimestamp: !info.weatherCode }">
        <template v-if="isLastWeek(info, selectedDate)">{{ translations.APDT_ADVICE.WEATHER_WIDGET.LAST_WEEK }}</template>
        <template v-else>{{ formatDate(info) }}</template>
      </div>
      <div class="icon" v-if="info.weatherCode">
        <div>
          <v-img :src="getImageUrl(info.weatherCode)"
                 :title="info.weatherDescription"
                 :alt="info.weatherDescription"
                 width="48" height="48">

          </v-img>
        </div>
      </div>
      <TemperatureBar :info="info"></TemperatureBar>
      <div class="precipitation" title="Precipitation (mm)">
        {{ info.precipitation }}
      </div>
      <div class="wind" title="Wind speed (km/h)">
        <v-icon color="white" small
                :style="'transform: rotate('+ (225-info.windDegrees) +'deg)'">
          fa-location-arrow
        </v-icon>
        <div>{{ info.windSpeed }}</div>
      </div>
    </div>

    <div v-if="header" class="info-container header">
      <div class="timestamp"></div>
      <div class="icon"></div>
      <div class="temperature">
        <div class="header-icon">
          <v-icon color="white">{{ icons.temperature }}</v-icon>
        </div>
        <div class="unit">(°C)</div>
      </div>
      <div class="precipitation">
        <div class="header-icon">
          <v-icon color="white">{{ icons.precipitation }}</v-icon>
        </div>
        <div class="unit">(mm)</div>
      </div>
      <div class="wind-speed">
        <div class="header-icon">
          <v-icon color="white">{{ icons.wind }}</v-icon>
        </div>
        <div class="unit">(km/h)</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { WeatherInfo } from '@/weather/weather-info.domain';
import TemperatureBar from '@/weather/TemperatureBar.vue';
import { icons } from "../utils/icons.utils";
import moment from 'moment';
import {mapState} from "vuex";
import {State} from "@/store";

export default {
  name: 'WeatherInfoComponent',
  components: {
    TemperatureBar
  },
  props :{
    info: {
      type: Object as PropType<WeatherInfo>,
      required: false,
    },
    header: {
      type: Boolean,
      default: () => false,
    },
    selectedDate: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    icons() {
      return icons
    }
  },
  methods: {
    getImageUrl(weatherCode: string) {
      return require(`../assets/icons/weather/icon-${weatherCode}.png`);
    },
    formatDate(info: WeatherInfo): string {
      return moment(info.date).format('ddd[\n]D-MM');
    },
    isLastWeek(info: WeatherInfo, selectedDate: string): boolean {
      return moment(info.date).isSame(moment(selectedDate).subtract(1, 'week'), 'day');
    }
  }
}
</script>

<style lang="scss" scoped>
@import './weather-colors';

$timestampHeight: 48px;
$iconHeight: 48px;
$rowGap: 8px;

.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $rowGap;
  padding: 8px 12px;
  &:hover:not(.header) {
    background: $blue-15;
  }
  &.header {
    margin-left: 8px;
  }
  > * {
    width: 56px;

    display: flex;
    justify-content: center;
    align-items: center;
    &.extendedTimestamp {
      align-items: start;
      position: relative;
      top: 7px;
      text-align: center;
    }
  }
  > .icon {
    height: $iconHeight;
  }
  > TemperatureBar,
  .temperature {
    height: 80px;
  }
  &.header > * {
    width: 72px;
    justify-content: start;
  }
  &.today {
    background: $blue-20;
  }
  .header-icon {
    width: 32px;
    text-align: center;
  }
}

.timestamp {
  font-weight: 500;
  height: $timestampHeight;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  &.extendedTimestamp {
    height: calc($iconHeight + $timestampHeight + $rowGap);
  }
}

.precipitation,
.wind, .wind-speed {
  height: 24px;
}

.wind {
  display: flex;
  gap: 10px;
  align-items: center;
}

.unit {
  font-size: 0.7rem;
  padding-left: 8px;
  white-space: nowrap;
}
</style>
