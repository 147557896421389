<template>
  <v-combobox v-if="comboboxMode" :value="value" :rules="rules" @input="handleInput" :items="teamNames" :search-input.sync="teamNameSearch" :label="trans.TEAM.TEAM_NAME"  clearable>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            No results matching "<strong>{{ teamNameSearch }}</strong>". Press <kbd>enter</kbd> to create a new one
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
  <v-autocomplete v-else :value="value" :rules="rules" @input="$emit('input', $event)" :items="teamNames" dark hide-details :label="trans.TEAM.TEAM_NAME" return-object/>

</template>

<script lang="ts">

import {AutoCompleteItem, returnIfUndefined, selectAllAutoCompleteItem, selectAllPredicate, toAutocompleteItems} from "./Picker.helper";
import Vue, {PropType} from "vue";
import {mapState} from "vuex";
import {State} from "@/store";
import {not} from "@utils/functional.util";
import {FarmTeamSummary} from "@/farmTeam/farmTeam.domain";
import {uniqueTeamNames} from "@components/picker/TeamNamePicker.helper";

const teamNameSelectAllAutoCompleteItem = selectAllAutoCompleteItem("All Teams");
export default Vue.extend({
  name: 'TeamNamePicker',
  props: {
    value: {},
    comboboxMode: {
      type: Boolean,
      default: false
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    alphabetically: {
      type: Boolean,
      default: false
    },
    farmTeams: {
      type: Array as PropType<FarmTeamSummary[]>
    },
    grower: {
      type: Object,
    },
    farm: {
      type: Object,
    },
    year: {
      type: Object,
    },
    field: {
      type: Object,
    },
    crop: {
      type: Object,
    },
    rules: []
  },
  data() {
    return {
      teamNameSearch: "",
      createdTeams:[]
    }
  },
  computed: {
    ...mapState({
      trans: (state: State) => state.translation.translations
    }),
    teamNames(): AutoCompleteItem[] {
      return this.findTeamNames(this.grower, this.farm, this.year, this.field, this.crop).concat(this.createdTeams);
    },
  },
  methods: {
    findTeamNames(grower: AutoCompleteItem, farm: AutoCompleteItem, year: AutoCompleteItem, field: AutoCompleteItem, crop: AutoCompleteItem): AutoCompleteItem[] {
      let teamNames = this.selectAll ? [teamNameSelectAllAutoCompleteItem] : [];
      return (
          teamNames.concat(
              uniqueTeamNames(this.farmTeams, this.alphabetically, grower, farm, year, field, crop)
                  ?.map(toAutocompleteItems(this.trans.TEAM.TEAM_NAME, this.trans))
          )
      );
    },
    updateTeamName(crop?: AutoCompleteItem) {
      const cropUpdate = crop ?? this.crop
      if (cropUpdate?.selectAll) {
        this.$emit('input', teamNameSelectAllAutoCompleteItem)
      } else {
        this.$emit('input', this.firstTeamName(
            this.grower,
            this.farm,
            this.year,
            this.field,
            returnIfUndefined(cropUpdate, this.crop)
        ))
      }
    },
    firstTeamName(grower: AutoCompleteItem, farm: AutoCompleteItem, year: AutoCompleteItem, field: AutoCompleteItem, crop: AutoCompleteItem): AutoCompleteItem {
      return this.findTeamNames(grower, farm, year, field, crop).find(not(selectAllPredicate));
    },
    handleInput(entry): void {
      if (typeof entry === 'string') {
        let trimmedEntry = entry.trim();
        let foundEntry = this.teamNames.find(value => value.value === entry);
        if (foundEntry) {
          this.$emit('input', foundEntry)
        } else if (trimmedEntry.trim() !== "") {
          let newEntry = toAutocompleteItems(this.trans.TEAM.TEAM_NAME, this.trans)(entry, null)
          this.createdTeams.push(newEntry)
          this.$emit('input', newEntry)
        }
      } else {
        this.$emit('input', entry)
      }
    }

  },
  watch: {
    'grower': function (newEntry: AutoCompleteItem, oldGrower: AutoCompleteItem): void {
      if (newEntry?.value === oldGrower?.value && newEntry?.selectAll === newEntry?.selectAll) return;
      this.updateTeamName();
    },
    'farm': function (newFarm: AutoCompleteItem, oldFarm: AutoCompleteItem): void {
      if (newFarm?.value === oldFarm?.value && newFarm?.selectAll === newFarm?.selectAll) return;
      this.updateTeamName();
    },
    'year': function (newYear: AutoCompleteItem, oldYear: AutoCompleteItem): void {
      if (newYear?.value === oldYear?.value && newYear?.selectAll === newYear?.selectAll) return;
      this.updateTeamName();
    },
    'field': function (newField: AutoCompleteItem, oldField: AutoCompleteItem): void {
      if (newField?.value === oldField?.value && newField?.selectAll === newField?.selectAll) return;
      this.updateTeamName();
    },
    'crop': function (newCrop: AutoCompleteItem, oldCrop: AutoCompleteItem): void {
      if (newCrop?.value === oldCrop?.value && newCrop?.selectAll === newCrop?.selectAll) return;
      this.updateTeamName(newCrop);
    },
  }
});
</script>
