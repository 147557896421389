import KeycloakService from '@services/keycloak.service';
import jwt_decode, {JwtPayload} from "jwt-decode";

export type AuthorizationHeader = {
    Authorization: string
}

interface KeycloakJwtPayload extends JwtPayload {
    realm_access: RealmAccessClaim
}

interface RealmAccessClaim {
    roles: string[];
}

const REALM_ROLE_CNHI_ADMIN = 'cnhi_admin';

class AuthenticationService {
    public async getAuthorizationHeader(): Promise<AuthorizationHeader> {
        const token = await this.getEncodedToken();

        return {
            Authorization: `Bearer ${token}`
        };
    }

    public async isAdmin(): Promise<boolean> {
        const decodedToken = await this.getDecodedToken();
        const realmRoles = decodedToken.realm_access.roles;
        return realmRoles.includes(REALM_ROLE_CNHI_ADMIN)
    }

    private async getEncodedToken(): Promise<string> {
        const kcInstance = await KeycloakService.getOrInit();
        return await kcInstance.getToken();
    }

    private async getDecodedToken() {
        const encodedToken = await this.getEncodedToken();
        return jwt_decode<KeycloakJwtPayload>(encodedToken);
    }
}

export default new AuthenticationService();
