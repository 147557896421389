<template>
  <div class="teamMemberList__collapsed elevation-2" v-if="selectedTeam$">
    <template v-if="users.length">
      <span>{{ users.length }}</span>
      <MapIcon :noColor="true" :custom-width="30" :field-entity="genericUser"/>
    </template>
    <template v-if="combines.length">
      <span>{{ combines.length }}</span>
      <MapIcon :noColor="true" :custom-width="60" :field-entity="genericCombine"/>
    </template>
    <template v-if="spfhs.length">
      <span>{{ spfhs.length }}</span>
      <MapIcon :noColor="true":custom-width="60" :field-entity="genericSpfh"/>
    </template>
    <template v-if="balers.length">
      <span>{{ balers.length }}</span>
      <MapIcon :noColor="true":custom-width="60" :field-entity="genericBaler"/>
    </template>
    <template v-if="bins.length">
      <span>{{ bins.length }}</span>
      <MapIcon :noColor="true" :custom-width="60" :field-entity="genericBin"/>
    </template>
    <template v-if="baleServiceVehicles.length">
      <span>{{ baleServiceVehicles.length }}</span>
      <MapIcon :noColor="true" :custom-width="60" :field-entity="genericBaleServiceVehicle"/>
    </template>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {mapState} from 'vuex';

import {Baler, BalerIcon, BalerTypeEnum, BaleServiceVehicle, BaleServiceVehicleTypeEnum, Bin, BinIcon, BinTypeEnum, Combine, CombineIcon, CombineTypeEnum, IconColors, Spfh, SpfhIcon, SpfhTypeEnum, User} from 'shared-frontend';
import TeamService from '@/team/team.service';
import MapIcon from '@/map/icons/MapIcon.icon.vue';
import {Icons, icons} from '@utils/icons.utils';
import {State} from '@/store';
import TeamMember from '@/team/teamMemberList/TeamMember.component.vue';
import {BaleServiceVehicleIcon} from 'shared-frontend/dist/icon/icon.domain'

export default Vue.extend({
  name: 'CollapsedList',
  components: {TeamMember, MapIcon},
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    ...mapState({
      isFieldCoverageEnabled: (state: State) => state.fieldCoverageEnabled
    }),
    icons(): Icons {
      return icons;
    },
    combines(): Combine[] {
      return (this as any).selectedTeam$?.combines?.getList();
    },
    spfhs(): Spfh[] {
      return (this as any).selectedTeam$?.spfhs?.getList();
    },
    balers(): Baler[] {
      return (this as any).selectedTeam$?.balers?.getList();
    },
    bins(): Bin[] {
      return (this as any).selectedTeam$?.bins?.getList();
    },
    baleServiceVehicles(): BaleServiceVehicle[] {
      return (this as any).selectedTeam$?.baleServiceVehicles?.getList();
    },
    users(): User[] {
      return (this as any).selectedTeam$?.users?.getList();
    },
    genericCombine(): Combine {
      return new Combine(
          null,
          null,
          'generic',
          'somebody',
          new CombineIcon(IconColors.YELLOW),
          null,
          null,
          CombineTypeEnum.CANBUS,
          null,
          null,
          null,
          null,
          null
      );
    },
    genericSpfh(): Spfh {
      return new Spfh(
          null,
          null,
          'generic',
          'somebody',
          null,
          SpfhTypeEnum.CANBUS,
          null,
          null,
          undefined,
          undefined,
          undefined,
          new SpfhIcon(IconColors.YELLOW)
      );
    },
    genericBaler(): Baler {
      return new Baler(
          null,
          null,
          'generic',
          'somebody',
          new BalerIcon(IconColors.YELLOW),
          null,
          null,
          BalerTypeEnum.CANBUS,
                null,
                null,
                null
            );
        },
        genericBin(): Bin {
            return new Bin(null, null, 'generic', 'somebody', new BinIcon(IconColors.RED, BinTypeEnum.NORMAL_BIN), null, false, null, false);
        },
        genericBaleServiceVehicle(): BaleServiceVehicle {
          return new BaleServiceVehicle(null, null, 'generic', 'somebody', new BaleServiceVehicleIcon(IconColors.RED, BaleServiceVehicleTypeEnum.WRAPPER), false);
        },
        genericUser(): User {
            return new User(null, null, 'generic', '', '', true);
        }
    },
    subscriptions() {
        return {
            selectedTeam$: TeamService.selectedTeamOrNull$
        };
    }
});
</script>
