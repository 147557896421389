<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          color="error"
          small
          class="ml-auto"
          style="cursor: pointer"
          v-bind="attrs"
          v-on="on">
        {{ icons.delete }}
      </v-icon>
    </template>

    <v-card>
      <v-card-title>
        Remove data provider
      </v-card-title>

      <v-card-text>
        Are you sure you want to remove this data provider? We will no longer be able to fetch data from this source.
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="dialog=false;">
          Cancel
        </v-btn>
        <v-btn
            color="error"
            text
            @click="handleSubmit"
        >
          Remove
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { icons } from "@utils/icons.utils";

export default {
  name: "removeDataProvider",
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    icons() {
      return icons;
    },

  },
  methods: {
    handleSubmit() {
      this.dialog = false;
      this.$emit("submit");
    }
  },
  watch: {
    dialog() {
    },
  }
};
</script>

<style scoped>

</style>
