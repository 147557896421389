import {ApdtField} from '@/apdtAdvice/advice.domain';
import httpService from '@services/http.service';
import {RecalibrationData} from '@/apdtAdvice/soil-analysis/Recalibration.domain';
import {Moment} from 'moment';

export async function getRecalibrationData(field: ApdtField, date: Moment): Promise<RecalibrationData> {
    const responseAPDTField = await httpService.get(`${process.env.VUE_APP_APDT_URL}/api/field/${field.fieldId}/${date.format("yyyy-MM-DD")}/calibration`);
    if (responseAPDTField.data) {
        return new RecalibrationData(responseAPDTField.data)
    } else {
        return null
    }
}
export async function postRecalibrationData(field: ApdtField, date: Moment,recalibrationData:RecalibrationData): Promise<any> {
   await httpService.post(`${process.env.VUE_APP_APDT_URL}/api/field/${field.fieldId}/${date.format("yyyy-MM-DD")}/calibration`,recalibrationData);
}
