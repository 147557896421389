<template>
  <div class="newFarmServiceVehicle__container">
    <div class="newFarmServiceVehicle__option" @click="addBin">
          <MapIcon :custom-width="120" :field-entity="dummyBin" />
          <span class="newFarmServiceVehicle__type">{{ translations.MODALS.ADD_SERVICE_VEHICLE.BIN }}</span>
    </div>
    <div class="newFarmServiceVehicle__option" @click="addBaleServiceVehicle">
      <div class="newFarmServiceVehicle__baleServiceVehicles">
        <MapIcon :custom-width="120" :field-entity="dummyBaleWrapper" />
        <MapIcon :custom-width="120" :field-entity="dummyBalePickup" />
      </div>
      <span class="newFarmServiceVehicle__type">{{ translations.MODALS.ADD_SERVICE_VEHICLE.BALE_SERVICE_VEHICLE }}</span>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

import {BaleServiceVehicle, BaleServiceVehicleTypeEnum, Bin, BinIcon, BinTypeEnum, IconColors} from 'shared-frontend';
import MapIcon from '@/map/icons/MapIcon.icon.vue';
import ColorSelect from '@components/ColorSelect.component.vue';
import ModalService from "@/modals/modal.service";
import {ModalTypeEnum} from "@/modals/modalType.enum";
import {BaleServiceVehicleIcon} from "shared-frontend/dist/icon/icon.domain";

export default {
  name: 'NewFarmServiceVehicle',
  components: {
    MapIcon,
    ColorSelect
  },
  model: {
    prop: 'show',
    event: 'dismiss'
  },
  computed: {
    ...mapState({
      translations: state => state.translation.translations
    }),
    dummyBin() {
      const binIcon = new BinIcon(IconColors.BLUE, BinTypeEnum.NORMAL_BIN);
      return new Bin(null, null, null, 'dummy', binIcon, null, false, null, null);
    },
    dummyBaleWrapper() {
      const baleServiceVehicleIcon = new BaleServiceVehicleIcon(IconColors.BLUE, BaleServiceVehicleTypeEnum.WRAPPER);
      return new BaleServiceVehicle(null, null, null, 'dummy', baleServiceVehicleIcon, null, null, null);
    },
    dummyBalePickup() {
      const baleServiceVehicleIcon = new BaleServiceVehicleIcon(IconColors.BLUE, BaleServiceVehicleTypeEnum.PICKUP);
      return new BaleServiceVehicle(null, null, null, 'dummy', baleServiceVehicleIcon, null, null, null);
    }
  },
  props: {
    show: Boolean
  },
  methods: {
    addBin() {
      ModalService.showModal(ModalTypeEnum.ADD_BIN);
    },
    addBaleServiceVehicle() {
      ModalService.showModal(ModalTypeEnum.ADD_BALE_SERVICE_VEHICLE);
    },
  }
};
</script>
