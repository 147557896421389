<template>
    <v-btn text @click="$emit('click')">
        <span class="d-none d-lg-block mr-3 white--text">
            <slot />
        </span>
        <v-icon color="white">{{ icon }}</v-icon>
    </v-btn>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'ToolbarItem',
    props: {
        icon: String
    }
});
</script>
