export class ThirdPartyCustomerAllowDataProvider {
    provider: string;
    accessPeriodConfiguration: AccessPeriodConfiguration;
    farmManagerAccessConfiguration: FarmManagerAccessConfiguration;

    constructor(data: Partial<ThirdPartyCustomerAllowDataProvider>) {
        this.provider = data.provider;
        this.accessPeriodConfiguration = data.accessPeriodConfiguration;
        this.farmManagerAccessConfiguration = data.farmManagerAccessConfiguration;
    }
}

export class AccessPeriodConfiguration {
    limitedAccess: Boolean;
    periods: AccessPeriod[];

    constructor(data: Partial<AccessPeriodConfiguration>) {
        this.limitedAccess = data.limitedAccess;
        this.periods = data.periods;
    }
}

export class AccessPeriod {
    from: string;
    to: string;

    constructor(data: Partial<AccessPeriod>) {
        this.from = data.from;
        this.to = data.to;
    }
}

export class FarmManagerAccessConfiguration {
    limitedAccess: Boolean;
    farmers: string[];

    constructor(data: Partial<FarmManagerAccessConfiguration>) {
        this.limitedAccess = data.limitedAccess;
        this.farmers = data.farmers;
    }
}

export class ThirdPartySummary {
    readonly id: string;
    readonly name: string;
    readonly allowDataProviders: string[];

    constructor(data: Partial<ThirdPartySummary>) {
        this.id = data.id;
        this.name = data.name;
        this.allowDataProviders = data.allowDataProviders;
    }
}

export class ThirdParty {
    readonly id: string;
    name: string;
    apiKey: string;
    allowDataProviders: ThirdPartyCustomerAllowDataProvider[];

    constructor(data: Partial<ThirdParty>) {
        this.id = data.id;
        this.name = data.name;
        this.apiKey = data.apiKey;
        this.allowDataProviders = data.allowDataProviders;
    }
}

export class UsernameAndId {
    readonly id: string;
    readonly username: String;

    constructor(data: Partial<UsernameAndId>) {
        this.id = data.id;
        this.username = data.username;
    }
}
