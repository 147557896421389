<template>
  <Aside>
    <template v-if="!selectedTeam$" v-slot:content>
      <Title :title="trans.TEAM_INFO.TITLE"/>
      <div class="teamInfo__details">
        {{ trans.NO_TEAM }}
      </div>
    </template>
    <template v-else v-slot:content>
      <Title :title="trans.TEAM_INFO.TITLE">
        <template v-if="!isViewingAsGrower$">
        <UnderscriptIcon
        :icon="icons.edit"
        :underscript="trans.GENERAL.EDIT"
        :color="brandColors.PRIMARY"
        clickable
        @click="edit"
        />

        <v-tooltip top :disabled="hasEvoPortalAccess$">
        <template v-slot:activator="{ on, attrs }">
        <div v-on="on">
        <UnderscriptIcon
        v-if="!isTeamActive"
        :icon="icons.upload"
        :underscript="trans.GENERAL.UPLOAD"
        :color="hasEvoPortalAccess$ ? brandColors.PRIMARY : 'TeamInfo.aside.vuegrey'"
        :clickable="hasEvoPortalAccess$"
        @click="exportFarmTeam"
        />
        </div>
        </template>
        <span>{{ trans.EVO_PORTAL.UNAVAILABLE }}</span>
        </v-tooltip>

        <UnderscriptIcon
        v-if="isTeamActive"
        :icon="icons.stop"
        :underscript="trans.GENERAL.STOP"
        :color="brandColors.RED"
        clickable
        @click="archive"
        />
        <UnderscriptIcon
        v-if="!isTeamActive"
        :icon="icons.delete"
        :underscript="trans.GENERAL.DELETE"
        :color="brandColors.RED"
        clickable
        @click="deleteFarmTeam"
        />
        </template>
      </Title>
      <div class="teamInfo__details">
        <TextWithLabel v-if="farmManager" :label="trans.TEAM.FARM_MANAGER" :text="farmManager"/>
        <TextWithLabel v-if="grower" :label="trans.TEAM.GROWER" :text="grower"/>
        <TextWithLabel v-if="teamName" :label="trans.TEAM.TEAM_NAME" :text="teamName"/>
        <TextWithLabel v-if="farm" :label="trans.TEAM.FARM" :text="farm"/>
        <TextWithLabel v-if="year" :label="trans.TEAM.YEAR" :text="year"/>
        <TextWithLabel v-if="field" :label="trans.TEAM.FIELD" :text="field"/>
        <TextWithLabel v-if="crop" :label="trans.TEAM.CROP" :text="crop"/>
        <TextWithLabel v-if="operator" :label="trans.TEAM.OPERATOR" :text="operator"/>
      </div>
    </template>
  </Aside>
</template>

<script lang="ts">
import Vue from 'vue';
import Title from '@components/Title.component.vue';
import Aside from '@/layout/Aside.vue';
import TextWithLabel from '@components/TextWithLabel.component.vue';
import UnderscriptIcon from '@components/UnderscriptIcon.component.vue';
import TeamService from '@/team/team.service';
import {State} from '@/store';
import {mapState} from 'vuex';
import {Brand, BrandingService} from 'shared-frontend';
import {icons} from '@utils/icons.utils';
import ModalService from '@/modals/modal.service';
import {ModalTypeEnum} from '@/modals/modalType.enum';
import {ColorObj} from 'shared-frontend/dist/utils/colors.utils';
import EvoPortalService from '@services/evoPortal.service';
import {formatValue} from "@components/picker/Picker.helper";

export default Vue.extend({
  name: 'TeamInfo',
  components: {Aside, Title, UnderscriptIcon, TextWithLabel},
  computed: {
    ...mapState({
      trans: (state: State) => state.translation.translations
    }),
    icons(): { [iconName: string]: string } {
      return icons;
    },
    brandColors(): ColorObj {
      return BrandingService.getColors(process.env.VUE_APP_BRAND as Brand);
    },
    teamName(): string {
      return formatValue(this.trans.TEAM.TEAM_NAME, (this as any).selectedTeam$?.name, this.trans);
    },
    grower(): string {
      return formatValue(this.trans.TEAM.GROWER, (this as any).selectedTeam$?.grower, this.trans);
    },
    farm(): string {
      return formatValue(this.trans.TEAM.FARM, (this as any).selectedTeam$?.farm, this.trans);
    },
    year(): string {
      return formatValue(this.trans.TEAM.YEAR, (this as any).selectedTeam$?.year, this.trans);
    },
    field(): string {
      return formatValue(this.trans.TEAM.FIELD, (this as any).selectedTeam$?.field, this.trans);
    },
    crop(): string {
      return formatValue(this.trans.TEAM.CROP, (this as any).selectedTeam$?.crop, this.trans);
    },
    operator(): string {
      return formatValue(this.trans.TEAM.OPERATOR, (this as any).selectedTeam$?.operator, this.trans);
    },
    farmManager(): string {
      const farmMgrName = (this as any).selectedTeam$?.farmManager?.getFullName();
      return formatValue(this.trans.TEAM.FARM_MANAGER, farmMgrName, this.trans);
    },
    isTeamActive(): boolean {
      return (this as any).selectedTeam$?.isActive()
    }
  },
  methods: {
    edit(): void {
      ModalService.showModal(ModalTypeEnum.EDIT_TEAM_INFO);
    },
    archive(): void {
      ModalService.showModal(ModalTypeEnum.STOP_TEAM, {team: (this as any).selectedTeam$});
    },
    deleteFarmTeam(): void {
      // Can not be called delete because vue template doesn't compile otherwise
      ModalService.showModal(ModalTypeEnum.DELETE_FARM_TEAM, {team: (this as any).selectedTeam$});
    },
    exportFarmTeam(): void {
      ModalService.showModal(ModalTypeEnum.CONFIRM_UPLOAD_TO_EVO_PORTAL, {farmTeamId: (this as any).selectedTeam$?.id})
    }
  },
  subscriptions() {
    return {
      isViewingAsGrower$: TeamService.isViewingAsGrower$,
      selectedTeam$: TeamService.selectedTeamOrNull$,
      hasEvoPortalAccess$: EvoPortalService.hasEvoPortalAccess$
    };
  }
});
</script>
