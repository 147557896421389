import {concat, from, Observable} from 'rxjs';
import {map, pluck, scan} from 'rxjs/operators';

import {API_ENDPOINTS} from 'shared-frontend';
import httpService from '@services/http.service';
import {CreateFarmBaleServiceVehicle, FarmBaleServiceVehicle, FarmBaleServiceVehicleEvent, FarmBaleServiceVehiclesSseEvents} from '@/farmBaleServiceVehicle/farmBaleServiceVehicle.domain';
import EventSource from '@domain/eventSource.domain';
import {logger} from '@services/logger.service';
import {reduceFarmBaleServiceVehicleEvent} from '@/farmBaleServiceVehicle/farmBaleServiceVehicle.helper';
import {pick} from '@utils/functional.util';

class FarmBaleServiceVehicleService {
    public get farmBaleServiceVehicles$(): Observable<FarmBaleServiceVehicle[]> {
        return concat(this.fetchFarmBaleServiceVehicles$(), this.farmBaleServiceVehicleEvents$()).pipe(scan(reduceFarmBaleServiceVehicleEvent));
    }

    public fetchFarmBaleServiceVehicles$ = (): Observable<FarmBaleServiceVehicle[]> => {
        return from(httpService.get(API_ENDPOINTS.FARM_BALE_SERVICE_VEHICLES())).pipe(pluck('data'));
    };

    public farmBaleServiceVehicleEvents$(): Observable<FarmBaleServiceVehicleEvent> {
        const url = `${process.env.VUE_APP_BACKEND_URL}${API_ENDPOINTS.FARM_BALE_SERVICE_VEHICLES()}`;
        const eventTypes = Object.values(FarmBaleServiceVehiclesSseEvents);
        return EventSource.createObservable(url, eventTypes).pipe(map(pick('data')));
    }

    public async addFarmBaleServiceVehicle(createFarmBaleServiceVehicle: CreateFarmBaleServiceVehicle): Promise<string> {
        const newFarmBaleServiceVehicle = await httpService.post(API_ENDPOINTS.FARM_BALE_SERVICE_VEHICLES(), createFarmBaleServiceVehicle);
        logger.info('[farmBaleServiceVehicles.service] - addFarmBaleServiceVehicle: farmBaleServiceVehicle ADDED, ID: ', newFarmBaleServiceVehicle.data);
        return newFarmBaleServiceVehicle.data;
    }

    public async deleteFarmBaleServiceVehicle(farmBaleServiceVehicle: FarmBaleServiceVehicle): Promise<void> {
        await httpService.delete(API_ENDPOINTS.MY_FARMMANAGER_BALE_SERVICE_VEHICLE(farmBaleServiceVehicle.baleServiceVehicleId));
        logger.info('[farmBaleServiceVehicles.service] - deleteFarmBaleServiceVehicle: farmBaleServiceVehicle DELETED');
    }
}

export default new FarmBaleServiceVehicleService();
