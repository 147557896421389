<template>
  <div class="advice-history">
    <h4 class="advice-history__title">{{ translations.APDT_ADVICE.PREV_ADVICES }}</h4>

    <div class="advice-history__grid" v-if="adviceHistory.length">
      <div class="advice-history__row header">
        <span class="advice-history__col iconcol"/>
        <span class="advice-history__col">
                    {{ translations.APDT_ADVICE.PREV_ADVICES_TABLE.ACTION }}
                </span>
        <span class="advice-history__col">
                    {{ translations.APDT_ADVICE.PREV_ADVICES_TABLE.CREATED }}
                </span>
      </div>

      <div class="advice-history__row content" v-for="advice in adviceHistory" :key="advice.id"
           @click="historyClicked(advice)">
        <v-avatar class="advice-history__col" tile
                            height="100"
                            width="100"
                            max-height="100"
                            max-width="100"
        >
          <v-img v-if="advice.thumbnailUrl" :src="advice.thumbnailUrl"/>
        </v-avatar>

        <span class="advice-history__col">
                    {{ translations.APDT_ADVICE.ACTIONS[advice.action] }}
                </span>
        <span class="advice-history__col">
                    {{ advice.creationDate.toLocaleString() }}
                </span>
      </div>
    </div>

    <p v-if="!adviceHistory.length && !loading">
      {{ translations.APDT_ADVICE.NO_PREV_ADVICES }}
    </p>
  </div>
</template>

<script>
import {getAdviceHistoryForBoundary} from "@/apdtAdvice/Advice.service";
import {mapState} from "vuex";

export default {
  name: "AdviceContentHistory.component",
  components: {},
  props: {
    farmBoundaryId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      adviceHistory: [],
      loading: false
    };
  },
  created() {
    this.fetchHistory();
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    })
  },
  methods: {
    async fetchHistory() {
      this.loading = true;
      this.adviceHistory = await getAdviceHistoryForBoundary(this.farmBoundaryId);
      this.loading = false;
    },
    historyClicked(advice) {
      this.$emit("advice-selected", advice);
    }
  },
  watch: {
    farmBoundaryId() {
      this.fetchHistory();
    }
  }
};
</script>

<style scoped></style>
