// INTERNAL
import {Setoid} from '@utils/functional.util';
import {LocationJSON} from 'shared-frontend';

export interface FarmBaleServiceVehicle {
    baleServiceVehicleId: string;
    baleServiceVehicleName: string;
    icon: string;
};

export interface FarmBaleServiceVehicleInfo {
    [baleServiceVehicleId: string]: {
        baleServiceVehicleName: string;
        icon: string;
        baleServiceVehicleReports: string[];
        startedOn: string;
        stoppedOn: string;
        location: LocationJSON;
    }
};

export interface CreateFarmBaleServiceVehicle {
    baleServiceVehicleName: string;
    icon: string;
}

export const eqFarmBaleServiceVehicle: Setoid<FarmBaleServiceVehicle> = {
    equals: (x: FarmBaleServiceVehicle, y: FarmBaleServiceVehicle) => x?.baleServiceVehicleId === y?.baleServiceVehicleId
}

// FarmBaleServiceVehicle events
// =================

export enum FarmBaleServiceVehiclesSseEvents {
    FARM_BALE_SERVICE_VEHICLE_EVENT = 'farmBaleServiceVehicleEvent'
}

export enum FarmBaleServiceVehicleEventTypes {
    FARM_BALE_SERVICE_VEHICLE_CREATED = 'farmBaleServiceVehicleCreated',
    FARM_BALE_SERVICE_VEHICLE_UPDATED = 'farmBaleServiceVehicleUpdated',
    FARM_BALE_SERVICE_VEHICLE_REMOVED = 'farmBaleServiceVehicleRemoved'
}

interface FarmBaleServiceVehicleEventBase {
    type: FarmBaleServiceVehicleEventTypes;
    farmMgrId: string;
    baleServiceVehicleId: string;
    eventTime: string;
}

export interface FarmBaleServiceVehicleCreatedEvent extends FarmBaleServiceVehicleEventBase {
    type: FarmBaleServiceVehicleEventTypes.FARM_BALE_SERVICE_VEHICLE_CREATED;
    farmBaleServiceVehicle: FarmBaleServiceVehicle;
}

export interface FarmBaleServiceVehicleRemovedEvent extends FarmBaleServiceVehicleEventBase {
    type: FarmBaleServiceVehicleEventTypes.FARM_BALE_SERVICE_VEHICLE_REMOVED;
}

export interface FarmBaleServiceVehicleUpdatedEvent extends FarmBaleServiceVehicleEventBase {
    type: FarmBaleServiceVehicleEventTypes.FARM_BALE_SERVICE_VEHICLE_UPDATED;
    farmBaleServiceVehicle: FarmBaleServiceVehicle;
}

export type FarmBaleServiceVehicleEvent = FarmBaleServiceVehicleCreatedEvent | FarmBaleServiceVehicleRemovedEvent | FarmBaleServiceVehicleUpdatedEvent;
