import {FarmTeamSummary} from '@/farmTeam/farmTeam.domain';
import {AutoCompleteItem, filterApplies} from '@components/picker/Picker.helper';
import {pick, toUnique} from '@utils/functional.util';
import {sortAlphabeticallyBy, sortByMostRecent} from '@/farmTeam/farmTeam.helper';

export const uniqueTeamNames = (farmTeams: FarmTeamSummary[], alphabetically: boolean, grower: AutoCompleteItem, farm: AutoCompleteItem, year: AutoCompleteItem, field: AutoCompleteItem, crop: AutoCompleteItem): string[] => {
    if (!farmTeams?.length) return [];
    return toUnique(
        farmTeams.slice()
            .filter(filterApplies({growerName: grower?.value, farmName: farm?.value, year: year?.valueAsNumber, fieldName: field?.value, cropName: crop?.value}))
            .sort(alphabetically ? sortAlphabeticallyBy('teamName') : sortByMostRecent)
            .map(pick('teamName'))
    );
}
