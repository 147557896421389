<template>
  <div class="new-farm-bALE_SERVICE_VEHICLE__container">
    <v-form ref="formAddBaleServiceVehicle" @submit.prevent="addBaleServiceVehicle">
      <v-text-field
          v-model="baleServiceVehicle.name"
          :label="translations.MODALS.ADD_BALE_SERVICE_VEHICLE.FORM.NAME_LABEL"
          :rules="formRules.baleServiceVehicleName"
          autofocus
          required
      />

      <p class="mt-3 body-2 font-weight-light">{{ translations.MODALS.ADD_BALE_SERVICE_VEHICLE.FORM.BALE_SERVICE_VEHICLE_TYPE_LABEL }}</p>

      <v-radio-group v-model="baleServiceVehicle.icon.baleServiceVehicleType">
        <v-radio
            v-for="baleServiceVehicleType in baleServiceVehicleTypes"
            :key="baleServiceVehicleType"
            :label="`${translations.MODALS.ADD_BALE_SERVICE_VEHICLE.FORM.BALE_SERVICE_VEHICLE_TYPES[baleServiceVehicleType]}`"
            :value="baleServiceVehicleType"
            color="primary"
        >
          <template v-slot:label>
            <MapIcon :custom-width="100" :field-entity="getBaleServiceVehicleOfType(baleServiceVehicleType)"/>
            <span class="ml-2">{{ translations.MODALS.ADD_BALE_SERVICE_VEHICLE.FORM.BALE_SERVICE_VEHICLE_TYPES[baleServiceVehicleType] }}</span>
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mt-3 body-2 font-weight-light">{{ translations.MODALS.ADD_BALE_SERVICE_VEHICLE.FORM.BALE_SERVICE_VEHICLE_COLOR_LABEL }}</p>

      <ColorSelect :colors="baleServiceVehicleColors" :initialValue="initialBaleServiceVehicleColor" @colorChange="colorChangeHandler"/>

      <p v-if="errorMessage" class="new-workforce-bin__error">{{ errorMessage }}</p>

      <div class="form__actions">
        <v-btn v-show="!loading" color="error" text @click="$emit('close')">{{
            translations.GENERAL.CANCEL
          }}
        </v-btn>
        <v-btn :loading="loading" color="primary" dark type="submit">{{
            translations.MODALS.ADD_BALE_SERVICE_VEHICLE.FORM.CONFIRM_BUTTON
          }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import {mapState} from 'vuex';

import {BaleServiceVehicle, BaleServiceVehicleIcon, BaleServiceVehicleTypeEnum, FieldEntityEnum, IconColors, IconService} from 'shared-frontend';
import FarmBaleServiceVehicleService from '@/farmBaleServiceVehicle/farmBaleServiceVehicle.service';
import {logger} from '@services/logger.service';
import MapIcon from '@/map/icons/MapIcon.icon.vue';
import ColorSelect from '@components/ColorSelect.component.vue';
import {baleServiceVehicleToCreateFarmBaleServiceVehicle} from "@/farmBaleServiceVehicle/farmBaleServiceVehicle.helper";

export default {
  name: 'NewFarmBaleServiceVehicle',
  components: {
    MapIcon,
    ColorSelect
  },
  model: {
    prop: 'show',
    event: 'dismiss'
  },
  data() {
    return {
      baleServiceVehicle: new BaleServiceVehicle(null, null, null, null, new BaleServiceVehicleIcon(this.initialBaleServiceVehicleColor, null), null, null, null),
      showModal: false,
      loading: false,
      errorMessage: '',
      formRules: {
        baleServiceVehicleName: [
          v => !!v || this.translations.MODALS.ADD_BALE_SERVICE_VEHICLE.FORM.NAME_ERROR_REQUIRED,
          v => (v && v.length <= 50) || this.translations.MODALS.ADD_BALE_SERVICE_VEHICLE.FORM.NAME_ERROR_LENGTH
        ]
      }
    };
  },
  computed: {
    ...mapState({
      translations: state => state.translation.translations
    }),
    baleServiceVehicleTypes() {
      return Object.values(BaleServiceVehicleTypeEnum);
    },
    baleServiceVehicleColors() {
      return IconService.getIconColors(FieldEntityEnum.BALE_SERVICE_VEHICLE) || {};
    },
    initialBaleServiceVehicleColor() {
      return IconColors.RED;
    }
  },
  props: {
    show: Boolean
  },
  methods: {
    getBaleServiceVehicleOfType(baleServiceVehicleType) {
      const baleServiceVehicleIcon = new BaleServiceVehicleIcon(this.baleServiceVehicle.icon.color, baleServiceVehicleType);
      return new BaleServiceVehicle(null, null, null, 'dummy', baleServiceVehicleIcon, null, null, null);
    },
    async addBaleServiceVehicle() {
      if (this.loading) return;

      const isValid = this.$refs.formAddBaleServiceVehicle.validate();

      if (!isValid) {
        return;
      }

      this.loading = true;

      try {
        const createFarmBaleServiceVehicle = baleServiceVehicleToCreateFarmBaleServiceVehicle(this.baleServiceVehicle);
        await FarmBaleServiceVehicleService.addFarmBaleServiceVehicle(createFarmBaleServiceVehicle);
      } catch (e) {
        this.errorMessage = this.translations.ERRORS.GENERAL;
        logger.error('[NewFarmBaleServiceVehicle.modal] - addBaleServiceVehicle: Error:  ', e);
      } finally {
        this.loading = false;
        this.$emit('close');
      }
    },
    colorChangeHandler(colorLabel) {
      this.baleServiceVehicle.icon.color = colorLabel;
    }
  }
};
</script>
