import {FarmTeamSummary} from '@/farmTeam/farmTeam.domain';
import {AutoCompleteItem, filterApplies} from '@components/picker/Picker.helper';
import {pick, toUnique} from '@utils/functional.util';
import {sortAlphabeticallyBy, sortByMostRecent} from '@/farmTeam/farmTeam.helper';

export const uniqueFarms = (farmTeams: FarmTeamSummary[], alphabetically: boolean, grower: AutoCompleteItem): string[] => {
    if (!farmTeams?.length) return [];
    return toUnique(farmTeams.slice()
        .filter(filterApplies({growerName: grower?.value}))
        .sort(alphabetically ? sortAlphabeticallyBy('farmName') : sortByMostRecent)
        .map(pick('farmName')));
}
