<template>
  <div class="espCams" v-if="selectedTeam$">
    <div class="espCams__retry">
      <span v-if="error" class="espCams__error">Failed to retrieve cameras</span>
      <span v-else/>
      <v-icon size="15" color="primary" @click="refresh()">{{ icons.retry }}</v-icon>
    </div>
    <v-progress-circular v-if="loading && !error" />
    <div v-else-if="!error">
      <div
          v-if="espCams.length === 0"
          class="espCams__noCams"
      >
        {{ translations.MODALS.ESP_CAMS.NO_ESP_CAMS }}
      </div>
      <div v-else>
        <v-select
            class="mb-3"
            :items="items"
            v-model="selectedId"
            :label="translations.MODALS.ESP_CAMS.FORM.ESP_CAM.LABEL"
            required
        >
        </v-select>

        <template v-if="selected">
          <img
              ref="image"
              v-if="selected.tunnelBaseUrl"
              class="espCams__stream"
              :src="streamUrl"
          />
          <v-progress-circular v-else />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import {mapState} from 'vuex'
import {State} from '@/store'
import EspCamService from './espCam.service'
import TeamService from '@/team/team.service'
import {Combine, EspCam, FieldEntity, Spfh} from 'shared-frontend'
import {icons} from '@utils/icons.utils';
import {logger} from '@services/logger.service';
import sleep from '@utils/sleep.util';
import {EspCamId} from 'shared-frontend/dist/espCam/espCam.domain'

const SEND_TUNNEL_HEARTBEAT_INTERVAL = 10_000;

export default Vue.extend({
  name: "EspCams",
  props: {
    params: Object
  },
  data() {
    return {
      selectedId: null,
      espCams: null,
      intervalId: null,
      error: null,
    }
  },
  async created() {
    await (this as any).getEspCams();
    (this as any).selectFirstEspCam();

    (this as any).intervalId = setInterval(async () => {
      if ((this as any).selectedId) {
        await (this as any).trySendTunnelHeartbeat();
      }
    }, SEND_TUNNEL_HEARTBEAT_INTERVAL)
  },
  beforeDestroy() {
    // By default, the image loading doesn't get canceled. This hack fixes this.
    // This is to ensure that the flash light behaves correctly.
    const image = (this as any).$refs.image
    if (image) {
      image.src = '';
    }
  },
  destroyed() {
    clearInterval((this as any).intervalId)
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    fieldEntity(): FieldEntity {
      return this.params?.fieldEntity;
    },
    loading(): boolean {
      return (this as any).espCams === null;
    },
    selected(): EspCam | null {
      return (this as any).espCams?.find(espCam => espCam.id === (this as any).selectedId) ?? null
    },
    streamUrl(): string | null {
      const selected = this.selected as EspCam | null;
      if (selected) {
        return EspCamService.getStreamUrl(selected);
      } else {
        return null;
      }
    },
    sortedEspCams(): EspCam[] {
      return [...((this as any).espCams.sort((a, b) => a.mac.localeCompare(b.mac)))]
    },
    items() {
      return (this.sortedEspCams as EspCam[]).map(espCam => {
        return {
          text: this.translations.MODALS.ESP_CAMS.FORM.ESP_CAM.ITEM(espCam.mac),
          value: espCam.id
        }
      })
    },
    icons() {
      return icons;
    }
  },
  methods: {
    async getEspCams() {
      try {
        const team = (this as any).selectedTeam$
        if (this.fieldEntity instanceof Combine) {
          (this as any).espCams = await EspCamService.getEspCamsForCombine(team.farmManagerId, team.id, this.fieldEntity.id)
        } else if (this.fieldEntity instanceof Spfh) {
          (this as any).espCams = await EspCamService.getEspCamsForSpfh(team.farmManagerId, team.id, this.fieldEntity.id)
        } else {
          throw Error('Unrecognized entity');
        }
      } catch (e) {
        (this as any).error = this.translations.MODALS.ESP_CAMS.ERROR_GET_ESP_CAMS;
        throw e
      }
    },
    async trySendTunnelHeartbeat() {
      try {
        // Because this is async and selectedId can have changed in the mean time
        const selectedId = (this as any).selectedId;
        const team = (this as any).selectedTeam$;
        let tunnelBaseUrl: string;
        if (this.fieldEntity instanceof Combine) {
          tunnelBaseUrl = await EspCamService.registerTunnelHeartbeatForCombine(team.farmManagerId, team.id, this.fieldEntity.id, (this as any).selectedId);
        } else if (this.fieldEntity instanceof Spfh) {
          tunnelBaseUrl = await EspCamService.registerTunnelHeartbeatForSpfh(team.farmManagerId, team.id, this.fieldEntity.id, (this as any).selectedId);
        } else {
          throw Error('Unrecognized entity');
        }
        (this as any).setTunnelBaseUrl(selectedId, tunnelBaseUrl);
      } catch (e) {
        logger.error(`Failed to send heartbeat ${e}`);
        await sleep(2_000);
        (this as any).refresh();
      }
    },
    setTunnelBaseUrl(id: EspCamId, tunnelBaseUrl: string | null) {
      (this as any).espCams = (this as any).espCams.map((espCam: EspCam) => espCam.id === id ? espCam.copy({tunnelBaseUrl}) : espCam);
    },
    async refresh() {
      (this as any).error = null;
      // A very soft reset, might not be enough
      const espCamsBeforeReset = (this as any).espCams;

      (this as any).espCams = null;
      await (this as any).getEspCams();

      const selectedStillPresent = (this as any).espCams.some(espCam => espCam.id === (this as any).selectedId);
      if (espCamsBeforeReset.length === 0) {
        (this as any).selectFirstEspCam();
      } else if (!selectedStillPresent) {
        (this as any).selectedId = null;
      }
    },
    selectFirstEspCam() {
      if ((this as any).sortedEspCams.length > 0) {
        (this as any).selectedId = this.sortedEspCams[0].id;
      }
    }
  },
  subscriptions() {
    return {
      selectedTeam$: TeamService.selectedTeamOrNull$
    }
  },
  watch: {
    selectedId: {
      immediate: true,
      handler(value) {
        if (value) {
          // Tunnel of the camera might have died in the mean time. Just assume that it died and register heartbeat.
          (this as any).setTunnelBaseUrl(value, null);
          (this as any).trySendTunnelHeartbeat();
        }
      }
    },
    streamUrl() {
      // By default, the image loading of the previous cam doesn't get canceled. This hack fixes this. This is to ensure that the flash light behaves correctly.
      const image = (this as any).$refs.image
      if (image && this.streamUrl) {
        image.src = this.streamUrl;
      }
    }
  }
})
</script>

<style scoped>

</style>
