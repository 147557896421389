// INTERNAL
// Shared
import {Baler, Bin, Combine, FarmBoundary, FarmBoundaryId, FieldEntity, Gate, Marker, MarkerType, Spfh, Team} from 'shared-frontend';
import {Modal} from '@domain/modal.domain';
import {WorkForceMember} from '@/workforce/workForceMember.domain';
import GateService from '@services/./gate.service';
import WorkForceService from '@/workforce/workforce.service';
import FarmBinService from '@/farmBin/farmBin.service';
import FarmBaleServiceVehicleService from '@/farmBaleServiceVehicle/farmBaleServiceVehicle.service';
import ActiveTeamService from '@/activeTeam/activeTeam.service';
import FarmTeamService from '@/farmTeam/farmTeam.service';
import {ModalTypeEnum} from './modalType.enum';
import store, {Mutations} from '../store';
import {Grower} from '@/grower/grower.domain';
import {FarmBin} from '@/farmBin/farmBin.domain';
import {FarmTeamId} from "@/farmTeam/farmTeam.domain";
import {FarmBaleServiceVehicle} from "@/farmBaleServiceVehicle/farmBaleServiceVehicle.domain";

type Params = {
    user?: WorkForceMember;
    bin?: Bin;
    farmBin?: FarmBin;
    farmBaleServiceVehicle?: FarmBaleServiceVehicle;
    combine?: Combine;
    spfh?: Spfh;
    baler?: Baler;
    teamState?: Team;
    gate?: Gate;
    team?: Team;
    fuelLevelThreshold__fraction?: number;
    fieldEntity?: FieldEntity;
    farmBoundary?: FarmBoundary;
    marker?: Marker;
    farmBoundaryId?: FarmBoundaryId;
    farmTeamId?: FarmTeamId;
    onConfirm?: () => Promise<void>;
    link?: boolean;
    grower?: Grower;
};

const onConfirmWithCatchError = (fn: () => Promise<void>) => () => {
    fn().catch((_) => new ModalService().showModal(ModalTypeEnum.ERROR));
};

class ModalService {
    public showModal(modalType: ModalTypeEnum, params: Params = {}): void {
        const {translations} = store.state.translation;
        const {
            user,
            bin,
            farmBin,
            farmBaleServiceVehicle,
            combine,
            spfh,
            baler,
            teamState,
            gate,
            team,
            fuelLevelThreshold__fraction,
            fieldEntity,
            farmBoundary,
            marker,
            farmBoundaryId,
            farmTeamId,
            onConfirm,
            link,
            grower
        } = params;

        let modal;

        switch (modalType) {
            case ModalTypeEnum.ERROR:
                modal = {
                    title: translations.ERRORS.GENERAL_TITLE,
                    message: translations.ERRORS.GENERAL,
                    hasCancelButton: false,
                    hasConfirmButton: true,
                    cancelButtonText: translations.GENERAL.CANCEL,
                    confirmButtonText: translations.GENERAL.CONFIRM,
                    color: 'red'
                };
                break;

            case ModalTypeEnum.GEOLOCATION_FAILED:
                modal = {
                    title: translations.GEOLOCATION_FAILED.TITLE,
                    message: translations.GEOLOCATION_FAILED.MESSAGE,
                    hasCancelButton: false,
                    hasConfirmButton: true,
                    cancelButtonText: translations.GENERAL.CANCEL,
                    confirmButtonText: translations.GENERAL.CONFIRM,
                    color: 'red'
                };
                break;

            case ModalTypeEnum.SETTINGS:
                modal = {
                    title: translations.MODALS.SETTINGS.TITLE,
                    hasCancelButton: false,
                    hasConfirmButton: false,
                    formComponent: 'Settings'
                };
                break;

            case ModalTypeEnum.ADD_USER:
                modal = {
                    title: translations.MODALS.ADD_USER.TITLE,
                    hasCancelButton: false,
                    hasConfirmButton: false,
                    formComponent: 'NewWorkForceMember'
                };
                break;

            case ModalTypeEnum.ADD_BIN:
                modal = {
                    title: translations.MODALS.ADD_BIN.TITLE,
                    hasCancelButton: false,
                    hasConfirmButton: false,
                    formComponent: 'NewFarmBin'
                };
                break;

            case ModalTypeEnum.ADD_BALE_SERVICE_VEHICLE:
                modal = {
                    title: translations.MODALS.ADD_BALE_SERVICE_VEHICLE.TITLE,
                    hasCancelButton: false,
                    hasConfirmButton: false,
                    formComponent: 'NewFarmBaleServiceVehicle'
                };
                break;

            case ModalTypeEnum.ADD_SERVICE_VEHICLE:
                modal = {
                    title: translations.MODALS.ADD_SERVICE_VEHICLE.TITLE,
                    hasCancelButton: false,
                    hasConfirmButton: false,
                    formComponent: 'NewFarmServiceVehicle'
                };
                break;

            case ModalTypeEnum.CONFIRM_DELETE_USER:
                modal = {
                    title: `${translations.MODALS.CONFIRM_DELETE_USER.TITLE}: ${user.name}`,
                    message: translations.MODALS.CONFIRM_DELETE_USER.MESSAGE,
                    hasCancelButton: true,
                    hasConfirmButton: true,
                    cancelButtonText: translations.GENERAL.CANCEL,
                    confirmButtonText: translations.GENERAL.CONFIRM,
                    onConfirm: onConfirmWithCatchError(() => WorkForceService.deleteUser(user)),
                    color: 'red'
                };
                break;

            case ModalTypeEnum.CONFIRM_DELETE_BIN:
                modal = {
                    title: `${translations.MODALS.CONFIRM_DELETE_BIN.TITLE}: ${farmBin.binName}`,
                    message: translations.MODALS.CONFIRM_DELETE_BIN.MESSAGE,
                    hasCancelButton: true,
                    hasConfirmButton: true,
                    cancelButtonText: translations.GENERAL.CANCEL,
                    confirmButtonText: translations.GENERAL.CONFIRM,
                    onConfirm: onConfirmWithCatchError(() => FarmBinService.deleteFarmBin(farmBin)),
                    color: 'red'
                };
                break;

            case ModalTypeEnum.CONFIRM_DELETE_BALE_SERVICE_VEHICLE:
                modal = {
                    title: `${translations.MODALS.CONFIRM_DELETE_BALE_SERVICE_VEHICLE.TITLE}: ${farmBaleServiceVehicle.baleServiceVehicleName}`,
                    message: translations.MODALS.CONFIRM_DELETE_BALE_SERVICE_VEHICLE.MESSAGE,
                    hasCancelButton: true,
                    hasConfirmButton: true,
                    cancelButtonText: translations.GENERAL.CANCEL,
                    confirmButtonText: translations.GENERAL.CONFIRM,
                    onConfirm: onConfirmWithCatchError(() => FarmBaleServiceVehicleService.deleteFarmBaleServiceVehicle(farmBaleServiceVehicle)),
                    color: 'red'
                };
                break;

            case ModalTypeEnum.CREATE_GATE:
                modal = {
                    title: translations.MODALS.CREATE_GATE.TITLE,
                    message: translations.MODALS.CREATE_GATE.MESSAGE,
                    hasCancelButton: true,
                    hasConfirmButton: true,
                    cancelButtonText: translations.GENERAL.CANCEL,
                    confirmButtonText: translations.GENERAL.CONFIRM,
                    onConfirm: onConfirmWithCatchError(() => GateService.addGate(teamState, gate))
                };
                break;

            case ModalTypeEnum.DELETE_GATE:
                modal = {
                    title: translations.MODALS.DELETE_GATE.TITLE,
                    message: translations.MODALS.DELETE_GATE.MESSAGE,
                    hasCancelButton: true,
                    hasConfirmButton: true,
                    cancelButtonText: translations.GENERAL.CANCEL,
                    confirmButtonText: translations.GENERAL.CONFIRM,
                    onConfirm: onConfirmWithCatchError(() => GateService.deleteGate(teamState, gate.id)),
                    color: 'red'
                };
                break;

            case ModalTypeEnum.EDIT_USER:
                modal = {
                    title: `${translations.MODALS.EDIT_USER.TITLE}: ${user.name}`,
                    hasCancelButton: false,
                    hasConfirmButton: false,
                    formComponent: 'EditUser',
                    params: {user}
                };
                break;

            case ModalTypeEnum.TELEMETRY:
                modal = {
                    title: `${translations.TELEMETRY.TITLE}`,
                    hasCancelButton: false,
                    hasConfirmButton: false,
                    formComponent: 'Telemetry',
                    params: {fieldEntity}
                };
                break;

            case ModalTypeEnum.STOP_TEAM:
                modal = {
                    title: translations.MODALS.STOP_TEAM.TITLE(team.name),
                    message: translations.MODALS.STOP_TEAM.MESSAGE,
                    hasCancelButton: true,
                    hasConfirmButton: true,
                    cancelButtonText: translations.GENERAL.CANCEL,
                    confirmButtonText: translations.GENERAL.CONFIRM,
                    onConfirm: onConfirmWithCatchError(() => ActiveTeamService.stopTeam(team.id)),
                    color: 'red'
                };
                break;

            case ModalTypeEnum.DELETE_FARM_TEAM:
                modal = {
                    title: translations.MODALS.DELETE_TEAM.TITLE(team.name),
                    message: translations.MODALS.DELETE_TEAM.MESSAGE,
                    hasCancelButton: true,
                    hasConfirmButton: true,
                    cancelButtonText: translations.GENERAL.CANCEL,
                    confirmButtonText: translations.GENERAL.CONFIRM,
                    onConfirm: onConfirmWithCatchError(() => FarmTeamService.deleteFarmTeam(team.id)),
                    color: 'red'
                };
                break;

            case ModalTypeEnum.COMBINE_LOW_FUEL:
                const combineFuelLevelFractionAsPerc = fuelLevelThreshold__fraction
                    ? fuelLevelThreshold__fraction * 100
                    : null;

                modal = {
                    title: translations.MODALS.COMBINE_FUEL_LOW.TITLE,
                    message: translations.MODALS.COMBINE_FUEL_LOW.MESSAGE(combineFuelLevelFractionAsPerc, combine.name),
                    hasCancelButton: false,
                    hasConfirmButton: true,
                    confirmButtonText: translations.GENERAL.CONFIRM
                };
                break;

            case ModalTypeEnum.SPFH_LOW_FUEL:
                const spfhFuelLevelFractionAsPerc = fuelLevelThreshold__fraction
                    ? fuelLevelThreshold__fraction * 100
                    : null;

                modal = {
                    title: translations.MODALS.SPFH_FUEL_LOW.TITLE,
                    message: translations.MODALS.SPFH_FUEL_LOW.MESSAGE(spfhFuelLevelFractionAsPerc, spfh.name),
                    hasCancelButton: false,
                    hasConfirmButton: true,
                    confirmButtonText: translations.GENERAL.CONFIRM
                };
                break;

            case ModalTypeEnum.BALER_LOW_FUEL:
                const balerFuelLevelFractionAsPerc = fuelLevelThreshold__fraction
                    ? fuelLevelThreshold__fraction * 100
                    : null;

                modal = {
                    title: translations.MODALS.BALER_FUEL_LOW.TITLE,
                    message: translations.MODALS.BALER_FUEL_LOW.MESSAGE(balerFuelLevelFractionAsPerc, baler.name),
                    hasCancelButton: false,
                    hasConfirmButton: true,
                    confirmButtonText: translations.GENERAL.CONFIRM
                };
                break;

            case ModalTypeEnum.FIELD_REPORT:
                modal = {
                    title: `Field Report`,
                    hasCancelButton: false,
                    hasConfirmButton: false,
                    formComponent: 'FieldReport'
                };
                break;

            case ModalTypeEnum.COMING_SOON:
                modal = {
                    title: translations.MODALS.COMING_SOON.TITLE,
                    message: translations.MODALS.COMING_SOON.MESSAGE,
                    hasCancelButton: false,
                    hasConfirmButton: true,
                    confirmButtonText: translations.GENERAL.CONFIRM
                };
                break;

            case ModalTypeEnum.CONFIRM_DELETE_FARM_BOUNDARY:
                modal = {
                    title: `${translations.MODALS.CONFIRM_DELETE_FARM_BOUNDARY.TITLE}: ${farmBoundary.name}`,
                    message: translations.MODALS.CONFIRM_DELETE_FARM_BOUNDARY.MESSAGE,
                    hasCancelButton: true,
                    hasConfirmButton: true,
                    cancelButtonText: translations.GENERAL.CANCEL,
                    confirmButtonText: translations.GENERAL.CONFIRM,
                    onConfirm: onConfirmWithCatchError(onConfirm),
                    color: 'red'
                };
                break;

            case ModalTypeEnum.EDIT_TEAM_INFO:
                modal = {
                    title: translations.MODALS.EDIT_TEAM_INFO.TITLE,
                    hasCancelButton: false,
                    hasConfirmButton: false,
                    formComponent: 'EditTeamInfo'
                };
                break;

            case ModalTypeEnum.CONFIRM_IMPORT_FROM_EVO_PORTAL:
                modal = {
                    title: translations.MODALS.CONFIRM_IMPORT_FROM_EVO_PORTAL.TITLE,
                    hasCancelButton: false,
                    hasConfirmButton: false,
                    formComponent: 'ConfirmImportFromEvoPortal'
                };
                break;

            case ModalTypeEnum.CONFIRM_UPLOAD_TO_EVO_PORTAL:
                modal = {
                    title: translations.MODALS.CONFIRM_UPLOAD_TO_EVO_PORTAL.TITLE,
                    hasCancelButton: false,
                    hasConfirmButton: false,
                    formComponent: 'ConfirmUploadToEvoPortal',
                    params: {farmBoundaryId, farmTeamId}
                };
                break;

            case ModalTypeEnum.MARKER:
                modal = {
                    title: marker.type === MarkerType.PICTURE
                        ? translations.MODALS.MARKER.TITLE.PICTURE
                        : translations.MODALS.MARKER.TITLE.OBSTACLE,
                    hasCancelButton: false,
                    hasConfirmButton: false,
                    formComponent: 'ViewMarker',
                    params: {marker}
                };
                break;

            case ModalTypeEnum.LINK_EDIT_GROWER:
                const {linkedTo, growerName} = grower;
                const name = linkedTo ? `${linkedTo.firstName} ${linkedTo.lastName}` : growerName;
                const title = `${translations.MODALS.LINK_EDIT_GROWER[link ? 'TITLE_LINK' : 'TITLE_EDIT']}: ${name}`;

                modal = {
                    title,
                    hasCancelButton: false,
                    hasConfirmButton: false,
                    formComponent: 'LinkEditGrower',
                    params: {link, grower}
                };
                break;

            case ModalTypeEnum.CONFIRM_UNLINK_GROWER:
                const gName = `${grower.linkedTo.firstName} ${grower.linkedTo.lastName}`
                modal = {
                    title: `${translations.MODALS.CONFIRM_UNLINK_GROWER.TITLE}: ${gName}`,
                    message: translations.MODALS.CONFIRM_UNLINK_GROWER.MESSAGE,
                    hasCancelButton: true,
                    hasConfirmButton: true,
                    cancelButtonText: translations.GENERAL.CANCEL,
                    confirmButtonText: translations.GENERAL.CONFIRM,
                    onConfirm: onConfirmWithCatchError(onConfirm),
                    color: 'red'
                };
                break

            case ModalTypeEnum.ESP_CAMS:
                modal = {
                    title: `${translations.MODALS.ESP_CAMS.TITLE}`,
                    hasCancelButton: false,
                    hasConfirmButton: false,
                    formComponent: 'EspCams',
                    params: {fieldEntity}
                };
                break;
        }

        store.commit(Mutations.UPDATE_MODAL_SHOWN, new Modal(modal));
    }
}

export default new ModalService();
