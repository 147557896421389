// INTERNAL
// Shared
import { API_ENDPOINTS, Gate, Team } from 'shared-frontend';
//
// Services
import httpService from '@services/http.service';
import { logger } from '@services/logger.service';

class GateService {
    public async addGate(team: Team, gate: Gate): Promise<void> {
        const { location } = gate;

        const payload = {
            lon: location.longitude,
            lat: location.latitude
        };

        const newGate = await httpService.post(API_ENDPOINTS.GATES(team.farmManagerId, team.id), payload);

        logger.info('[gate.service.ts] - addGate: GATE ADDED, with ID: ', newGate.data);

        return newGate.data;
    }

    public async deleteGate(team: Team, gateId: string): Promise<void> {
        await httpService.delete(API_ENDPOINTS.GATE(team.farmManagerId, team.id, gateId));

        logger.info('[gate.service.ts] - deleteGate: GATE DELETED');
    }
}

export default new GateService();
