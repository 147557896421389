<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :rules="rules"
          v-model="dateFormatted"
          :label="label"
          readonly
          v-bind="attrs"
          v-on="on"
          outlined
          @blur="date = parseDate(dateFormatted)"
          :disabled="disabled"
      />
    </template>
    <v-date-picker
        v-model="date"
        no-title
        scrollable
    >
      <v-spacer></v-spacer>
      <v-btn
          text
          color="primary"
          @click="menu = false"
      >
        Cancel
      </v-btn>
      <v-btn
          text
          color="primary"
          @click="$refs.menu.save(date); menu = false"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>

export default {
  name: "DateInput.component",
  props: {
    label: String,
    value: [Object, String],
    disabled: Boolean,
    rules: []
  },
  data: (instance) => {
    return {
      date: instance.value ? instance.value : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: instance.formatDate(instance.value ? instance.value : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      menu: false
    };
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
      this.$emit("input", this.date);
    }
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  mounted() {
    this.$emit("input", this.date);
  }
};
</script>

<style scoped>

</style>
