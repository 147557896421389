<template>
  <v-list-item>
    <v-combobox
        v-model="activeDays"
        :id="source.name"
        :items="possibleDays"
        item-text="label"
        item-value="value"
        return-object
        outlined
        background-color="white"
        chips
        multiple
    />
  </v-list-item>
</template>

<script lang="ts">
import Vue from "vue";
import { AdviceSource } from "@/apdtAdvice/advice.domain";

export default Vue.extend({
  name: "AdviceSource.day-selector",
  props: {
    source: AdviceSource
  },
  data() {
    return {
      possibleDays: [], // {label: string, value: Moment}
      activeDays: [] // {label: string, value: Moment}
    };
  },
  mounted() {
    this.possibleDays = this.source.options
        .flatMap(item => item.data)
        .map(item => ({
          label: item.day.format("DD/MM/YYYY"),
          value: item.day
        }))
        .reduce((unique, item) => (unique.find(uniqueItem => uniqueItem.label === item.label) ? unique : [...unique, item]), []);


    this.activeDays = this.source.options
        .flatMap(item => item.data)
        .filter(item => item.active)
        .map(item => ({
          label: item.day.format("DD/MM/YYYY"),
          value: item.day
        }))
        .reduce((unique, item) => (unique.find(uniqueItem => uniqueItem.label === item.label) ? unique : [...unique, item]), []);
    this.updateDays();
  },
  methods: {
    updateDays() {
      this.$emit("update", this.activeDays.map(item => item.value));
    }
  },
  watch: {
    activeDays() {
      this.updateDays();
    }
  }
});
</script>

<style scoped>

</style>
