<template>
  <div class="new-farm-bin__container">
    <v-form ref="formAddBin" @submit.prevent="addBin">
      <v-text-field
          v-model="bin.name"
          :label="translations.MODALS.ADD_BIN.FORM.NAME_LABEL"
          :rules="formRules.binName"
          autofocus
          required
      />
      <v-text-field
          v-model="bin.tankCapacity__m3"
          :label="translations.MODALS.ADD_BIN.FORM.CAPACITY_LABEL"
          :rules="formRules.binCapacity"
          required
          type="number"
      />

      <p class="mt-3 body-2 font-weight-light">{{ translations.MODALS.ADD_BIN.FORM.BIN_TYPE_LABEL }}</p>

      <v-radio-group v-model="bin.icon.binType">
        <v-radio
            v-for="binType in binTypes"
            :key="binType"
            :label="`${translations.MODALS.ADD_BIN.FORM.BIN_TYPES[binType]}`"
            :value="binType"
            color="primary"
        >
          <template v-slot:label>
            <MapIcon :custom-width="100" :field-entity="getBinOfType(binType)"/>
            <span class="ml-2">{{ translations.MODALS.ADD_BIN.FORM.BIN_TYPES[binType] }}</span>
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mt-3 body-2 font-weight-light">{{ translations.MODALS.ADD_BIN.FORM.BIN_COLOR_LABEL }}</p>

      <ColorSelect :colors="binColors" :initialValue="initialBinColor" @colorChange="colorChangeHandler"/>

      <p v-if="errorMessage" class="new-workforce-bin__error">{{ errorMessage }}</p>

      <div class="form__actions">
        <v-btn v-show="!loading" color="error" text @click="$emit('close')">{{
            translations.GENERAL.CANCEL
          }}
        </v-btn>
        <v-btn :loading="loading" color="primary" dark type="submit">{{
            translations.MODALS.ADD_BIN.FORM.CONFIRM_BUTTON
          }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import {mapState} from 'vuex';

import {Bin, BinIcon, BinTypeEnum, FieldEntityEnum, IconColors, IconService} from 'shared-frontend';
import FarmBinService from '@/farmBin/farmBin.service';
import {logger} from '@services/logger.service';
import MapIcon from '@/map/icons/MapIcon.icon.vue';
import ColorSelect from '@components/ColorSelect.component.vue';
import {binToCreateFarmBin} from "@/farmBin/farmBin.helper";

export default {
  name: 'NewFarmBin',
  components: {
    MapIcon,
    ColorSelect
  },
  model: {
    prop: 'show',
    event: 'dismiss'
  },
  data() {
    return {
      bin: new Bin(null, null, null, null, new BinIcon(this.initialBinColor, null), null, false, null, null),
      showModal: false,
      loading: false,
      errorMessage: '',
      formRules: {
        binName: [
          v => !!v || this.translations.MODALS.ADD_BIN.FORM.NAME_ERROR_REQUIRED,
          v => (v && v.length <= 50) || this.translations.MODALS.ADD_BIN.FORM.NAME_ERROR_LENGTH
        ],
        binCapacity: [
          v => !!v || this.translations.MODALS.ADD_BIN.FORM.CAPACITY_ERROR_REQUIRED,
          y => y > 0 || this.translations.MODALS.ADD_BIN.FORM.CAPACITY_ERROR_POSITIVE
        ]
      }
    };
  },
  computed: {
    ...mapState({
      translations: state => state.translation.translations
    }),
    binTypes() {
      return Object.values(BinTypeEnum);
    },
    binColors() {
      return IconService.getIconColors(FieldEntityEnum.BIN) || {};
    },
    initialBinColor() {
      return IconColors.RED;
    }
  },
  props: {
    show: Boolean
  },
  methods: {
    getBinOfType(binType) {
      const binIcon = new BinIcon(this.bin.icon.color, binType);
      return new Bin(null, null, null, 'dummy', binIcon, null, false, null, null);
    },
    async addBin() {
      if (this.loading) return;

      const isValid = this.$refs.formAddBin.validate();

      if (!isValid) {
        return;
      }

      this.loading = true;

      try {
        const createFarmBin = binToCreateFarmBin(this.bin);
        await FarmBinService.addFarmBin(createFarmBin);
      } catch (e) {
        this.errorMessage = this.translations.ERRORS.GENERAL;
        logger.error('[NewFarmBin.modal] - addBin: Error:  ', e);
      } finally {
        this.loading = false;
        this.$emit('close');
      }
    },
    colorChangeHandler(colorLabel) {
      this.bin.icon.color = colorLabel;
    }
  }
};
</script>
