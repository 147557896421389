<template>
  <div v-if="selectedTeam$">
    <template v-if="isDataAvailable" class="telemetry">
      <div v-if="isBale" class="telemetry__time">
        {{ baleNumber }}
      </div>
      <div v-else class="telemetry__time">
        {{ this.dateTimeReceivedString }}
      </div>
      <div class="telemetry__table">
        <v-simple-table dense>
          <template v-slot:default class="p-10">
            <tbody>
            <tr v-for="(item, index) in formattedTelemetry" :key="index">
              <td>{{ item.label }}</td>
              <td>{{ item.value }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </template>
    <template v-else-if="!isDataAvailable && isLoading">
      <v-progress-circular color="primary" indeterminate/>
      <span class="ml-5">{{ translations.TELEMETRY.loading }}</span>
    </template>
    <template v-else-if="!isDataAvailable && !isLoading">
      <span class="ml-5">{{ translations.TELEMETRY.notAvailable }}</span>
    </template>
  </div>
</template>

<script lang="ts">
// EXTERNAL
import {mapState} from 'vuex';
//
// INTERNAL
import Vue from 'vue';
import {
  Baler,
  BalerLoadReport,
  BalerLoadReportPresenter,
  BalerTelemetryPresenter,
  Bins,
  CanbusVehicleTelemetry,
  Combine,
  CombineTelemetryPresenter,
  EMPTY_VALUE,
  FieldEntity,
  FieldEntityEnum,
  IconService,
  Row,
  Spfh,
  SpfhTelemetryPresenter
} from 'shared-frontend';
import {Icons, icons} from '@/utils/icons.utils';
import TelemetryService from '@/telemetry/telemetry.service';
import TeamService from '@/team/team.service';
import {COMBINE_TELEMETRY_TIMEOUT} from '@config/config';
import {logger} from '@services/logger.service';
import {State} from '@/store';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export default Vue.extend({
  name: 'Telemetry',
  props: {
    params: Object
  },
  data() {
    return {
      show: true,
      isLoading: true,
      timeoutTimer: null,
      telemetry$: null,
      destroy$: new Subject()
    };
  },
  created(): void {
    if(!this.isBale) {
      this.startTelemetrySSE();
      this.setTimeoutTimer();
    }
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    icons(): Icons {
      return icons;
    },
    fieldEntity(): FieldEntity {
      return this.params?.fieldEntity;
    },
    fieldEntityType(): FieldEntityEnum {
      return IconService.getFieldEntityType((this as any).fieldEntity);
    },
    isBale(): boolean {
      return this.fieldEntity instanceof BalerLoadReport;
    },
    isDataAvailable(): boolean {
      return this.isBale ? true : Boolean((this as any).telemetry$);
    },
    formattedTelemetry(): Row[] {
      const telemetry$ = (this as any).telemetry$;

      switch (this.fieldEntity.constructor) {
        case Combine:
          return new CombineTelemetryPresenter(
              telemetry$,
              this.fieldEntity as Combine,
              this.translations.COMBINE_TELEMETRY
          ).toTable();
        case Spfh:
          return new SpfhTelemetryPresenter(
              telemetry$,
              this.fieldEntity as Spfh,
              (this as any).selectedTeam$?.bins as Bins,
              this.translations.SPFH_TELEMETRY
          ).toTable();
        case Baler:
          return new BalerTelemetryPresenter(
              telemetry$,
              this.fieldEntity as Baler,
              (this as any).selectedTeam$?.toTeamMetadata(),
              this.translations.BALER_TELEMETRY
          ).toTable();
        case BalerLoadReport:
          const baler = (this as any).selectedTeam$?.balers?.getBalerById(
              (this.fieldEntity as BalerLoadReport).balerId
          );
          return new BalerLoadReportPresenter(
              this.fieldEntity as BalerLoadReport,
              baler,
              this.translations.BALER_LOAD_REPORT
          ).toTable();
        default:
          logger.error('(Instantaneous information) shown for a field entity that has no telemetry');
          return [];
      }
    },
    dateTimeReceivedString(): string {
      const eventTime = (this as any).telemetry$?.summary?.eventTime;
      return eventTime?.toLocaleString() ?? EMPTY_VALUE;
    },
    baleNumber(): string {
      return `${this.translations.BALER_LOAD_REPORT.bale} ${(this.fieldEntity as BalerLoadReport).seqNo}`;
    }
  },
  methods: {
    startTelemetrySSE(): void {
      TelemetryService.getTelemetry$(this.fieldEntity.id, this.fieldEntityType)
          .pipe(takeUntil((this as any).destroy$))
          .subscribe((telemetry: CanbusVehicleTelemetry) => {
            (this as any).telemetry$ = telemetry;
          });
    },
    setTimeoutTimer(): void {
      this.timeoutTimer = setTimeout(() => {
        this.isLoading = false;
      }, COMBINE_TELEMETRY_TIMEOUT);
    },
    clearTimeoutTimer(): void {
      if (this.timeoutTimer) window.clearTimeout(this.timeoutTimer);
    }
  },
  subscriptions() {
    return {
      selectedTeam$: TeamService.selectedTeamOrNull$
    };
  },
  beforeDestroy(): void {
    this.clearTimeoutTimer();
    (this as any).destroy$.next();

  }
});
</script>
