import { FieldEntity, Gate, IconService, Person } from 'shared-frontend';

// CSS that modifies SVG
// =====================

export const getRotationStyling = (fieldEntity: FieldEntity) => {
    const rotation = IconService.getIconRotation(fieldEntity);
    return rotation ? { transform: `rotateZ(${rotation}deg)` } : {};
};

export const getSizeStyling = (fieldEntity: FieldEntity, zoom: number, customWidth?: number) => {
    if (customWidth) {
        return { width: `${customWidth}px` };
    }

    const width = getIconSize(fieldEntity, zoom);
    return { width: `${width}px`, height: `${width}px` };
};

export const getIconSize = (fieldEntity: FieldEntity, zoom: number) => {
    // Set a minimum zoom level, so that icons don't disappear into nothing when zoomed out a lot
    const constrainedZoom = Math.max(zoom, 19);

    let sizeInPixels =
        IconService.getIconWidthInMetresForFieldEntity(fieldEntity) / IconService.metersPerPixelForZoomLevelOSM(constrainedZoom);

    if (fieldEntity instanceof Person || fieldEntity instanceof Gate) {
        // lets increase the size of persons or else we don't see them anymore!!
        sizeInPixels *= 2;
    }

    return sizeInPixels;
};
