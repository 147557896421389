<template>
  <Aside>
    <template v-slot:content>
      <div class="fieldReportPage__back">
        <Back :to="{name: 'farmBoundaries'}"/>
      </div>
      <Title :title="translations.FIELD_REPORT.TITLE"/>
      <template class="fieldReportPage" v-if="fieldReportTable.length && !isLoading">
        <div class="fieldReportPage__table">
          <v-simple-table dense>
            <template v-slot:default class="p-10">
              <tbody>
              <tr v-for="(item, index) in fieldReportTable" :key="index">
                <td v-bind:class="{'font-weight-bold': item.emphasize}">{{ item.label }}</td>
                <td>{{ item.value }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </template>
      <div v-else-if="error" class="pa-5 d-flex align-center">
        <v-icon>{{icons.exclamation}}</v-icon>
        <span class="ml-5 font-weight-bold red--text">{{ error.message }}</span>
      </div>

      <div v-else class="pa-5">
        <v-progress-circular indeterminate color="primary"/>
        <span class="ml-5">{{ translations.FIELD_REPORT.loading }}</span>
      </div>
    </template>

    <template v-slot:footer>
      <div class="fieldReportPage__buttons">
        <v-btn :loading="isLoadingPdf" :disabled="isLoadingPdf" color="primary" @click="downloadFieldReport(responseType.PDF)">
          <v-icon class="mr-1" small>{{ icons.filePdf }}</v-icon>
          {{ translations.FIELD_REPORT.DOWNLOAD_PDF }}
        </v-btn>
        <v-btn :loading="isLoadingCsv" :disabled="isLoadingCsv" color="primary" @click="downloadFieldReport(responseType.CSV)">
          <v-icon class="mr-1" small>{{ icons.fileCsv }}</v-icon>
          {{ translations.FIELD_REPORT.DOWNLOAD_CSV }}
        </v-btn>
      </div>
    </template>
  </Aside>
</template>

<script>
// EXTERNAL
import {mapState} from 'vuex';
//
// INTERNAL
import {icons} from '@utils/icons.utils';
import ReportService from '../services/report.service.ts';
import Title from '../components/Title.component.vue';
import Back from "@components/Back.component";
import TeamService from '@/team/team.service';
import {ContentTypeEnum} from '@services/http.service';
import {takeUntil} from 'rxjs/operators';
import Aside from '@/layout/Aside';
import {FieldReportPresenter} from "shared-frontend";
import {Subject} from "rxjs";

export default {
  name: 'FieldReport',
  components: {Aside, Title, Back},
  data() {
    return {
      isLoading: true,
      error: null,
      isDownloadingFile: {
        [ContentTypeEnum.PDF.contentType]: false,
        [ContentTypeEnum.CSV.contentType]: false,
      },
      timeOutTimer: null,
      fieldReportTable: [],
      destroy$: new Subject()
    };
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    }),
    responseType() {
      return ContentTypeEnum;
    },
    icons() {
      return icons;
    },
    isLoadingPdf() {
      return this.isDownloadingFile[ContentTypeEnum.PDF.contentType];
    },
    isLoadingCsv() {
      return this.isDownloadingFile[ContentTypeEnum.CSV.contentType];
    },
  },
  created() {
    TeamService.selectedTeamIdOrNull$.pipe(takeUntil(this.destroy$)).subscribe((teamId) => this.getFieldReportTable(teamId));
  },
  methods: {
    getFieldReportTable(teamId) {
      this.isLoading = true;
      this.error = null

      ReportService.getFieldReport(teamId).then((fieldReport) => {
        this.fieldReportTable = new FieldReportPresenter(fieldReport, this.translations.FIELD_REPORT).toTable();
      }).catch((err) => {
       this.error = err;
      }).finally(() => {
        this.isLoading = false;
      })
    },
    downloadFieldReport(responseType) {
      const contentType = responseType.contentType;

      if (this.isDownloadingFile[contentType]) return;

      this.isDownloadingFile[contentType] = true;

      ReportService.downloadFieldReport(this.teamId$, responseType).finally(() => {
        this.isDownloadingFile[contentType] = false;
      })
    },
  },
  beforeDestroy() {
    this.destroy$.next();
  },
  subscriptions() {
    return {
      teamId$: TeamService.selectedTeamIdOrNull$
    }
  }
};
</script>
