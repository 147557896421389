/*
 * L.Control.WMSLegend is used to add a WMS Legend to the map
 */

window.L.Control.WMSLegend = window.L.Control.extend({
    options: {
        position: 'bottomright',
        uri: ''
    },

    onAdd: function () {
        var controlClassName = 'leaflet-control-wms-legend',
            legendClassName = 'wms-legend',
            stop = window.L.DomEvent.stopPropagation;
        this.container = window.L.DomUtil.create('div', controlClassName);
        this.img = window.L.DomUtil.create('img', legendClassName, this.container);
        this.img.src = this.options.uri;
        this.img.alt = 'Legend';

        window.L.DomEvent.on(this.img, 'click', this._click, this)
            .on(this.container, 'click', this._click, this)
            .on(this.img, 'mousedown', stop)
            .on(this.img, 'dblclick', stop)
            .on(this.img, 'click', window.L.DomEvent.preventDefault)
            .on(this.img, 'click', stop);
        this.height = null;
        this.width = null;
        return this.container;
    },
    _click: function (e) {
        window.L.DomEvent.stopPropagation(e);
        window.L.DomEvent.preventDefault(e);
        // toggle legend visibility
        var style = window.getComputedStyle(this.img);
        if (style.display === 'none') {
            this.container.style.height = this.height + 'px';
            this.container.style.width = this.width + 'px';
            this.img.style.display = this.displayStyle;
        } else {
            if (this.width === null && this.height === null) {
                // Only do inside the above check to prevent the container
                // growing on successive uses
                this.height = this.container.offsetHeight;
                this.width = this.container.offsetWidth;
            }
            this.displayStyle = this.img.style.display;
            this.img.style.display = 'none';
            this.container.style.height = '20px';
            this.container.style.width = '20px';
        }
    }
});

let wmsLegendControl = null;

window.L.wmsLegend = function (map, uri) {
    if (wmsLegendControl) {
        map.removeControl(wmsLegendControl);
    }

    wmsLegendControl = new window.L.Control.WMSLegend();
    wmsLegendControl.options.uri = uri;

    map.addControl(wmsLegendControl);
    return wmsLegendControl;
};
