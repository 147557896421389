<template>
  <div class="workforce">
    <v-row justify="center">
      <v-col lg="3" xl="3">
        <Users/>
      </v-col>

      <v-col lg="3" xl="3">
        <Growers/>
      </v-col>

      <v-col lg="3" xl="3">
        <FarmServiceVehicles/>
        <ArableVehicles/>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Users from '@/workforce/Users.component.vue';
import FarmServiceVehicles from '@/farmServiceVehicle/FarmServiceVehicles.component.vue';
import ArableVehicles from '@/workforce/ArableVehicles.component.vue';
import Growers from '@/grower/Growers.component.vue';

export default Vue.extend({
  name: 'Workforce',
  components: {
    ArableVehicles,
    FarmServiceVehicles,
    Users,
    Growers,
  },
});
</script>

<style lang="scss" scoped>
.workforce {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, rgba(#fff, 75%), rgba(#fff, 75%)), url('../assets/images/wheat-field.jpg');
  background-size: cover;

  & > * {
    flex-grow: 1;
    overflow-y: auto;
  }
}
</style>
