<template>
  <Aside>
    <template v-slot:content>
      <Title :title="trans.REPORTS.TITLE"/>
      <div class="reportsPage">

        <div v-if="!teamsExist">
          {{ trans.NO_TEAMS }}
        </div>
        <template v-else>
          <div v-if="!teamExists" class="reportsPage__noTeam">
            {{ trans.NO_TEAM }}
          </div>
          <template v-else>
            <div class="reportsPage__fieldReport">
              <SubTitle>{{ trans.REPORTS.FIELD_REPORT.TITLE }}</SubTitle>
              <FieldReportBtn :team-name="teamName" :boundary="boundary"/>
            </div>
            <template v-if="bins.length">
              <SubTitle>{{ trans.REPORTS.BIN_REPORTS.TITLE }}</SubTitle>
              <div class="reportsPage__binReports">
                <BinReportBtn v-for="bin in bins" :key="bin.id" :bin="bin" :team-id="selectedTeam$.id"/>
              </div>
            </template>
          </template>

          <SubTitle>{{ trans.REPORTS.YEAR_REPORT.TITLE }}</SubTitle>
          <div class="reportsPage__yearReport">
            <YearReportBtn :year="year" v-for="year in years" :key="year"/>
          </div>

        </template>


      </div>
    </template>
  </Aside>
</template>

<script lang="ts">
import Vue from 'vue';
import TeamService from '@/team/team.service';
import Aside from '../layout/Aside.vue';
import Title from '../components/Title.component.vue';
import BinReportBtn from '@/reports/BinReportBtn.component.vue';
import YearReportBtn from '@/reports/YearReportBtn.component.vue';
import {mapState} from 'vuex';
import {State} from '@/store';
import FieldReportBtn from './FieldReportBtn.component.vue';
import {Bin, Boundary} from 'shared-frontend';
import SubTitle from '@components/SubTitle.component.vue';
import FarmTeamService from "@/farmTeam/farmTeam.service";
import {FarmTeamSummary} from "@/farmTeam/farmTeam.domain";
import {toUnique} from "@utils/functional.util";

export default Vue.extend({
  name: 'Reports',
  components: {SubTitle, FieldReportBtn, Title, BinReportBtn, Aside, YearReportBtn},
  computed: {
    ...mapState({
      trans: (state: State) => state.translation.translations
    }),
    bins(): Bin[] {
      return (this as any).selectedTeam$?.bins?.getList() ?? [];
    },
    teamName(): string {
      return (this as any).selectedTeam$?.getTeamIdentifier(this.trans);
    },
    teamExists(): boolean {
      return Boolean((this as any).selectedTeam$?.id);
    },
    teamsExist(): boolean {
      const farmTeams$ = (this as any).farmTeams$ || [];
      return Boolean(farmTeams$.length > 0);
    },
    boundary(): Boundary {
      return (this as any).selectedTeam$?.boundary;
    },
    years(): number[] {
      return toUnique(
          ((this as any).farmTeams$ as FarmTeamSummary[]).map(t => t.year)
      )
          .sort();
    },
  },
  subscriptions() {
    return {
      selectedTeam$: TeamService.selectedTeamOrNull$,
      farmTeams$: FarmTeamService.farmTeams$,
    };
  }
});
</script>
