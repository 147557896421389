import { render, staticRenderFns } from "./AdviceDayStyle-selector.vue?vue&type=template&id=33fe6422&scoped=true"
import script from "./AdviceDayStyle-selector.vue?vue&type=script&lang=ts"
export * from "./AdviceDayStyle-selector.vue?vue&type=script&lang=ts"
import style0 from "./AdviceDayStyle-selector.vue?vue&type=style&index=0&id=33fe6422&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33fe6422",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VDivider,VRadio,VRadioGroup,VSelect})
