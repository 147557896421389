<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          color="primary"
          large
          class="ml-auto"
          style="cursor: pointer"
          v-bind="attrs"
          v-on="on">
        {{ icons.addCircle }}
      </v-icon>
    </template>

    <v-card>
      <v-card-title>
        Add new data provider
      </v-card-title>

      <v-card-text>
        <v-form ref="dataProvidersForm" v-model="valid">
          <v-select
              label="Select data provider"
              v-model="dataProvider"
              :items="availableDataProviders"
              :rules="formRules.source"
              item-text="label"
              item-value="value"
              outlined
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
            color="danger"
            text
            @click="dialog=false;"
        >Cancel
        </v-btn>
        <v-spacer/>
        <v-btn
            color="primary"
            text
            @click="handleSubmit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {icons} from "@utils/icons.utils";
import {mapState} from "vuex";

export default {
  name: "addDataProvider",
  components: {},
  props: {
    availableDataProviders: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      dialog: false,
      dataProvider: undefined,
      valid: false,
      formRules: {
        source: [
          v => !!v || this.translations.GENERAL.REQUIRED,
        ]
      }

    };
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    }),
    icons() {
      return icons;
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.dataProvidersForm.validate()
      if (!this.valid) {
        return
      }
      this.dialog = false;
      this.$emit("submit", this.dataProvider);
    }
  },
  watch: {
    dialog() {
      this.source = undefined;
      this.dataProvider = undefined;
    }
  }
};
</script>

<style scoped>

</style>
