import {FarmTeamSummary} from '@/farmTeam/farmTeam.domain';
import {pick, toUnique} from '@utils/functional.util';
import {sortAlphabeticallyBy, sortByMostRecent} from '@/farmTeam/farmTeam.helper';

export const uniqueGrowers = (farmTeams: FarmTeamSummary[], alphabetically: boolean): string[] => {
    if (!farmTeams?.length) return [];
    return toUnique(farmTeams.slice()
        .sort(alphabetically ? sortAlphabeticallyBy('growerName') : sortByMostRecent)
        .map(pick('growerName')));
}
