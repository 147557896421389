<template>
    <div @click="toggleState" class="btn primary elevation-2">
        <v-icon color="white" medium>{{ isExpanded ? icons.chevronDown : icons.chevronUp }}</v-icon>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Icons, icons } from '@utils/icons.utils';

export default Vue.extend({
    name: 'ExpandButton',
    props: {
        isExpanded: Boolean
    },
    computed: {
        icons(): Icons {
            return icons;
        }
    },
    methods: {
        toggleState(): void {
            this.$emit('toggle');
        }
    }
});
</script>
<style lang="scss">
.btn {
    display: inline-block;
    margin-right: 0.5rem;
    padding: 1rem;
    border-radius: 5px;
}
</style>
