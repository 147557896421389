var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"farmBoundaries"},[_c('div',{staticClass:"farmBoundaries__content"},[_c('Title',{attrs:{"title":_vm.translations.BOUNDARIES.TITLE,"subtitle":_vm.translations.BOUNDARIES.SUBTITLE}}),_c('div',{staticClass:"farmBoundaries__table"},[_c('SearchInput',{attrs:{"placeholder":_vm.translations.BOUNDARIES.SEARCH.PLACEHOLDER},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"items-per-page":10,"search":_vm.searchInput,"sort-by":_vm.sortColumnName,"sort-desc":_vm.isDescending},on:{"update:sortBy":function($event){_vm.sortColumnName=$event},"update:sort-by":function($event){_vm.sortColumnName=$event},"update:sortDesc":function($event){_vm.isDescending=$event},"update:sort-desc":function($event){_vm.isDescending=$event},"click:row":function($event){return _vm.editFarmBoundary($event.farmBoundaryId)}},scopedSlots:_vm._u([{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.createdOn.toLocaleDateString()))])]}},{key:"item.fieldSize",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.fieldSize.toString(2)))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"farmBoundaries__name",attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))])]}},{key:"item.coordinates",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"farmBoundaries__polygonImage"},[_c('PolygonImage',{attrs:{"coordinates":item.coordinates}})],1)]}},{key:"item.apdt",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function (event) { return _vm.handleApdt(event, item); }}},[_vm._v("APDT")])]}}])})],1),_c('div',{staticClass:"farmBoundaries__footer"},[_c('v-tooltip',{attrs:{"top":"","disabled":_vm.hasEvoPortalAccess$},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.hasEvoPortalAccess$},on:{"click":_vm.importFromEvoPortal}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.upload))]),_vm._v(" "+_vm._s(_vm.translations.EVO_PORTAL.IMPORT)+" ")],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.translations.EVO_PORTAL.UNAVAILABLE))])]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addFarmBoundary}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.add))]),_vm._v(" "+_vm._s(_vm.translations.BOUNDARIES.CREATE)+" ")],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }