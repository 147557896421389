<template>
  <div
      class="innerLayout"
      :class="{
        'innerLayout--footer': hasFooter,
        'innerLayout--noFooter': !hasFooter
      }"
  >
    <div class="innerLayout__sideBarContent">
      <slot name="sideBarContent" />
    </div>
    <div class="innerLayout__sideBarFooter">
      <slot name="sideBarFooter" />
    </div>
    <div class="innerLayout__content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InnerLayout',
  computed: {
    hasFooter () {
      return Boolean(this.$slots.sideBarFooter);
    }
  },
}
</script>