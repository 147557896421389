export const enum ModalTypeEnum {
    ADD_USER = 'ADD_USER',
    CONFIRM_DELETE_USER = 'CONFIRM_DELETE_USER',
    EDIT_USER = 'EDIT_USER',
    ADD_BIN = 'ADD_BIN',
    ADD_BALE_SERVICE_VEHICLE = 'ADD_BALE_SERVICE_VEHICLE',
    ADD_SERVICE_VEHICLE = 'ADD_SERVICE_VEHICLE',
    CONFIRM_DELETE_BIN = 'CONFIRM_DELETE_BIN',
    CONFIRM_DELETE_BALE_SERVICE_VEHICLE = 'CONFIRM_DELETE_BALE_SERVICE_VEHICLE',
    SETTINGS = 'SETTINGS',
    CREATE_GATE = 'CREATE_GATE',
    DELETE_GATE = 'DELETE_GATE',
    STOP_TEAM = 'STOP_TEAM',
    DELETE_FARM_TEAM = 'DELETE_FARM_TEAM',
    TELEMETRY = 'TELEMETRY',
    COMBINE_LOW_FUEL = 'COMBINE_LOW_FUEL',
    SPFH_LOW_FUEL = 'SPFH_LOW_FUEL',
    BALER_LOW_FUEL = 'BALER_LOW_FUEL',
    FIELD_REPORT = 'FIELD_REPORT',
    ERROR = 'ERROR',
    COMING_SOON = 'COMING_SOON',
    GEOLOCATION_FAILED = 'GEOLOCATION_FAILED',
    CONFIRM_DELETE_FARM_BOUNDARY = 'CONFIRM_DELETE_FARM_BOUNDARY',
    EDIT_TEAM_INFO = 'EDIT_TEAM_INFO',
    CONFIRM_IMPORT_FROM_EVO_PORTAL = 'CONFIRM_IMPORT_FROM_EVO_PORTAL',
    CONFIRM_UPLOAD_TO_EVO_PORTAL = 'CONFIRM_UPLOAD_TO_EVO_PORTAL',
    MARKER = 'MARKER',
    LINK_EDIT_GROWER= 'LINK_EDIT_GROWER',
    CONFIRM_UNLINK_GROWER = 'CONFIRM_UNLINK_GROWER',
    ESP_CAMS = "ESP_CAMS",
}
