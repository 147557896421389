<template>
  <div>
    <merge-dialog v-model="showMergeDialog" :source-type="selectedMergeSourceType" v-on:merge="handleMerge()" />
    <div class="advice-sources mb-8">

      <v-card
          class="mx-auto mb-8"
          max-width="500"
          v-for="source in sources"
          :key="source.type"
      >
        <v-card-title>
          <v-row>
            <v-col cols="8">
              {{ translations.APDT_ADVICE.LAYERS.SOURCES[source.type] }}
            </v-col>
            <v-col cols="4">
              <v-btn v-if=" source.type==AdviceSourceType.AGDNA_CN1" color="primary"
                     @click.prevent="(event) => {
                       showMergeDialog=true;
                       selectedMergeSourceType=source.type;
                     }">
                {{ translations.APDT_ADVICE.LAYERS.SOURCES.ACTIONS.MERGE }}
              </v-btn>
            </v-col>
          </v-row>

        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-list>
            <advice-source-day-selector v-if="!readOnly" :source="source"
                                        @update="(days) => handleUpdateDays(source, days)" />

            <v-list-item-group
                v-model="activeOptions"
                multiple
            >
              <template>
                <v-list-item
                    :key="`${source.type}---${sourceOption.name}`"
                    :value="`${source.type}---${sourceOption.name}`"
                    active-class="primary--text"
                    v-for="sourceOption in source.options"
                    :class="{'active-thumbnail': sourceOption.thumbnailSelected}"
                    @click="handleItemClick(sourceOption)"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-avatar tile
                                        height="100"
                                        width="100"
                                        max-height="100"
                                        max-width="100"
                                        @click.prevent="(event) => handleThumbnailClick(event, sourceOption)"
                    >
                      <v-img v-if="sourceOption.thumbnailUrl" :src="sourceOption.thumbnailUrl" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="translations.APDT_ADVICE.LAYERS.OPTIONS[sourceOption.name]" />
                    </v-list-item-content>

                    <v-list-item-action v-if="!readOnly">
                      <v-checkbox
                          :input-value="active"
                          color="primary accent-4"
                      />
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>

      <v-card
          class="mx-auto mb-8 relativePosition"
          max-width="500"
          v-for="noDataSource in noDataSources"
          :key="noDataSource.option">
        <div class="greyedLock">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="white" large class="mb-2">{{ icons().lock }}</v-icon>
            </template>
            {{ translations.APDT_ADVICE.HIDDEN_LAYERS }}
          </v-tooltip>
        </div>

        <v-card-title>
          {{ translations.APDT_ADVICE.LAYERS.SOURCES[noDataSource.sensor.toUpperCase()] }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-list>
            <v-list-item v-for="(item, index) in noDataSource.options" :key="index">
              <v-list-item-content>
                <v-list-item-title v-text="translations.APDT_ADVICE.LAYERS.OPTIONS[item]" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>

    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import { findApdtField, getAdviceSources, getUseableSources } from "@/apdtAdvice/Advice.service";
import { AdviceSource, AdviceSourceOption, UseableSource } from "@/apdtAdvice/advice.domain";
import AdviceSourceDaySelector from "@/apdtAdvice/forms/AdviceSource.day-selector.vue";
import { Mutations } from "@/apdtAdvice/store/apdtStore";
import { icons } from "@utils/icons.utils";
import { AdviceSourceType } from "@/apdtAdvice/advice.enum";
import MergeDialog from "@/apdtAdvice/cn1-merge/MergeDialog.vue";

export default Vue.extend({
  name: "AdviceSource.form-item",
  components: { MergeDialog, AdviceSourceDaySelector },
  props: {
    disabled: Boolean,
    farmBoundaryId: String,
    readOnly: Boolean,
    adviceAction: String
  },
  data() {
    return {
      sources: [], //all sources for this boundary
      activeOptions: [], //selected sources by the user to use in advice generation
      useableSources: [], //All sensors that the advice generation can use to generate (the more, the more precise an advice) //type: UseableSensor
      noDataSources: [], //all useableOptions that have no data available for this boundary //type:UseableSensor
      showMergeDialog: false,
      selectedMergeSourceType: false
    };
  },
  created() {
    this.fetchSources();
    this.fetchUseableSources();
  },
  computed: {
    AdviceSourceType() {
      return AdviceSourceType;
    },
    ...mapState({
      translations: (state: any) => state.translation.translations
    })
  },
  methods: {
    handleMerge() {
      this.showMergeDialog=false;
      this.fetchSources();
      this.updateFormItemValue();
    },
    icons() {
      return icons;
    },
    ...mapMutations([
      Mutations.SET_CURRENT_FIELD
    ]),
    async fetchSources() {
      let apdtField = await findApdtField(this.farmBoundaryId);
      if (apdtField) {
        this.setCurrentField(apdtField);
        this.sources = await getAdviceSources(apdtField);

        if (this.adviceAction) {
          this.filterSources();
        }

        this.activeOptions = this.sources.flatMap((source: AdviceSource) =>
            source.options
                .filter((sourceOption: AdviceSourceOption) => sourceOption.active)
                .map((sourceOption: AdviceSourceOption) => `${source.type}---${sourceOption.name}`));

        if (this.sources.length) {
          this.sources = this.sources.map((source: AdviceSource, index: number) => {
            if (index === 0 && source.options.length) {
              return source.updateThumbnailSelected(source.options[0]);
            }
            return source;
          });
        }
        this.updateFormItemValue();
      }

    },
    updateFormItemValue() {
      this.$emit("input", this.sources);
    },
    handleUpdateDays(updateSource, activeDays) {
      this.sources = this.sources.map((source: AdviceSource) => source.type === updateSource.type ? source.updateDays(activeDays) : source);
      this.updateFormItemValue();
    },
    handleItemClick(sourceOption) {
      if (this.readOnly) {
        this.handleThumbnailClick({
          stopPropagation: () => {
          }
        }, sourceOption);
      }
    },
    handleThumbnailClick(event, sourceOption) {
      event.stopPropagation();
      this.sources = this.sources.map((source: AdviceSource) => source.updateThumbnailSelected(sourceOption));
      this.updateFormItemValue();
    },
    async fetchUseableSources() {
      if (this.adviceAction) {
        this.useableSources = await getUseableSources(this.adviceAction);
      }
    },
    preselectAllOptions(listOptions: AdviceSourceOption[]) {
      for (let i = 0; i < listOptions.length; i++) {
        listOptions[i].active = true;
      }
    },
    identifyNoDataUseableSensors() {
      const sourceTypes = this.sources.flatMap(sourceItem => sourceItem.type);
      this.useableSources.forEach(useableSource => {
        const ind = sourceTypes.indexOf(useableSource.sensor.toUpperCase());
        if (ind >= 0) {
          const sourceItem = this.sources[ind];
          if (sourceItem.type == useableSource.sensor.toUpperCase()) {
            let noDatas = useableSource.options.filter(useableOption => !sourceItem.options.flatMap(item => item.name).includes(useableOption));
            if (noDatas.length > 0) {
              this.noDataSources.push(
                  new UseableSource(
                      sourceItem.type,
                      noDatas
                  )
              );
            }
          }
        } else {
          this.noDataSources.push(useableSource);
        }
      });
    },
    filterSources() {
      //item.type = AGDNA_CN1
      //item.options[0].name = AGDNA_CN1_CROP_FLOW
      //item.option : AdviceSourceOption
      let filteredSources: AdviceSource[] = [];
      let useableOptionsTemp = this.useableSources.flatMap(useable => useable.options);
      this.sources.forEach((sourceItem: AdviceSource) => {
        let filteredOptions: AdviceSourceOption[] = [];
        sourceItem.options.forEach((option: AdviceSourceOption) => {
          let index = useableOptionsTemp.indexOf(option.name);
          if (index >= 0) {
            filteredOptions.push(option);
          }
        });

        if (filteredOptions.length > 0) {
          this.preselectAllOptions(filteredOptions);
          filteredSources.push(
              new AdviceSource(
                  {
                    type: sourceItem.type,
                    active: sourceItem.active,
                    options: filteredOptions
                  }
              ));
        }
      });
      this.sources = filteredSources;
      this.identifyNoDataUseableSensors();
    }
  },
  watch:
      {
        activeOptions() {
          this.sources = this.sources.map((source: AdviceSource) => source.updateOptions(this.activeOptions));
          this.updateFormItemValue();
        }
      }
})
;
</script>

<style scoped>
.active-thumbnail {
  background-color: var(--v-primary-lighten5);
}
</style>
