import {API_ENDPOINTS, SseEventsEnum, Team} from 'shared-frontend';
import EventSource from '@domain/eventSource.domain';
import httpService from '@services/http.service';
import {logger} from '@services/logger.service';
import {from, Observable} from 'rxjs';
import {ActiveTeamEvent} from '@/activeTeam/activeTeam.domain';
import {finalize, pluck, scan, switchMap} from 'rxjs/operators';
import {enrichActiveTeamEvent, reduceActiveTeamEvent} from '@/activeTeam/activeTeam.helper';

class ActiveTeamService {
    public getActiveTeam$(initialTeamState: Team): Observable<Team> {
        const {id: teamId, farmManagerId} = initialTeamState;
        return this.activeTeamEvents$(farmManagerId, teamId)
            .pipe(
                scan(reduceActiveTeamEvent, initialTeamState),
            );
    };

    public async stopTeam(teamId: string): Promise<void> {
        await httpService.delete(API_ENDPOINTS.TEAM(teamId));
        logger.info('[activeTeam.service] - stopTeam: ', teamId);
    }

    private fetchActiveTeam$ = (farmManagerId: string, teamId: string): Observable<Team> => {
        return from(httpService.get(API_ENDPOINTS.FARMMANAGER_TEAM(farmManagerId, teamId))).pipe(pluck('data'));
    }

    private activeTeamEvents$ = (farmManagerId: string, teamId: string): Observable<ActiveTeamEvent> => {
        console.info("[activeTeam.service] - Start listening to events from team", teamId)
        const url = `${process.env.VUE_APP_BACKEND_URL}${API_ENDPOINTS.FARMMANAGER_TEAM(farmManagerId, teamId)}`;
        return EventSource.createObservable<ActiveTeamEvent>(url, Object.values(SseEventsEnum))
            .pipe(
                switchMap(enrichActiveTeamEvent(teamId, farmManagerId)),
                finalize(() => console.debug("[activeTeam.service] - Stop listening to events from team", teamId))
            )
    };
}

export default new ActiveTeamService();
