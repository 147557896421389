import {InputValidationRule} from "vuetify";

export enum FormItemType {
    text,
    number,
    select,
    radio,
    source,
    calendar,
    liming_input,
    sowing_input,
    fertilizing_n1_input,
    fertilizing_n2_input,
    fertilizing_n3_input,
    fusarium_input
}

type FormItemOption = { value: string | number; label: string };

export enum FormState {
    CLEAN,
    SUBMITTING,
    SUBMITTED,
    ERROR
}

type FormItemData = {
    name: string;
    type: FormItemType;
    value: any;
    label: string;
    options: FormItemOption[];
    rules: InputValidationRule[];
    optionsApiService: Function;
};

export class AdviceLimingForm {
    soilType: string;
    phLab: number;
    limingRecommendation: number;
    zbwLime: number;
}

export class AdviceSowingForm {
    thousandKernelWeight: number;
    cultivarType: string;
    sowDate: string;
}

export class AdviceFusariumForm {
    precedingRisk: number;
    varietyRisk: number;
}

export class AdviceFertilizingN1Form {
    adviceN1: number;
    n_ContentFertilizer: string;
    homogenize: boolean;
}

export class AdviceFertilizingN2Form {
    adviceN2: number;
    adviceN3: number;
    n_ContentFertilizer: string;
    homogenize: boolean;
    addN3Percentage: number;
}

export class AdviceFertilizingN3Form {
    adviceN3: number;
    n_ContentFertilizer: string;
    homogenize: boolean;
}


export class FormItem {
    private readonly _name: string;
    private readonly _type: FormItemType;
    private _value: any;
    private readonly _label: string;
    private _options: FormItemOption[];
    private readonly _optionsApiService: Function | undefined;
    private readonly _rules: InputValidationRule[];

    constructor(data: Partial<FormItemData>) {
        this._name = data.name;
        this._type = data.type || FormItemType.text;
        this._value = data.value || null;
        this._label = data.label || "";
        this._options = data.options || [];
        this._rules = data.rules || [];
        this._optionsApiService = data.optionsApiService || undefined;
    }

    get name(): string {
        return this._name;
    }

    get type(): FormItemType {
        return this._type;
    }

    get value(): any {
        return this._value;
    }

    get label(): string {
        return this._label;
    }

    get options(): FormItemOption[] {
        return this._options;
    }

    get optionsApiService(): Function | undefined {
        return this._optionsApiService;
    }


    get rules(): InputValidationRule[] {
        return this._rules;
    }

    set value(value: any) {
        this._value = value;
    }

    public updateOptionsFromApiService(options: FormItemOption[]): void {
        this._options = options;
    }
}

export class Form {
    private readonly _items: FormItem[];
    private _state: FormState;

    constructor(items: FormItem[]) {
        this._items = items;
        this._state = FormState.CLEAN;
    }

    get items(): FormItem[] {
        return this._items;
    }

    get state(): FormState {
        return this._state;
    }

    set state(value: FormState) {
        this._state = value;
    }
}
