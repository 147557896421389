<template>
  <v-list>
    <template v-if="isBin">
      <v-list-item @click="downloadBinReport(contentTypeEnum.PDF)" link>
        <v-list-item-title>{{ trans.CONTEXT_MENU.DOWNLOAD_BIN_REPORT_PDF }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="downloadBinReport(contentTypeEnum.CSV)" link>
        <v-list-item-title>{{ trans.CONTEXT_MENU.DOWNLOAD_BIN_REPORT_CSV }}</v-list-item-title>
      </v-list-item>
    </template>
    <template v-if="isArableVehicle">
      <v-list-item v-if="isTeamActive" @click="showTelemetryInformation()" link>
        <v-list-item-title>{{ trans.CONTEXT_MENU.SHOW_INFORMATION }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="toggleFieldCoverage()" link>
        <v-list-item-title v-if="fieldCoverageEnabled"
        >{{ trans.CONTEXT_MENU.HIDE_FIELD_COVERAGE }}
        </v-list-item-title>
        <v-list-item-title v-else>{{ trans.CONTEXT_MENU.SHOW_FIELD_COVERAGE }}</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="isTeamActive && supportsEspCam" @click="showEspCams()" link>
        <v-list-item-title>{{ trans.CONTEXT_MENU.SHOW_ESP_CAMS }}</v-list-item-title>
      </v-list-item>
    </template>
    <template v-if="isBalerLoadReport">
      <v-list-item @click="showTelemetryInformation()" link>
        <v-list-item-title>{{ trans.CONTEXT_MENU.SHOW_INFORMATION }}</v-list-item-title>
      </v-list-item>
    </template>
    <template v-if="isMarker">
      <v-list-item @click="showMarkerInformation()" link>
        <v-list-item-title>{{ trans.CONTEXT_MENU.SHOW_INFORMATION }}</v-list-item-title>
      </v-list-item>
    </template>
  </v-list>
</template>

<script lang="ts">
import Vue, {PropType} from 'vue';
import {mapState} from 'vuex';
import {Mutations, State} from '@/store';
import ReportService from '@services/report.service';
import {ContentTypeEnum} from '@services/http.service';
import TeamService from '@/team/team.service';
import ModalService from '@/modals/modal.service';
import {Baler, BalerLoadReport, Bin, Combine, FieldEntity, Marker, Spfh, Vehicle} from 'shared-frontend';
import {ModalTypeEnum} from '@/modals/modalType.enum';

export default Vue.extend({
  name: 'ContextMenu',
  props: {
    fieldEntity: Object as PropType<FieldEntity>,
    isTeamActive: Boolean
  },
  computed: {
    ...mapState({
      trans: (state: State) => state.translation.translations,
      fieldCoverageEnabled: (state: State) => state.fieldCoverageEnabled
    }),
    contentTypeEnum(): any {
      return ContentTypeEnum;
    },
    isBin(): boolean {
      return (this as any).fieldEntity instanceof Bin;
    },
    isArableVehicle(): boolean {
      return (
          (this as any).fieldEntity instanceof Combine ||
          (this as any).fieldEntity instanceof Baler ||
          (this as any).fieldEntity instanceof Spfh
      );
    },
    isBalerLoadReport(): boolean {
      return (this as any).fieldEntity instanceof BalerLoadReport;
    },
    isMarker(): boolean {
      return (this as any).fieldEntity instanceof Marker;
    },
    supportsEspCam(): boolean {
      const fieldEntity = (this as any).fieldEntity
      return fieldEntity instanceof Vehicle && fieldEntity.supportsEspCam();
    }
  },
  methods: {
    downloadBinReport(type: { contentType: string; extension: string }) {
      ReportService.downloadBinReport((this as any).selectedTeamId$, (this as any).fieldEntity.id, type);
    },
    toggleFieldCoverage(): void {
      this.$store.commit(Mutations.TOGGLE_FIELDCOVERAGE, !this.fieldCoverageEnabled);
    },
    showTelemetryInformation(): void {
      ModalService.showModal(ModalTypeEnum.TELEMETRY, {fieldEntity: this.fieldEntity})
    },
    showEspCams(): void {
      ModalService.showModal(ModalTypeEnum.ESP_CAMS, {fieldEntity: this.fieldEntity})
    },
    showMarkerInformation(): void {
      ModalService.showModal(ModalTypeEnum.MARKER, {marker: this.fieldEntity as Marker})
    }
  },
  subscriptions() {
    return {
      selectedTeamId$: TeamService.selectedTeamIdOrNull$
    };
  }
});
</script>
