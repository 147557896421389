<template>
  <div>
    <Title :title="translations.ADMIN.VEHICLE"/>
    <v-card class="dashboard__card">
      <v-card-text class="dashboard__textContainer">
        <v-progress-circular
            v-if="loading"
            color="primary"
            indeterminate
        />
        <template v-else>
          <v-select
              :items="macAddressInfos"
              v-model="macAddressInfo"
              :label="translations.ADMIN.MAC_ADDRESS"
          >
            <template v-slot:selection="data">
              {{ data.item.macAddress }} <span v-if="data.item.serialNumber">&nbsp;({{ data.item.serialNumber }})</span>
            </template>
            <template v-slot:item="data">
              {{ data.item.macAddress }} <span v-if="data.item.serialNumber">&nbsp;({{ data.item.serialNumber }})</span>
            </template>
          </v-select>

          <template v-if="macAddressInfo && uploadProgress">
            <h3 class="mb-1">{{ translations.ADMIN.LOGS }}</h3>
            <div v-if="uploadProgress.status === UploadStatus.NEVER_REQUESTED">
              {{ translations.ADMIN.UPLOAD_STATUS.NEVER_REQUESTED }}
            </div>
            <!-- User doesn't really have to know about the requested status, just treat it as started -->
            <div v-if="[UploadStatus.REQUESTED, UploadStatus.IN_PROGRESS].includes(uploadProgress.status)">
              {{ translations.ADMIN.UPLOAD_STATUS.IN_PROGRESS }}
            </div>
            <template v-if="uploadProgress.status === UploadStatus.SUCCEEDED">
              <div>
                {{ translations.ADMIN.UPLOAD_STATUS.SUCCEEDED }}
              </div>
            </template>
            <div v-if="uploadProgress.status === UploadStatus.FAILED">
              {{ translations.ADMIN.UPLOAD_STATUS.FAILED(uploadProgress.failureMessage) }}
            </div>
            <div class="admin__startUploadingLogsWrapper">
              <v-btn center :disabled="!uploadProgress.canStartNewUpload || forceDisableStartUpload" @click="startUploadingLogs">
                {{ translations.ADMIN.START_UPLOADING_LOGS }}
              </v-btn>
            </div>
          </template>
          <div class="admin__linkToStorage">
            <a :href="storageLink" target="storageAccount">{{ translations.ADMIN.LINK_TO_STORAGE }}</a>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Toolbar from '@/layout/Toolbar.vue';
import Navigation from "@/layout/Navigation.vue";
import {Brand, BrandingService} from "shared-frontend";
import {mapState} from 'vuex'
import {State} from '@/store'
import AdminService from '@/admin/admin.service';
import {UploadStatus} from '@/admin/admin.domain'
import authenticationService from '@services/authentication.service'
import {icons} from "@utils/icons.utils";
import Title from "@components/Title.component.vue";

export default Vue.extend({
  name: "Admin",
  components: {Title, Navigation, Toolbar},
  data() {
    return {
      macAddressInfos: null,
      macAddressInfo: null,
      uploadProgress: null,
      UploadStatus,
      intervalId: null,
      forceDisableStartUpload: false,
      isAdmin: null,
      storageLink: process.env.VUE_APP_ADMIN_STORAGE_ACCOUNT_URL
    }
  },
  async created() {
    this.isAdmin = await authenticationService.isAdmin();
    if (this.isAdmin) {
      this.macAddressInfos = await AdminService.getMacAddresses();
      this.intervalId = setInterval(async () => {
        if (this.macAddressInfo) {
          this.uploadProgress = await AdminService.getLogsUploadProgress(this.macAddressInfo.macAddress);
        }
      }, 1000)
    }
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    brandColors(): any {
      return BrandingService.getColors(process.env.VUE_APP_BRAND as Brand);
    },
    loading(): boolean {
      return this.macAddressInfos === null;
    },
  },
  methods: {
    icons() {
      return icons
    },
    async getUploadProgress() {
      this.uploadProgress = await AdminService.getLogsUploadProgress(this.macAddressInfo.macAddress);
    },
    async startUploadingLogs() {
      this.forceDisableStartUpload = true;
      await AdminService.startUploadingLogs(this.macAddressInfo.macAddress);
      await this.getUploadProgress()
      this.forceDisableStartUpload = false;
    },
    goToThirdPartyManagement() {
      this.$router.push({name: "thirdPartyManagement", params: {}});
    }
  },
  watch: {
    macAddressInfo() {
      this.getUploadProgress();
    }
  },
})
</script>

<style scoped>

</style>
