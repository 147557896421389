<template>
  <div class="titleWrapper">
    <div class="titleWrapper__root">
      <div class="titleWrapper__text">
        <h2 class="titleWrapper__title">{{ title }}</h2>
        <InfoIcon v-if="subtitle && !subtitleFull" :text="subtitle" />
      </div>
      <div class="titleWrapper__actions">
        <slot />
      </div>
    </div>

    <p class="titleWrapper__subtitle" v-if="subtitle && subtitleFull">{{ subtitle }}</p>
  </div>
</template>

<script>
import InfoIcon from "@components/InfoIcon.component.vue";

export default {
  name: "Title",
  components: { InfoIcon },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    subtitleFull: {
      type: Boolean,
      default: false
    }
  }
};
</script>
