<template>
  <div>
    <v-snackbar
        v-model="showSuccessSnackbar"
        color="success"
        absolute
        style="transform: translateY(-70vh)"
        timeout="3000"
    >
      The values are being recalibrated, this will take a few minutes!

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="showSuccessSnackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            @click="fetchRecalibrationData"
        >
          Lab Calibration
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Calibrate using lab results</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="calibrationForm" v-model="formValid">
              <v-text-field v-model="calibrationForm.ph" :rules="formRules.ph" type="number" label="pH - KCI" step="0.1" />
              <v-text-field v-model="calibrationForm.nitrogen" :rules="formRules.nitrogen" type="number" label="Stikstof (N)" step="0.1" suffix="0/00;" />
              <v-text-field v-model="calibrationForm.phosphorus" :rules="formRules.phosphorus" type="number" label="Fosfor (P)" suffix="mg/100g" step="0.1" />
              <v-text-field v-model="calibrationForm.potassium" :rules="formRules.potassium" type="number" label="Kalium (K)" suffix="mg/100g" step="0.1" />
              <v-text-field v-model="calibrationForm.carbon" :rules="formRules.carbon" type="number" label="Koolstof" suffix="%" step="0.1" />
              <v-text-field v-model="calibrationForm.humus" :rules="formRules.humus" type="number" label="Humus" suffix="%" step="0.1" />
              <v-text-field v-model="calibrationForm.calcium" :rules="formRules.calcium" type="number" label="Calcium (Ca)" suffix="mg/100g" step="0.1" />
              <v-text-field v-model="calibrationForm.natrium" :rules="formRules.natrium" type="number" label="Natrium (Na)" suffix="mg/100g" step="0.1" />
              <v-text-field v-model="calibrationForm.sulfur" :rules="formRules.sulfur" type="number" label="Zwavel (S)" suffix="mg/100g" step="0.1" />
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error" text
              @click="showDialog = false"
          >
            Close
          </v-btn>
          <v-btn
              color="primary"
              @click="handleSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar
          v-model="showErrorSnackbar"
          color="error"
          absolute
          style="transform: translateY(-70vh)"
          timeout="3000"
      >
        Something went wrong!

        <template v-slot:action="{ attrs }">
          <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="showErrorSnackbar = false"
          >
            X
          </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Vue from "vue";
import {getRecalibrationData, postRecalibrationData} from "@/apdtAdvice/soil-analysis/Recalibration.service";
import {RecalibrationData} from "@/apdtAdvice/soil-analysis/Recalibration.domain";

export default Vue.extend({
  name: "RecalibrateAction",
  props: {
    date: null
  },
  data() {
    return {
      showDialog: false,
      showSuccessSnackbar: false,
      showErrorSnackbar: false,
      formValid: false,
      calibrationForm:
          { ph: "0.0", nitrogen: "0.0", phosphorus: "0.0", potassium: "0.0", carbon: "0.0", humus: "0.0", calcium: "0.0", natrium: "0.0", sulfur: "0.0" },
      formRules: {
        ph: [
          v => !!v || this.translations.GENERAL.REQUIRED
        ],
        nitrogen: [
          v => !!v || this.translations.GENERAL.REQUIRED
        ],
        phosphorus: [
          v => !!v || this.translations.GENERAL.REQUIRED
        ],
        potassium: [
          v => !!v || this.translations.GENERAL.REQUIRED
        ],
        carbon: [
          v => !!v || this.translations.GENERAL.REQUIRED
        ],
        humus: [
          v => !!v || this.translations.GENERAL.REQUIRED
        ],
        calcium: [
          v => !!v || this.translations.GENERAL.REQUIRED
        ],
        natrium: [
          v => !!v || this.translations.GENERAL.REQUIRED
        ],
        sulfur: [
          v => !!v || this.translations.GENERAL.REQUIRED
        ]
      }
    };
  },
  computed: {
    ...mapState({
      currentField: state => state.apdtStore.current_field,
      translations: state => state.translation.translations

    })
  },
  methods: {
    async fetchRecalibrationData() {
      let calibrationData = await getRecalibrationData(this.currentField, this.date);
      this.calibrationForm.ph = calibrationData.ph.toString();
      this.calibrationForm.nitrogen = calibrationData.nitrogen.toString();
      this.calibrationForm.phosphorus = calibrationData.phosphorus.toString();
      this.calibrationForm.potassium = calibrationData.potassium.toString();
      this.calibrationForm.carbon = calibrationData.carbon.toString();
      this.calibrationForm.humus = calibrationData.humus.toString();
      this.calibrationForm.calcium = calibrationData.calcium.toString();
      this.calibrationForm.natrium = calibrationData.natrium.toString();
      this.calibrationForm.sulfur = calibrationData.sulfur.toString();
    },

    handleSave() {
      this.$refs.calibrationForm.validate();
      if (!this.formValid) {
        return;
      }
      let recalibrationData = new RecalibrationData({
        ph: Number(this.calibrationForm.ph),
        nitrogen: Number(this.calibrationForm.nitrogen),
        phosphorus: Number(this.calibrationForm.phosphorus),
        potassium: Number(this.calibrationForm.potassium),
        carbon: Number(this.calibrationForm.carbon),
        humus: Number(this.calibrationForm.humus),
        calcium: Number(this.calibrationForm.calcium),
        natrium: Number(this.calibrationForm.natrium),
        sulfur: Number(this.calibrationForm.sulfur),
      });
      postRecalibrationData(this.currentField, this.date, recalibrationData)
          .then(() => {
            this.showDialog = false;
            this.showSuccessSnackbar = true;
            this.fetchRecalibrationData();
          }).catch(reason => {
        this.showErrorSnackbar = true;
      });
    }
  },
  watch: {}
});
</script>

<style scoped></style>
