import httpService from "@services/http.service";
import {API_ENDPOINTS, MacAddress} from "shared-frontend";
import {logger} from "@services/logger.service";
import {parseLogsUploadProgress} from "@/admin/admin.utils.jsonParsers";
import {LogsUploadProgress, MacAddressInfo} from "@/admin/admin.domain";

class AdminService {

    public async getMacAddresses(): Promise<MacAddressInfo[]> {
        try {
            const response = await httpService.get(API_ENDPOINTS.MAC_ADDRESSES());
            return response.data
        } catch (err) {
            logger.error('[AdminService] - getMacAddresses', err);
        }
    }

    public async startUploadingLogs(mac: MacAddress): Promise<void> {
        try {
            await httpService.post(API_ENDPOINTS.START_UPLOADING_FOR_MAC_ADDRESS(mac), {});
        } catch (err) {
            logger.error('[AdminService] - uploadLogs', err);
        }
    }

    public async getLogsUploadProgress(mac: MacAddress): Promise<LogsUploadProgress> {
        try {
            const response = await httpService.get(API_ENDPOINTS.LOGS_UPLOAD_STATUS_FOR_MAC_ADDRESS(mac));
            return parseLogsUploadProgress(response.data)
        } catch (err) {
            logger.error('[AdminService] - getLogsUploadProgress', err);
        }
    }
}

export default new AdminService()
