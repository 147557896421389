import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs'
import {API_ENDPOINTS, MetalDetection, parseMetalDetection} from 'shared-frontend'
import TeamService from '@/team/team.service'
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators'
import httpService from '@services/http.service'
import {logger} from '@services/logger.service'

class MetalDetectionService {

    private metalDetectionsChanged$ = new BehaviorSubject<void>(void 0);

    public get metalDetections$(): Observable<MetalDetection[]> {
        return combineLatest(
            [
                TeamService.selectedTeamIdOrNull$.pipe(distinctUntilChanged()),
                this.metalDetectionsChanged$
            ])
            .pipe(
                map(([teamId, _]) => teamId),
                switchMap(teamId => {
                    if(teamId){
                        return this.getMetalDetections(teamId as string);
                    } else {
                        return of([])
                    }
                })
            )

    }

    public onMetalDetectionDetected() {
        console.debug(`[MetalDetectionService] - onMetalDetectionDetected`);
        this.metalDetectionsChanged$.next();
    }

    private async getMetalDetections(teamId: string): Promise<MetalDetection[]> {
        try {
            const response = await httpService.get(API_ENDPOINTS.METAL_DETECTIONS_FOR_FARMMANAGER(teamId));
            return response?.data?.map(parseMetalDetection) ?? [];
        } catch (err) {
            logger.error(`[getMetalDetections] - Error get MetalDetections for team ${teamId}`, err);
            return Promise.resolve([]);
        }
    }
}

export default new MetalDetectionService()
