// EXTERNAL
import { API_ENDPOINTS, Spfh, Spfhs, FarmSpfhJSON, parseFarmSpfh } from 'shared-frontend';
import httpService from '@services/http.service';
import { logger } from '@services/logger.service';

class FarmSpfhsService {
    public async fetchFarmSpfhs(): Promise<Spfhs> {
        try {
            const response = await httpService.get(API_ENDPOINTS.FARM_SPFHS());
            const farmSpfhsJSON: FarmSpfhJSON[] = response.data;

            const spfhs: Spfh[] = farmSpfhsJSON.map((farmSpfhJSON: FarmSpfhJSON) =>
                parseFarmSpfh(farmSpfhJSON)
            );

            logger.info('[FarmBinsService] - fetchFarmSpfhs', spfhs);

            return new Spfhs(spfhs);
        } catch (err) {
            logger.error(`[farmSpfhs.service] - fetchFarmSpfhs: Error in fetching farmSpfhs`, err);
        }
    }
}

export default new FarmSpfhsService();
