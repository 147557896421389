// EXTERNAL
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
//
// INTERNAL
// Shared
import {API_ENDPOINTS, FarmManager, UserInfoJSON} from 'shared-frontend';
//
// Services
import httpService from '@services/http.service';
import {logger} from '@services/logger.service';

class FarmManagerService {
    private _farmManager$: BehaviorSubject<FarmManager> = new BehaviorSubject<FarmManager>(null);

    public async init() {
        const farmManager = await this.getCurrentFarmManager();
        this.setFarmManagerToState(farmManager);
    }

    public get farmManager$() {
        return this._farmManager$.asObservable();
    }

    public get farmManagerId$(): Observable<string> {
        return this._farmManager$.pipe(
            map((farmManager: FarmManager) => farmManager?.id),
            distinctUntilChanged()
        );
    }

    public async getCurrentFarmManager(): Promise<FarmManager> {
        try {
            const response = await httpService.get(API_ENDPOINTS.CURRENT_USER());
            const userInfoJSON: UserInfoJSON = response.data;
            const { userId, userName, firstName, lastName } = userInfoJSON;

            // Create a farmManager object because me => farmManager
            const farmManager: FarmManager = new FarmManager(userId, null, userName, firstName, lastName);
            logger.debug('[FarmManagerService] - getCurrentFarmManager', farmManager);
            return farmManager;
        } catch (e) {
            logger.error('[FarmManagerService] - ERROR - getCurrentFarmManager: ', e);
            return null;
        }
    }

    public setFarmManagerToState(farmManager: FarmManager) {
        this._farmManager$.next(farmManager);
    }
}

export default new FarmManagerService();
