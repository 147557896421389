import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import authenticationService from '@services/authentication.service';
import KeycloakService from '@services/keycloak.service';
import {logger} from '@services/logger.service';


export const ContentTypeEnum = {
    ALL: 'text/html',
    JSON: 'application/json',
    CSV: {
        contentType: 'text/csv',
        extension: '.csv'
    },
    ZIP: 'application/zip',
    PDF: {contentType: 'application/pdf', extension: '.pdf'}
};

class HttpService {
    public get(url: string): Promise<AxiosResponse> {
        return this._request(url, Method.GET);
    }

    public getFile(url: string, contentType: string, responseType: string = null): Promise<AxiosResponse> {
        return this._request(url, Method.GET, null, contentType, null, {
            responseType: responseType,
        });
    }

    public post(url: string, body: any): Promise<AxiosResponse> {
        return this._request(url, Method.POST, body);
    }

    public postCallback(url: string, body: any, onUploadProgress: (progressEvent: any) => void): Promise<AxiosResponse> {
        return this._request(url, Method.POST, body, ContentTypeEnum.JSON, onUploadProgress);
    }

    public put(url: string, body: any): Promise<AxiosResponse> {
        return this._request(url, Method.PUT, body);
    }

    public patch(url: string, body: any): Promise<AxiosResponse> {
        return this._request(url, Method.PATCH, body);
    }

    public delete(url: string): Promise<AxiosResponse> {
        return this._request(url, Method.DELETE);
    }

    private _request(
        url: string,
        method: Method,
        body?: any,
        contentType: string = ContentTypeEnum.JSON,
        onUploadProgress: (progressEvent: any) => void = undefined,
        options: any = null
):

    Promise<AxiosResponse> {
        return authenticationService.getAuthorizationHeader().then(header => {
            const headers = {
                'content-type': ContentTypeEnum.JSON, // what we send
                Accept: contentType, // what we expect to receive
                ...header
            };

            // Interceptor: if we receive a 401 Unauthorized response, log user out and go to login page
            axios.interceptors.response.use(undefined, err => {
                return new Promise((resolve, reject) => {
                    if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                        logger.warn('[http.service] - Retry to login - 401 error');
                        KeycloakService.getOrInit().then(service =>
                            service.loginAndRedirectBackTo(window.location.href)
                        );
                    } else {
                        reject(err?.response?.data?.message ?? 'HTTP error')
                    }
                });
            });

            const axiosConfig: AxiosRequestConfig = {
                baseURL: process.env.VUE_APP_BACKEND_URL,
                headers,
                data: body,
                method,
                url,
                onUploadProgress,
                ...options
            };

            // Add additional header for file downloads (should be handled as a 'blob' response)
            // responseType: tells Axios how to handle the response for us (if this is incorrect, we get no response data (!) - so we only set this property for files: 'blob')
            if (contentType === ContentTypeEnum.PDF.contentType || contentType === ContentTypeEnum.CSV.contentType) {
                axiosConfig.responseType = 'blob';
            }

            return axios(axiosConfig);
        });
    }
}

export default new HttpService();

enum Method {
    GET = 'GET',
    PUT = 'PUT',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE'
}
