import {BehaviorSubject, from, Observable} from 'rxjs';
import {API_ENDPOINTS} from 'shared-frontend';
import {FilteredTeam} from '@/farmTeam/farmTeam.domain';
import {distinctUntilChanged, pluck, shareReplay, switchMap} from 'rxjs/operators';
import httpService from '@services/http.service';
import equal from 'fast-deep-equal/es6';

class FilteredFarmTeamService {
    private _filteredTeamIds$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    private _filteredTeams$: Observable<FilteredTeam[]>;

    public get filteredTeams$(): Observable<FilteredTeam[]> {
        if (!this._filteredTeams$) {
            this._filteredTeams$ = this._filteredTeamIds$.pipe(
                distinctUntilChanged(equal),
                switchMap(this.fetchFilteredTeams),
                shareReplay(1)
            )
        }
        return this._filteredTeams$;
    }

    public setFilteredTeamIds(filteredTeamIds: string[]): void {
        console.info(`[filteredFarmTeam.service] - Setting filtered team ids to ${filteredTeamIds.join(", ")}`)
        this._filteredTeamIds$.next(filteredTeamIds);
    }


    private fetchFilteredTeams(teamIds: string[]): Observable<FilteredTeam[]> {
        return from(httpService.post(API_ENDPOINTS.FARM_TEAMS(), teamIds)).pipe(
            pluck('data'),
        );
    };
}



export default new FilteredFarmTeamService();
