import { render, staticRenderFns } from "./VolumeWeightToFinish.component.vue?vue&type=template&id=30206859"
import script from "./VolumeWeightToFinish.component.vue?vue&type=script&lang=ts"
export * from "./VolumeWeightToFinish.component.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports