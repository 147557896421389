<template>
  <div class="agdnaCn1">
    <div class="agdnaImport__content">
      <Title :title="translations.AGDNA_CN1.TITLE" :subtitle="translations.AGDNA_CN1.SUBTITLE"/>

      <div class="agdnaImport__table">
        <div class="agdnaImport__banner">
          <v-btn
              color="primary"
              :loading="isSelecting"
              @click="handleFileImport"
          >
            <v-icon small>{{ icons.add }}</v-icon>
            {{ translations.AGDNA_CN1.ADD_FILE }}
          </v-btn>

          <input
              id="input_file"
              ref="uploader"
              class="d-none"
              type="file"
              @input="onFileChanged"
          >

          <v-progress-linear
              v-if="currentFile"
              :value="progress"
              color="light-blue"
              rounded
              height="26"
              font-color="white"
          >
            <strong style="color:white">{{ progress }}%</strong>
          </v-progress-linear>
        </div>

        <v-alert v-if="message" border="left" color="red" dark>
          {{ message }}
        </v-alert>

        <div v-if="fileInfos.length > 0">

          <v-divider/>

          <v-data-table
              :headers="[{
                            text: this.translations.AGDNA_CN1.TABLE.FILENAME,
                            align: 'start',
                            value: 'fileName',
                          },
                            {text: this.translations.AGDNA_CN1.TABLE.UPLOADED_ON, value: 'uploadedOn'},
                            {text: this.translations.AGDNA_CN1.TABLE.STATUS, value: 'status'}
                          ]"
              :items="fileInfos"
              disable-pagination
              hide-default-footer
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Toolbar from "@/layout/Toolbar.vue";
import Navigation from "@/layout/Navigation.vue";
import Title from "@components/Title.component.vue";
import AgdnaImportService from "@/agdnaImport/agdnaImport.service";
import Vue from "vue";
import {UploadProgress} from "@/agdnaImport/agdnaImport.domain";
import {mapState} from "vuex";
import {State} from "@/store";
import {icons} from "@utils/icons.utils";

export default Vue.extend({
  name: "AgdnaImport",
  components: {Title, Navigation, Toolbar},
  data() {
    return {
      currentFile: undefined,
      progress: 0,
      message: "",
      isSelecting: false,
      fileInfos: [],
    }
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    icons() {
      return icons;
    }
  },
  created() {
    this.updateFileInfo()
  },
  methods: {
    handleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, {once: true});

      (this.$refs.uploader as HTMLInputElement).click();
    },
    onFileChanged(e) {
      this.selectFile(e.target.files[0]);
    },
    selectFile(file) {
      this.progress = 0;
      this.currentFile = file;
      this.upload();
    },
    async upload() {
      this.message = "";

      if (!this.currentFile) {
        return;
      }

      const totalSize = this.currentFile.size
      this.progress = 0

      if (this.currentFile.type === 'application/zip' || this.currentFile.type === 'application/x-zip-compressed') {
        await AgdnaImportService.uploadCn1File(this.currentFile, (progressEvent) => this.uploadPogressCallback(totalSize, progressEvent))
      } else {
        this.message = this.translations.AGDNA_CN1.SEARCH.NO_ZIP_FILE
      }
      await this.updateFileInfo()
      this.currentFile = null;
    },
    async updateFileInfo() {
      AgdnaImportService.getFiles().then(response => {
        this.fileInfos = response.data.map((r) => {
          return new UploadProgress(r, this.translations.AGDNA_CN1.STATUS[r.status]);
        })
      })
    },
    uploadPogressCallback(totalsize, progressEvent) {
      this.progress = Math.round((progressEvent.loaded / totalsize) * 100)
    }
  }
})
</script>

<style scoped>

</style>
