<template>
  <div class="yearReport" @click="">
    <div class="yearReport__top">
      <span class="yearReport__year">{{ year }}</span>
    </div>
    <div class="yearReport__icons">
<!--      <div class="yearReport__icon" @click.stop="downloadYearReport(contentTypeEnum.PDF)">-->
<!--        <v-progress-circular indeterminate v-if="isLoadingPdf"/>-->
<!--        <v-icon v-else large>{{ icons.filePdf }}</v-icon>-->
<!--      </div>-->
      <div class="yearReport__icon" @click.stop="downloadYearReport(contentTypeEnum.CSV)">
        <v-progress-circular indeterminate v-if="isLoadingCsv"/>
        <v-icon v-else large>{{ icons.fileCsv }}</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import ReportService from '@services/report.service';
import {icons} from '@utils/icons.utils';
import {ContentTypeEnum} from '@services/http.service';

export default {
  name: 'YearReportBtn',
  props: {
    year: {
      type: Number,
      required: true
    },
  },
  computed: {
    contentTypeEnum() {
      return ContentTypeEnum;
    },
    icons() {
      return icons;
    },
    isLoadingPdf() {
      return this.isLoading[ContentTypeEnum.PDF.contentType];
    },
    isLoadingCsv() {
      return this.isLoading[ContentTypeEnum.CSV.contentType];
    },
  },
  data() {
    return {
      isLoading: {
        [ContentTypeEnum.PDF.contentType]: false,
        [ContentTypeEnum.CSV.contentType]: false,
      }
    }
  },
  methods: {
    downloadYearReport(responseType) {
      const contentType = responseType.contentType;

      if (this.isLoading[contentType]) return;

      this.isLoading[contentType] = true;

      ReportService.downloadYearReport(this.year, responseType).finally(() => {
        this.isLoading[contentType] = false;
      })
    }
  }
};
</script>
