<template>
    <router-view />
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'App',
    components: { },
});
</script>

<style lang="scss">
@import './styles/styles';
</style>
