<template>
  <v-text-field
      class="searchInput"
      :background-color="bgColor"
      color="primary"
      autofocus
      clearable
      outlined
      :placeholder="placeholder"
      @input="$emit('input', $event)"
  />
</template>

<script>
import {Brand, BrandingService, hexToRgba} from "shared-frontend";

export default {
  name: 'SearchInput',
  components: {},
  props: {
    value: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  computed: {
    bgColor() {
      return hexToRgba(BrandingService.getColors(process.env.VUE_APP_BRAND)?.PRIMARY, .1)
    },
  }
};
</script>
