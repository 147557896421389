import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw-src.css';
import Vue from 'vue';
import {
    LControl,
    LControlScale,
    LFeatureGroup,
    LIcon,
    LIconDefault,
    LMap,
    LMarker,
    LPolygon,
    LPolyline,
    LTileLayer,
    LTooltip,
    LPopup,
    LWMSTileLayer
} from 'vue2-leaflet';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-wms-layer', LWMSTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-control', LControl);
Vue.component('l-icon', LIcon);
Vue.component('l-icon-default', LIconDefault);
Vue.component('l-feature-group', LFeatureGroup);
Vue.component('l-tooltip', LTooltip);
Vue.component('l-polyline', LPolyline);
Vue.component('l-control-scale', LControlScale);
Vue.component('l-polygon', LPolygon);
Vue.component('l-popup', LPopup);

// @ts-ignore
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
