<template>
    <div class="mapView__warnings">
        <StoppedTeam v-if="team && !isActiveTeam" />
        <ViewingAsGrower v-if="isViewingAsGrower$" />
    </div>
</template>

<script lang="ts">
import Vue, {PropType} from 'vue';
import {Team} from 'shared-frontend';
import StoppedTeam from '@/map/warnings/StoppedTeam.component.vue';
import ViewingAsGrower from '@/map/warnings/ViewingAsGrower.component.vue';
import TeamService from '@/team/team.service';

export default Vue.extend({
  name: 'PolygonMap',
  components: {StoppedTeam, ViewingAsGrower},
  props: {
    team: {
      type: Object as PropType<Team>
    },
    isActiveTeam: {
      type: Boolean
    }
  },
  subscriptions() {
    return {
      isViewingAsGrower$: TeamService.isViewingAsGrower$
    }
  }
});
</script>
