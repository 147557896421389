import {Observable} from 'rxjs';
import {first, map, switchMap} from 'rxjs/operators';
import {CanbusVehicleTelemetry, FieldEntityEnum} from 'shared-frontend';
import EventSource from '@domain/eventSource.domain';
import TeamService from '@/team/team.service';
import {telemetrySSEConfig} from '@/telemetry/telemetry.helper';
import {logger} from '@services/logger.service';
import {pick} from '@utils/functional.util';

class TelemetryService {
    public getTelemetry$ = (vehicleId: string, arableVehicleType: FieldEntityEnum): Observable<CanbusVehicleTelemetry> => {
        const config = telemetrySSEConfig[arableVehicleType];

        if (config) {
            const { getUrl, eventTypes, parseTelemetry } = config;

            return TeamService.selectedTeam$.pipe(
                first(),
                map(getUrl(vehicleId)),
                switchMap((url: string) => EventSource.createObservable(url, eventTypes)),
                map(pick('data')),
                map(parseTelemetry(vehicleId))
            );
        } else {
            logger.error('Telemetry was requested for a harvester type that doesnt have a telemetry SSE');
        }
    };
}

export default new TelemetryService();
