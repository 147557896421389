import {AdviceFertilizingN1Form, AdviceFertilizingN2Form, AdviceFertilizingN3Form, AdviceLimingForm, AdviceSowingForm, Form, FormItem, FormItemType} from "@/apdtAdvice/forms/forms.domain";
import {Translations} from "@/translations/translations.type";
import {AdviceSource, AdviceSourceOption, AdviceSourceOptionData} from "@/apdtAdvice/advice.domain";
import {getActivityTypes} from "@/apdtAdvice/Advice.service";
import {ActivityType} from '@/apdtAdvice/advice.enum';

export class AdviceForm {
    private readonly _translations: Translations;
    private _action: string | undefined;
    private _formBuilder: FormBuilder | undefined;
    private _step: number;

    constructor(translations: Translations) {
        this._translations = translations;
        this._action = undefined;
        this._formBuilder = undefined;
        this._step = -1;
    }

    get currentFormItem(): FormItem | undefined {
        if (this.isFirstStep) {
            return this._getActionFormItem();
        }

        if (!this._formBuilder || this._formBuilder.form.items.length <= this._step) {
            return undefined;
        }

        return this._formBuilder.form.items[this._step];
    }

    get prevFormItems(): FormItem[] {
        if (this.isFirstStep) {
            return [];
        }
        return [this._getActionFormItem()].concat(this._formBuilder.form.items.slice(0, this._step));
    }

    get step(): number {
        return this._step;
    }

    get isFirstStep(): boolean {
        return this._step === -1;
    }

    get isLastStep(): boolean {
        if (!this._formBuilder) {
            return false;
        }

        return this._step === this._formBuilder.form.items.length - 1;
    }

    get canGoNext(): boolean {
        if (this.isFirstStep) {
            return true;
        }
        return this._formBuilder.form.items.length > this._step + 1;
    }

    get formItemSource(): FormItem | null {
        if (!this._formBuilder) {
            return null;
        }
        return this._formBuilder.form.items.find((item: FormItem) => item.type === FormItemType.source);
    }

    get isFormItemSourceActive(): boolean {
        if (!this._formBuilder) {
            return false;
        }
        const sourceIndex = this._formBuilder.form.items.findIndex(
            (item: FormItem) => item.type === FormItemType.source
        );
        return sourceIndex !== -1 && sourceIndex <= this._step;
    }

    public goNext(value?: any): void {
        if (this.isFirstStep) {
            this._changeAction(value);
        } else if (this.currentFormItem) {
            this._formBuilder.form.items[this._step].value = value;
        }

        if (this.canGoNext) {
            this._step += 1;
        }
    }

    public goPrev(): void {
        this._step -= 1;
    }

    private _changeAction(action: string): void {
        this._action = action;
        this._generateForm();
    }

    private _getActionFormItem(): FormItem {
        return new FormItem({
            name: "action",
            type: FormItemType.select,
            value: this._action,
            label: this._translations.APDT_ADVICE.FORMS.ACTION_LABEL,
            rules: [
                value => {
                    if (!value) return "Is required";
                    return true;
                }
            ]
            ,
            optionsApiService: () => getActivityTypes().then(value => value.map(item => ({
                value: item,
                // @ts-ignore
                label: this._translations.APDT_ADVICE.ACTIONS[item]
            })))
        });
    }

    private _generateForm(): void {
        switch (this._action) {
            case ActivityType.LIMING:
                this._formBuilder = new LimingFormBuilder(this._translations);
                break;
            case ActivityType.SOWING:
                this._formBuilder = new SowingFormBuilder(this._translations);
                break;
            case ActivityType.FUSARIUM:
                this._formBuilder = new FusariumFormBuilder(this._translations);
                break;
            case ActivityType.FERTILIZING_N1:
                this._formBuilder = new FertilizingN1FormBuilder(this._translations);
                break;
            case ActivityType.FERTILIZING_N2:
                this._formBuilder = new FertilizingN2FormBuilder(this._translations);
                break;
            case ActivityType.FERTILIZING_N3:
                this._formBuilder = new FertilizingN3FormBuilder(this._translations);
                break;
            case ActivityType.APHID:
                this._formBuilder = new AphidFormBuilder(this._translations);
                break;
            case ActivityType.OULEMA:
                this._formBuilder = new OulemaFormBuilder(this._translations);
                break;
            default: {
                this._formBuilder = undefined;
            }
        }
    }

    toString(): string {
        if (!this._formBuilder) {
            return "";
        }
        return this._formBuilder.toString();
    }

    toJSON(): any {
        if (this.isFirstStep) {
            return {};
        }

        const getValue = (item: FormItem): any => {
            switch (item.type) {
                case FormItemType.source: {
                    return (item.value as AdviceSource[]).filter((advice: AdviceSource) => advice.options.some(value => value.active)).flatMap((advice: AdviceSource) => {
                        return advice.options.filter(
                            (option: AdviceSourceOption) => option.active
                        ).flatMap(activeOption => {
                            return {
                                externalServiceType: advice.type.toLowerCase(),
                                dates: activeOption.data
                                    .filter((data: AdviceSourceOptionData) => data.active)
                                    .flatMap((data) => data.day.toISOString())
                            };
                        });
                    });
                }
                case FormItemType.liming_input: {
                    return {activityType: this._action, ...item.value};
                }
                case FormItemType.sowing_input: {
                    return {activityType: this._action, ...item.value};
                }
                case FormItemType.fertilizing_n1_input: {
                    return {activityType: this._action, ...item.value};
                }
                case FormItemType.fertilizing_n2_input: {
                    return {activityType: this._action, ...item.value};
                }
                case FormItemType.fertilizing_n3_input: {
                    return {activityType: this._action, ...item.value};
                }
                case FormItemType.fusarium_input: {
                    return {activityType: this._action, ...item.value};
                }
                default:
                    return item.value;
            }
        };

        const allItems = [this._getActionFormItem()].concat(this._formBuilder.form.items);
        let jsonOutput = allItems.reduce((output: any, currentItem: FormItem) => {
            return {...output, [currentItem.name]: getValue(currentItem)};
        }, {});
        if (this._action === ActivityType.APHID || this._action === ActivityType.OULEMA) {
            return {...jsonOutput, inputData: {activityType: this._action}};
        } else {
            return jsonOutput;
        }
    }

    get action(): String {
        return this._action
    }

}

export abstract class FormBuilder {
    protected readonly _translations: Translations;
    private readonly _form: Form;

    constructor(translations: Translations) {
        this._translations = translations;
        this._form = new Form(this._getFormItems());
    }

    get form(): Form {
        return this._form;
    }

    protected abstract _getFormItems(): FormItem[];
}

export class LimingFormBuilder extends FormBuilder {
    protected _getFormItems(): FormItem[] {
        return [
            new FormItem({
                name: "inputData",
                type: FormItemType.liming_input,
                value: new AdviceLimingForm()
            }),
            new FormItem({
                name: "selectedSources",
                type: FormItemType.source,
                label: this._translations.APDT_ADVICE.FORMS.SOURCE.LABEL,
                value: []
            })
        ];
    }

    toString(): string {
        return `We will generate an advice for Liming.`;
    }
}

export class SowingFormBuilder extends FormBuilder {
    protected _getFormItems(): FormItem[] {
        return [
            new FormItem({
                name: "inputData",
                type: FormItemType.sowing_input,
                value: new AdviceSowingForm()
            }),
            new FormItem({
                name: "selectedSources",
                type: FormItemType.source,
                label: this._translations.APDT_ADVICE.FORMS.SOURCE.LABEL,
                value: []
            })
        ];
    }

    toString(): string {
        return `We will generate an advice for SOWING.`;
    }
}

export class FusariumFormBuilder extends FormBuilder {
    protected _getFormItems(): FormItem[] {
        return [
            new FormItem({
                name: "inputData",
                type: FormItemType.fusarium_input,
                value: new AdviceSowingForm()
            })
        ];
    }

    toString(): string {
        return `We will generate an advice for SOWING.`;
    }
}


export class FertilizingN1FormBuilder extends FormBuilder {
    protected _getFormItems(): FormItem[] {
        return [
            new FormItem({
                name: "inputData",
                type: FormItemType.fertilizing_n1_input,
                value: new AdviceFertilizingN1Form()
            }),
            new FormItem({
                name: "selectedSources",
                type: FormItemType.source,
                label: this._translations.APDT_ADVICE.FORMS.SOURCE.LABEL,
                value: []
            })
        ];
    }

    toString(): string {
        return `We will generate an advice for Fertilizing N1.`;
    }
}

export class FertilizingN2FormBuilder extends FormBuilder {
    protected _getFormItems(): FormItem[] {
        return [
            new FormItem({
                name: "inputData",
                type: FormItemType.fertilizing_n2_input,
                value: new AdviceFertilizingN2Form()
            }),
            new FormItem({
                name: "selectedSources",
                type: FormItemType.source,
                label: this._translations.APDT_ADVICE.FORMS.SOURCE.LABEL,
                value: []
            })
        ];
    }

    toString(): string {
        return `We will generate an advice  for Fertilizing N2.`;
    }
}

export class FertilizingN3FormBuilder extends FormBuilder {
    protected _getFormItems(): FormItem[] {
        return [
            new FormItem({
                name: "inputData",
                type: FormItemType.fertilizing_n3_input,
                value: new AdviceFertilizingN3Form()
            }),
            new FormItem({
                name: "selectedSources",
                type: FormItemType.source,
                label: this._translations.APDT_ADVICE.FORMS.SOURCE.LABEL,
                value: []
            })
        ];
    }

    toString(): string {
        return `We will generate an advice  for Fertilizing N3.`;
    }
}

export class AphidFormBuilder extends FormBuilder {
    protected _getFormItems(): FormItem[] {
        return [
            new FormItem({
                name: "selectedSources",
                type: FormItemType.source,
                label: this._translations.APDT_ADVICE.FORMS.SOURCE.LABEL,
                value: []
            })
        ];
    }

    toString(): string {
        return `We will generate an advice  for Aphid.`;
    }
}

export class OulemaFormBuilder extends FormBuilder {
    protected _getFormItems(): FormItem[] {
        return [
            new FormItem({
                name: "selectedSources",
                type: FormItemType.source,
                label: this._translations.APDT_ADVICE.FORMS.SOURCE.LABEL,
                value: []
            })
        ];
    }

    toString(): string {
        return `We will generate an advice  for Aphid.`;
    }
}
