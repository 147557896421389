import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs'
import {API_ENDPOINTS, Marker, MarkerId, parseFarmMarker, parsePicture, Picture} from 'shared-frontend'
import TeamService from '@/team/team.service'
import {distinctUntilChanged, map} from 'rxjs/operators'
import {flatMap} from 'rxjs/internal/operators'
import httpService from '@services/http.service'
import {logger} from '@services/logger.service'

class MarkerService {

    private markersChanged$ = new BehaviorSubject<void>(void 0);

    public get markers$(): Observable<Marker[]> {
        // Just always retrieve all markers again, saves us from a lot of possible race conditions
        return combineLatest(
            [
                TeamService.selectedTeamIdOrNull$.pipe(distinctUntilChanged()),
                this.markersChanged$
            ])
            .pipe(
                map(([teamId, _]) => teamId),
                flatMap(teamId => {
                    if(teamId){
                        return this.getMarkers(teamId as string);
                    } else{
                        return of([])
                    }
                })
            )

    }

    public onUserCreatedMarker() {
        console.debug(`[MarkerService] - onUserCreatedMarker`);
        this.markersChanged$.next();
    }

    public onUserDeletedMarker() {
        console.debug(`[MarkerService] - onUserDeletedMarker`);
        this.markersChanged$.next();
    }

    public async getMarkerPicture(teamId: string, markerId: MarkerId): Promise<Picture> {
        logger.debug(`[MarkerService] - Retrieving picture for marker ${markerId}`);
        const response = await httpService.get(API_ENDPOINTS.MARKER_FOR_FARMMANAGER_PICTURE(teamId, markerId))
        const pictureJSOn = response.data
        return parsePicture(pictureJSOn)
    }

    private async getMarkers(teamId: string): Promise<Marker[]> {
        try {
            const response = await httpService.get(API_ENDPOINTS.MARKERS_FOR_FARMMANAGER(teamId));
            return response?.data?.map(parseFarmMarker) ?? [];
        } catch (err) {
            logger.error(`[getMarkers] - Error get markers for team ${teamId}`, err);
            return Promise.resolve([]);
        }
    }
}

export default new MarkerService()
