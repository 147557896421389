import { render, staticRenderFns } from "./AdviceSource.day-selector.vue?vue&type=template&id=af951438&scoped=true"
import script from "./AdviceSource.day-selector.vue?vue&type=script&lang=ts"
export * from "./AdviceSource.day-selector.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af951438",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VListItem } from 'vuetify/lib/components/VList';
installComponents(component, {VCombobox,VListItem})
