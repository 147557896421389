<template>
  <div v-if="team && isActiveTeam">
    <div v-if="isVisible" class="mapView__statistics" :style="{ borderColor: primaryColor }">
      <TimeToBinFull :time-to-bin-full="timeToBinFull" :primary-color="primaryColor"/>
      <FieldFinishedAt :field-finished-at="fieldFinishedAt" :primary-color="primaryColor"/>
      <EstimatedTotalBaleCount :estimated-total-bale-count="estimatedTotalBaleCount" :primary-color="primaryColor"/>
      <EstimatedBinsLeft :estimated-bins-left="estimatedBinsLeft" :primary-color="primaryColor"/>
      <VolumeWeightToFinish :value="volumeWeightToFinishFormatted" :primary-color="primaryColor"/>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, {PropType} from 'vue';
import {Brand, BrandingService, pick, secondsToDuration, Team} from 'shared-frontend';
import HarvestingProgressService from '@services/harvestingProgress.service';
import {mapState} from 'vuex';
import {map} from 'rxjs/operators';
import {State} from '@/store';
import TeamService from '@/team/team.service';
import FieldFinishedAt from './FieldFinishedAt.component.vue';
import EstimatedTotalBaleCount from './EstimatedTotalBaleCount.component.vue';
import TimeToBinFull from './TimeToBinFull.component.vue';
import {notNullOrUndefined} from 'shared-frontend/dist/utils/functional.utils';
import EstimatedBinsLeft from "@/map/statistics/EstimatedBinsLeft.component.vue";
import VolumeWeightToFinish from "@/map/statistics/VolumeWeightToFinish.component.vue";

export default Vue.extend({
  name: 'Statistics',
  components: {VolumeWeightToFinish, EstimatedBinsLeft, FieldFinishedAt, TimeToBinFull, EstimatedTotalBaleCount},
  props: {
    team: {
      type: Object as PropType<Team>
    },
    isActiveTeam: {
      type: Boolean
    }
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    primaryColor(): string {
      return BrandingService.getColors((process.env.VUE_APP_BRAND as Brand) ?? Brand.NEW_HOLLAND)?.PRIMARY;
    },
    isVisible(): boolean {
      return (
          notNullOrUndefined(this.timeToBinFull) ||
          notNullOrUndefined(this.fieldFinishedAt) ||
          notNullOrUndefined(this.estimatedTotalBaleCount) ||
          notNullOrUndefined(this.estimatedBinsLeft) ||
          notNullOrUndefined(this.volumeWeightToFinishFormatted)
      );
    },
    fieldFinishedAt(): Date {
      return (this as any).harvestingProgress$?.fieldFinishedAt;
    },
    estimatedTotalBaleCount(): number {
      return (this as any).harvestingProgress$?.estimatedTotalBaleCount;
    },
    timeToBinFull(): string {
      // Take the first bin with a non-empty timeToBinFull
      const bin = [...(this as any).bins$].find((b) => Boolean(b?.telemetry?.timeToBinFull));
      return bin ? secondsToDuration(bin.telemetry.timeToBinFull) : null;
    },
    estimatedBinsLeft(): number {
      return (this as any).harvestingProgress$?.estimatedBinsLeft;
    },
    weightToFinishFormatted(): number {
      return (this as any).harvestingProgress$?.weightToFinishFormatted;
    },
    volumeToFinishFormatted(): string {
      return (this as any).harvestingProgress$?.volumeToFinishFormatted;
    },
    volumeWeightToFinishFormatted(): string {
      return (this as any).harvestingProgress$?.volumeWeightToFinishFormatted;
    },
  },
  subscriptions() {
    return {
      harvestingProgress$: HarvestingProgressService.harvestingProgress$,
      bins$: TeamService.selectedTeam$.pipe(
          map(pick('bins'))
      )
    };
  }
});
</script>
