import Vue from 'vue';
import Vuex from 'vuex';
import {LanguageEnum, User} from 'shared-frontend';

import { Modal } from '@domain/modal.domain';
import en from '@translations/en';
import translationMap from '@translations/index';
import { Translations } from '@translations/translations.type';
import apdtStore from '@/apdtAdvice/store/apdtStore';

export const enum Mutations {
    UPDATE_MODAL_SHOWN = 'updateModalShown',
    UPDATE_LANGUAGE = 'updateLanguage',
    TOGGLE_FIELDCOVERAGE = 'toggleFieldCoverage',
    SET_CURRENT_USER = 'setCurrentUser'
}

Vue.use(Vuex);

export default new Vuex.Store<State>({
    modules:{
      apdtStore
    },
    state: {
        currentUser: null,
        modalShown: null,
        translation: { translations: en, language: LanguageEnum.ENGLISH },
        fieldCoverageEnabled: true,
    },
    mutations: {
        [Mutations.SET_CURRENT_USER](state: State, user: User) {
            state.currentUser = user;
        },
        [Mutations.UPDATE_MODAL_SHOWN](state: State, modal: Modal) {
            state.modalShown = modal;
        },
        [Mutations.UPDATE_LANGUAGE](state: State, language: LanguageEnum) {
            const findTranslation = translationMap.find((t) => t.key.toLowerCase() === language.toLowerCase());

            if (!findTranslation) {
                return;
            }

            state.translation = { translations: findTranslation.translations, language };
        },
        [Mutations.TOGGLE_FIELDCOVERAGE](state: State, enabled: boolean) {
            state.fieldCoverageEnabled = enabled;
        }
    }
});

export interface State {
    currentUser: User;
    modalShown: Modal;
    translation: {
        translations: Translations;
        language: LanguageEnum;
    };
    fieldCoverageEnabled: boolean;
}
