import { Brand } from 'shared-frontend';

export const images = {
    [Brand.NEW_HOLLAND]: {
        LOGO: require('../assets/images/NH_logo.png'),
        LOGIN: require('../assets/images/NH_login.png')
    },
    [Brand.CASE]: {
        LOGO: require('../assets/images/Case_logo.png'),
        LOGIN: require('../assets/images/Case_login.jpg')
    },
    SHARED: {
        WHEAT_FIELD: require('../assets/images/wheat-field.jpg'),
        EVO_PORTAL_GEAR: require('../assets/images/evo_portal_gear.png'),
    }
};