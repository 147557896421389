import {
    eqFarmTeam,
    FarmTeamCreatedEvent,
    FarmTeamDeletedEvent,
    FarmTeamDetail,
    FarmTeamEvent,
    FarmTeamEventTypes,
    FarmTeamLinkedEvent,
    FarmTeamSummary,
    FarmTeamUnlinkedEvent,
    FarmTeamUpdatedEvent,
    UpdateFarmTeam
} from '@/farmTeam/farmTeam.domain';
import {addItemToArray, deleteBy, pick, updateArray, updateArrayItem} from '@utils/functional.util';
import {parseDate, parseFarmBalersInfo, parseFarmBaleServiceVehiclesInfo, parseFarmBinsInfo, parseFarmCombinesInfo, parseFarmSpfhsInfo, parseFarmUsersInfo, Team} from 'shared-frontend';
import {parseBoundary} from 'shared-frontend/dist/boundary/boundary.jsonParsers.utils';

export const reduceFarmTeamEvent = (farmTeams: FarmTeamSummary[], event: FarmTeamEvent): FarmTeamSummary[] => {
    switch (event.type) {
        case FarmTeamEventTypes.FARM_TEAM_CREATED:
            return reduceFarmTeamCreatedEvent(farmTeams, event as FarmTeamCreatedEvent);
        case FarmTeamEventTypes.FARM_TEAM_UPDATED:
            return reduceFarmTeamUpdatedEvent(farmTeams, event as FarmTeamUpdatedEvent);
        case FarmTeamEventTypes.FARM_TEAM_DELETED:
            return reduceFarmTeamDeletedEvent(farmTeams, event as FarmTeamDeletedEvent);
        case FarmTeamEventTypes.FARM_TEAM_LINKED:
            return reduceFarmTeamLinkedEvent(farmTeams, event as FarmTeamLinkedEvent);
        case FarmTeamEventTypes.FARM_TEAM_UNLINKED:
            return reduceFarmTeamUnlinkedEvent(farmTeams, event as FarmTeamUnlinkedEvent);
    }
};

const reduceFarmTeamCreatedEvent = (farmTeams: FarmTeamSummary[], event: FarmTeamCreatedEvent): FarmTeamSummary[] => {
    return addItemToArray<FarmTeamSummary>(eqFarmTeam)(farmTeams, event.farmTeam);
};

const reduceFarmTeamUpdatedEvent = (farmTeams: FarmTeamSummary[], event: FarmTeamUpdatedEvent): FarmTeamSummary[] => {
    return updateArray<FarmTeamSummary>(eqFarmTeam)(farmTeams, event.farmTeam);
};

const reduceFarmTeamDeletedEvent = (farmTeams: FarmTeamSummary[], event: FarmTeamDeletedEvent): FarmTeamSummary[] => {
    const selectTeam = (farmTeam: FarmTeamSummary) => farmTeam.teamId === event.farmTeamId;
    return deleteBy<FarmTeamSummary>(farmTeams, selectTeam);
};

const reduceFarmTeamLinkedEvent = (farmTeams: FarmTeamSummary[], event: FarmTeamLinkedEvent): FarmTeamSummary[] => {
    const selectTeam = (farmTeam: FarmTeamSummary): boolean => farmTeam?.teamId === event.farmTeamId;
    const updateLinkedTo = (farmTeam: FarmTeamSummary): FarmTeamSummary => ({...farmTeam, linkedTo: event.linkedTo});
    return updateArrayItem<FarmTeamSummary>(farmTeams, selectTeam, updateLinkedTo);
};

const reduceFarmTeamUnlinkedEvent = (farmTeams: FarmTeamSummary[], event: FarmTeamUnlinkedEvent): FarmTeamSummary[] => {
    const selectTeam = (farmTeam: FarmTeamSummary): boolean => farmTeam?.teamId === event.farmTeamId;
    const updateLinkedTo = (farmTeam: FarmTeamSummary): FarmTeamSummary => ({...farmTeam, linkedTo: null});
    return updateArrayItem<FarmTeamSummary>(farmTeams, selectTeam, updateLinkedTo);
};

// Helper functions
// ================

export const farmTeamSummaryToTeam = (farmTeamSummary: FarmTeamSummary): Team => {
    const {
        teamId,
        teamName,
        year,
        growerName,
        farmName,
        fieldName,
        cropName,
        operatorName,
        startedOn,
        stoppedOn,
        ownedBy,
        linkedTo
    } = farmTeamSummary;

    const {farmMgrId} = ownedBy || {};
    const {userId: linkedGrowerId} = linkedTo || {};

    const team = new Team(teamId, teamName, farmMgrId, null, parseDate(startedOn), parseDate(stoppedOn), year);

    return team
        .updateTeamState({
                name: teamName,
                grower: growerName,
                farm: farmName,
                field: fieldName,
                crop: cropName,
                operator: operatorName,
                boundary: null,
                users: null,
                combines: null,
                spfhs: null,
                balers: null,
                bins: null,
                baleServiceVehicles: null,
                dumps: null,
                gates: null,
            }
        )
        .updateLinkedGrower(linkedGrowerId);
};

export const farmTeamDetailToTeam = (farmTeam: FarmTeamDetail): Team => {
    const {
        teamId,
        teamName,
        year,
        growerName,
        farmName,
        fieldName,
        cropName,
        operatorName,
        startedOn,
        stoppedOn,
        combines,
        spfhs,
        balers,
        bins,
        baleServiceVehicles,
        users,
        boundary,
        ownedBy,
        linkedTo
    } = farmTeam;

    const {farmMgrId} = ownedBy || {};
    const {userId: linkedGrowerId} = linkedTo || {};


    const team = new Team(
        teamId,
        teamName,
        farmMgrId,
        parseBoundary(boundary),
        parseDate(startedOn),
        parseDate(stoppedOn),
        year
    );

    return team
        .updateTeamState({
                name: teamName,
                grower: growerName,
                farm: farmName,
                field: fieldName,
                crop: cropName,
                operator: operatorName,
                boundary: parseBoundary(boundary),
                users: parseFarmUsersInfo(users),
                combines: parseFarmCombinesInfo(combines),
                spfhs: parseFarmSpfhsInfo(spfhs),
                balers: parseFarmBalersInfo(balers),
                bins: parseFarmBinsInfo(bins),
                baleServiceVehicles: parseFarmBaleServiceVehiclesInfo(baleServiceVehicles),
                dumps: null,
                gates: null,
            }
        )
        .updateLinkedGrower(linkedGrowerId);
};

export const toUpdateFarmTeam = (team: Team): UpdateFarmTeam => {
    return {
        teamName: team.name,
        growerName: team.grower,
        farmName: team.farm,
        year: team.year,
        fieldName: team.field,
        cropName: team.crop,
        operatorName: team.operator
    };
};



const isTeamActive = (farmTeam: FarmTeamSummary): boolean => {
    return farmTeam ? farmTeam.teamId && !Boolean(farmTeam.stoppedOn) : false;
};
const PREMIUM_FOR_ACTIVE = Date.now();
const sortValueByDate = (team: FarmTeamSummary) =>
    isTeamActive(team)
        ? (parseDate(team?.startedOn)?.getTime() ?? 0) + PREMIUM_FOR_ACTIVE
        : parseDate(team?.stoppedOn)?.getTime() ?? 0;
export const sortByMostRecent = (teamA: FarmTeamSummary, teamB: FarmTeamSummary): number => {
    return sortValueByDate(teamB) - sortValueByDate(teamA);
};

export function sortAlphabeticallyBy(key: string, reverse: boolean = false) {
    return (teamA: FarmTeamSummary, teamB: FarmTeamSummary): number => {
        const valA = (pick(key)(teamA) ?? "").toString();
        const valB = (pick(key)(teamB) ?? "").toString();
        const result = valA.localeCompare(valB);
        return reverse ? -1 * result : result;
    };
}

export const getMostRecentActiveTeam = (farmTeams: FarmTeamSummary[]): FarmTeamSummary => {
    if (farmTeams.length > 0) {
        return farmTeams.sort(sortByMostRecent)[0];
    } else {
        return null
    }
};
