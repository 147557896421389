import {concat, from, Observable} from 'rxjs';
import {map, pluck, scan} from 'rxjs/operators';

import {API_ENDPOINTS} from 'shared-frontend';
import httpService from '@services/http.service';
import {CreateFarmBin, FarmBin, FarmBinEvent, FarmBinsSseEvents} from '@/farmBin/farmBin.domain';
import EventSource from '@domain/eventSource.domain';
import {logger} from '@services/logger.service';
import {reduceFarmBinEvent} from '@/farmBin/farmBin.helper';
import {pick} from '@utils/functional.util';

class FarmBinService {
    public get farmBins$(): Observable<FarmBin[]> {
        return concat(this.fetchFarmBins$(), this.farmBinEvents$()).pipe(scan(reduceFarmBinEvent));
    }

    public fetchFarmBins$ = (): Observable<FarmBin[]> => {
        return from(httpService.get(API_ENDPOINTS.FARM_BINS())).pipe(pluck('data'));
    };

    public farmBinEvents$(): Observable<FarmBinEvent> {
        const url = `${process.env.VUE_APP_BACKEND_URL}${API_ENDPOINTS.FARM_BINS()}`;
        const eventTypes = Object.values(FarmBinsSseEvents);
        return EventSource.createObservable(url, eventTypes).pipe(map(pick('data')));
    }

    public async addFarmBin(createFarmBin: CreateFarmBin): Promise<string> {
        const newFarmBin = await httpService.post(API_ENDPOINTS.FARM_BINS(), createFarmBin);
        logger.info('[farmBins.service] - addFarmBin: farmBin ADDED, ID: ', newFarmBin.data);
        return newFarmBin.data;
    }

    public async deleteFarmBin(farmBin: FarmBin): Promise<void> {
        await httpService.delete(API_ENDPOINTS.MY_FARMMANAGER_BIN(farmBin.binId));
        logger.info('[farmBins.service] - deleteFarmBin: farmBin DELETED');
    }
}

export default new FarmBinService();
