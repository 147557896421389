import {addItemToArray, deleteById, updateArray} from 'shared-frontend';
import {WorkForceMember} from '@/workforce/workForceMember.domain';

// A workForce is the collection of persons that a farmManager added to this workforce

export class Workforce {
    public members: WorkForceMember[];

    constructor(workforce: WorkForceMember[]) {
        this.members = workforce;
    }

    public addMember(newMember: WorkForceMember): Workforce {
        const isAddedAlready = this.members.some((member: WorkForceMember) => member.id === newMember.id);
        if (isAddedAlready) return this;
        return new Workforce(addItemToArray(this.members, newMember));
    }

    public removeMember(memberId: string): Workforce {
        return new Workforce(deleteById(this.members, memberId));
    }

    public setMemberActiveStatus(memberToUpdate: WorkForceMember, isActive: boolean) {
        const indexToUpdate = this.members.findIndex((member: WorkForceMember) => member.id === memberToUpdate.id);
        if (indexToUpdate === -1) return this;
        memberToUpdate.active = isActive;
        return new Workforce(updateArray(this.members, memberToUpdate, indexToUpdate));
    }

    public setMemberAuthorityLevel(memberToUpdate: WorkForceMember, hasAuthority: boolean) {
        const indexToUpdate = this.members.findIndex((member: WorkForceMember) => member.id === memberToUpdate.id);
        if (indexToUpdate === -1) return this;
        memberToUpdate.authorityLevel = hasAuthority;
        return new Workforce(updateArray(this.members, memberToUpdate, indexToUpdate));
    }
}