import {addItemToArray, deleteBy, updateArray} from '@utils/functional.util';
import {CreateFarmBin, eqFarmBin, FarmBin, FarmBinCreatedEvent, FarmBinEvent, FarmBinEventTypes, FarmBinRemovedEvent, FarmBinUpdatedEvent} from '@/farmBin/farmBin.domain';
import {Bin, parseBinIcon} from 'shared-frontend';

export const reduceFarmBinEvent = (farmBins: FarmBin[], event: FarmBinEvent): FarmBin[] => {
    switch (event.type) {
        case FarmBinEventTypes.FARM_BIN_CREATED:
            return reduceFarmBinCreatedEvent(farmBins, event as FarmBinCreatedEvent);
        case FarmBinEventTypes.FARM_BIN_UPDATED:
            return reduceFarmBinUpdatedEvent(farmBins, event as FarmBinUpdatedEvent);
        case FarmBinEventTypes.FARM_BIN_REMOVED:
            return reduceFarmBinDeletedEvent(farmBins, event as FarmBinRemovedEvent);
    }
}


const reduceFarmBinCreatedEvent = (farmBins: FarmBin[], event: FarmBinCreatedEvent): FarmBin[] => {
    return addItemToArray<FarmBin>(eqFarmBin)(farmBins, event.farmBin);
}

const reduceFarmBinUpdatedEvent = (farmBins: FarmBin[], event: FarmBinUpdatedEvent): FarmBin[] => {
    return updateArray<FarmBin>(eqFarmBin)(farmBins, event.farmBin)
}

const reduceFarmBinDeletedEvent = (farmBins: FarmBin[], event: FarmBinRemovedEvent): FarmBin[] => {
    const selectFarmBin = (farmBin: FarmBin) => farmBin.binId === event.binId;
    return deleteBy<FarmBin>(farmBins, selectFarmBin);
}

// Convert FarmBin from/to other structs
// ======================================

export const farmBinToBin = ({binId, binName, tankCapacity__m3, icon}: FarmBin): Bin => {
    return new Bin(binId, null, binName, null, parseBinIcon(icon), tankCapacity__m3, false,null, false);
};

export const binToCreateFarmBin = (bin: Bin): CreateFarmBin => {
    return {
        binName: bin.name,
        tankCapacity__m3: bin.tankCapacity__m3,
        icon: JSON.stringify(bin.icon)
    }
}
