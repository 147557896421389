<template>
    <v-tooltip max-width="400" right>
        <template v-slot:activator="{ on }">
            <v-btn icon>
                <v-icon small v-on="on">{{ icons.info }}</v-icon>
            </v-btn>
        </template>
        <span>{{ text }}</span>
    </v-tooltip>
</template>

<script>
import { icons } from '../utils/icons.utils';

export default {
    name: 'InfoIcon',
    props: {
        text: String
    },
    computed: {
        icons() {
            return icons;
        }
    }
};
</script>
