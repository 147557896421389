import { CanbusVehicleTelemetry, Team } from 'shared-frontend';

export enum TelemetrySseEvents {
    COMBINE_TELEMETRY = 'combineTelemetry',
    SPFH_TELEMETRY = 'spfhTelemetry',
    BALER_TELEMETRY = 'balerTelemetry'
}

export interface TelemetryConfig {
    getUrl: (vehicleId: string) => (team: Team) => string;
    eventTypes: string[];
    parseTelemetry: (vehicleId: string) => (json: any) => CanbusVehicleTelemetry;
}
