import {pick} from 'shared-frontend';
import httpService from "@services/http.service";

class AgdnaImportService {
    public async uploadCn1File(cn1File: File | Blob, onUploadProgress: (progressEvent: any) => void): Promise<string> {
        const formData = new FormData();
        formData.append('file', cn1File);

        return httpService.postCallback(`${process.env.VUE_APP_APDT_URL}/api/cn1-extract`, formData, onUploadProgress).then(pick('data'))
    }

    async getFiles() {
        return httpService.get(`${process.env.VUE_APP_APDT_URL}/api/cn1-extract`)
    }
}

export default new AgdnaImportService()
