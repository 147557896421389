import {BehaviorSubject} from 'rxjs';
import {HarvestingProgress} from 'shared-frontend';
import {distinctUntilChanged, filter, startWith, switchMap} from 'rxjs/operators';
import TeamService from '@/team/team.service'

class HarvestingProgressService {
    private _harvestingProgress$: BehaviorSubject<HarvestingProgress> = new BehaviorSubject<HarvestingProgress>(null);

    public get harvestingProgress$() {
        return TeamService.selectedTeamIdOrNull$.pipe(distinctUntilChanged()).pipe(switchMap((teamId: string) => {
            return this._harvestingProgress$.pipe(
                filter((harvestingProgress: HarvestingProgress) => harvestingProgress?.teamId === teamId),
                startWith(null as HarvestingProgress),
        )}));
    }

    public updateHarvestingProgress(harvestingProgress: HarvestingProgress): void {
        this._harvestingProgress$.next(harvestingProgress);
    }
}

export default new HarvestingProgressService();
