import moment from "moment";
import {AdviceSourceType} from "@/apdtAdvice/advice.enum";

export class ApdtField {
    private readonly _fieldId: String;
    private readonly _boundary: String;
    private readonly _name: String;
    private readonly _hcaRef: String;

    constructor(item: any) {
        this._fieldId = item.fieldId.value;
        this._boundary = item.boundary;
        this._name = item.name;
        this._hcaRef = item.hcaRef;
    }

    get fieldId(): String {
        return this._fieldId;
    }

    get boundary(): String {
        return this._boundary;
    }

    get name(): String {
        return this._name;
    }

    get hcaRef(): String {
        return this._hcaRef;
    }
}

export class AdviceSourceApi {
    private readonly _dates: Date[];
    private readonly _externalServiceType: string;

    constructor(item: Partial<AdviceSourceApi>) {
        this._dates = item.dates;
        this._externalServiceType = item.externalServiceType;
    }

    get dates(): Date[] {
        return this._dates;
    }

    get externalServiceType(): string {
        return this._externalServiceType;
    }
}

export class Advice {
    private readonly _action: string;
    private readonly _creationDate: string;
    private readonly _id: string;
    private readonly _motivation: string;
    private readonly _geoserverLayerId: string;
    private readonly _geoserverStyles: AdviceWmsStyle[];
    private readonly _inputData: AdviceInputData;
    private readonly _thumbnailUrl: string;
    private readonly _sources: AdviceSourceApi[]

    constructor(item: any) {
        this._action = item.action;
        this._id = item.id;
        this._motivation = item.motivation;
        this._geoserverLayerId = item.geoserverLayerId;
        this._creationDate = item.creationDate;
        this._geoserverStyles = item.geoserverStyles;
        this._inputData = item.inputData;
        this._thumbnailUrl = item.thumbnailUrl;
        this._sources = item.sources;
    }

    get action(): string {
        return this._action;
    }

    get creationDate(): string {
        return this._creationDate;
    }

    get id(): string {
        return this._id;
    }

    get geoserverLayerId(): string {
        return this._geoserverLayerId;
    }

    get geoserverStyles(): AdviceWmsStyle[] {
        return this._geoserverStyles;
    }

    get motivation(): string {
        return this._motivation
    }

    get inputData(): AdviceInputData {
        return this._inputData;
    }

    get thumbnailUrl(): string {
        return this._thumbnailUrl;
    }

    get sources(): AdviceSourceApi[] {
        return this._sources;
    }
}

class WeatherData {
    private readonly _temp: number;
    private readonly _hum: number;
    private readonly _rain: number;
    private readonly _weather_code: number;
    private readonly _wind_speed: number;

    constructor(item: any) {
        this._temp = item.temp;
        this._hum = item.hum;
        this._rain = item.rain;
        this._weather_code = item.weather_code;
        this._wind_speed = item.wind_speed;
    }

    get temp(): number {
        return this._temp;
    }

    get hum(): number {
        return this._hum;
    }

    get rain(): number {
        return this._rain;
    }

    get weather_code(): number {
        return this._weather_code;
    }

    get wind_speed(): number {
        return this._wind_speed;
    }
}

export class AdviceSourceOptionData {
    private readonly _day: moment.Moment;
    private readonly _layerName: string | undefined;
    private readonly _wmsLayer: string | undefined;
    private readonly _wmsStyles: AdviceWmsStyle[];
    private _active: boolean;
    private readonly _weather: WeatherData[];

    constructor(item: any) {
        this._day = moment(item.day);
        this._layerName = item.layerName;
        this._wmsLayer = item.wmsLayer;
        this._wmsStyles = (item.wmsStyles || [])
            // .map((style: any) => new AdviceWmsStyle(style))
            .sort((a: AdviceWmsStyle, b: AdviceWmsStyle) => a.sortOrder - b.sortOrder);

        this._active = item.hasOwnProperty("active") ? item.active : true;
        this._weather = item.weather;
    }

    get day(): moment.Moment {
        return this._day;
    }

    get layerName(): string | undefined {
        return this._layerName;
    }

    get wmsLayer(): string | undefined {
        return this._wmsLayer;
    }

    get wmsStyles(): AdviceWmsStyle[] {
        return this._wmsStyles;
    }

    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = value;
    }

    get weather(): WeatherData[] {
        return this._weather;
    }
}

export class AdviceSourceOption {
    private readonly _name: string;
    private _active: boolean;
    private _thumbnailSelected: boolean;
    private readonly _thumbnailUrl: string;
    private readonly _data: AdviceSourceOptionData[];

    constructor(item: any) {
        this._name = item.name;
        this._active = item.active;
        this._thumbnailSelected = item.thumbnailSelected;
        this._thumbnailUrl = item.thumbnailUrl;
        this._data = (item.data || []).map((day) => new AdviceSourceOptionData(day));
    }

    get name(): string {
        return this._name;
    }

    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = value;
    }

    get data(): AdviceSourceOptionData[] {
        return this._data;
    }

    get thumbnailUrl(): string | undefined {
        return this._thumbnailUrl;
    }

    get thumbnailSelected(): boolean {
        return this._thumbnailSelected;
    }

    set thumbnailSelected(value: boolean) {
        this._thumbnailSelected = value;
    }
}

export abstract class AdviceWmsStyle {
    private readonly _name: string;
    private readonly _ref: string;
    private readonly _sort_order: number;

    constructor(item: any) {
        this._name = item.name;
        this._ref = item.ref;
        this._sort_order = item.sortOrder;
    }

    get name(): string {
        return this._name;
    }

    get ref(): string {
        return this._ref;
    }

    get sortOrder(): number {
        return this._sort_order;
    }
}

export class AdviceGeoTiffWmsStyle extends AdviceWmsStyle {
    private readonly _channel: number;

    constructor(item: any) {
        super(item);
        this._channel = item.channel;
    }


    get channel(): number {
        return this._channel;
    }
}

export class AdviceShapeWmsStyle extends AdviceWmsStyle {

    constructor(item: any) {
        super(item);
    }
}


export class AdviceSource {
    private readonly _type: AdviceSourceType;
    private _active: boolean;
    private readonly _options: AdviceSourceOption[];

    constructor(item: any) {
        this._type = item.type;
        this._options = (item.options || [])
            .map((option) => new AdviceSourceOption(option))
            .sort((a: AdviceSourceOption, b: AdviceSourceOption) => a.name.localeCompare(b.name));
        this._active = item.active;
    }

    get  type(): AdviceSourceType {
        return this._type;
    }

    get options(): AdviceSourceOption[] {
        return this._options;
    }

    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = value;
    }

    get thumbnailUrl(): string | undefined {
        try {
            return this.options[0].thumbnailUrl;
        } catch (e) {
            return undefined;
        }
    }

    public updateOptions(activeOptions: string[]): AdviceSource {
        return new AdviceSource({
            type: this.type,
            active: this.active,
            options: this.options.map((option: AdviceSourceOption) => ({
                name: option.name,
                thumbnailUrl: option.thumbnailUrl,
                active: activeOptions.some((activeSource: string) => activeSource === `${this.type}---${option.name}`),
                data: [...option.data],
                thumbnailSelected: option.thumbnailSelected
            }))
        });
    }

    public updateThumbnailSelected(thumbnail: AdviceSourceOption): AdviceSource {
        return new AdviceSource({
            type: this.type,
            active: this.active,
            options: this.options.map((option: AdviceSourceOption) => ({
                name: option.name,
                thumbnailUrl: option.thumbnailUrl,
                active: option.active,
                data: [...option.data],
                thumbnailSelected: thumbnail.name === option.name
            }))
        });
    }

    public updateDays(activeDays: moment.Moment[]): AdviceSource {
        return new AdviceSource({
            type: this.type,
            active: this.active,
            options: this.options.map((option: AdviceSourceOption) => ({
                name: option.name,
                active: option.active,
                thumbnailUrl: option.thumbnailUrl,
                data: option.data.map((data: AdviceSourceOptionData) => ({
                    day: data.day,
                    wmsLayer: data.wmsLayer,
                    wmsStyles: data.wmsStyles,
                    active: Boolean(activeDays.find((ad) => ad.isSame(data.day, "day")))
                })),
                thumbnailSelected: option.thumbnailSelected
            }))
        });
    }
}

export interface AdviceInputData {
    activityType: String;
}

export class AdviceLimingInputData implements AdviceInputData {
    private readonly _phLab: String;
    private readonly _soilType: String;
    private readonly _limingRecommendation: String;
    private readonly _zbwLime: String;
    private readonly _activityType: String;

    constructor(item: any) {
        this._phLab = item.phLab;
        this._soilType = item.soilType;
        this._limingRecommendation = item.limingRecommendation;
        this._zbwLime = item.zbwLime;
        this._activityType = item.activityType;
    }

    get phLab(): String {
        return this._phLab;
    }

    get soilType(): String {
        return this._soilType;
    }

    get limingRecommendation(): String {
        return this._limingRecommendation;
    }

    get zbwLime(): String {
        return this._zbwLime;
    }

    get activityType(): String {
        return this._activityType;
    }
}

export class AdviceSowingInputData implements AdviceInputData {
    private readonly _thousandKernelWeight: String;
    private readonly _cultivarType: String;
    private readonly _sowDate: String;
    private readonly _activityType: String;

    constructor(item: any) {
        this._thousandKernelWeight = item.thousandKernelWeight;
        this._cultivarType = item.cultivarType;
        this._sowDate = item.sowDate;
        this._activityType = item.activityType;
    }


    get thousandKernelWeight(): String {
        return this._thousandKernelWeight;
    }

    get cultivarType(): String {
        return this._cultivarType;
    }

    get sowDate(): String {
        return this._sowDate;
    }

    get activityType(): String {
        return this._activityType;
    }
}

export class AdviceFusariumInputData implements AdviceInputData {
    private readonly _precedingRisk: String;
    private readonly _varietyRisk: String;
    private readonly _activityType: String;

    constructor(item: any) {
        this._precedingRisk = item.precedingRisk;
        this._varietyRisk = item.varietyRisk;
        this._activityType = item.activityType;
    }


    get precedingRisk(): String {
        return this._precedingRisk;
    }

    get varietyRisk(): String {
        return this._varietyRisk;
    }

    get activityType(): String {
        return this._activityType;
    }
}


export class AdviceFertilingN1InputData implements AdviceInputData {
    private readonly _adviceN1: String;
    private readonly _n_ContentFertilizer: String;
    private readonly _homogenize: Boolean;
    private readonly _activityType: String;

    constructor(item: any) {
        this._adviceN1 = item.adviceN1;
        this._n_ContentFertilizer = item.n_ContentFertilizer;
        this._activityType = item.activityType;
        this._homogenize = item.homogenize;
    }


    get adviceN1(): String {
        return this._adviceN1;
    }

    get n_ContentFertilizer(): String {
        return this._n_ContentFertilizer;
    }

    get homogenize(): Boolean {
        return this._homogenize;
    }

    get activityType(): String {
        return this._activityType;
    }
}

export class AdviceFertilingN2InputData implements AdviceInputData {
    private readonly _adviceN2: String;
    private readonly _adviceN3: String;
    private readonly _n_ContentFertilizer: String;
    private readonly _homogenize: Boolean;
    private readonly _activityType: String;
    private readonly _addN3Percentage: String;

    constructor(item: any) {
        this._adviceN2 = item.adviceN2;
        this._adviceN3 = item.adviceN3;
        this._n_ContentFertilizer = item.n_ContentFertilizer;
        this._activityType = item.activityType;
        this._homogenize = item.homogenize;
        this._addN3Percentage = item.addN3Percentage;
    }


    get adviceN2(): String {
        return this._adviceN2;
    }

    get adviceN3(): String {
        return this._adviceN3;
    }

    get n_ContentFertilizer(): String {
        return this._n_ContentFertilizer;
    }

    get homogenize(): Boolean {
        return this._homogenize;
    }

    get addN3Percentage(): String {
        return this._addN3Percentage;
    }

    get activityType(): String {
        return this._activityType;
    }
}

export class AdviceFertilingN3InputData implements AdviceInputData {
    private readonly _adviceN3: String;
    private readonly _n_ContentFertilizer: String;
    private readonly _homogenize: Boolean;
    private readonly _activityType: String;

    constructor(item: any) {
        this._adviceN3 = item.adviceN3;
        this._n_ContentFertilizer = item.n_ContentFertilizer;
        this._activityType = item.activityType;
        this._homogenize = item.homogenize;
    }


    get adviceN3(): String {
        return this._adviceN3;
    }

    get n_ContentFertilizer(): String {
        return this._n_ContentFertilizer;
    }

    get homogenize(): Boolean {
        return this._homogenize;
    }

    get activityType(): String {
        return this._activityType;
    }
}


export class UseableSource {
    private readonly _sensor: string;
    private readonly _options: string[];

    constructor(sensor: string, option: string[]) {
        this._sensor = sensor;
        this._options = option;
    }

    get sensor(): string {
        return this._sensor;
    }

    get options(): string[] {
        return this._options;
    }
}
