<template>
    <div class="team">
        <Map />
        <TeamMemberList />
    </div>
</template>

<script>
import Map from '@/map/Map.component.vue';
import TeamMemberList from './teamMemberList/TeamMemberList.component';

export default {
    name: 'Team',
    props: {
        teamId: String
    },
    components: { Map, TeamMemberList }
};
</script>
