<template>
  <div class="binReport" @click="">
    <div class="binReport__top">
      <MapIcon :custom-width="100" :field-entity="binToDisplay" :rotation="false"/>
      <span class="binReport__binName">{{ bin.getFullName() }}</span>
    </div>
    <div class="binReport__icons">
      <div class="binReport__icon" @click.stop="downloadBinReport(contentTypeEnum.PDF)">
        <v-progress-circular indeterminate v-if="isLoadingPdf"/>
        <v-icon v-else large>{{ icons.filePdf }}</v-icon>
      </div>
      <div class="binReport__icon" @click.stop="downloadBinReport(contentTypeEnum.CSV)">
        <v-progress-circular indeterminate v-if="isLoadingCsv"/>
        <v-icon v-else large>{{ icons.fileCsv }}</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import MapIcon from '../map/icons/MapIcon.icon.vue';
import ReportService from '@services/report.service';
import {icons} from '@utils/icons.utils';
import {ContentTypeEnum} from '@services/http.service';

export default {
  name: 'BinReportBtn',
  components: {MapIcon},
  props: {
    teamId: {
      type: String,
      required: true
    },
    bin: {
      type: Object,
      required: true
    }
  },
  computed: {
    contentTypeEnum() {
      return ContentTypeEnum;
    },
    binToDisplay() {
      const copy = this.bin.copy();
      copy.assignedTo = 'dummy';
      return copy;
    },
    icons() {
      return icons;
    },
    isLoadingPdf() {
      return this.isLoading[ContentTypeEnum.PDF.contentType];
    },
    isLoadingCsv() {
      return this.isLoading[ContentTypeEnum.CSV.contentType];
    },
  },
  data() {
    return {
      isLoading: {
        [ContentTypeEnum.PDF.contentType]: false,
        [ContentTypeEnum.CSV.contentType]: false,
      }
    }
  },
  methods: {
    downloadBinReport(responseType) {
      const contentType = responseType.contentType;

      if (this.isLoading[contentType]) return;

      this.isLoading[contentType] = true;

      ReportService.downloadBinReport(this.teamId, this.bin.id, responseType).finally(() => {
        this.isLoading[contentType] = false;
      })
    }
  }
};
</script>
