<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <g>
      <title>Straw bale</title>
      <g id="body">
        <path
            id="svg_1"
            d="m471,148l0,-1l-125,-31a10973,10973 0 0 0 -285,49l87,53c9,6 20,7 30,5l293,-75z"
            fill="#f3cb1b"
        />
        <path
            id="svg_2"
            opacity="0.3"
            d="m346,116a10973,10973 0 0 0 -285,49l87,53c9,6 20,7 30,5c29,-8 53,-17 90,-21l10,-3c44,-14 77,-20 113,-29l10,-2c20,-9 46,-14 70,-20l0,-1l-125,-31z"
        />
        <path
            id="svg_3"
            d="m465,280l-313,115l5,-153c1,-8 7,-16 15,-18l299,-76l-2,125c1,3 -1,6 -4,7z"
            fill="#f3cb1b"
        />
        <path
            id="svg_4"
            opacity="0.6"
            d="m465,280l-313,115l5,-153c1,-8 7,-16 15,-18l299,-76l-2,125c1,3 -1,6 -4,7z"
        />
        <path
            id="svg_5"
            d="m152,395l-85,-70c-6,-5 -10,-12 -10,-20l4,-140l83,51c9,6 14,16 13,26l-5,153z"
            fill="#f3cb1b"
        />
        <path id="svg_6" d="m178,223c-12,2 -19,7 -21,20c1,-11 -4,-21 -13,-27c13,8 23,9 34,7z" fill="#f3cb1b"/>
        <path
            id="svg_7"
            opacity="0.4"
            d="m178,223c-12,2 -19,7 -21,20c1,-11 -4,-20 -12,-27c13,9 22,10 33,7z"
            fill="#fff"
        />
      </g>
      <g stroke-width="0.5" stroke-miterlimit="10" stroke="#000" id="ropes">
        <g id="top_ropes">
          <path
              id="svg_8"
              d="m355,118l-282,54l1,1l283,-54l-2,-1zm20,5l-286,59l1,0l288,-58l-3,-1zm23,7l-291,63l2,1l292,-63l-3,-1zm26,6l-299,68l2,1l299,-68l-2,-1zm27,7l-309,71l1,1l311,-72l-3,0z"
          />
        </g>
        <g id="side_ropes">
          <path
              id="svg_9"
              d="m70,328l0,-1l3,-155l1,1l-4,155zm13,11l0,-1l6,-156l1,1l-7,156zm19,15l0,-1l5,-159l2,0l-7,160zm17,14l-1,-1l7,-162l1,0l-7,163zm17,13l-1,0l6,-167l1,1l-6,166z"
          />
        </g>
      </g>
      <g id="svg_18">
        <ellipse stroke-width="8" ry="74" rx="74" id="svg_15" cy="340" cx="397.5" stroke="#000" fill="#fff"/>
        <text
            class="seqNo"
            xml:space="preserve"
            text-anchor="middle"
            font-family="sans-serif"
            font-size="79"
            id="svg_16"
            y="368"
            x="395"
            stroke-width="0"
            stroke="#000"
            fill="#000000"
        >
                    {{ seqNo }}
                </text>
      </g>
    </g>
  </svg>
</template>

import Vue from 'vue';

<script lang="ts">
import Vue, {PropType} from 'vue';
import {BalerLoadReport} from 'shared-frontend';

export default Vue.extend({
  name: 'StrawBale',
  props: {
    bale: Object as PropType<BalerLoadReport>
  },
  computed: {
    seqNo(): number {
      return this.bale.seqNo;
    }
  }
});
</script>
