<template>
  <inner-layout>
    <template v-slot:sideBarContent>
      <Title
          v-if="!loading"
          :title="translations.APDT_ADVICE.DETAIL.TITLE"
          :subtitle="farmBoundary.name"
          subtitle-full
      />
      <div class="apdt-advice__header">
        <div class="apdt-advice__backAndActions">
          <Back :to="{ name: 'apdtAdvice' }" />
        </div>
      </div>

      <div v-if="advice" class="apdt-advice__details">
        <v-text-field
            v-model="advice.action.toLowerCase()"
            label="Action"
            readonly
            disabled
        ></v-text-field>

        <v-text-field
            v-model="advice.creationDate.toLocaleString()"
            label="Request on"
            readonly
            disabled
        ></v-text-field>

        <h4 class="advice-title">{{ translations.APDT_ADVICE.DETAIL.MANUAL_INPUT }}</h4>
        <advice-liming-input v-if="!!advice && advice.inputData.activityType===ActivityType.LIMING" disabled v-bind:value="createLimingForm()" />
        <advice-sowing-input v-if="!!advice && advice.inputData.activityType===ActivityType.SOWING" disabled v-bind:value="createSowingForm()" />
        <advice-fertilizing-n1-input v-if="!!advice && advice.inputData.activityType===ActivityType.FERTILIZING_N1" disabled v-bind:value="createFertilizingN1Form()" />
        <advice-fertilizing-n2-input v-if="!!advice && advice.inputData.activityType===ActivityType.FERTILIZING_N2" disabled v-bind:value="createFertilizingN2Form()" />
        <advice-fertilizing-n3-input v-if="!!advice && advice.inputData.activityType===ActivityType.FERTILIZING_N3" disabled v-bind:value="createFertilizingN3Form()" />
        <advice-fusarium-input v-if="!!advice && advice.inputData.activityType===ActivityType.FUSARIUM" disabled v-bind:value="createFusariumForm()" />

        <h4 v-if="advice.sources.length>0" class="advice-title">{{ translations.APDT_ADVICE.DETAIL.SENSOR_DATA }}</h4>
        <v-text-field
            v-for="source in advice.sources"
            v-model="source.dates"
            :label="translations.APDT_ADVICE.LAYERS.SOURCES[source.externalServiceType.toUpperCase()]"
            readonly
            disabled />
      </div>
    </template>

    <template v-slot:content>
      <div class="apdt-advice__content">
        <v-progress-circular v-if="loading" indeterminate color="primary" />
        <polygon-map
            v-else
            :initial-coordinates="farmBoundary.coordinates"
            :layer="layerValue"
            :layer-style="styleValue"
            :feature-info-enabled="true"
            @input="farmBoundary.coordinates = $event"
        />

        <Weather :farm-boundary-id="farmBoundary.farmBoundaryId"
                 :selected-day="selectedDay"
                 class="weather-widget"/>

        <div class="apdt-advice__motivation" v-if="!!advice">
          <div class="advice-box">
            <p class="header-text">{{ translations.APDT_ADVICE.DETAIL.MOTIVATION }}</p>
            <p class="content-text">{{ advice.motivation }}</p>
          </div>
        </div>

        <div class="apdt-advice__filter">
          <advice-day-style-selector
              :selected-day="selectedDay"
              :days="possibleDays"
              :selected-style="selectedStyle"
              :styles="selectableStyles"
              @updateDay="selectedDay=$event"
              @updateStyle="selectedStyle=$event"
              v-if="selectableStyles"
          >
            <template v-slot:additionalActions>
              <v-divider vertical class="mx-6" />

              <v-btn
                  color="primary"
                  @click="downloadAdvice"
              >
                <v-icon small>{{ icons().download }}</v-icon>
              </v-btn>
            </template>

          </advice-day-style-selector>
        </div>
      </div>
    </template>
  </inner-layout>
</template>

<script>
import { mapState } from "vuex";
import InnerLayout from "@/layout/InnerLayout.vue";
import PolygonMap from "@/map/PolygonWMSMap.component.vue";
import FarmBoundaryService from "@services/farmBoundary.service";
import Title from "@components/Title.component.vue";
import Back from "@components/Back.component.vue";
import AdviceContentHistory from "@/apdtAdvice/components/AdviceContentHistory.component.vue";
import AdviceFormVue from "@/apdtAdvice/forms/Advice.form.vue";
import AdviceDayStyleSelector from "@/apdtAdvice/forms/AdviceDayStyle-selector.vue";
import { getAdvice, getStyleForDefaultZoneOrHighest } from "@/apdtAdvice/Advice.service";
import AdviceDetailService from "@/apdtAdvice/detail/AdviceDetail.service";
import { icons } from "@utils/icons.utils";
import { ActivityType, AdviceSourceType } from "@/apdtAdvice/advice.enum";
import moment from "moment";
import RecalibrateAction from "@/apdtAdvice/soil-analysis/RecalibrateAction.vue";
import AdviceLimingInput from "@/apdtAdvice/forms/AdviceLimingInput.form-item.vue";
import AdviceSowingInput from "@/apdtAdvice/forms/AdviceSowingInput.form-item.vue";
import AdviceFusariumInput from "@/apdtAdvice/forms/AdviceFusariumInput.form-item.vue";
import AdviceFertilizingN1Input from "@/apdtAdvice/forms/AdviceFertilizingN1Input.form-item.vue";
import AdviceFertilizingN2Input from "@/apdtAdvice/forms/AdviceFertilizingN2Input.form-item.vue";
import AdviceFertilizingN3Input from "@/apdtAdvice/forms/AdviceFertilizingN3Input.form-item.vue";
import { AdviceFertilizingN1Form, AdviceFertilizingN2Form, AdviceFertilizingN3Form, AdviceFusariumForm, AdviceLimingForm, AdviceSowingForm } from "@/apdtAdvice/forms/forms.domain";
import AdviceSourceDaySelector from "@/apdtAdvice/forms/AdviceSource.day-selector.vue";
import { AdviceSource } from "@/apdtAdvice/advice.domain";
import Weather from "@/weather/Weather.component.vue";

export default {
  name: "apdtAdviceDetail.page",

  components: {
    Weather,
    AdviceSourceDaySelector,
    AdviceLimingInput,
    AdviceSowingInput,
    AdviceFusariumInput,
    AdviceFertilizingN1Input,
    AdviceFertilizingN2Input,
    AdviceFertilizingN3Input,
    RecalibrateAction,
    AdviceDayStyleSelector,
    AdviceFormVue,
    AdviceSource,
    AdviceContentHistory,
    Back,
    Title,
    PolygonMap,
    InnerLayout
  },
  props: {
    farmBoundaryId: {
      type: String,
      default: null
    },
    adviceId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      farmBoundary: null,
      advice: null,
      selectedStyle: null,
      selectableStyles: null,
      possibleDays: [],
      selectedDay: null
    };
  },
  async created() {
    this.farmBoundary = await FarmBoundaryService.getFarmBoundary(this.farmBoundaryId);
    this.advice = await getAdvice(this.adviceId);
    this.selectableStyles = this.advice?.geoserverStyles.sort((a, b) => a.sortOrder - b.sortOrder);
    this.selectedDay = moment(this.advice.creationDate);
    this.possibleDays = [{ label: this.selectedDay.format("DD/MM/yyyy"), value: this.selectedDay }];
    const nbrSelectableStyles = getStyleForDefaultZoneOrHighest(this.advice, 3);
    this.selectedStyle = this.selectableStyles[nbrSelectableStyles];
  }
  ,
  computed: {
    ActivityType() {
      return ActivityType;
    },
    AdviceSourceType() {
      return AdviceSourceType;
    }
    ,
    ...
        mapState({
          translations: (state) => state.translation.translations
        }),
    loading() {
      return !this.farmBoundary && !this.advice;
    }
    ,
    layerValue() {
      return `apdt:${this.advice?.geoserverLayerId}`;
    }
    ,
    styleValue() {
      return this.selectedStyle;
    }
  }
  ,
  methods: {
    icons() {
      return icons;
    }
    ,
    downloadAdvice() {
      AdviceDetailService.getDownloadAdvice(this.adviceId);
    }
    ,
    createLimingForm() {
      let adviceLimingForm = new AdviceLimingForm();
      adviceLimingForm.soilType = this.advice.inputData.soilType;
      adviceLimingForm.phLab = this.advice.inputData.phLab;
      adviceLimingForm.limingRecommendation = this.advice.inputData.limingRecommendation;
      adviceLimingForm.zbwLime = this.advice.inputData.zbwLime;
      return adviceLimingForm;
    },
    createSowingForm() {
      let adviceSowingForm = new AdviceSowingForm();
      adviceSowingForm.thousandKernelWeight = this.advice.inputData.thousandKernelWeight;
      adviceSowingForm.cultivarType = this.advice.inputData.cultivarType;
      adviceSowingForm.sowDate = this.advice.inputData.sowDate;
      return adviceSowingForm;
    },
    createFusariumForm() {
      let adviceFusariumForm = new AdviceFusariumForm();
      adviceFusariumForm.precedingRisk = this.advice.inputData.precedingRisk;
      adviceFusariumForm.varietyRisk = this.advice.inputData.varietyRisk;
      return adviceFusariumForm;
    },
    createFertilizingN1Form() {
      let adviceFertilizingN1Form = new AdviceFertilizingN1Form();
      adviceFertilizingN1Form.adviceN1 = this.advice.inputData.adviceN1;
      adviceFertilizingN1Form.n_ContentFertilizer = this.advice.inputData.n_ContentFertilizer;
      adviceFertilizingN1Form.homogenize = this.advice.inputData.homogenize;
      return adviceFertilizingN1Form;
    },
    createFertilizingN2Form() {
      let adviceFertilizingN2Form = new AdviceFertilizingN2Form();
      adviceFertilizingN2Form.adviceN2 = this.advice.inputData.adviceN2;
      adviceFertilizingN2Form.adviceN3 = this.advice.inputData.adviceN3;
      adviceFertilizingN2Form.n_ContentFertilizer = this.advice.inputData.n_ContentFertilizer;
      adviceFertilizingN2Form.homogenize = this.advice.inputData.homogenize;
      adviceFertilizingN2Form.addN3Percentage = this.advice.inputData.addN3Percentage;
      return adviceFertilizingN2Form;
    },
    createFertilizingN3Form() {
      let adviceFertilizingN3Form = new AdviceFertilizingN3Form();
      adviceFertilizingN3Form.adviceN3 = this.advice.inputData.adviceN3;
      adviceFertilizingN3Form.n_ContentFertilizer = this.advice.inputData.n_ContentFertilizer;
      adviceFertilizingN3Form.homogenize = this.advice.inputData.homogenize;
      return adviceFertilizingN3Form;
    }
  }
  ,
  watch: {}
}
;
</script>

<style lang="scss" scoped>
.weather-widget {
  z-index: 200;
  position: absolute;
  top: 8px;
  right: 8px;
}
</style>
