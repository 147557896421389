<template>

  <v-row>
    <v-col cols="12" class="pa-0" v-if="isUsernamePassword">
      <v-text-field v-model="value.username" label="Username" :rules="formRules.username" outlined @input="handleChange" />
    </v-col>

    <v-col cols="12" class="pa-0" v-if="isUsernamePassword">
      <v-text-field v-model="value.password" :append-icon="showPassword ? icons.eye : icons.eyeSlash" :type="showPassword ? 'text' : 'password'" label="Password" :rules="formRules.password" outlined @input="handleChange"
                    @click:append="showPassword = !showPassword" />
    </v-col>

    <v-col cols="12" class="pa-0" v-if="isToken">
      <v-text-field v-model="value.token" :append-icon="showToken ? icons.eye : icons.eyeSlash" :type="showToken ? 'text' : 'password'" label="Token" :rules="formRules.token" outlined @input="handleChange" @click:append="showToken = !showToken" />
    </v-col>
  </v-row>

</template>

<script lang="ts">
import Vue from "vue";
import { CredentialToken, CredentialUsernamePassword } from "@/credentials/credentials.domain";
import { mapState } from "vuex";
import { State } from "@/store";
import { icons, Icons } from "@utils/icons.utils";

export default Vue.extend({
  name: "credentialInputs",
  props: ["value"],
  data() {
    return {
      showPassword: false,
      showToken: false,
      credentials: undefined,
      formRules: {
        username: [
          v => !!v || (this as any).translations.GENERAL.REQUIRED
        ],
        password: [
          v => !!v || (this as any).translations.GENERAL.REQUIRED
        ],
        token: [
          v => !!v || (this as any).translations.GENERAL.REQUIRED
        ]
      }

    };
  },
  computed: {
    icons() {
      return icons;
    },
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    isUsernamePassword() {
      return this.value instanceof CredentialUsernamePassword;
    },
    isToken() {
      return this.value instanceof CredentialToken;
    }
  },
  methods: {
    handleChange: function() {
      this.$emit("input", this.$props.value);
    }
  }
});
</script>

<style scoped>

</style>
