<template>
  <inner-layout>
    <template v-slot:sideBarContent>
      <Title
          v-if="!loading"
          :title="translations.APDT_ADVICE.TITLE"
          :subtitle="farmBoundary.name"
          subtitle-full
      />
      <div class="apdt-advice__header">
        <div class="apdt-advice__backAndActions">
          <Back :to="{ name: 'farmBoundaries' }" />
        </div>
      </div>

      <advice-content-history v-if="isHistoryState" :farm-boundary-id="farmBoundaryId" @advice-selected="handleAdviceSelected($event)" />
      <advice-form-vue v-if="isFormState" :farm-boundary-id="farmBoundaryId" @goPrev="goBackToHistory" @updateForm="handleUpdateForm" />
      <div class="px-4 my-8 d-flex flex-column overflow-auto" style="flex: 1;" v-if="isShowSourcesState">
        <advice-source v-model="sourceFormItem.value" :farm-boundary-id="farmBoundaryId" read-only />
        <div class="d-flex align-center justify-center pa-2" style="background-color: white; position: sticky; bottom: 12px;">
          <v-btn @click="goBackToHistory" class="mx-auto">
            {{ translations.APDT_ADVICE.FORMS.ACTIONS.PREV }}
          </v-btn>
        </div>
      </div>

    </template>

    <template v-slot:sideBarFooter>
      <div v-if="isHistoryState" class="apdt-advice__footer">
        <v-btn color="primary" width="40%" @click="generateNewAdvice">
          {{ translations.APDT_ADVICE.NEW_ADVICE }}
        </v-btn>

        <v-btn color="primary" width="40%" @click="showSources">
          {{ translations.APDT_ADVICE.SHOW_DATA }}
        </v-btn>
      </div>
    </template>

    <template v-slot:content>
      <div class="apdt-advice__content">
        <v-progress-circular v-if="loading" indeterminate color="primary" />
        <polygon-map
            v-else
            :initial-coordinates="farmBoundary.coordinates"
            :layer="layerValue"
            :layer-style="styleValue"
            @input="farmBoundary.coordinates = $event"
        />

        <Weather v-if="!loading"
                 :farm-boundary-id="farmBoundary.farmBoundaryId"
                 :selected-day="selectedDay"
                 class="weather-widget"/>

        <div class="apdt-advice__filter">
          <advice-day-style-selector
              :selected-day="selectedDay"
              :days="possibleDays"
              :selected-style="selectedStyle"
              :styles="selectableStyles"
              @updateDay="handleUpdateDay"
              @updateStyle="handleUpdateStyle"
              v-if="selectableStyles"
          >
            <template v-slot:additionalActions v-if="selectedSourceType!=null && selectedSourceType===AdviceSourceType.SOIL_ANALYSIS">
              <v-divider vertical class="mx-6" />
              <recalibrate-action :date="selectedDay" />
            </template>
          </advice-day-style-selector>
        </div>
      </div>
    </template>
  </inner-layout>
</template>

<script>
import { mapState } from "vuex";
import InnerLayout from "@/layout/InnerLayout.vue";
import PolygonMap from "@/map/PolygonWMSMap.component.vue";
import FarmBoundaryService from "@services/farmBoundary.service";
import Title from "@components/Title.component.vue";
import Back from "@components/Back.component.vue";
import AdviceContentHistory from "@/apdtAdvice/components/AdviceContentHistory.component.vue";
import AdviceFormVue from "@/apdtAdvice/forms/Advice.form.vue";
import AdviceSource from "@/apdtAdvice/forms/AdviceSource.form-item.vue";
import { FormItem, FormItemType } from "@/apdtAdvice/forms/forms.domain";
import AdviceDayStyleSelector from "@/apdtAdvice/forms/AdviceDayStyle-selector.vue";
import { AdviceSourceType } from "@/apdtAdvice/advice.enum";
import RecalibrateAction from "@/apdtAdvice/soil-analysis/RecalibrateAction.vue";
import Weather from "@/weather/Weather.component.vue";
import moment from "moment/moment";

export default {
  name: "apdtAdvice.page",

  components: { RecalibrateAction, AdviceDayStyleSelector, AdviceFormVue, AdviceSource, AdviceContentHistory, Back, Title, PolygonMap, InnerLayout, Weather},
  props: {
    farmBoundaryId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      farmBoundary: null,
      adviceHistory: [],
      state: "history", // new

      possibleDays: [],
      selectedDay: null,

      layersWithStyles: [], // {layer: LAYER, style: STYLE}[]
      selectedLayer: null,
      selectedStyle: null,
      selectedSourceType: null,

      form: null,
      sourceFormItem: new FormItem({
        name: "source",
        type: FormItemType.source
      })
    };
  },
  async created() {
    this.farmBoundary = await FarmBoundaryService.getFarmBoundary(this.farmBoundaryId);
  },
  computed: {
    AdviceSourceType() {
      return AdviceSourceType;
    },
    ...mapState({
      translations: (state) => state.translation.translations
    }),
    loading() {
      return !this.farmBoundary;
    },
    isHistoryState() {
      return this.state === "history";
    },
    isFormState() {
      return this.state === "form";
    },
    isShowSourcesState() {
      return this.state === "showSources";
    },
    selectableStyles() {
      return this.layersWithStyles.flatMap(item => (item.styles));
    },
    layerValue() {
      return this.selectedLayer;
    },
    styleValue() {
      return this.selectedStyle;
    }
  },
  methods: {
    generateNewAdvice() {
      this.state = "form";
    },
    showSources() {
      this.state = "showSources";
      this.updateSource();
    },
    updateSelectFilters() {
      if (this.isFormState) {
        this.updateAdviceForm();
      }

      if (this.isShowSourcesState) {
        this.updateSource();
      }
    },
    updateSource() {
      this.update(this.sourceFormItem);
    },
    updateAdviceForm() {
      let item = null;
      if (this.form && this.form.formItemSource && this.form.formItemSource.value && this.form.isFormItemSourceActive) {
        item = this.form.formItemSource;
      }
      this.update(item);
    },
    update(item) {
      this.possibleDays = [];
      this.layersWithStyles = [];

      if (!item || !item.value) {
        return;
      }

      const itemSelected = item.value
          .flatMap(source => source.options)
          .find(option => option.thumbnailSelected);

      if (!itemSelected) {
        this.selectedLayer = null;
        this.selectedStyle = null;
        return;
      }

      const activeOptions = itemSelected.data;
      this.selectedSourceType = item.value
          .filter(v =>
              v.options
                  .map(o => o.name)
                  .includes(item.value
                      .flatMap(v => v.options)
                      .filter(o => o.thumbnailSelected)[0].name
                  )
          )[0].type;
      this.possibleDays = activeOptions
          .filter(item => item.active)
          .map(item => ({
            label: item.day.format("DD/MM/YYYY"),
            value: item.day
          }));

      if (!this.possibleDays.length) {
        this.selectedDay = null;
        return;
      }

      if (!this.selectedDay || !this.possibleDays.find(day => day.value.isSame(this.selectedDay, "day"))) {
        this.selectedDay = this.possibleDays.sort((a, b) => b.value.valueOf() - a.value.valueOf())[0].value;
      }

      if (this.selectedDay) {
        this.layersWithStyles = activeOptions
            .filter(item => item.day.isSame(this.selectedDay))
            .filter(item => item.wmsLayer)
            .map(item => ({
              layerName: item.layerName,
              layer: item.wmsLayer,
              styles: item.wmsStyles
            }));


        // only update the selected style when:
        // Not yet selected
        // The selected layer + style is not available with the selected date (layersWithStyles will update in steps above)
        if (!this.selectedStyle ||
            !this.layersWithStyles.find(
                layerWithStyles => layerWithStyles.layer === this.selectedLayer &&
                    Boolean(layerWithStyles.styles.find(style => style.ref === this.selectedStyle.ref))
            )) {
          let defaultLayer = this.layersWithStyles[0];
          let defaultStyle = defaultLayer.styles.find((value) => value.name === "ZONE_THREE") || defaultLayer?.styles[0];
          this.selectedLayer = defaultLayer?.layer;
          this.selectedStyle = defaultStyle;
        }
      }
    },
    handleUpdateForm(newForm) {
      this.form = newForm;
      this.updateAdviceForm();
    },
    handleUpdateDay(day) {
      this.selectedDay = day;
      this.updateSelectFilters();
    },
    handleUpdateStyle(style) {
      this.selectedStyle = style;
      this.updateSelectFilters();
    },
    goBackToHistory() {
      this.state = "history";
    },
    handleAdviceSelected(advice) {
      this.$router.push({ name: "apdtAdviceDetail", params: { adviceId: advice.id } });
    }
  },
  watch: {
    "sourceFormItem.value"() {
      this.updateSource();
    }
  }
};
</script>

<style lang="scss" scoped>
.weather-widget {
  z-index: 200;
  position: absolute;
  top: 8px;
  right: 8px;
}
</style>
