<template>
  <div v-if="selectedTeam$ && hasMembers" class="teamMemberList">
    <ExpandButton :is-expanded="isExpanded" @toggle="toggleState"/>
    <ExpandedList v-if="isExpanded"/>
    <CollapsedList v-if="!isExpanded"/>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {mapState} from 'vuex';

import {Baler, BaleServiceVehicle, Bin, Combine, Spfh, User} from 'shared-frontend';
import TeamService from '@/team/team.service';
import MapIcon from '@/map/icons/MapIcon.icon.vue';
import {Icons, icons} from '@utils/icons.utils';
import {State} from '@/store';
import TeamMember from './TeamMember.component.vue';
import ExpandedList from './ExpandedList.component.vue';
import CollapsedList from './CollapsedList.component.vue';
import ExpandButton from './ExpandButton.component.vue';

export default Vue.extend({
  name: 'TeamMemberList',
  components: {TeamMember, MapIcon, ExpandButton, ExpandedList, CollapsedList},
  data() {
    return {
      isExpanded: true
    };
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    ...mapState({
      isFieldCoverageEnabled: (state: State) => state.fieldCoverageEnabled
    }),
    icons(): Icons {
      return icons;
    },
    hasMembers(): boolean {
      return Boolean(this.combines.length || this.spfhs.length || this.balers.length || this.bins.length || this.baleServiceVehicles.length || this.users.length);
    },
    combines(): Combine[] {
      return (this as any).selectedTeam$?.combines?.getList();
    },
    spfhs(): Spfh[] {
      return (this as any).selectedTeam$?.spfhs?.getList();
    },
    balers(): Baler[] {
      return (this as any).selectedTeam$?.balers?.getList();
    },
    bins(): Bin[] {
      return (this as any).selectedTeam$?.bins?.getList();
    },
    baleServiceVehicles(): BaleServiceVehicle[] {
      return (this as any).selectedTeam$?.baleServiceVehicles?.getList();
    },
    users(): User[] {
      return (this as any).selectedTeam$?.users?.getList();
    }
  },
  methods: {
    toggleState() {
      this.isExpanded = !this.isExpanded;
    }
  },
  subscriptions() {
    return {
      selectedTeam$: TeamService.selectedTeamOrNull$
    };
  }
});
</script>
