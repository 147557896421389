<template>
  <v-combobox v-if="comboboxMode" :value="value" :rules="rules" @input="handleInput" :items="fields" :search-input.sync="fieldSearch" :label="trans.TEAM.FIELD" clearable>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            No results matching "<strong>{{ fieldSearch }}</strong>". Press <kbd>enter</kbd> to create a new one
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
  <v-autocomplete v-else :value="value" :rules="rules" @input="$emit('input', $event)" :items="fields" dark hide-details :label="trans.TEAM.FIELD" return-object/>

</template>

<script lang="ts">

import {AutoCompleteItem, returnIfUndefined, selectAllAutoCompleteItem, selectAllPredicate, toAutocompleteItems} from "./Picker.helper";
import Vue, {PropType} from "vue";
import {mapState} from "vuex";
import {State} from "@/store";
import {not} from "@utils/functional.util";
import {FarmTeamSummary} from "@/farmTeam/farmTeam.domain";
import {uniqueFields} from "@components/picker/FieldPicker.helper";

const fieldSelectAllAutoCompleteItem = selectAllAutoCompleteItem("All Farms");
export default Vue.extend({
  name: 'FieldPicker',
  props: {
    value: {},
    comboboxMode: {
      type: Boolean,
      default: false
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    alphabetically: {
      type: Boolean,
      default: false
    },
    farmTeams: {
      type: Array as PropType<FarmTeamSummary[]>
    },
    grower: {
      type: Object,
    },
    farm: {
      type: Object,
    },
    year: {
      type: Object,
    },
    rules: []
  },
  data() {
    return {
      fieldSearch: "",
      createdFields:[]
    }
  },
  computed: {
    ...mapState({
      trans: (state: State) => state.translation.translations
    }),
    fields(): AutoCompleteItem[] {
      return this.findFields(this.grower, this.farm, this.year).concat(this.createdFields);
    },
  },
  methods: {
    findFields(grower: AutoCompleteItem, farm: AutoCompleteItem, year: AutoCompleteItem): AutoCompleteItem[] {
      let fields = this.selectAll ? [fieldSelectAllAutoCompleteItem] : [];
      return (
          fields.concat(
              uniqueFields(this.farmTeams, this.alphabetically, grower, farm, year)
                  ?.map(toAutocompleteItems(this.trans.TEAM.FIELD, this.trans))
          )
      );
    },
    updateField(year?: AutoCompleteItem) {
      const yearUpdate = year ?? this.year
      if (yearUpdate?.selectAll) {
        this.$emit('input', fieldSelectAllAutoCompleteItem)
      } else {
        this.$emit('input', this.firstField(
            this.grower,
            this.farm,
            returnIfUndefined(yearUpdate, this.year)
        ))
      }
    },
    firstField(grower: AutoCompleteItem, farm: AutoCompleteItem, year: AutoCompleteItem): AutoCompleteItem {
      return this.findFields(grower, farm, year).find(not(selectAllPredicate));
    },
    handleInput(entry): void {
      if (typeof entry === 'string') {
        let trimmedEntry = entry.trim();
        let foundEntry = this.fields.find(value => value.value === entry);
        if (foundEntry) {
          this.$emit('input', foundEntry)
        } else if (trimmedEntry.trim() !== "") {
          let newEntry = toAutocompleteItems(this.trans.TEAM.FIELD, this.trans)(entry, null)
          this.createdFields.push(newEntry)
          this.$emit('input', newEntry)
        }
      } else {
        this.$emit('input', entry)
      }
    }

  },
  watch: {
    'grower': function (newEntry: AutoCompleteItem, oldGrower: AutoCompleteItem): void {
      if (newEntry?.value === oldGrower?.value && newEntry?.selectAll === newEntry?.selectAll) return;
      this.updateField();
    },
    'farm': function (newFarm: AutoCompleteItem, oldFarm: AutoCompleteItem): void {
      if (newFarm?.value === oldFarm?.value && newFarm?.selectAll === newFarm?.selectAll) return;
      this.updateField();
    },
    'year': function (newYear: AutoCompleteItem, oldYear: AutoCompleteItem): void {
      if (newYear?.value === oldYear?.value && newYear?.selectAll === newYear?.selectAll) return;
      this.updateField(newYear);
    },
  }
})
;
</script>
