<template>
  <v-combobox v-if="comboboxMode" :rules="rules" :value="value" @input="handleInput" :items="crops" :search-input.sync="cropSearch" :label="trans.TEAM.CROP" clearable>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            No results matching "<strong>{{ cropSearch }}</strong>". Press <kbd>enter</kbd> to create a new one
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
  <v-autocomplete v-else :value="value" :rules="rules" @input="$emit('input', $event)" :items="crops" dark hide-details :label="trans.TEAM.CROP" return-object/>

</template>

<script lang="ts">

import {AutoCompleteItem, returnIfUndefined, selectAllAutoCompleteItem, selectAllPredicate, toAutocompleteItems} from "./Picker.helper";
import Vue, {PropType} from "vue";
import {mapState} from "vuex";
import {State} from "@/store";
import {not} from "@utils/functional.util";
import {FarmTeamSummary} from "@/farmTeam/farmTeam.domain";
import {uniqueCrops} from "@components/picker/CropPicker.helper";

const cropSelectAllAutoCompleteItem = selectAllAutoCompleteItem("All Crops");
export default Vue.extend({
  name: 'CropPicker',
  props: {
    value: {},
    comboboxMode: {
      type: Boolean,
      default: false
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    alphabetically: {
      type: Boolean,
      default: false
    },
    farmTeams: {
      type: Array as PropType<FarmTeamSummary[]>
    },
    grower: {
      type: Object,
    },
    farm: {
      type: Object,
    },
    year: {
      type: Object,
    },
    field: {
      type: Object,
    },
    rules: []
  },
  data() {
    return {
      cropSearch: "",
      createdCrops:[]
    }
  },
  computed: {
    ...mapState({
      trans: (state: State) => state.translation.translations
    }),
    crops(): AutoCompleteItem[] {
      return this.findCrops(this.grower, this.farm, this.year, this.field).concat(this.createdCrops);
    },
  },
  methods: {
    findCrops(grower: AutoCompleteItem, farm: AutoCompleteItem, year: AutoCompleteItem, field: AutoCompleteItem): AutoCompleteItem[] {
      let crops = this.selectAll ? [cropSelectAllAutoCompleteItem] : [];
      return (
          crops.concat(
              uniqueCrops(this.farmTeams, this.alphabetically, grower, farm, year, field)
                  ?.map(toAutocompleteItems(this.trans.TEAM.CROP, this.trans))
          )
      );
    },
    updateCrop(field?: AutoCompleteItem) {
      const fieldUpdate = field ?? this.field
      if (fieldUpdate?.selectAll) {
        this.$emit('input', cropSelectAllAutoCompleteItem)
      } else {
        this.$emit('input', this.firstCrop(this.grower, this.farm, this.year, returnIfUndefined(fieldUpdate, this.field)));
      }
    },
    firstCrop(grower: AutoCompleteItem, farm: AutoCompleteItem, year: AutoCompleteItem, field: AutoCompleteItem): AutoCompleteItem {
      return this.findCrops(grower, farm, year, field).find(not(selectAllPredicate));
    },
    handleInput(entry): void {
      if (typeof entry === 'string') {
        let trimmedEntry = entry.trim();
        let foundEntry = this.crops.find(value => value.value === entry);
        if (foundEntry) {
          this.$emit('input', foundEntry)
        } else if (trimmedEntry.trim() !== "") {
          let newEntry = toAutocompleteItems(this.trans.TEAM.CROP, this.trans)(entry, null)
          this.createdCrops.push(newEntry)
          this.$emit('input', newEntry)
        }
      } else {
        this.$emit('input', entry)
      }
    }
  },
  watch: {
    'grower': function (newEntry: AutoCompleteItem, oldGrower: AutoCompleteItem): void {
      if (newEntry?.value === oldGrower?.value && newEntry?.selectAll === newEntry?.selectAll) return;
      this.updateCrop();
    },
    'farm': function (newFarm: AutoCompleteItem, oldFarm: AutoCompleteItem): void {
      if (newFarm?.value === oldFarm?.value && newFarm?.selectAll === newFarm?.selectAll) return;
      this.updateCrop();
    },
    'year': function (newYear: AutoCompleteItem, oldYear: AutoCompleteItem): void {
      if (newYear?.value === oldYear?.value && newYear?.selectAll === newYear?.selectAll) return;
      this.updateCrop();
    },
    'field': function (newField: AutoCompleteItem, oldField: AutoCompleteItem): void {
      if (newField?.value === oldField?.value && newField?.selectAll === newField?.selectAll) return;
      this.updateCrop(newField);
    },
  }
});
</script>
