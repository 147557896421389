<template>
  <v-form @submit.prevent="linkEditGrower" ref="form">
    <v-text-field
        :label="translations.MODALS.LINK_EDIT_GROWER.FORM.GROWER_NAME"
        required
        disabled
        v-model="growerName"
    />
    <v-text-field
        :rules="formRules.firstName"
        :label="translations.MODALS.LINK_EDIT_GROWER.FORM.FIRST_NAME_LABEL"
        required
        v-model="firstName"
        autofocus
    />
    <v-text-field
        :rules="formRules.lastName"
        :label="translations.MODALS.LINK_EDIT_GROWER.FORM.LAST_NAME_LABEL"
        required
        v-model="lastName"
    />
    <v-text-field
        :rules="formRules.email"
        :label="translations.MODALS.LINK_EDIT_GROWER.FORM.EMAIL_LABEL"
        type="mail"
        v-model="email"
    />
    <div class="new-workforce-user__result--error" v-if="showErrorMessage">
      {{ errorMessage }}
    </div>

    <div class="form__actions">
      <v-btn v-show="!loading" @click="$emit('close')" color="error" text>{{ translations.GENERAL.CANCEL }}</v-btn>
      <v-btn type="submit" color="primary" :loading="loading" dark>{{
          translations.MODALS.LINK_EDIT_GROWER.FORM[link ? 'CONFIRM_BUTTON_LINK' : 'CONFIRM_BUTTON_EDIT']
        }}
      </v-btn>
    </div>
  </v-form>
</template>

<script lang="ts">
import Vue, {PropType} from 'vue';
import {mapState} from 'vuex';
import {PhoneNumber} from '@domain/phonenumber.domain';
import {logger} from '@services/logger.service';
import emailValidator from '@utils/email-validator.util';
import {Grower, GrowerMember, LinkGrower} from '@/grower/grower.domain';
import GrowerService from './grower.service';
import {State} from '@/store';
import {first} from 'rxjs/operators';
import {FarmManager} from 'shared-frontend';
import FarmManagerService from '@services/farmManager.service';

export default Vue.extend({
  name: 'LinkEditGrower',
  props: {
    params: Object as PropType<{ link: boolean, grower: Grower }>
  },
  data() {
    const grower = this.params.grower;
    const link = this.params.link;
    const linkedTo = this.params.grower?.linkedTo;
    const growerName = grower.growerName;

    return {
      farmMgrEmail: null,
      errorMessage: '',
      growerName,
      firstName: link ? '' : linkedTo.firstName,
      lastName: link ? '' : linkedTo.lastName,
      email: link ? '' : linkedTo.email,
      loading: false,
      formRules: {
        firstName: [
          v => !!v || (this as any).translations.MODALS.LINK_EDIT_GROWER.FORM.FIRST_NAME_ERROR_REQUIRED,
          v => (v && v.length <= 50) || (this as any).translations.MODALS.LINK_EDIT_GROWER.FORM.FIRST_NAME_ERROR_LENGTH
        ],
        lastName: [
          v => !!v || (this as any).translations.MODALS.LINK_EDIT_GROWER.FORM.LAST_NAME_ERROR_REQUIRED,
          v => (v && v.length <= 50) || (this as any).translations.MODALS.LINK_EDIT_GROWER.FORM.LAST_NAME_ERROR_LENGTH
        ],
        email: [
          v => (!!v && emailValidator(v)) || (this as any).translations.MODALS.LINK_EDIT_GROWER.FORM.EMAIL_ERROR_VALID,
          v => v?.trim()?.toLowerCase() !== (this as any).farmMgrEmail?.trim()?.toLowerCase() || (this as any).translations.MODALS.LINK_EDIT_GROWER.FORM.EMAIL_ERROR_SAME_AS_FARM_MANAGER
        ],
        telephone: [
          v => (!!v && PhoneNumber.isValid(v)) || (this as any).translations.MODALS.LINK_EDIT_GROWER.FORM.PHONE_ERROR_VALID
        ]
      }
    };
  },
  created() {
    FarmManagerService.farmManager$.pipe(first()).subscribe((farmManager: FarmManager) => {
      this.farmMgrEmail = farmManager.name;
    });
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    showErrorMessage(): boolean {
      return !!this.errorMessage;
    },
    link(): boolean {
      return this.params.link;
    },
    grower(): Grower {
      return this.params.grower
    },
    linkedTo(): GrowerMember {
      return this.params.grower?.linkedTo;
    },
    linkGrower(): LinkGrower {
      return {
        growerName: this.growerName,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email
      }
    }
  },
  methods: {
    async linkEditGrower(): Promise<void> {
      if (!this.isFormValid() || this.loading) return;

      this.loading = true;

      try {
        await GrowerService.linkGrower(this.linkGrower);
      } catch (e) {
        // @ts-ignore
        this.loading = false;
        this.errorMessage = this.translations.ERRORS.GENERAL;
        logger.error('[LinkEditGrower.modal] - linkEditGrower: Error:  ', e);
        return;
      }

      this.$emit('close');
    },
    isFormValid(): boolean {
      return (this.$refs.form as any).validate();
    }
  }
});
</script>
