<template>
  <v-combobox v-if="comboboxMode" :value="value" :rules="rules" @input="handleInput" item-value="valueAsNumber" :items="years" :search-input.sync="yearSearch" :label="trans.TEAM.YEAR" clearable>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            No results matching "<strong>{{ yearSearch }}</strong>". Press <kbd>enter</kbd> to create a new one
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
  <v-autocomplete v-else :value="value" :rules="rules" @input="$emit('input', $event)" item-value="valueAsNumber" :items="years" dark hide-details :label="trans.TEAM.YEAR" return-object/>

</template>

<script lang="ts">

import {AutoCompleteItem, returnIfUndefined, selectAllAutoCompleteItem, selectAllPredicate, toAutocompleteItems} from "./Picker.helper";
import Vue, {PropType} from "vue";
import {mapState} from "vuex";
import {State} from "@/store";
import {not} from "@utils/functional.util";
import {FarmTeamSummary} from "@/farmTeam/farmTeam.domain";
import {uniqueYears} from "@components/picker/YearPicker.helper";

const yearSelectAllAutoCompleteItem = selectAllAutoCompleteItem("All Years");
export default Vue.extend({
  name: 'YearPicker',
  props: {
    value: {},
    comboboxMode: {
      type: Boolean,
      default: false
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    alphabetically: {
      type: Boolean,
      default: false
    },
    farmTeams: {
      type: Array as PropType<FarmTeamSummary[]>
    },
    grower: {
      type: Object,
    },
    farm: {
      type: Object,
    },
    rules: []
  },
  data() {
    return {
      yearSearch: "",
      createdYears: []
    }
  },
  computed: {
    ...mapState({
      trans: (state: State) => state.translation.translations
    }),
    years(): AutoCompleteItem[] {
      return this.findYears(this.grower, this.farm).concat(this.createdYears);
    },
  },
  methods: {
    findYears(grower: AutoCompleteItem, farm: AutoCompleteItem): AutoCompleteItem[] {
      let years = this.selectAll ? [yearSelectAllAutoCompleteItem] : [];
      return (
          years.concat(
              uniqueYears(this.farmTeams, this.alphabetically, grower, farm)
                  ?.map(el => toAutocompleteItems(this.trans.TEAM.YEAR, this.trans)(el.toString(), el))
          )
      );
    },
    updateYear(farm?: AutoCompleteItem): void {
      const farmUpdate = farm ?? this.farm
      if (farmUpdate?.selectAll) {
        this.$emit('input', yearSelectAllAutoCompleteItem)
      } else {
        this.$emit('input', this.firstYear(this.grower, returnIfUndefined(farmUpdate, this.farm)))
      }
    },
    firstYear(grower: AutoCompleteItem, farm: AutoCompleteItem): AutoCompleteItem {
      return this.findYears(grower, farm).find(not(selectAllPredicate));
    },
    handleInput(entry): void {
      if (typeof entry === 'string') {
        let trimmedEntry = entry.trim();
        let foundEntry = this.years.find(value => value.value === entry);
        if (foundEntry) {
          this.$emit('input', foundEntry)
        } else if (trimmedEntry.trim() !== "") {
          let newEntry = toAutocompleteItems(this.trans.TEAM.GROWER, this.trans)(entry, null)
          this.createdYears.push(newEntry)
          this.$emit('input', newEntry)
        }
      } else {
        this.$emit('input', entry)
      }
    }

  },
  watch: {
    'grower': function (newEntry: AutoCompleteItem, oldGrower: AutoCompleteItem): void {
      if (newEntry?.value === oldGrower?.value && newEntry?.selectAll === newEntry?.selectAll) return;
      this.updateYear();
    },
    'farm': function (newFarm: AutoCompleteItem, oldFarm: AutoCompleteItem): void {
      if (newFarm?.value === oldFarm?.value && newFarm?.selectAll === newFarm?.selectAll) return;
      this.updateYear(newFarm);
    }
  }
});
</script>
