<template>
  <div class="img-magnifier-container">
    <img :class="imgClass" id="image"
         :src="`data:image/${fileTypeExtension(picture.type)};base64,${picture.base64}`"/>
  </div>
</template>

<script>
import { fileTypeExtension } from "shared-frontend";

function magnify(imgID, zoom) {

  const img = document.getElementById(imgID);
  /*create magnifier glass:*/
  const glass = document.createElement("DIV");
  glass.setAttribute("class", "img-magnifier-glass");
  /*insert magnifier glass:*/
  img.parentElement.insertBefore(glass, img);
  /*set background properties for the magnifier glass:*/
  glass.style.backgroundImage = "url('" + img.src + "')";
  glass.style.backgroundRepeat = "no-repeat";
  glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
  const w = glass.offsetWidth / 2;
  const h = glass.offsetHeight / 2;
  /*execute a function when someone moves the magnifier glass over the image:*/
  glass.addEventListener("mousemove", moveMagnifier);
  img.addEventListener("mousemove", moveMagnifier);
  /*and also for touch screens:*/
  glass.addEventListener("touchmove", moveMagnifier);
  img.addEventListener("touchmove", moveMagnifier);

  glass.addEventListener("click", function (e) {
    e.preventDefault();
    if (zoom > 10) {
      zoom = 3;
    } else {
      zoom++
    }
    glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
    moveMagnifier(e)
  })

  function moveMagnifier(e) {
    /*prevent any other actions that may occur when moving over the image*/
    e.preventDefault();
    /*get the cursor's x and y positions:*/
    const pos = getCursorPos(e);
    let x = pos.x;
    let y = pos.y;
    /*prevent the magnifier glass from being positioned outside the image:*/
    if (x > img.width - (w / zoom)) {
      x = img.width - (w / zoom);
    }
    if (x < w / zoom) {
      x = w / zoom;
    }
    if (y > img.height - (h / zoom)) {
      y = img.height - (h / zoom);
    }
    if (y < h / zoom) {
      y = h / zoom;
    }
    /*set the position of the magnifier glass:*/
    glass.style.left = (x - w) + "px";
    glass.style.top = (y - h) + "px";
    /*display what the magnifier glass "sees":*/
    glass.style.backgroundPosition = "-" + ((x * zoom) - w) + "px -" + ((y * zoom) - h) + "px";
  }

  function getCursorPos(e) {
    e = e || window.event;
    /*get the x and y positions of the image:*/
    const a = img.getBoundingClientRect();
    /*calculate the cursor's x and y coordinates, relative to the image:*/
    let x = e.pageX - a.left;
    let y = e.pageY - a.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return {x: x, y: y};
  }
}

export default {
  name: "MagnifyPicture",
  mounted() {
    magnify("image", 3)
  },
  props: {
    picture: {
      type: Object,
      required: true
    },
    imgClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileTypeExtension
    }
  },
}
</script>

<style>
* {
  box-sizing: border-box;
}

.img-magnifier-container {
  position: relative;
}

.img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  border-radius: 50%;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 150px;
  height: 150px;
}
</style>
