<template>
    <l-feature-group v-if="segments.length">
        <l-polyline
            v-for="segment in segments"
            v-if="segment.positions.length"
            :key="segment.segmentId"
            :lat-lngs="toLatLng(segment.positions)"
            :color="getColor(color)"
            :weight="calculateWeight(segment.workWidth__m)"
        />
    </l-feature-group>
</template>r

<script>
// INTERNAL
import {hexToRgba, IconService} from 'shared-frontend';
import {WORK_WIDTH_MANUAL_HARVESTER} from "@config/config";

export default {
    name: 'Segments',
    props: {
        segments: Array,
        color: String,
        metresPerPixel: Number,
        zoom: Number
    },
    methods: {
        getColor(color) {
            return hexToRgba(color, 0.25);
        },
        toLatLng(positions) {
            return positions.map((position) => [position.lat, position.lon]);
        },
        calculateWeight(workWidth__m) {
            // For manual combines (coverage history from server)
            if (!workWidth__m) {
                workWidth__m = WORK_WIDTH_MANUAL_HARVESTER;
            }

          // Sorry for the weird code, if you make it more logical, it breaks (somehow)
            const latitude =
                this.segments &&
                this.segments[0] &&
                this.segments[0].positions &&
                this.segments[0].positions[0] &&
                this.segments[0].positions[0].lat
                    ? this.segments[0].positions[0].lat
                    : null;

            const metresPerPix = latitude
                ? IconService.metersPerPixelForZoomLevelOSM(this.zoom, latitude)
                : IconService.metersPerPixelForZoomLevelOSM(this.zoom); //

          return (workWidth__m * 0.95) / metresPerPix;
        }
    }
};
</script>
