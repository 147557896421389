<template>
    <v-form @submit.prevent="addUser" ref="formUser">
        <v-text-field
            :rules="formRules.firstName"
            :label="translations.MODALS.ADD_USER.FORM.FIRST_NAME_LABEL"
            required
            v-model="firstName"
            autofocus
        />
        <v-text-field
            :rules="formRules.lastName"
            :label="translations.MODALS.ADD_USER.FORM.LAST_NAME_LABEL"
            required
            v-model="lastName"
        />
        <v-text-field
            :rules="formRules.email"
            :label="translations.MODALS.ADD_USER.FORM.EMAIL_LABEL"
            type="mail"
            v-model="email"
        />
        <v-text-field
            :rules="formRules.telephone"
            :label="translations.MODALS.ADD_USER.FORM.PHONE_LABEL"
            type="text"
            v-model="phoneNumber"
        />
        <div class="new-workforce-user__result--error" v-if="showErrorMessage">
            {{ errorMessage }}
        </div>

        <div class="form__actions">
            <v-btn v-show="!loading" @click="$emit('close')" color="error" text>{{ translations.GENERAL.CANCEL }}</v-btn>
            <v-btn type="submit" color="primary" :loading="loading" dark>{{
                translations.MODALS.ADD_USER.FORM.CONFIRM_BUTTON
            }}</v-btn>
        </div>
    </v-form>
</template>

<script>
    // EXTERNAL
    import {mapState} from 'vuex';
    //
    // INTERNAL
    import {PhoneNumber} from '@domain/phonenumber.domain';
    import {WorkForceMember} from '@/workforce/workForceMember.domain';
    import workforceService from '@/workforce/workforce.service';
    import {logger} from '@services/logger.service';
    import emailValidator from '@utils/email-validator.util';

    export default {
    name: 'NewWorkForceMember',
    data() {
        return {
            errorMessage: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '+',
            loading: false,
            formRules: {
                firstName: [
                    v => !!v || this.translations.MODALS.ADD_USER.FORM.FIRST_NAME_ERROR_REQUIRED,
                    v => (v && v.length <= 50) || this.translations.MODALS.ADD_USER.FORM.FIRST_NAME_ERROR_LENGTH
                ],
                lastName: [
                    v => !!v || this.translations.MODALS.ADD_USER.FORM.LAST_NAME_ERROR_REQUIRED,
                    v => (v && v.length <= 50) || this.translations.MODALS.ADD_USER.FORM.LAST_NAME_ERROR_LENGTH
                ],
                email: [v => (!!v && emailValidator(v)) || this.translations.MODALS.ADD_USER.FORM.EMAIL_ERROR_VALID],
                telephone: [
                    v => (!!v && PhoneNumber.isValid(v)) || this.translations.MODALS.ADD_USER.FORM.PHONE_ERROR_VALID
                ]
            }
        };
    },
    computed: {
        ...mapState({
            translations: state => state.translation.translations
        }),
        showErrorMessage() {
            return !!this.errorMessage;
        }
    },
    methods: {
        async addUser() {
            if (!this.isFormValid() || this.loading) return;

            this.loading = true;

            const workforceMember = new WorkForceMember(
                null,
                this.firstName,
                this.lastName,
                this.email,
                PhoneNumber.createFromString(this.phoneNumber),
                true,
                false
            );

            try {
                await workforceService.addUser(workforceMember);
            } catch (e) {
                // @ts-ignore
                this.loading = false;
                this.errorMessage = this.translations.ERRORS.GENERAL;
                logger.error('[NewWorkForceMember.modal] - addUser: Error:  ', e);
                return;
            }

            this.$emit('close');
        },
        isFormValid() {
            return this.$refs.formUser.validate();
        }
    }
};
</script>
