<template>
    <v-form @submit.prevent="saveUser" ref="editUserForm">
        <v-text-field :value="user.email" :label="translations.MODALS.EDIT_USER.FORM.EMAIL_LABEL" disabled />
        <v-text-field
            :value="fullPhoneNumber"
            :label="translations.MODALS.EDIT_USER.FORM.TELEPHONE_NUMBER_LABEL"
            disabled
        />

        <span class="d-flex align-center">
            <v-switch
                v-model="active"
                color="primary"
                :label="translations.MODALS.EDIT_USER.FORM.ACTIVE_INACTIVE_LABEL"
            />
            <InfoIcon :text="translations.MODALS.EDIT_USER.FORM.ACTIVE_INACTIVE_INFO_TEXT" />
        </span>

        <span class="d-flex align-center">
            <v-switch v-model="authority" color="primary" :label="translations.MODALS.EDIT_USER.FORM.AUTHORITY_LABEL" />
            <InfoIcon :text="translations.MODALS.EDIT_USER.FORM.AUTHORITY_INFO_TEXT" />
        </span>

        <p class="red--text" v-if="errorMessage">{{ errorMessage }}</p>

        <div class="form__actions">
            <v-btn v-show="!loading" @click="$emit('close')" color="error" text>{{
                translations.GENERAL.CANCEL
            }}</v-btn>
            <v-btn type="submit" color="primary" :loading="loading" dark>{{
                translations.MODALS.EDIT_USER.FORM.CONFIRM_BUTTON
            }}</v-btn>
        </div>
    </v-form>
</template>

<script>
// INTERNAL
import { icons } from '@utils/icons.utils';
import { mapState } from 'vuex';
import WorkforceService from '@/workforce/workforce.service';
import InfoIcon from '@components/InfoIcon.component';

export default {
    name: 'EditUser',
    components: { InfoIcon },
    props: {
        params: Object
    },
    data() {
        return {
            active: this.params.user.active,
            loading: false,
            errorMessage: '',
            authority: this.params.user.authorityLevel
        };
    },
    computed: {
        ...mapState({
            translations: state => state.translation.translations
        }),
        icons() {
            return icons;
        },
        user() {
            return this.params ? this.params.user : {};
        },
        fullPhoneNumber() {
            return this.user.phoneNumber && this.user.phoneNumber.getFullNumber();
        }
    },
    methods: {
        async saveUser() {
            this.loading = true;
            try {
                await Promise.all([this.updateActiveStatus(), this.updateAuthorityLevel()]);
                this.$emit('close');
            } catch (err) {
                this.loading = false;
                this.errorMessage = this.translations.ERRORS.GENERAL;
            }
        },
        async updateActiveStatus() {
            const { user } = this.params;
            this.active ? await WorkforceService.activateUser(user) : await WorkforceService.deactivateUser(user);
        },
        async updateAuthorityLevel() {
            const { user } = this.params;
            this.authority
                ? await WorkforceService.grantAuthorityToUser(user)
                : await WorkforceService.revokeAuthorityFromUser(user);
        }
    }
};
</script>
