import {Translations} from '@translations/translations.type';
import {PropsEqual} from '@utils/functional.util';
import {FarmTeamSummary} from '@/farmTeam/farmTeam.domain';

export function toAutocompleteItems(categoryName: string, trans: Translations) {
    return (value: string, valueAsNumber: number | null): AutoCompleteItem => {
        return {
            text: formatValue(categoryName, value, trans),
            value,
            valueAsNumber,
            selectAll: false
        };
    }
}

export interface AutoCompleteItem {
    text: string;
    value: string | null;
    valueAsNumber: number | null;
    selectAll: boolean;
}

export const formatValue = (categoryName: string, value: number | string, trans: Translations): string =>
    value === null ? `${categoryName} ${trans.TEAM.UNKNOWN}` : value?.toString() ?? '';

export function selectAllAutoCompleteItem(text: string): AutoCompleteItem {
    return {
        text: text,
        value: "*",
        selectAll: true
    } as AutoCompleteItem
}

export function filterApplies(propsEqual: PropsEqual<FarmTeamSummary>): (obj: FarmTeamSummary) => boolean {
    return (obj: FarmTeamSummary) => {
        // If 'val' is undefined, we interpret this as the absence of a filter
        // If 'val' is '*', we interpret this as the absence of a filter
        return Object.entries(propsEqual).every(([key, val]) => val === undefined || val === '*' || obj?.[key] === val);
    };
}

export const selectAllPredicate: (AutoCompleteItem) => boolean =
    el => el.selectAll;


export const returnIfUndefined = (val: any, fallback: any) => (val === undefined ? fallback : val);

