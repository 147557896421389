// INTERNAL
import {Setoid} from '@utils/functional.util';
import {LocationJSON} from 'shared-frontend';

export interface FarmBin {
    binId: string;
    binName: string;
    tankCapacity__m3: number;
    icon: string;
};

export interface FarmBinInfo {
    [binId: string]: {
        binName: string;
        icon: string;
        virtual: boolean;
        binReports: string[];
        startedOn: string;
        stoppedOn: string;
        location: LocationJSON;
    }
};

export interface FarmBaleServiceVehicleInfo {
    [baleServiceVehicleId: string]: {
        baleServiceVehicleName: string;
        icon: string;
        baleServiceVehicleReports: string[];
        startedOn: string;
        stoppedOn: string;
        location: LocationJSON;
    }
}

export interface CreateFarmBin {
    binName: string;
    tankCapacity__m3: number;
    icon: string;
}

export const eqFarmBin: Setoid<FarmBin> = {
    equals: (x: FarmBin, y: FarmBin) => x?.binId === y?.binId
}

// FarmBin events
// =================

export enum FarmBinsSseEvents {
    FARM_BIN_EVENT = 'farmBinEvent'
}

export enum FarmBinEventTypes {
    FARM_BIN_CREATED = 'farmBinCreated',
    FARM_BIN_UPDATED = 'farmBinUpdated',
    FARM_BIN_REMOVED = 'farmBinRemoved'
}

interface FarmBinEventBase {
    type: FarmBinEventTypes;
    farmMgrId: string;
    binId: string;
    eventTime: string;
}

export interface FarmBinCreatedEvent extends FarmBinEventBase {
    type: FarmBinEventTypes.FARM_BIN_CREATED;
    farmBin: FarmBin;
}

export interface FarmBinRemovedEvent extends FarmBinEventBase {
    type: FarmBinEventTypes.FARM_BIN_REMOVED;
}

export interface FarmBinUpdatedEvent extends FarmBinEventBase {
    type: FarmBinEventTypes.FARM_BIN_UPDATED;
    farmBin: FarmBin;
}

export type FarmBinEvent = FarmBinCreatedEvent | FarmBinRemovedEvent | FarmBinUpdatedEvent;
