<template>
  <div v-if="isVisible" class="statistic">
    <span class="statistic__label">{{ translations.TEAM_PAGE.VOLUME_WEIGHT_TO_FINISH }}</span>
    <span class="statistic__value" :style="{ color: primaryColor }">{{ value }}</span>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {mapState} from 'vuex';
import {State} from '@/store';

export default Vue.extend({
  name: 'VolumeWeightToFinish',
  props: {
    value: String,
    primaryColor: String
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    isVisible(): boolean {
      return Boolean(this.value);
    },
  }
});
</script>
