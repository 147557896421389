<template>
  <div>
    <Title :title="this.translations.ADMIN.THIRD_PARTY.TITLE">
      <add-third-party @submit="handleAddThirdParty"/>
    </Title>

    <v-container>
      <v-row>
        <v-col :key="party.id" cols="4" class="d-flex flex-column" v-for="party in thirdParties">
          <v-card class="d-flex flex-column flex-grow-1">
            <v-card-title>
              {{ party.name }}
              <v-spacer/>
              <remove-third-party @submit="handleRemoveCredential(party)"/>
            </v-card-title>
            <v-card-text class="flex-grow-1">

              <p class="ma-0">
                Data entities
              </p>
              <span v-for="(allowDataProvider,index) in party.allowDataProviders" :key="allowDataProvider">
                      <span v-if="index !== 0">, </span><span>{{ allowDataProvider }}</span>
                </span>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn color="primary" @click="handleGoForward(party)">
                <v-icon small>{{ icons.forward }}</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">

import Vue from "vue";
import Navigation from "@/layout/Navigation.vue";
import Toolbar from "@/layout/Toolbar.vue";
import Title from "@/components/Title.component.vue";
import {createThirdParty, deleteThirdParty, getThirdParties} from "@/thirdPartyManagement/thirdParty.service";
import {icons, Icons} from "@utils/icons.utils";
import {ThirdParty} from "@/thirdPartyManagement/thirdParty.domain";
import {mapState} from "vuex";
import {State} from "@/store";
import RemoveThirdParty from "@/thirdPartyManagement/RemoveThirdParty.vue";
import AddThirdParty from "@/thirdPartyManagement/AddThirdParty.vue";

export default Vue.extend({
  name: "ThirdPartyManagement",
  components: {AddThirdParty, RemoveThirdParty, Navigation, Toolbar, Title},
  data() {
    return {
      thirdParties: [],
      title: "Third party data consumers"
    };
  },
  mounted() {
    this.fetchThirdParties();
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    icons(): Icons {
      return icons;
    }
  },
  methods: {
    fetchThirdParties: async function () {
      this.thirdParties = await getThirdParties();
    },
    handleGoForward: function (party: ThirdParty) {
      this.$router.push({name: "thirdPartyManagementDetails", params: {id: party.id}});
    },
    handleAddThirdParty(thirdPartyName: string) {
      let thirdPart = new ThirdParty({name: thirdPartyName});
      console.log(thirdPart)
      createThirdParty(thirdPart).then((t) => this.thirdParties.push(t))
    },
    handleRemoveCredential(thirdParty: ThirdParty) {
      deleteThirdParty(thirdParty).then(() => this.fetchThirdParties());
    }
  }
});
</script>

<style scoped>

</style>
