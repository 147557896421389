// EXTERNAL
import {BehaviorSubject, concat, from, Observable, of} from 'rxjs';
import {filter, map, mergeMap, pluck, shareReplay, startWith, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {v1 as uuidv1} from 'uuid';

//
// INTERNAL
// Shared
import {API_ENDPOINTS, BinReportSegments, FieldEntityEnum, Location, LocationEvent, LocationJSON, Segment} from 'shared-frontend';
//
// Services
import httpService from '@services/http.service';
import {logger} from '@services/logger.service';
import {WORK_WIDTH_MANUAL_HARVESTER} from '@config/config';

export type HeaderEvent = {
    arableVehicleId: string;
    workWidth__m: number;
    harvesting: boolean;
};

class FieldCoverageService {
    private _harvesterCoverageChanged$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private _harvesterLocationEvents$: BehaviorSubject<LocationEvent> = new BehaviorSubject<LocationEvent>(null);
    private _headerEvent$: BehaviorSubject<HeaderEvent> = new BehaviorSubject<HeaderEvent>(null);

    // =================
    // Public methods
    // ==================

    // Getters
    // -------
    public getFieldCoverage(
        teamId: string,
        arableVehicleId: string,
        arableVehicleType: FieldEntityEnum,
        hasCanbus: boolean
    ): Observable<BinReportSegments> {
        const fetchCoverageTrigger$: Observable<string> = this.isHarvesterCoverageChanged(arableVehicleId).pipe(
            startWith(arableVehicleId)
        );

        return fetchCoverageTrigger$.pipe(
            switchMap((avId: string) => {
                return concat(
                    this._getFieldCoverageHistoryFor(teamId, avId, arableVehicleType),
                    this._getIncomingFieldCoverageFor(avId, hasCanbus)
                );
            })
        );
    }

    public isHarvesterCoverageChanged(arableVehicleId: string): Observable<string> {
        return this._harvesterCoverageChanged$.pipe(
            filter((id: string) => arableVehicleId === id),
            tap((avId: string) =>
                logger.info('##################" coverage changed for: ', avId, ' => re-fetching coverage history')
            )
        );
    }

    // Add to stream
    // -------------
    public addHarvesterCoverageChangedEvent = (arableVehicleId: string): void => {
        logger.info('[fieldCoverage.service] - new binReport info available for: ', arableVehicleId);
        this._harvesterCoverageChanged$.next(arableVehicleId);
    };

    public addLocationEvent = (locationEvent: LocationEvent): void => {
        this._harvesterLocationEvents$.next(locationEvent);
    };

    public addHeaderEvent = (headerEvent: HeaderEvent): void => {
        this._headerEvent$.next(headerEvent);
    };

    // =================
    // Private methods
    // ==================

    private _getIncomingFieldCoverageFor(arableVehicleId: string, hasCanbus: boolean): Observable<BinReportSegments> {
        const harvesterLocations$: Observable<LocationJSON> = this._getLocationEventsFor(arableVehicleId);
        const header$: Observable<HeaderEvent> = this._getHeaderEventsFor(arableVehicleId, hasCanbus);

        const isHarvesting = (headerEvent: HeaderEvent) => headerEvent && headerEvent.harvesting;

        let currentSegmentId = uuidv1();
        return harvesterLocations$.pipe(
            withLatestFrom(header$),
            map(
                ([location, header]): BinReportSegments => {
                    if (!isHarvesting(header)) {
                        currentSegmentId = uuidv1();
                    }

                    const segment: Segment = {
                        segmentId: currentSegmentId.toString(),
                        workWidth__m: header && header.workWidth__m,
                        positions: isHarvesting(header) ? [location] : null
                    };

                    return {
                        binReportId: null,
                        segments: [segment]
                    };
                }
            ),
            filter((binReportSegments: BinReportSegments) => Boolean(binReportSegments.segments[0].positions)),
            shareReplay(1)
        );
    }

    private _getLocationEventsFor(arableVehicleId: string): Observable<LocationJSON> {
        return this._harvesterLocationEvents$.pipe(
            filter((locationEvent: LocationEvent) => locationEvent && locationEvent.fieldEntityId === arableVehicleId),
            pluck('location'),
            map((location: Location) => ({lon: location.longitude, lat: location.latitude}))
        );
    }

    private _getHeaderEventsFor(arableVehicleId: string, hasCanbus: boolean): Observable<HeaderEvent> {
        if (hasCanbus) {
            return this._headerEvent$.pipe(
                filter((headerEvent: HeaderEvent) => headerEvent && headerEvent.arableVehicleId === arableVehicleId)
            );
        } else {
            // For a manual harvester
            // - header width is fixed at a width that is too small (in order to encourage them to buy a canbus combine)
            // - header down is always true
            return of({
                arableVehicleId: arableVehicleId,
                workWidth__m: WORK_WIDTH_MANUAL_HARVESTER,
                harvesting: true // always harvesting
            });
        }
    }

    private _getFieldCoverageHistoryFor(
        teamId: string,
        arableVehicleId: string,
        arableVehicleType: FieldEntityEnum
    ): Observable<BinReportSegments> {
        return from(this._fetchFieldCoverage(teamId, arableVehicleId, arableVehicleType)).pipe(
            tap((binReportSegments: BinReportSegments[]) =>
                logger.info('[fieldCoverage.service] - _getFieldCoverageHistoryFor: ', binReportSegments)
            ),
            filter(Boolean),
            mergeMap((binReportSegments: BinReportSegments[]) => of(...binReportSegments))
        );
    }

    private async _fetchFieldCoverage(
        teamId: string,
        arableVehicleId: string,
        arableVehicleType: FieldEntityEnum
    ): Promise<BinReportSegments[]> {
        type Endpoints = {
            [arableVehicleType: string]: string;
        };

        const endpoint: Endpoints = {
            [FieldEntityEnum.COMBINE]: API_ENDPOINTS.COMBINE_COVERAGE_FOR_FARMMANAGER(teamId, arableVehicleId),
            [FieldEntityEnum.BALER]: API_ENDPOINTS.BALER_COVERAGE_FOR_FARMMANAGER(teamId, arableVehicleId),
            [FieldEntityEnum.SPFH]: API_ENDPOINTS.SPFH_COVERAGE_FOR_FARMMANAGER(teamId, arableVehicleId)
        };

        try {
            const response = await httpService.get(endpoint[arableVehicleType]);

            return response.data;
        } catch (e) {
            logger.error('[fieldCoverage.service.ts] - _fetchFieldCoverage ', e);
        }
    }
}

export default new FieldCoverageService();
