import Vue from 'vue';
import Router from 'vue-router';

import WorkforcePage from '@/workforce/Workforce.page.vue';
import TeamInfoAside from './teamInfo/TeamInfo.aside.vue';
import FarmBoundariesPage from '@/boundaries/FarmBoundaries.page.vue';
import FarmBoundaryPage from '@/boundaries/FarmBoundary.page.vue';
import MessagingAside from '@/messaging/Messaging.aside.vue';
import ReportsAside from '@/reports/Reports.aside.vue';
import Team from '@/team/Team.page.vue';
import FieldReport from '@/reports/FieldReport.aside.vue';
import Admin from '@/admin/Admin.page.vue';
import BaseLayout from '@/BaseLayout.vue';
import ApdtAdvicePage from '@/apdtAdvice/Advice.page.vue';
import CredentialsPage from '@/credentials/Credentials.page.vue';
import ThirdPartyManagement from '@/thirdPartyManagement/ThirdPartyManagement.vue';
import ThirdPartyManagementDetails from '@/thirdPartyManagement/ThirdPartyManagementDetails.vue';
import AdminLayout from '@/AdminLayout.vue';
import AgdnaImportPage from '@/agdnaImport/AgdnaImport.page.vue';
import AdviceDetailPage from '@/apdtAdvice/detail/AdviceDetail.page.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '',
            name: 'index',
            redirect: {name: 'map'},
            component: BaseLayout,
            children: [
                {
                    path: '/map',
                    name: 'map',
                    components: {
                        default: Team
                    }
                },
                {
                    path: '/teamInfo',
                    name: 'teamInfo',
                    components: {
                        aside: TeamInfoAside,
                        default: Team
                    }
                },
                {
                    path: '/messaging',
                    name: 'messaging',
                    components: {
                        aside: MessagingAside,
                        default: Team
                    }
                },
                {
                    path: '/reports',
                    name: 'reports',
                    components: {
                        aside: ReportsAside,
                        default: Team
                    }
                },
                {
                    path: '/reports/fieldReport',
                    name: 'fieldReport',
                    components: {
                        aside: FieldReport,
                        default: Team
                    }
                },
                {
                    path: '/boundaries',
                    name: 'farmBoundaries',
                    component: FarmBoundariesPage
                },
                {
                    path: '/boundaries/add',
                    name: 'addFarmBoundary',
                    component: FarmBoundaryPage
                },
                {
                    path: '/boundaries/edit/:farmBoundaryId',
                    name: 'editFarmBoundary',
                    props: true,
                    component: FarmBoundaryPage
                },
                {
                    path: '/apdt/:farmBoundaryId',
                    name: 'apdtAdvice',
                    props: true,
                    component: ApdtAdvicePage
                },
                {
                    path: "/apdt/:farmBoundaryId/advice/:adviceId",
                    name: "apdtAdviceDetail",
                    props: true,
                    component: AdviceDetailPage
                },
                {
                    path: '/agdnaImport',
                    name: 'agdnaImport',
                    component: AgdnaImportPage
                },
                {
                    path: '/workforce',
                    name: 'workforce',
                    component: WorkforcePage
                }
            ]
        },
        {
            path: '',
            name: 'indexAdmin',
            redirect: {name: 'admin'},
            component: AdminLayout,
            children: [
                {
                    path: '/admin',
                    name: 'admin',
                    component: Admin
                },
                {
                    path: '/third-party-management',
                    name: 'thirdPartyManagement',
                    component: ThirdPartyManagement
                }
            ]
        },
        {
            path: '/apdt-credentials',
            name: 'apdtCredentials',
            component: CredentialsPage
        },
        {
            path: '/third-party-management/:id',
            name: 'thirdPartyManagementDetails',
            props: true,
            component: ThirdPartyManagementDetails
        }
    ]
});
