import {KEYCLOAK_CLIENT_ID} from '@config/keycloak';
import {logger} from '@services/logger.service';


export default class KeycloakService {
    public static async getOrInit(): Promise<KeycloakService> {
        if (!KeycloakService.instance) {
            KeycloakService.instance = await KeycloakService.init();
        }
        return KeycloakService.instance;
    }

    private static instance: KeycloakService;

    private static init = (): Promise<any> => {
        const kcBseURL = process.env.VUE_APP_KEYCLOAK_BASE_URL;
        const realm = process.env.VUE_APP_KEYCLOAK_REALM;
        const clientId = KEYCLOAK_CLIENT_ID;

        logger.info('[keycloak.service] - Keycloak realm: ', realm);
        logger.info('[keycloak.service] - Keycloak clientID: ', clientId);

        const kcOptions = {
            url: kcBseURL,
            realm: realm,
            clientId: clientId
        }; // todo: download json file automatically from own backend? (see: https://github.com/keycloak/keycloak-documentation/blob/master/securing_apps/topics/oidc/javascript-adapter.adoc)

        const initOptions = {
            onLoad: 'login-required',
            checkLoginIframe: false
        };

        const keycloak = new window['Keycloak'](kcOptions);

        return new Promise((resolve, reject) => {
            keycloak
                .init(initOptions)
                .then(auth => {
                    if (!auth) {
                        window.location.reload();
                    } else {
                        logger.debug('[keycloak.service] - init: Authenticated'); // todo: what is the best way of logging in vue? / should this be logged
                    }

                    resolve(new KeycloakService(keycloak, true));
                })
                .catch(() => {
                    logger.error('[keycloak.service] - Authentication Failed');
                    reject();
                });
        });
    };
    private keycloakInstance;
    private loggedIn: boolean;

    private constructor(keycloak, loggedIn: boolean) {
        this.keycloakInstance = keycloak;
        this.loggedIn = loggedIn;
    }

    public logout = () => {
        this.keycloakInstance.loggedIn = false;
        this.keycloakInstance.logout();
    };

    public getToken(): Promise<string> {
        return new Promise((resolve, reject) => {
            this.keycloakInstance
                .updateToken()
                .then(() => {
                    resolve(this.keycloakInstance.token);
                })
                .catch(() => {
                    logger.error('[keycloak.service] - Failed refreshing the access token');
                    this.loggedIn = false;
                    this.loginAndRedirectBackTo(window.location.href);
                });
        });
    }

    public loginAndRedirectBackTo(next: string) {
        this.keycloakInstance
            .login({
                redirectUri: next
            })
            .then(() => {
                this.loggedIn = true;
                logger.debug('[keycloak.service] - loginAndRedirectBackTo: Authenticated');
            })
            .catch(() => {
                this.loggedIn = false;
                logger.error('[keycloak.service] - loginAndRedirectBackTo: Authentication Failed');
            });
    }
}
