import { LanguageEnum } from 'shared-frontend';

export default [
    {
        key: LanguageEnum.ENGLISH,
        translations: require('./en').default
    },
    {
        key: LanguageEnum.DUTCH,
        translations: require('./nl').default
    },
    {
        key: LanguageEnum.FRENCH,
        translations: require('./fr').default
    },
];
