import store from '@/store';
import moment from 'moment';

export interface CurrentWeatherDTO {
    temperature: number | null;
    weatherCode: string | null;
    weatherDescription: string | null;
}

export class CurrentWeather {
    currentT: number | null;
    weatherCode: string | null;
    weatherDescription: string | null;

    constructor(currentT: number | null, weatherCode: string | null) {
        this.currentT = currentT;
        this.weatherCode = weatherCode;

        if (weatherCode) {
            this.weatherDescription = store.state.translation.translations.APDT_ADVICE.WEATHER_CODES.DAY[weatherCode];
        }
    }

    static from(dto: CurrentWeatherDTO): CurrentWeather {
        return new CurrentWeather(
            dto.temperature ? Math.round(dto.temperature) : null,
            dto.weatherCode
        )
    }
}

export interface WeatherInfoDTO {
    timestamp: string;
    historical: boolean;
    minT: number | null;
    maxT: number | null;
    weatherCode: string | null;
    weatherDescription: string | null;
    precipitation: number | null;
    windSpeed: number | null;
    windDegrees: number | null; // [0, 360]
}

export class WeatherInfo {
    date: string;
    historical: boolean;
    minT: number | null;
    currentT: number | null;
    maxT: number | null;
    weatherCode: string | null;
    weatherDescription: string | null = null;
    precipitation: number | null;
    windSpeed: number | null;
    windDegrees: number | null; // [0, 360]

    constructor(
        date: string,
        historical: boolean,
        minT: number | null,
        maxT: number | null,
        weatherCode: string | null,
        precipitation: number | null,
        windSpeed: number | null,
        windDegrees: number | null
    ) {
        this.date = date;
        this.historical = historical;
        this.minT = minT;
        this.maxT = maxT;
        this.weatherCode = weatherCode;
        this.precipitation = precipitation;
        this.windSpeed = windSpeed;
        this.windDegrees = windDegrees;

        if (weatherCode) {
            this.weatherDescription = store.state.translation.translations.APDT_ADVICE.WEATHER_CODES.DAY[weatherCode];
        }
    }

    setCurrentTemperature(t: number | null) {
        this.currentT = t;
    }

    toCurrentWeather(): CurrentWeather {
        return new CurrentWeather(this.currentT ?? Math.round((this.maxT + this.minT) / 2), this.weatherCode);
    }

    static from(dto: WeatherInfoDTO): WeatherInfo {
        return new WeatherInfo(
            moment(dto.timestamp).format('YYYY-MM-DD'),
            dto.historical,
            dto.minT !== null ? Math.round(dto.minT) : null,
            dto.maxT !== null ? Math.round(dto.maxT) : null,
            dto.weatherCode,
            dto.precipitation !== null ? Math.round(dto.precipitation) : null,
            dto.windSpeed !== null ? Math.round(dto.windSpeed) : null,
            dto.windDegrees !== null ? Math.round(dto.windDegrees) : null
        )
    }
}

