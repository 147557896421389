var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"agdnaCn1"},[_c('div',{staticClass:"agdnaImport__content"},[_c('Title',{attrs:{"title":_vm.translations.AGDNA_CN1.TITLE,"subtitle":_vm.translations.AGDNA_CN1.SUBTITLE}}),_c('div',{staticClass:"agdnaImport__table"},[_c('div',{staticClass:"agdnaImport__banner"},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.isSelecting},on:{"click":_vm.handleFileImport}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.add))]),_vm._v(" "+_vm._s(_vm.translations.AGDNA_CN1.ADD_FILE)+" ")],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"id":"input_file","type":"file"},on:{"input":_vm.onFileChanged}}),(_vm.currentFile)?_c('v-progress-linear',{attrs:{"value":_vm.progress,"color":"light-blue","rounded":"","height":"26","font-color":"white"}},[_c('strong',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.progress)+"%")])]):_vm._e()],1),(_vm.message)?_c('v-alert',{attrs:{"border":"left","color":"red","dark":""}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),(_vm.fileInfos.length > 0)?_c('div',[_c('v-divider'),_c('v-data-table',{attrs:{"headers":[{
                          text: this.translations.AGDNA_CN1.TABLE.FILENAME,
                          align: 'start',
                          value: 'fileName',
                        },
                          {text: this.translations.AGDNA_CN1.TABLE.UPLOADED_ON, value: 'uploadedOn'},
                          {text: this.translations.AGDNA_CN1.TABLE.STATUS, value: 'status'}
                        ],"items":_vm.fileInfos,"disable-pagination":"","hide-default-footer":""}})],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }