<template>
  <v-app>

    <v-snackbar
        v-model="showSnackbar"
        color="success"
        absolute
        style="transform: translateY(-70vh)"
        timeout="3000"
    >
      Thank you for updating your credentials!

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="showSnackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>

    <Toolbar
        :show-farm-team-picker="false"
        :show-settings="false"
    />
    <navigation :show-nav-items="false" :show-to-portal="true"/>

    <v-main>
      <v-container>
        <v-row>
          <v-col class="mx-auto">
            <v-card>
              <v-card-title>
                Credentials

                <add-credential v-if="availableSources.length>0" @submit="handleAddCredential" :available-sources="availableSources"/>
              </v-card-title>
              <v-card-text>

                <v-card v-for="credential of credentials" :key="credential.source" class="ma-8 px-8 py-4">
                  <v-card-title>
                    {{ credential.source }}

                    <remove-credential @submit="handleRemoveCredential(credential)"/>
                  </v-card-title>

                  <v-card-text>
                    <credential-inputs :value="credential"/>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" text small @click="handleUpdateCredential(credential)">Submit</v-btn>
                  </v-card-actions>
                </v-card>

                <h1 v-if="!credentials.length" style="margin: 150px auto; color: black;" class="text-center">Please add a credential</h1>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import Navigation from "@/layout/Navigation.vue";
import Toolbar from "@/layout/Toolbar.vue";
import {deleteCredential, getCredentials, saveCredential, updateCredential} from "@/credentials/credentials.service";
import AddCredential from "@/credentials/addCredential.vue";
import CredentialInputs from "@/credentials/credentialInputs.vue";
import RemoveCredential from "@/credentials/removeCredential.vue";
import {CredentialsSource, CredentialToken, CredentialUsernamePassword} from "@/credentials/credentials.domain";

export default Vue.extend({
  name: "Credentials.page",
  components: {RemoveCredential, CredentialInputs, AddCredential, Navigation, Toolbar},
  data() {
    return {
      credentials: [],
      showSnackbar: false
    };
  },
  mounted() {
    this.fetchCredentials();
  },
  computed: {
    availableSources(): Array<Object> {
      let existingSources = this.credentials.map(value => value.source);
      let allSources = Object.keys(CredentialsSource).map(key => ({
        label: CredentialsSource[key],
        value: CredentialsSource[key]
      }));
      return allSources.filter(allSourceEntry => {
        return existingSources.findIndex(existingSourceEntry => {
          return allSourceEntry.value === existingSourceEntry
        }) === -1
      });
    }
  },
  methods: {
    async fetchCredentials() {
      let unsortedCredentials = await getCredentials()
      this.credentials = unsortedCredentials.sort((a, b) => {
        if (a.source < b.source) {
          return -1;
        } else if (a.source > b.source) {
          return 1;
        } else {
          return 0;
        }
      })
    },
    handleUpdateCredential(credential) {
      updateCredential(credential)
          .then(() => this.showSnackbar = true)
          .then(() => this.fetchCredentials())
    },
    handleRemoveCredential(credential) {
      deleteCredential(credential.source).then(() => this.fetchCredentials())
    },
    handleAddCredential(credential) {
      saveCredential(credential).then(() => this.fetchCredentials())
    }
  },
});

</script>

<style scoped>

</style>
