<template>
  <div class="back" @click="goBack">
    <v-icon class="back__icon" color="primary" small>{{icons.back}}</v-icon>
    <span class="back__text">{{ translations.GENERAL.BACK.toUpperCase() }}</span>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { icons } from "@utils/icons.utils";
import UnderscriptIcon from "@components/UnderscriptIcon.component";

export default {
  name: 'Back',
  components: { UnderscriptIcon },
  props: {
    to: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    }),
    icons() {
      return icons;
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  },
}
</script>