<template>
  <l-feature-group v-if="binReports.length">
    <Segments
        v-for="(binReport, idx) in binReports"
        :key="binReport.binReportId"
        :segments="binReport.segments"
        :color="getColor(idx, binReport)"
        :zoom="zoom"
    />
  </l-feature-group>
</template>

<script>
import Segments from './Segments.component.vue';
import FieldCoverageService from '../../services/fieldCoverage.service';
import {addItemToArray, FieldEntityEnum, updateArray} from 'shared-frontend';

const GREY = '#AAAAAA';

export default {
  name: 'FieldCoveragePerHarvester',
  components: {Segments},
  props: {
    teamId: String,
    arableVehicleId: String,
    arableVehicleType: FieldEntityEnum,
    hasCanbus: Boolean,
    metresPerPixel: Number,
    zoom: Number
  },
  data() {
    return {
      binReports: [],
      colors: [
        '#FFB300', // Vivid Yellow
        '#803E75', // Strong Purple
        '#FF6800', // Vivid Orange
        '#A6BDD7', // Very Light Blue
        '#C10020', // Vivid Red
        '#CEA262', // Grayish Yellow
        '#817066', // Medium Gray
        '#007D34', // Vivid Green
        '#F6768E', // Strong Purplish Pink
        '#00538A', // Strong Blue
        '#FF7A5C', // Strong Yellowish Pink
        '#53377A', // Strong Violet
        '#FF8E00', // Vivid Orange Yellow
        '#B32851', // Strong Purplish Red
        '#F4C800', // Vivid Greenish Yellow
        '#7F180D', // Strong Reddish Brown
        '#93AA00', // Vivid Yellowish Green
        '#593315', // Deep Yellowish Brown
        '#F13A13', // Vivid Reddish Orange
        '#232C16', // Dark Olive Green,
        // Start 2nd iteration of some colors (so we never run out of colors)
        '#FFB300', // Vivid Yellow
        '#803E75', // Strong Purple
        '#FF6800', // Vivid Orange
        '#A6BDD7', // Very Light Blue
        '#C10020', // Vivid Red
        '#CEA262', // Grayish Yellow
        '#817066', // Medium Gray
        '#007D34', // Vivid Green
        '#F6768E', // Strong Purplish Pink
        '#00538A', // Strong Blue
        '#FF7A5C', // Strong Yellowish Pink
        '#53377A', // Strong Violet
        '#FF8E00', // Vivid Orange Yellow
        '#B32851', // Strong Purplish Red
        '#F4C800', // Vivid Greenish Yellow
        '#7F180D', // Strong Reddish Brown
        '#93AA00', // Vivid Yellowish Green
        '#593315', // Deep Yellowish Brown
        '#F13A13', // Vivid Reddish Orange
        '#232C16' // Dark Olive Green
      ],
      colorsBaler: [
        '#a5780a',
        '#f6d818',
        '#1743e5',
        '#4e5e70',
        '#44bfad',
        '#88765d',
        '#f3d946',
        '#7d0045',
        '#507ecd',
        '#7a8891',
        '#dd223e',
        '#d3d1d6',
        '#544c44',
        '#dbbe71',
        '#5d5014',
        '#7483d0',
        '#6f725d',
        '#a4e502',
        '#d98099',
        '#0b0326',
        // Start 2nd iteration of some colors (so we never run out of colors)
        '#a5780a',
        '#f6d818',
        '#1743e5',
        '#4e5e70',
        '#44bfad',
        '#88765d',
        '#f3d946',
        '#7d0045',
        '#507ecd',
        '#7a8891',
        '#dd223e',
        '#d3d1d6',
        '#544c44',
        '#dbbe71',
        '#5d5014',
        '#7483d0',
        '#6f725d',
        '#a4e502',
        '#d98099',
        '#0b0326',
      ]

    };
  },
  mounted() {
    this.fieldCoverageReducerSub = FieldCoverageService.getFieldCoverage(
        this.teamId,
        this.arableVehicleId,
        this.arableVehicleType,
        this.hasCanbus
    ).subscribe(this.binReportReducer);

    this.combineCoverageChangedSub = FieldCoverageService.isHarvesterCoverageChanged(this.arableVehicleId).subscribe(
        (arableVehicleId) => {
          if (arableVehicleId) {
            // reset
            this.binReports = [];
          }
        }
    );
  },
  methods: {
    getColor(idx, binReport) {
      const colors = this.arableVehicleType === FieldEntityEnum.BALER ? this.colorsBaler : this.colors;
      return binReport && binReport.binReportId
          ? colors[Math.min(idx + 1, colors.length - 1)]
          : colors[0];
    },
    binReportReducer(newSegments) {
      const binReportIndex = this.binReports.findIndex(
          (oldSegments) => {
            return oldSegments.binReportId === newSegments.binReportId;
          }
      );

      if (binReportIndex === -1) {
        // New binReport
        this.binReports.push(newSegments);
      } else {
        // Existing binReport
        this.segmentReducer(binReportIndex, newSegments);
      }
    },
    segmentReducer(binReportIndex, newSegments) {
      newSegments.segments.forEach((segment) => {
        const segmentIndex = this.binReports[binReportIndex].segments.findIndex(
            (segm) => segm.segmentId === segment.segmentId
        );

        if (segmentIndex === -1) {
          // New segment
          this.binReports.splice(binReportIndex, 1, {
            binReportId: newSegments.binReportId,
            segments: addItemToArray(this.binReports[binReportIndex].segments,  segment)
          });
        } else {
          // Existing segment
          this.binReports.splice(binReportIndex, 1, {
            binReportId: newSegments.binReportId,
            segments: updateArray(
                this.binReports[binReportIndex].segments,
                {
                  segmentId: segment.segmentId,
                  workWidth__m: segment.workWidth__m,
                  positions: [
                    ...this.binReports[binReportIndex].segments[segmentIndex].positions,
                    ...segment.positions
                  ]
                },
                segmentIndex
            )
          });
        }
      });

    }
  },
  beforeDestroy() {
    if (this.fieldCoverageReducerSub) this.fieldCoverageReducerSub.unsubscribe();
    if (this.combineCoverageChangedSub) this.combineCoverageChangedSub.unsubscribe();
  }
};
</script>
