import ModalService from '@/modals/modal.service';
import {ModalTypeEnum} from '@/modals/modalType.enum';
import {BehaviorSubject} from 'rxjs';
import {withLatestFrom} from 'rxjs/operators';
import TeamService from '@/team/team.service';
import {Combine, CombineLowFuelDetected, Spfh, SpfhLowFuelDetected, Team} from 'shared-frontend';
import MetalDetectionService from '@/spfh/metalDetection/metalDetection.service'

export const enum DetectionEnum {
    COMBINE_LOW_FUEL_DETECTED_EVENT_TIME = 'combineLowFuelDetected',
    SPFH_LOW_FUEL_DETECTED_EVENT_TIME = 'spfhLowFuelDetected',
    BALE_DROP_DETECTED = 'baleDropDetected',
    SPFH_METAL_DETECTION_DETECTED = 'spfhMetalDetectionDetected',
}

class DetectionService {
    private _detectionEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public init() {
        this._detectionEvent$.pipe(
            withLatestFrom(TeamService.selectedTeam$)
        )
            .subscribe(([detectionEvent, team]) => {
                if (detectionEvent) this._handleIncomingEvent(detectionEvent, team);
            });
    }

    public addDetectionEvent(data: any) {
        this._detectionEvent$.next(data);
    }

    private _handleIncomingEvent = (data: any, team: Team): void => {
        const {type} = data;

        if (type === DetectionEnum.COMBINE_LOW_FUEL_DETECTED_EVENT_TIME) {
            const {combineId, fuelLevelThreshold__fraction} = data as CombineLowFuelDetected;
            const combine = team.combines.getCombineById(combineId);
            if (combine) this._combineLowFuelDetectedEventTime(combine, fuelLevelThreshold__fraction);
        }

        if (type === DetectionEnum.SPFH_LOW_FUEL_DETECTED_EVENT_TIME) {
            const {spfhId, fuelLevelThreshold__fraction} = data as SpfhLowFuelDetected;
            const spfh = team.spfhs.getSpfhById(spfhId);
            if (spfh) this._spfhLowFuelDetectedEventTime(spfh, fuelLevelThreshold__fraction);
        }

        if (type === DetectionEnum.SPFH_METAL_DETECTION_DETECTED) {
            MetalDetectionService.onMetalDetectionDetected();
        }

    };

    private _combineLowFuelDetectedEventTime(combine: Combine, fuelLevelThreshold__fraction: number): void {
        ModalService.showModal(ModalTypeEnum.COMBINE_LOW_FUEL, {
            fuelLevelThreshold__fraction: fuelLevelThreshold__fraction,
            combine
        });
    }

    private _spfhLowFuelDetectedEventTime(spfh: Spfh, fuelLevelThreshold__fraction: number): void {
        ModalService.showModal(ModalTypeEnum.SPFH_LOW_FUEL, {
            fuelLevelThreshold__fraction: fuelLevelThreshold__fraction,
            spfh
        });
    }

}

export default new DetectionService();
