import EventSource from '@domain/eventSource.domain';
import {concat, from, Observable} from 'rxjs';
import {API_ENDPOINTS, Team} from 'shared-frontend';
import {FarmTeamDetail, FarmTeamEvent, FarmTeamId, FarmTeamSseEvents, FarmTeamSummary} from '@/farmTeam/farmTeam.domain';
import {map, pluck, scan, shareReplay} from 'rxjs/operators';
import {reduceFarmTeamEvent, toUpdateFarmTeam} from '@/farmTeam/farmTeam.helper';
import httpService from '@services/http.service';
import {pick} from '@utils/functional.util';
import {logger} from '@services/logger.service';

class FarmTeamService {
    private _farmTeams$: Observable<FarmTeamSummary[]>;

    public get farmTeams$(): Observable<FarmTeamSummary[]> {
        if (this._farmTeams$) {
            return this._farmTeams$;
        } else {
            this._farmTeams$ = concat(this.fetchFarmTeams$(), this.farmTeamEvents$()).pipe(
                scan(reduceFarmTeamEvent),
                // only send changes to fields from FarmTeam, not the other ones as well
                shareReplay(1),
            );
            return this._farmTeams$;
        }
    }

    public fetchFarmTeamDetail = (farmTeamId: string): Observable<FarmTeamDetail> => {
        return from(httpService.get(API_ENDPOINTS.FARM_TEAM(farmTeamId))).pipe(
            pluck('data')
        );
    };

    public async updateFarmTeam(team: Team): Promise<void> {
        await httpService.put(API_ENDPOINTS.FARM_TEAM(team.id), toUpdateFarmTeam(team));
    }

    public async uploadToEvoPortal(farmTeamId: FarmTeamId): Promise<string> {
        const response = await httpService.post(API_ENDPOINTS.FARM_TEAM_UPLOAD_TO_EVO_PORTAL(farmTeamId), {});
        logger.info('[farmTeams.service] - upload: team exported');
        return response.data;
    }


    public async deleteFarmTeam(teamId: string): Promise<void> {
        await httpService.delete(API_ENDPOINTS.FARM_TEAM(teamId));
        logger.info('[farmTeams.service] - deleteFarmTeam: ', teamId);
    }

    private fetchFarmTeams$ = (): Observable<FarmTeamSummary[]> => {
        return from(httpService.get(API_ENDPOINTS.FARM_TEAMS())).pipe(
            pluck('data')
        );
    };

    private farmTeamEvents$ = (): Observable<FarmTeamEvent> => {
        const url = `${process.env.VUE_APP_BACKEND_URL}${API_ENDPOINTS.FARM_TEAMS()}`;
        const eventTypes: string[] = Object.values(FarmTeamSseEvents);
        return EventSource.createObservable(url, eventTypes).pipe(map(pick('data')));
    };


}

export default new FarmTeamService();
