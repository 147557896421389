// EXTERNAL
import FileSaver from 'file-saver';
//
// INTERNAL
// Shared
import {API_ENDPOINTS, FieldReport, parseFieldReport} from 'shared-frontend';
//
// Services
import httpService from '@services/./http.service';
//
// Enums
import {ContentTypeEnum} from '@services/http.service';

class ReportService {
    public getFieldReport = async (teamId: string): Promise<FieldReport> => {
        const response = await httpService.get(API_ENDPOINTS.FIELD_REPORT(teamId));
        return parseFieldReport(response.data);
    }

// ===============
    // Public methods
    // ================

    public async downloadYearReport(year: number, type = ContentTypeEnum.CSV): Promise<void> {
        return this._downloadFile(API_ENDPOINTS.YEAR_REPORT(year), type);
    }

    public async downloadBinReport(teamId: string, binId: string, type = ContentTypeEnum.CSV): Promise<void> {
        return this._downloadFile(API_ENDPOINTS.BINS_REPORT(teamId, binId), type);
    }

    public async downloadFieldReport(teamId: string, type = ContentTypeEnum.PDF): Promise<void> {
        return this._downloadFile(API_ENDPOINTS.FIELD_REPORT(teamId), type);
    }

    // ===============
    // Private methods
    // ================

    private async _downloadFile(apiEndpoint: string, type): Promise<void> {
        const response = await httpService.getFile(apiEndpoint, type.contentType);

        const contentDisposition = response && response.headers && response.headers['content-disposition'];

        const fileName = contentDisposition ? this.getFileName(contentDisposition) : `report`;

        // save csv as UTF-8 with BOM to make degenerated xslx work
        return FileSaver.saveAs(new Blob([response.data], {type: type.contentType + ';charset=utf-8'}), fileName);
    }

    private getFileName(contentDispositionHeader: string): string {
        // content-disposition: "attachment; filename="binReports_2019_hello_bin_02.pdf""
        const fileName: string = contentDispositionHeader.split(' ')[1]; // output: filename="binReports_2019_hello_bin_02.pdf"

        return fileName.replace('filename="', '').replace('"', ''); // output: binReports_2019_hello_bin_02.pdf|csv
    }
}

export default new ReportService();
