import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {Team} from 'shared-frontend';
import FarmTeamService from '@/farmTeam/farmTeam.service';
import ActiveTeamService from '@/activeTeam/activeTeam.service';
import {farmTeamDetailToTeam, farmTeamSummaryToTeam} from '@/farmTeam/farmTeam.helper';
import FarmManagerService from '@/services/farmManager.service';
import {FarmTeamSummary} from '@/farmTeam/farmTeam.domain';
import equal from 'fast-deep-equal/es6';
import {propEqual} from '@utils/functional.util';

class TeamService {
    private _selectedTeam$: Observable<Team | null>;
    private _selectedTeamId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    public selectTeam(teamId: string | null): void {
        console.info(`[team.service] - Setting team to ${teamId}`)
        this._selectedTeamId$.next(teamId);
    }


    public get selectedTeamOrNull$(): Observable<Team | null> {
        if (this._selectedTeam$) {
            return this._selectedTeam$;
        } else {
            this._selectedTeam$ = combineLatest([this._selectedTeamId$, FarmTeamService.farmTeams$])
                .pipe(
                    switchMap(([teamId, farmTeams]) => {
                        if (!teamId) {
                            return of(null)
                        } else {
                            return of(this.findFarmTeamSummary(teamId as string, farmTeams as FarmTeamSummary[]))
                        }
                    }),
                    distinctUntilChanged(equal),
                    map((team: FarmTeamSummary) => team ? farmTeamSummaryToTeam(team) : null),
                    switchMap(
                        (team) => {
                            if(!team) return of(null)
                            else if(team.isActive()) return ActiveTeamService.getActiveTeam$(team)
                            else return FarmTeamService.fetchFarmTeamDetail(team.id).pipe(map(farmTeamDetailToTeam))
                        }
                    ),
                    shareReplay(1)
                )
            ;

            return this._selectedTeam$
                .pipe(
                    tap(el => console.debug("[team.service] - Updated team ", el))
                );
        }
    }

    public get selectedTeam$(): Observable<Team> {
        return this.selectedTeamOrNull$.pipe(
            filter<Team>(Boolean)
        )
    }

    private findFarmTeamSummary(teamId: string, farmTeams: FarmTeamSummary[]): FarmTeamSummary {
        const selectedTeamId = teamId;
        return farmTeams.find(propEqual<FarmTeamSummary>({teamId: selectedTeamId}));
    }


    public get selectedTeamIdOrNull$(): Observable<string | null> {
        return this._selectedTeam$.pipe(
            map(el => el?.id),
            distinctUntilChanged());
    }

    public get isViewingAsGrower$(): Observable<boolean> {
        return combineLatest([this.selectedTeam$, FarmManagerService.farmManagerId$]).pipe(
            map(([team, farmManagerId]) => team?.linkedGrowerId === farmManagerId),
            distinctUntilChanged(),
            shareReplay(1)
        );
    }

    public get isTeamSelected$(): Observable<boolean> {
        return this._selectedTeamId$.pipe(map(Boolean));
    }

}

export default new TeamService();
