<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          color="error"
          small
          class="ml-auto"
          style="cursor: pointer"
          v-bind="attrs"
          v-on="on">
        {{ icons.retry }}
      </v-icon>
    </template>

    <v-card>
      <v-card-title>
        Change third party data consumer token
      </v-card-title>

      <v-card-text>
        Are you sure you want to change this third party data consumer key?
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="dialog=false;">
          Cancel
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="handleSubmit"
        >
          refresh
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {icons} from "@utils/icons.utils";

export default {
  name: "refreshToken",
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    icons() {
      return icons;
    },

  },
  methods: {
    handleSubmit() {
      this.dialog = false;
      this.$emit("submit");
    }
  },
  watch: {
    dialog() {
    },
  }
};
</script>

<style scoped>

</style>
