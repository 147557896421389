// EXTERNAL
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {delay, distinctUntilChanged, filter, map, mergeMap, scan, startWith, withLatestFrom} from 'rxjs/operators';
//
// INTERNAL
// Shared
import {API_ENDPOINTS, FarmMessageJSON, Message, MessageId, parseDate, Team, UserFromActivityJSON} from 'shared-frontend';
//
// Services
import httpService from '@services/http.service';
import TeamService from '@/team/team.service';
import FarmManagerService from '@services/./farmManager.service';
//
// Config
import {MESSAGES_VISIBILITY_TIME} from '@config/config';
import {logger} from '@services/logger.service'
import {MessagePerson} from "shared-frontend/dist/message/message.domain";

type IncomingMessage = {
    id: MessageId;
    user: UserFromActivityJSON;
    messageText: string;
    eventTime: string;
};

class MessageService {

    private _allMessages$: Subject<Message[]> = new BehaviorSubject<Message[]>([]); // contains all messages received

    private _rawIncomingMessages$: Subject<IncomingMessage> = new Subject<IncomingMessage>();
    private _accumulatedIncomingMessagesFromOtherUsers$: Observable<Message[]> = new BehaviorSubject<Message[]>([]);
    private _expirationSignals$: Observable<string[]> = new Observable<string[]>();
    private _removedByUser$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    public init() {
        const incomingMessagesFromOtherUsers$ = this._rawIncomingMessages$.pipe(
            withLatestFrom(FarmManagerService.farmManagerId$),
            filter(([incomingMessage, farmManagerId]) => !this.isSentByFarmManager(incomingMessage, farmManagerId)),
            map(([incomingMessage, _]) => this.createMessageObjectFrom(incomingMessage)),
        );

        this._accumulatedIncomingMessagesFromOtherUsers$ = incomingMessagesFromOtherUsers$.pipe(
            scan((messages: Message[], newMessage: Message) => [...messages, newMessage], [])
        );

        this._expirationSignals$ = incomingMessagesFromOtherUsers$.pipe(
            mergeMap((message: Message) => this.createNewMsgExpirationObservable(message.id)),
            scan((expiredMessagesIds: string[], id: string) => [...expiredMessagesIds, id], []),
            startWith([])
        );

        TeamService.selectedTeam$.pipe(
            distinctUntilChanged((prev, current) => prev.id === current.id)
        ).subscribe((state) => {
            this.getMessages(state).then(messages => this._allMessages$.next(messages))
        })

        this._rawIncomingMessages$.pipe(
            withLatestFrom(TeamService.selectedTeam$),
            map(([_, state]) => state),
        ).subscribe((state) => {
            this.getMessages(state).then(messages => this._allMessages$.next(messages))
        })
    }

    public newIncomingMessage(id: MessageId, user: UserFromActivityJSON, messageText: string, eventTime: string): void {
        const incomingMessage: IncomingMessage = {
            id,
            user,
            messageText,
            eventTime
        };

        logger.debug(`[MessageService.newIncomingMessage] - Received new message: ${id}`);
        this._rawIncomingMessages$.next(incomingMessage);
    }

    public recentMessages$(): Observable<Message[]> {
        return combineLatest([
            this._accumulatedIncomingMessagesFromOtherUsers$,
            this._expirationSignals$,
            this._removedByUser$,
        ]).pipe(
            map(([allMessages, expirationSignals, allMessagesRemovedByUser]) => {
                const msgsToHide = [...expirationSignals, ...allMessagesRemovedByUser];
                return this._filterOutMsgsButKeepLast(allMessages, msgsToHide);
            })
        );
    }

    public allMessages$(): Observable<Message[]> {
        return this._allMessages$.asObservable()
    }

    public removeMessage$(messageId: string) {
        this._removedByUser$.next([...this._removedByUser$.getValue(), messageId]);
    }

    public async sendMessage(message: string, farmManagerId: string, teamId: string) {
        await httpService.post(API_ENDPOINTS.MESSAGES(farmManagerId, teamId), {message});
    }

    private isSentByFarmManager(incomingMessage: IncomingMessage, farmManagerId: string): boolean {
        return incomingMessage && incomingMessage.user.userId === farmManagerId;
    }

    private _filterOutMsgsButKeepLast(allMessages: Message[], idsToHide: string[]) {
        if (allMessages.length <= 1) return allMessages;

        const lastMessage = allMessages[allMessages.length - 1];
        const withoutLastMessage = allMessages.slice(0, allMessages.length - 1);

        return [...withoutLastMessage.filter((message: Message) => !idsToHide.includes(message.id)), lastMessage];
    }

    private createNewMsgExpirationObservable(id: string): Observable<string> {
        return of(id).pipe(delay(MESSAGES_VISIBILITY_TIME));
    }

    private async getMessages(teamState: Team): Promise<Message[]> {
        try {
            const response = await httpService.get(API_ENDPOINTS.MESSAGES_FOR_FARMMANAGER(teamState.id));
            return response?.data?.map(json => parseFarmMessage(json)) ?? [];
        } catch (err) {
            logger.error(`[getMessages] - Error get messages for team ${teamState.id}`, err);
            return Promise.resolve([]);
        }
    }

    private createMessageObjectFrom(incomingMessage: IncomingMessage): Message {
        const {id, user, messageText, eventTime} = incomingMessage;
        return new Message(id, messageText, new MessagePerson(user.userId, user.firstName, user.lastName), parseDate(eventTime));
    }
}

function parseFarmMessage(json: FarmMessageJSON): Message {
    return new Message(
        json.farmMessageId,
        json.message,
        new MessagePerson(json.createdBy, json.createdByFirstName, json.createdByLastName),
        parseDate(json.createdOn)
    )
}


export default new MessageService();
