<template>
    <div class="colorSelect">
        <div
            v-for="[colorLabel, colorCode] in colorEntries"
            @click="colorSelectHandler(colorLabel)"
            :style="{ backgroundColor: colorCode }"
            :class="{ colorSelect__colorOption: true, colorSelect__selected: isSelected(colorLabel) }"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'ColorSelect',
    props: {
        colors: Object, // { [colorLabel: string] : colorValue, ... }
        initialValue: String // colorLabel
    },
    data() {
        return {
            selected: this.initialValue
        };
    },
    computed: {
        colorEntries() {
            return Object.entries(this.colors);
        }
    },
    methods: {
        colorSelectHandler(colorLabel) {
            this.selected = colorLabel;
            this.$emit('colorChange', colorLabel);
        },
        isSelected(colorLabel) {
            return colorLabel === this.selected;
        }
    }
};
</script>
