import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';

import {API_ENDPOINTS} from 'shared-frontend';
import {Workforce} from '@/workforce/workforce.domain';
import {parseWorkforceMembersJSON, WorkForceMember} from '@/workforce/workForceMember.domain';
import httpService from '@services/http.service';
import {logger} from '@services/logger.service';

class WorkforceService {
    private _workforce$: BehaviorSubject<Workforce> = new BehaviorSubject<Workforce>(null);

    public async init() {
        const workforce = await this.getWorkforce();
        this.setWorkForceToState(workforce);
    }

    public get workforce$() {
        return this._workforce$.pipe(map(workforce => workforce && workforce.members));
    }

    public async getWorkforce(): Promise<Workforce> {
        try {
            const response = await httpService.get(API_ENDPOINTS.FARMMANAGER_WORKFORCE_USERS());

            const workforceMembers: WorkForceMember[] = parseWorkforceMembersJSON(response.data);
            return new Workforce(workforceMembers);
        } catch (err) {
            logger.error('[WorkforceService] - getWorkforce', err);
        }
    }

    public async addUser(workForceMember: WorkForceMember): Promise<string> {
        try {
            const newUser = await httpService.post(API_ENDPOINTS.FARMMANAGER_WORKFORCE_USERS(), {
                firstName: workForceMember.firstName,
                lastName: workForceMember.lastName,
                email: workForceMember.email,
                phoneNumber: {
                    areaCode: workForceMember.phoneNumber.areaCode,
                    number: workForceMember.phoneNumber.number
                }
            });

            if (!newUser.data) {
                console.error('[WorkforceService] - addUser - no ID!', newUser.data);
            }

            workForceMember.id = newUser.data;
            const newWorkForce = this._workforce$.getValue().addMember(workForceMember);
            this._workforce$.next(newWorkForce);

            logger.info('[WorkforceService] - addUser, ID: ', newUser.data);

            return newUser.data;
        } catch (err) {
            logger.error('[WorkforceService] - addUser', err);
        }
    }

    public async deleteUser(workForceMember: WorkForceMember): Promise<void> {
        await httpService.delete(API_ENDPOINTS.FARMMANAGER_WORKFORCE_USER(workForceMember.id));

        const newWorkForce = this._workforce$.getValue().removeMember(workForceMember.id);
        this._workforce$.next(newWorkForce);

        logger.info('[WorkforceService] - deleteUser: ', workForceMember.id);
    }

    public async activateUser(workForceMember: WorkForceMember): Promise<void> {
        await httpService.put(API_ENDPOINTS.ACTIVATE(workForceMember.id), {});

        const newWorkForce = this._workforce$.getValue().setMemberActiveStatus(workForceMember, true);
        this.setWorkForceToState(newWorkForce);

        logger.info('[WorkforceService] - activateUser: ', workForceMember.id);
    }

    public async deactivateUser(workForceMember: WorkForceMember): Promise<void> {
        await httpService.put(API_ENDPOINTS.DEACTIVATE(workForceMember.id), {});

        const newWorkForce = this._workforce$.getValue().setMemberActiveStatus(workForceMember, false);
        this.setWorkForceToState(newWorkForce);

        logger.info('[WorkforceService] - deactivateUser: ', workForceMember.id);
    }

    public async grantAuthorityToUser(workForceMember: WorkForceMember): Promise<void> {
        await httpService.put(API_ENDPOINTS.GRANT(workForceMember.id), {});

        const newWorkForce = this._workforce$.getValue().setMemberAuthorityLevel(workForceMember, true);
        this.setWorkForceToState(newWorkForce);

        logger.info('[WorkforceService] - grantAuthorityToUser: ', workForceMember.id);
    }

    public async revokeAuthorityFromUser(workForceMember: WorkForceMember): Promise<void> {
        await httpService.put(API_ENDPOINTS.REVOKE(workForceMember.id), {});

        const newWorkForce = this._workforce$.getValue().setMemberAuthorityLevel(workForceMember, false);
        this.setWorkForceToState(newWorkForce);

        logger.info('[WorkforceService] - revokeAuthorityFromUser: ', workForceMember.id);
    }

    public setWorkForceToState(workforce: Workforce) {
        this._workforce$.next(workforce);
    }
}

export default new WorkforceService();
