import {v1 as uuidv1} from 'uuid';

interface ModalConstructorProps {
    title: string,
    message: string,
    hasCancelButton: boolean;
    hasConfirmButton: boolean;
    cancelButtonText: string;
    confirmButtonText: string;
    formComponent: any;
    onConfirm?: () => any;
    onCancel: () => any;
    params: any;
    color?: string;
}

export class Modal {
    public id: string;
    public title: string;
    public message: string;
    public hasCancelButton: boolean;
    public hasConfirmButton: boolean;
    public cancelButtonText: string;
    public confirmButtonText: string;
    public formComponent: string;
    public onConfirm: () => void;
    public onCancel: () => void;
    public params: any;
    public color: string;

    constructor(modal: ModalConstructorProps) {
        this.id = uuidv1();
        this.title = modal.title;
        this.message = modal.message;
        this.hasCancelButton = modal.hasCancelButton;
        this.hasConfirmButton = modal.hasConfirmButton;
        this.cancelButtonText = modal.cancelButtonText;
        this.confirmButtonText = modal.confirmButtonText;
        this.formComponent = modal.formComponent;
        this.onConfirm = modal.onConfirm;
        this.onCancel = modal.onCancel;
        this.params = modal.params || {};
        this.color = modal.color;
    }
}
