<template>
  <div class="farmBoundaries">
    <div class="farmBoundaries__content">
      <Title :title="translations.BOUNDARIES.TITLE" :subtitle="translations.BOUNDARIES.SUBTITLE" />

      <div class="farmBoundaries__table">
        <SearchInput v-model="searchInput" :placeholder="translations.BOUNDARIES.SEARCH.PLACEHOLDER" />
        <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            :items-per-page="10"
            :search="searchInput"
            :sort-by.sync="sortColumnName"
            :sort-desc.sync="isDescending"
            @click:row="editFarmBoundary($event.farmBoundaryId)"
        >
          <template v-slot:item.createdOn="{ item }">
            <span>{{ item.createdOn.toLocaleDateString() }}</span>
          </template>
          <template v-slot:item.fieldSize="{ item }">
            <span>{{ item.fieldSize.toString(2)}}</span>
          </template>
          <template v-slot:item.name="{ item }">
            <div class="farmBoundaries__name" :title="item.name">{{ item.name }}</div>
          </template>
          <template v-slot:item.coordinates="{ item }">
            <div class="farmBoundaries__polygonImage">
              <PolygonImage :coordinates="item.coordinates" />
            </div>
          </template>
          <template v-slot:item.apdt="{item}">
            <v-btn @click="(event) => handleApdt(event, item)">APDT</v-btn>
          </template>
        </v-data-table>
      </div>

      <div class="farmBoundaries__footer">

        <v-tooltip top :disabled="hasEvoPortalAccess$">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-btn
                  color="primary"
                  :disabled="!hasEvoPortalAccess$"
                  @click="importFromEvoPortal"
              >
                <v-icon small>{{ icons.upload }}</v-icon>
                {{ translations.EVO_PORTAL.IMPORT }}
              </v-btn>
            </div>
          </template>
          <span>{{ translations.EVO_PORTAL.UNAVAILABLE }}</span>
        </v-tooltip>
        <v-btn
            color="primary"
            @click="addFarmBoundary"
        >
          <v-icon small>{{ icons.add }}</v-icon>
          {{ translations.BOUNDARIES.CREATE }}
        </v-btn>
      </div>
    </div>

  </div>
</template>

<script>
import Title from "@components/Title.component";
import { mapState } from "vuex";
import SearchInput from "@components/SearchInput.component";
import FarmBoundaryService from "@services/farmBoundary.service";
import { FarmBoundaryType } from "shared-frontend/dist/farmBoundary/farmBoundary.domain";
import { icons } from "@utils/icons.utils";
import ModalService from "@/modals/modal.service";
import { ModalTypeEnum } from "@/modals/modalType.enum";
import PolygonImage from "@/boundaries/PolygonImage.component";
import EvoPortalService from "@services/evoPortal.service";

const COLUMN_FARM_BOUNDARY_ID = "farmBoundaryId"; // Not actually visible, used to determine which farm boundary it is on click;
const COLUMN_COORDINATES = "coordinates"; // Also has dependency in template, change it too if you change this
const COLUMN_NAME = "name";
const COLUMN_CROP = "crop";
const COLUMN_CREATED_ON = "createdOn"; // Also has dependency in template, change it too if you change this
const COLUMN_FIELD_SIZE = "fieldSize";
const COLUMN_TYPE = "type";
const COLUMN_APDT = "apdt";

export default {
  name: "farmBoundaries",
  components: { PolygonImage, SearchInput, Title },
  data() {
    return {
      sortColumnName: COLUMN_CREATED_ON,
      isDescending: true,
      searchInput: ""
    };
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    }),
    tableHeaders() {
      return [
        {
          value: COLUMN_COORDINATES,
          text: "",
          sortable: false,
          filterable: false
        },
        {
          value: COLUMN_NAME,
          text: this.translations.BOUNDARIES.TABLE.HEADERS.NAME
        },
        {
          value: COLUMN_CROP,
          text: this.translations.BOUNDARIES.TABLE.HEADERS.CROP
        },
        {
          value: COLUMN_CREATED_ON,
          text: this.translations.BOUNDARIES.TABLE.HEADERS.CREATED
        },
        {
          value: COLUMN_FIELD_SIZE,
          text: this.translations.BOUNDARIES.TABLE.HEADERS.FIELD_SIZE,
          sort: (a, b) => a.toNumber() - b.toNumber()
        },
        {
          value: COLUMN_TYPE,
          text: this.translations.BOUNDARIES.TABLE.HEADERS.TYPE
        },
        {
          value: COLUMN_APDT
        }
      ];
    },
    tableItems() {
      if (!this.farmBoundaries$) {
        return [];
      } else {
        return this.farmBoundaries$.map((farmBoundary) => {
          return {
            [COLUMN_FARM_BOUNDARY_ID]: farmBoundary.farmBoundaryId,
            [COLUMN_COORDINATES]: farmBoundary.coordinates,
            [COLUMN_NAME]: farmBoundary.name,
            [COLUMN_CROP]: farmBoundary.cropName,
            [COLUMN_CREATED_ON]: farmBoundary.createdOn,
            [COLUMN_FIELD_SIZE]: farmBoundary.fieldSize__ha,
            [COLUMN_TYPE]: this.translateType(farmBoundary.type)
          };
        });
      }
    },
    icons() {
      return icons;
    }
  },
  methods: {
    translateType(type) {
      switch (type) {
        case FarmBoundaryType.MANUAL:
          return this.translations.BOUNDARIES.TYPES.MANUAL;
        case FarmBoundaryType.EVO_PORTAL:
          return this.translations.BOUNDARIES.TYPES.EVO_PORTAL;
        case FarmBoundaryType.HISTORICAL:
          return this.translations.BOUNDARIES.TYPES.HISTORICAL;
        case FarmBoundaryType.TEAM:
          return this.translations.BOUNDARIES.TYPES.TEAM;
        default:
          throw Error(`Unsupported type: ${type}`);
      }
    },
    addFarmBoundary() {
      this.$router.push({ name: "addFarmBoundary" });
    },
    editFarmBoundary(farmBoundaryId) {
      this.$router.push({ name: "editFarmBoundary", params: { farmBoundaryId } });
    },
    importFromEvoPortal() {
      ModalService.showModal(ModalTypeEnum.CONFIRM_IMPORT_FROM_EVO_PORTAL);
    },
    handleApdt(event, item) {
      event.stopPropagation();
      this.$router.push({ name: "apdtAdvice", params: { farmBoundaryId: item.farmBoundaryId } });
    }
  },
  subscriptions() {
    return {
      farmBoundaries$: FarmBoundaryService.farmBoundaries$,
      hasEvoPortalAccess$: EvoPortalService.hasEvoPortalAccess$
    };
  }
};
</script>
