<template>
  <svg ref="svg" :width="width" :height="height" :viewBox="viewBox">
    <polygon :points="points" fill="none" stroke="black"/>
  </svg>
</template>

<script>
import { calculateBounds } from "shared-frontend";

export default {
  name: 'PolygonImage',
  components: {},
  props: {
    coordinates: {
      type: Array,
      required: true
    },
    width: {
      type: Number,
      required: false,
      default: 30
    },
    height: {
      type: Number,
      required: false,
      default: 30
    }
  },
  computed: {
    viewBox() {
     return `0 0 ${this.width} ${this.height}`
    },
    points() {
      const {maxLatitude, maxLongitude, minLatitude, minLongitude} = calculateBounds(this.coordinates);
      return this.coordinates.map(coordinate => {
        const normalizedLatitude = (coordinate.latitude - minLatitude) / (maxLatitude - minLatitude)
        const normalizedLongitude = (coordinate.longitude - minLongitude) / (maxLongitude - minLongitude)
        return [
          normalizedLongitude * this.width,
          this.height - (normalizedLatitude * this.height) // We're deducting from width here because svg y value increased top down while latitude decreased top down
        ]
      })
    }
  },
}
</script>