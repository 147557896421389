<template>
  <v-form @submit.prevent="saveTeamInfo" ref="editTeamForm">
    <v-text-field
        v-model="team.name"
        :label="translations.TEAM.TEAM_NAME"
        :rules="formRules.name"
    />
    <GrowerPicker v-model="selectedGrower"  :rules="formRules.grower" @input="syncGrower" combobox-mode alphabetically :farm-teams="farmTeams"></GrowerPicker>
    <FarmPicker v-model="selectedFarmName" :rules="formRules.farm" @input="syncFarmName" combobox-mode alphabetically :farm-teams="farmTeams" :grower="selectedGrower"></FarmPicker>
    <v-text-field
        v-model="team.year"
        :label="translations.TEAM.YEAR"
        :rules="formRules.year"
        type="number"
    />
    <FieldPicker v-model="selectedFieldName" :rules="formRules.field" @input="syncFieldName" combobox-mode alphabetically :farm-teams="farmTeams" :grower="selectedGrower" :farm="selectedFarmName"></FieldPicker>
    <CropPicker v-model="selectedCropName" :rules="formRules.crop" @input="syncCropName" combobox-mode alphabetically :farm-teams="farmTeams" :grower="selectedGrower" :farm="selectedFarmName" :field="selectedFieldName"></CropPicker>

    <v-text-field
        v-model="team.operator"
        :label="translations.TEAM.OPERATOR"
        :rules="formRules.operator"
    />
    <p class="red--text" v-if="errorMessage">{{ errorMessage }}</p>

    <div class="form__actions">
      <v-btn v-show="!loading" @click="$emit('close')" color="error" text>{{ translations.GENERAL.CANCEL }}</v-btn>
      <v-btn type="submit" color="primary" :loading="loading" dark
      >{{ translations.MODALS.EDIT_USER.FORM.CONFIRM_BUTTON }}
      </v-btn>
    </div>
  </v-form>
</template>

<script lang="ts">
import Vue from 'vue';
import {icons} from '@utils/icons.utils';
import {mapState} from 'vuex';
import TeamService from '@/team/team.service';
import FarmTeamService from '@/farmTeam/farmTeam.service';
import {State} from '../store';
import {first} from 'rxjs/operators';
import GrowerPicker from "@components/picker/GrowerPicker.component.vue";
import FarmPicker from "@components/picker/FarmPicker.component.vue";
import FieldPicker from "@components/picker/FieldPicker.component.vue";
import CropPicker from "@components/picker/CropPicker.component.vue";
import {toAutocompleteItems} from "@components/picker/Picker.helper";

export default Vue.extend({
  name: 'EditTeamInfo',
  components: {CropPicker, FieldPicker, FarmPicker, GrowerPicker},
  subscriptions() {
    return {
      farmTeams$: FarmTeamService.farmTeams$,
    };
  },
  data() {
    return {
      loading: false,
      errorMessage: '',
      team: null,
      growerSearch: null,
      selectedGrower: null,
      farmNameSearch: null,
      selectedFarmName: null,
      fieldNameSearch: null,
      selectedFieldName: null,
      cropNameSearch: null,
      selectedCropName: null,
      formRules: {
        name: [
          v => !!v || (this as any).translations.MODALS.EDIT_TEAM_INFO.NAME_ERROR_REQUIRED
        ],
        grower: [
          v => (!v || v.value.length <= 50) || (this as any).translations.MODALS.EDIT_TEAM_INFO.GROWER_ERROR_LENGTH
        ],
        farm: [
          v => (!v || v.value.length <= 50) || (this as any).translations.MODALS.EDIT_TEAM_INFO.FARM_ERROR_LENGTH
        ],
        year: [
          v => !!v || (this as any).translations.MODALS.EDIT_TEAM_INFO.YEAR_ERROR_FUTURE,
          v => (v && v >= 2000) || (this as any).translations.MODALS.EDIT_TEAM_INFO.YEAR_ERROR_MIN_YEAR,
          v => (v && v <= new Date().getFullYear()) || (this as any).translations.MODALS.EDIT_TEAM_INFO.YEAR_ERROR_FUTURE
        ],
        field: [
          v => (!v || v.value.length <= 50) || (this as any).translations.MODALS.EDIT_TEAM_INFO.FIELD_ERROR_LENGTH
        ],
        crop: [
          v => (!v || v.value.length <= 50) || (this as any).translations.MODALS.EDIT_TEAM_INFO.CROP_ERROR_LENGTH
        ],
        operator: [
          v => (!v || v.length <= 50) || (this as any).translations.MODALS.EDIT_TEAM_INFO.OPERATOR_ERROR_LENGTH
        ]
      }
    };
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    icons() {
      return icons;
    },
    farmTeams(): any {
      if ((this as any).farmTeams$ === null) {
        return []
      } else {
        return (this as any).farmTeams$
      }
    }

  },
  created() {
    // Edit a copy of the team such that it doesn't get changed everywhere in the application
    TeamService.selectedTeam$.pipe(
        first()
    )
        .subscribe(team => {
          this.team = team.createNewTeamState()
          this.selectedGrower = toAutocompleteItems(this.translations.TEAM.GROWER, this.translations)(this.team.grower, null)
          this.selectedFarmName = toAutocompleteItems(this.translations.TEAM.FARM, this.translations)(this.team.farm, null)
          this.selectedFieldName = toAutocompleteItems(this.translations.TEAM.FIELD, this.translations)(this.team.field, null)
          this.selectedCropName = toAutocompleteItems(this.translations.TEAM.CROP, this.translations)(this.team.crop, null)
        });
  },
  methods: {
    async saveTeamInfo(): Promise<void> {
      if (!this.isFormValid() || this.loading) return;
      this.loading = true;
      try {
        await FarmTeamService.updateFarmTeam(this.team);
        this.$emit('close');
      } catch (err) {
        this.errorMessage = this.translations.ERRORS.GENERAL;
      } finally {
        this.loading = false;
      }
    },
    isFormValid() {
      // @ts-ignore
      return this.$refs.editTeamForm.validate();
    },
    syncCropName(input) {
      this.team.crop = input?.value
    },
    syncGrower(input) {
      this.team.grower = input?.value
    },
    syncFarmName(input) {
      this.team.farm = input?.value
    },
    syncFieldName(input) {
      this.team.field = input?.value
    }
  },
});
</script>
