<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          color="primary"
          large
          class="ml-auto"
          style="cursor: pointer"
          v-bind="attrs"
          v-on="on">
        {{ icons.addCircle }}
      </v-icon>
    </template>

    <v-card>
      <v-card-title>
        Add new third party data consumer
      </v-card-title>

      <v-card-text>
        <v-form ref="thirdPartyForm" v-model="valid">
          <v-text-field
              label="Type a name for the new third party consumer"
              v-model="thirdPartyName"
              :rules="formRules.source">
          </v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
            color="danger"
            text
            @click="dialog=false;"
        >Cancel
        </v-btn>
        <v-spacer/>
        <v-btn
            color="primary"
            text
            @click="handleSubmit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {icons} from "@utils/icons.utils";
import {mapState} from "vuex";

export default {
  name: "addThirdParty",
  components: {},
  props: {},
  data() {
    return {
      dialog: false,
      thirdPartyName: "",
      valid: false,
      formRules: {
        source: [
          v => !!v || this.translations.GENERAL.REQUIRED,
        ]
      }

    };
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    }),
    icons() {
      return icons;
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.thirdPartyForm.validate()
      if (!this.valid) {
        return
      }
      this.dialog = false;
      this.$emit("submit", this.thirdPartyName);
    }
  },
  watch: {
    dialog() {
      this.source = undefined;
      this.thirdParty = undefined;
    }
  }
};
</script>

<style scoped>

</style>
