// EXTERNAL
import { BehaviorSubject } from 'rxjs';
import { API_ENDPOINTS } from "shared-frontend";
import httpService from "@services/http.service";
import { logger } from "@services/logger.service";

// Services

class EvoPortalService {
    private _hasEvoPortalAccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


    public get hasEvoPortalAccess$() {
        return this._hasEvoPortalAccess$;
    }

    public async init() {
        await this.getHasEvoPortalAccess();
    }

    private async getHasEvoPortalAccess(): Promise<boolean> {
        try {
            const response = await httpService.get(API_ENDPOINTS.EVO_PORTAL_ACCESS());

            const hasAccess: boolean = response.data;
            this.setHasEvoPortalAccessToState(hasAccess);

            logger.info(`[EvoPortalService] - hasEvoPortalAccess: ${hasAccess}`);

            return hasAccess;
        } catch (err) {
            logger.error('[EvoPortalService] - hasEvoPortalAccess', err);
        }
    }

    private setHasEvoPortalAccessToState(hasEvoPortalAccess: boolean) {
        this._hasEvoPortalAccess$.next(hasEvoPortalAccess);
    }
}

export default new EvoPortalService();
