<template>
  <v-form @submit.prevent="startImport">
    <p v-if="!loading">{{trans.MODALS.CONFIRM_IMPORT_FROM_EVO_PORTAL.MESSAGE}}</p>
    <p v-else>{{trans.MODALS.CONFIRM_IMPORT_FROM_EVO_PORTAL.LOADING_MESSAGE}}</p>
    <div class="form__actions">
      <v-btn v-show="!loading" @click="$emit('close')" color="error" text>{{ trans.GENERAL.CANCEL }}</v-btn>
      <v-btn type="submit" color="primary" :loading="loading" :click="startImport" dark
      >{{ trans.GENERAL.CONFIRM }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import FarmBoundaryService from "@services/farmBoundary.service";

export default {
  components: {},
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({
      trans: (state) => state.translation.translations
    }),
  },
  methods: {
    async startImport() {
      this.loading = true;
      try {
        await FarmBoundaryService.importFromEvoPortal()
      } finally {
        this.$emit('close');
        this.loading = false
      }
    }
  },

}
</script>