<template>
  <fragment>
    <v-card class="dashboard__card">
      <v-card-title :style="{ backgroundColor: brandColors.SECONDARY }">
        <span>{{ translations.DASHBOARD.WORKFORCE_USERS }}</span>
        <InfoIcon :text="translations.DASHBOARD.WORKFORCE_USERS_INFO"/>
      </v-card-title>
      <v-card-text class="dashboard__textContainer">
        <ul v-if="workforce$ && workforce$.length" class="dashboard__list">
          <li v-for="user in workforce$" :key="user.id" class="dashboard__item">
            <v-icon :color="user.active ? 'primary' : 'lightgrey'" class="dashboard__icon ml-2"
            >{{ user.authorityLevel? icons.user : icons.userWithoutAuthority }}
            </v-icon>
            <span
                :class="{ dashboard__inActive: !user.active }"
                class="dashboard__text dashboard__text-title"
            >{{ user.name }}
                                    <template v-if="!user.active">
                                        ({{ translations.DASHBOARD.INACTIVE }})
                                    </template></span
            >
            <span class="dashboard__actions">
                                    <v-btn icon @click="editUser(user)">
                                        <v-icon>{{ icons.edit }}</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="removeUser(user)">
                                        <v-icon color="red">{{ icons.delete }}</v-icon>
                                    </v-btn>
                                </span>
          </li>
        </ul>
        <template v-else-if="!workforce$">
          <v-progress-circular color="primary" indeterminate/>
        </template>
        <template v-else>
          <p style="font-weight: bold">
            {{ translations.DASHBOARD.NO_WORKFORCE.PARAGRAPH_1 }}
          </p>
          <p>{{ translations.DASHBOARD.NO_WORKFORCE.PARAGRAPH_2 }}</p>
        </template>
      </v-card-text>
      <v-btn absolute color="primary" dark fab large right top @click="addUser">
        <v-icon>{{ icons.add }}</v-icon>
      </v-btn>
    </v-card>
  </fragment>


</template>

<script lang="ts">
import Vue from 'vue';
import {mapState} from 'vuex';
import InfoIcon from '@components/InfoIcon.component.vue';
import MapIcon from '@/map/icons/MapIcon.icon.vue';
import {Icons, icons} from '@utils/icons.utils';
import ModalService from '@/modals/modal.service';
import {ModalTypeEnum} from '@/modals/modalType.enum';
import WorkforceService from './workforce.service';
import {Brand, BrandingService} from 'shared-frontend';
import {images} from '@utils/assets.util';
import {State} from '@/store';

export default Vue.extend({
  name: 'Users',
  components: {
    InfoIcon,
    MapIcon
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    icons(): Icons {
      return icons;
    },
    brandColors(): any {
      return BrandingService.getColors(process.env.VUE_APP_BRAND as Brand);
    },
    images(): { [imageName: string]: string } {
      return BrandingService.getImages(process.env.VUE_APP_BRAND as Brand, images);
    }
  },
  methods: {
    addUser(): void {
      ModalService.showModal(ModalTypeEnum.ADD_USER);
    },
    editUser(user): void {
      ModalService.showModal(ModalTypeEnum.EDIT_USER, {user});
    },
    removeUser(user): void {
      if (user) {
        ModalService.showModal(ModalTypeEnum.CONFIRM_DELETE_USER, {user});
      }
    },
  },
  subscriptions() {
    return {
      workforce$: WorkforceService.workforce$
    };
  }
});
</script>