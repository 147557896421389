<template>
  <v-combobox v-if="comboboxMode" :value="value" @input="handleInput" :items="growers" :search-input.sync="growerSearch" :label="trans.TEAM.GROWER" :rules="rules" clearable>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            No results matching "<strong>{{ growerSearch }}</strong>". Press <kbd>enter</kbd> to create a new one
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
  <v-autocomplete v-else :value="value" @input="$emit('input', $event)" :items="growers" dark hide-details :label="trans.TEAM.GROWER" :rules="rules" return-object/>

</template>

<script lang="ts">

import {AutoCompleteItem, selectAllAutoCompleteItem, toAutocompleteItems} from "./Picker.helper";
import {uniqueGrowers} from "@components/picker/GrowerPicker.helper";
import {FarmTeamSummary} from "@/farmTeam/farmTeam.domain";
import Vue, {PropType} from "vue";
import {mapState} from "vuex";
import {State} from "@/store";

export default Vue.extend({
  name: 'GrowerPicker',
  props: {
    value: {},
    comboboxMode: {
      type: Boolean,
      default: false
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    alphabetically: {
      type: Boolean,
      default: false
    },
    farmTeams: {
      type: Array as PropType<FarmTeamSummary[]>,
      required: false
    },
    rules: []
  },
  data() {
    return {
      growerSearch: "",
      createdGrowers: []
    }
  },
  computed: {
    ...mapState({
      trans: (state: State) => state.translation.translations
    }),
    growers(): AutoCompleteItem[] {
      return this.findGrowers().concat(this.createdGrowers);
    }
  },
  methods: {
    findGrowers(): AutoCompleteItem[] {
      let growers = this.selectAll ? [selectAllAutoCompleteItem("All Growers")] : [];
      return (
          growers.concat(
              uniqueGrowers(this.farmTeams, this.alphabetically)
                  ?.map(toAutocompleteItems(this.trans.TEAM.GROWER, this.trans))
          )
      );
    },
    handleInput(entry): void {
      if (typeof entry === 'string') {
        let trimmedEntry = entry.trim();
        let foundEntry = this.growers.find(value => value.value === entry);
        if (foundEntry) {
          this.$emit('input', foundEntry)
        } else if (trimmedEntry.trim() !== "") {
          let newEntry = toAutocompleteItems(this.trans.TEAM.GROWER, this.trans)(entry, null)
          this.createdGrowers.push(newEntry)
          this.$emit('input', newEntry)
        }
      } else {
        this.$emit('input', entry)
      }
    }

  }
});
</script>
