<template>
  <div class="aside">
    <div class="aside__content">
      <slot name="content"/>
    </div>

    <div v-if="hasFooter" class="aside__footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Aside',
  computed: {
    hasFooter() {
      return Boolean(this.$slots.footer);
    }
  }
};
</script>