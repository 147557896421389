// Pipe and compose
// =================
// https://dev.to/ascorbic/creating-a-typed-compose-function-in-typescript-3-351i

export const pipe = <T extends any[], R>(fn1: (...args: T) => R, ...fns: Array<(a: R) => R>) => {
    const piped = fns.reduce(
        (prevFn, nextFn) => (value: R) => nextFn(prevFn(value)),
        (value) => value
    );
    return (...args: T) => piped(fn1(...args));
};

export const compose = <R>(fn1: (a: R) => R, ...fns: Array<(a: R) => R>) =>
    fns.reduce((prevFn, nextFn) => (value) => prevFn(nextFn(value)), fn1);


// ======================

export interface Setoid<A> {
    equals: (x: A, y: A) => boolean;
}

export interface Semigroup<A> {
    concat: (x: A, y: A) => A;
}

export const includesBy = <A>(xs: A[], fn: (i: A) => boolean): boolean => xs.some(fn);
export const includes = <A>(setoid: Setoid<A>) => (xs: A[], y: A): boolean => xs.some((x: A) => setoid.equals(x, y));

export const findIndexBy = <A>(xs: A[], fn: (i: A) => boolean): number => xs.findIndex(fn);
export const findIndex = <A>(setoid: Setoid<A>) => (xs: A[], y: A): number =>
    xs.findIndex((x: A) => setoid.equals(x, y));

export const inverse = <T = any>(fn: (i: T) => boolean) => (x: T, ...y: any[]) => !fn(x);

export const addItemToArray = <T = any>(setoidType: Setoid<T>) => (arr: T[], item: T) => {
    const isPresent = includes(setoidType)(arr, item);
    return isPresent ? arr : [...arr, item];
};

export const updateArray = <T = any>(setoidType: Setoid<T>) => (arr: T[], item: T) => {
    const idx = findIndex(setoidType)(arr, item);
    return idx === -1 ? arr : [...arr.slice(0, idx), item, ...arr.slice(idx + 1)];
};

export const updateArrayItem = <T = any>(arr: T[], selectFn: (i: T) => boolean, updateFn: (i: T) => T): T[] => {
    return arr.map((i: T) => (selectFn(i) ? updateFn(i) : i));
};

export const deleteByProp = <T extends object, K extends keyof T>(arr: T[], propName: K, propVal: T[K]): T[] => {
    return arr.filter((item: T) => item?.[propName] !== propVal);
};

export const deleteBy = <T = any>(arr: T[], selectFn: (i: T) => boolean): T[] => {
    return arr.filter(inverse(selectFn));
};

export const toUnique = <T = any>(arr: T[]): T[] => {
    return [...new Set(arr)];
};

export const isNullOrUndefined = (val: any) => val === null || val === undefined;

export const pick = <T extends object = any, K extends keyof T = any>(key: K) => (obj: T): T[K] => {
    return obj?.[key];
};

export interface PropsEqual<T> {
    [key: string]: T[keyof T];
}

export const propEqual = <T extends object>(propsEqual: PropsEqual<T>) => (item: T) => {
    return Object.entries(propsEqual).every(([key, val]) => item?.[key] === val);
};

export function not<T>(predicate: (T) => boolean): (T) => boolean {
    return el => !predicate(el);
}
