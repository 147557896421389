import {PhoneNumber} from '@domain/phonenumber.domain';

export type WorkforceMemberJSON = {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: {
        areaCode: string;
        number: string;
    };
    active: boolean;
    authorityLevel: boolean;
};

export const parseWorkforceMembersJSON = (json: WorkforceMemberJSON[]): WorkForceMember[] => {
    return json.map(
        (workForceMemberJSON: WorkforceMemberJSON): WorkForceMember => {
            const { userId, firstName, lastName, email, phoneNumber, active, authorityLevel } = workForceMemberJSON;
            const phone: PhoneNumber = phoneNumber
                ? new PhoneNumber(phoneNumber.areaCode, phoneNumber.number)
                : null;

            return new WorkForceMember(
                userId,
                firstName,
                lastName,
                email,
                phone,
                active,
                authorityLevel
            );
        }
    );
};

export class WorkForceMember {
    constructor(
        public id: string,
        public firstName: string,
        public lastName: string,
        public email: string,
        public phoneNumber: PhoneNumber,
        public active: boolean,
        public authorityLevel: boolean
    ) {}

    get name() {
        return `${this.firstName} ${this.lastName}`;
    }

    public updatePhoneNumber(phoneNumber: PhoneNumber): void {
        this.phoneNumber = phoneNumber;
    }
}
