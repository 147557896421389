<template>
  <div class="mapView">
    <div class="mapView__top">
      <Warnings :team="selectedTeamOrNull$" :is-active-team="isTeamActive"/>
      <Statistics :team="selectedTeamOrNull$" :is-active-team="isTeamActive"/>
    </div>
    <l-map
        ref="mapView"
        :max-zoom="20"
        :min-zoom="3"
        style="z-index: 1"
        @mousedown="longPressStart"
        @mousemove="longPressStop"
        @mouseup="longPressStop"
        @update:zoom="onZoomEnd"
    >
      <l-tile-layer
          :options="{ maxZoom: 20, maxNativeZoom: 19, tileSize: 512, zoomOffset: -1 }"
          :url="tileServerUrl"
      />

      <l-control-scale :imperial="false" :metric="true" position="bottomleft"></l-control-scale>

      <template v-if="selectedTeamOrNull$">
        <l-feature-group ref="markers">
          <l-marker
              ref="marker"
              v-for="fieldEntity in locations"
              v-if="fieldEntity.location && fieldEntity.location.hasCorrectCoordinates()"
              :key="fieldEntity.id"
              :lat-lng="[fieldEntity.location.latitude, fieldEntity.location.longitude]"
              :z-index-offset="getZIndexForMarker(fieldEntity)"
              @click="markerClicked($event, fieldEntity)"
          >
            <l-icon
                :id="fieldEntity.id"
                :icon-anchor="getDynamicAnchor(fieldEntity)"
                :icon-size="getDynamicSize(fieldEntity)"
            >
              <MapIcon :field-entity="fieldEntity" :zoom="zoom"/>
              <div class="mapView__icon">
                                <span v-if="hasName(fieldEntity)" class="mapView__icon-label">
                                    {{ getFieldEntityName(fieldEntity) }}
                                    <span v-if="!isTeamActive">({{ translations.TEAM_PAGE.LAST_SEEN }})</span>
                                </span>
              </div>
            </l-icon>

            <Balloon :show-menu="showContextMenu" :fieldEntity="fieldEntity"/>
          </l-marker>
        </l-feature-group>

        <FieldCoverage v-if="isFieldCoverageEnabled" :zoom="zoom"/>

        <l-polygon v-if="hasBoundary(selectedTeamOrNull$)" :lat-lngs="translatedBoundaryCoordinates(selectedTeamOrNull$)" :color="brandColors.YELLOW"/>

        <v-menu v-model="showMenu" :position-x="menuX" :position-y="menuY" absolute offset-x>
          <ContextMenu :field-entity="fieldEntitySelected" :is-team-active="isTeamActive"/>
        </v-menu>
      </template>
      <template v-else>
        <l-feature-group ref="filteredTeams">
          <l-feature-group
              ref="filteredTeam"
              v-for="filteredTeam in filteredTeams$"
              :key="filteredTeam.teamId"
              v-if="hasBoundary(filteredTeam)"
          >
            <l-polygon
                :lat-lngs="translatedBoundaryCoordinates(filteredTeam)"
                :color="brandColors.RED"
                @click="filteredTeamClicked(filteredTeam)">
              <l-tooltip :options="{permanent: true, direction: 'center', className: 'boundaryTooltip'}">{{ filteredTeam.teamName }}</l-tooltip>
            </l-polygon>
          </l-feature-group>
        </l-feature-group>

      </template>
    </l-map>
  </div>
</template>

<script>
// EXTERNAL
import {mapState} from 'vuex';
//
// INTERAL
import {BalerLoadReport, BrandingService, Dump, Gate, IconService, Location, Marker, MetalDetection, User} from 'shared-frontend';
import ModalService from '@/modals/modal.service';
import TeamService from '@/team/team.service';
import FieldEntityService from '@services/fieldEntity.service';
import BalerLoadReportService, {eqBalerLoadReport} from '@services/balerLoadReport.service';
import MarkerService from '@services/marker.service';
import MetalDetectionService from '../spfh/metalDetection/metalDetection.service';
import FieldCoverage from '@components/fieldCoverage/FieldCoverage.component.vue';
import Statistics from './statistics/Statistics.component';
import {LONGPRESS_DURATION} from '@/config/config';
import {ModalTypeEnum} from '@/modals/modalType.enum';
import {logger} from '@services/logger.service';
import MapIcon from './icons/MapIcon.icon';
import {getIconSize} from '@/map/icons/MapIcon.helper';
import ContextMenu from './ContextMenu.component';
import {icons} from '@utils/icons.utils';
import Balloon from './marker/Balloon';
import Warnings from './warnings/Warnings.component';
import FilteredFarmTeamService from "@/farmTeam/filteredFarmTeam.service";
import {updateArray} from '@utils/functional.util';
import {map} from "rxjs/operators";
import {sortByMostRecent} from "@/farmTeam/farmTeam.helper";


export default {
  name: 'Map',
  components: {
    ContextMenu,
    FieldCoverage,
    Statistics,
    MapIcon,
    Balloon,
    Warnings
  },
  data() {
    return {
      isMapBoundsSet: false,
      longPressTimer: null,
      zoom: 20,
      showMenu: false,
      fieldEntitySelected: null,
      menuX: 0,
      menuY: 0,
      bales: [],
      markers: [],
      metalDetections: []
    };
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    }),
    ...mapState({
      isFieldCoverageEnabled: (state) => state.fieldCoverageEnabled
    }),
    locations() {
      return [...this.selectedTeamOrNull$.getAllLocations(), ...this.bales, ...this.markers, ...this.metalDetections];
    },
    currentRoute() {
      return this.$route.name;
    },
    isTeamActive() {
      return this.selectedTeamOrNull$ && this.selectedTeamOrNull$.isActive();
    },
    mapObject() {
      return this.$refs.mapView;
    },
    brandColors() {
      return BrandingService.getColors(process.env.VUE_APP_BRAND);
    },
    tileServerUrl() {
      return process.env.VUE_APP_TILE_SERVER_URL;
    },
    icons() {
      return icons;
    },
    BALLOONS_VISIBLE_ZOOM_THRESHOLD() {
      return 16;
    }
  },
  mounted() {
    this.centerMapOnBrowserLocation();

    // Set up subscriptions to dynamically react to changes in selected team + selected fieldEntity
    this.$subscribeTo(TeamService.selectedTeamIdOrNull$, (_) => {
      this.fitMapToTeam();
      this.showBalloons();
    });

    this.$subscribeTo(this.$observables.fieldEntity$, this.centerMapOnFieldEntity);

    this.$subscribeTo(BalerLoadReportService.balerLoadReports$, (report) => {
      this.bales = Array.isArray(report) ? report : updateArray(eqBalerLoadReport)(this.bales, report);
    });

    this.$subscribeTo(MarkerService.markers$, (markers) => {
      this.markers = markers;
    });

    this.$subscribeTo(MetalDetectionService.metalDetections$, (metalDetections) => {
      this.metalDetections = metalDetections;
    })

    this.$subscribeTo(this.$observables.filteredTeams$, this.fitMapToFilteredTeams);

  },
  methods: {
    hasBoundary(team) {
      return team && Boolean(team.boundary);
    },

    translatedBoundaryCoordinates(team) {
      if (team && team.boundary) {
        return team.boundary.coordinates.map((coord) => [
          coord.lat !== undefined ? coord.lat : coord.latitude,
          coord.lon !== undefined ? coord.lon : coord.longitude
        ]);
      } else {
        return [];
      }
    },
    showBalloons() {
      this.$nextTick(() => {
        (this.$refs.marker || []).forEach((marker) => {
          marker.mapObject.openPopup();
        });
      });
    },
    hideBalloons() {
      this.$nextTick(() => {
        (this.$refs.marker || []).forEach((marker) => {
          marker.mapObject.closePopup();
        });
      });
    },
    centerMapOnBrowserLocation() {
      if (!('geolocation' in navigator)) reject(new Error('Geolocation API not supported by browser!'));

      navigator.geolocation.getCurrentPosition(
          (position) => {
            const coords = position.coords;
            const {latitude, longitude} = coords || {};

            // Retrieving the browser location may take a while
            // If in the meantime, the map was centered on a team, refrain from centering on the browserlocation!
            if (!this.isMapBoundsSet) {
              logger.info(
                  `[Map.component] - map centered on browserLocation - lat: ${latitude}, lon: ${longitude}`
              );
              this.setMapView(latitude, longitude, 18);
            }
          },
          (error) => {
            logger.error('Geolocation API - getCurrentPosition - FAILED: ', error);
          }
      );
    },
    getFieldEntityName(fieldEntity) {
      return IconService.getDisplayName(fieldEntity, this.selectedTeamOrNull$.users.getList());
    },
    getDynamicSize(fieldEntity) {
      const iconSize = getIconSize(fieldEntity, this.zoom);
      return [iconSize, iconSize];
    },
    getDynamicAnchor(fieldEntity) {
      const iconSize = getIconSize(fieldEntity, this.zoom);
      return [iconSize / 2, iconSize / 2];
    },
    getZIndexForMarker(fieldEntity) {
      return IconService.getZIndexForMarker(fieldEntity);
    },
    fitMapToTeam() {
      this.$nextTick(() => {
        try {
          const markerBounds = this.$refs.markers?.mapObject?.getBounds();

          if (markerBounds?.isValid()) {
            this.$refs.mapView.fitBounds(markerBounds.pad(0.05));
            this.isMapBoundsSet = true;
          } else {
            this.centerMapOnBrowserLocation();
          }
        } catch (err) {
          logger.error('error: ', err);
        }
      });
    },
    fitMapToFilteredTeams() {
      this.$nextTick(() => {
        try {
          const filteredTeamsBounds = this.$refs.filteredTeams?.mapObject?.getBounds();

          if (filteredTeamsBounds?.isValid()) {
            this.$refs.mapView.fitBounds(filteredTeamsBounds.pad(0.05));
            this.isMapBoundsSet = true;
          } else {
            this.centerMapOnBrowserLocation();
          }
        } catch (err) {
          logger.error('error: ', err);
        }
      });
    },
    setMapView(latitude, longitude, zoom) {
      if (!latitude || !longitude) return;
      this.$refs.mapView.mapObject.setView([latitude, longitude], zoom);
    },
    centerMapOnFieldEntity(selectedFieldEntity) {
      if (!selectedFieldEntity) return;

      const {bins, combines, spfhs, balers, users} = this.selectedTeamOrNull$;

      const mapFieldEntities = [...bins, ...combines, ...spfhs, ...balers, ...users];

      const fieldEntityWithLocation = mapFieldEntities.find(
          (fieldEntity) => fieldEntity.id === selectedFieldEntity.id
      );

      if (!fieldEntityWithLocation) return;

      const {location} = fieldEntityWithLocation;

      if (location && location.hasCorrectCoordinates()) {
        this.setMapView(location.latitude, location.longitude, this.zoom);
      }
    },
    hasName(fieldEntity) {
      // Gates and Dumps dont have a name
      return !(
          fieldEntity instanceof Gate ||
          fieldEntity instanceof Dump ||
          fieldEntity instanceof BalerLoadReport ||
          fieldEntity instanceof Marker ||
          fieldEntity instanceof MetalDetection
      );
    },
    longPressStart(mouseEvent) {
      if (this.selectedTeamOrNull$) return;
      if (this.isViewingAsGrower$) return;
      this.longPressTimer = setTimeout(() => this.addGate(mouseEvent), LONGPRESS_DURATION);
    },
    longPressStop() {
      if (this.longPressTimer) {
        window.clearTimeout(this.longPressTimer);
        this.longPressTimer = null;
      }
    },
    addGate(mouseEvent) {
      if (!this.isTeamActive) return;

      const {lng: lon, lat} = mouseEvent.latlng;
      const location = new Location(lat, lon);
      const gate = new Gate(null, location);

      ModalService.showModal(ModalTypeEnum.CREATE_GATE, {teamState: this.selectedTeamOrNull$, gate});
    },
    markerClicked(event, fieldEntity) {
      if (fieldEntity instanceof Gate) {
        ModalService.showModal(ModalTypeEnum.DELETE_GATE, {
          teamState: this.selectedTeamOrNull$,
          gate: fieldEntity
        });
      }

      this.showContextMenu(fieldEntity, event);
    },
    filteredTeamClicked(filteredTeam) {
      TeamService.selectTeam(filteredTeam.teamId)
    },
    showContextMenu(fieldEntity, event) {
      if (fieldEntity instanceof User || fieldEntity instanceof Gate) return;

      this.menuX = event?.originalEvent?.clientX ?? event.clientX;
      this.menuY = event?.originalEvent?.clientY ?? event.clientY;
      this.showMenu = true;
      this.fieldEntitySelected = fieldEntity;

      if (this.zoom > this.BALLOONS_VISIBLE_ZOOM_THRESHOLD) {
        setTimeout(() => this.showBalloons(), 50);
      }
    },
    stopTeam() {
      if (this.isTeamActive) ModalService.showModal(ModalTypeEnum.STOP_TEAM, {team: this.selectedTeamOrNull$});
    },
    downloadFieldReport() {
      ModalService.showModal(ModalTypeEnum.FIELD_REPORT);
    },
    onZoomEnd(zoom) {
      this.zoom = zoom;
    }
  },
  watch: {
    currentRoute: function (newRoute, oldRoute) {
      this.$refs.mapView.mapObject.invalidateSize();
    },
    zoom: function (newZoom, oldZoom) {
      const THRESHOLD = this.BALLOONS_VISIBLE_ZOOM_THRESHOLD;

      if (newZoom <= THRESHOLD && oldZoom > THRESHOLD) {
        this.hideBalloons();
      }
      if (newZoom > THRESHOLD && oldZoom <= THRESHOLD) {
        this.showBalloons();
      }
    }
  },
  subscriptions() {
    return {
      isViewingAsGrower$: TeamService.isViewingAsGrower$,
      selectedTeamOrNull$: TeamService.selectedTeamOrNull$,
      fieldEntity$: FieldEntityService.fieldEntity$,
      filteredTeams$: FilteredFarmTeamService.filteredTeams$.pipe(map((teams) => {
        // let boundaries = teams.map(t => t.boundary);
        // const sorted = teams.sort(sortByMostRecent);
        // sorted.reduce((acc, el, ix, all )=> acc, [] )
        return teams
            .sort(sortByMostRecent)
            .reverse();
      })),
    };
  }
};
</script>

<style lang="scss">


.leaflet-tooltip.boundaryTooltip {
  box-shadow: unset;
  -webkit-box-shadow: unset;
  border: unset;

  font-weight: bold;
  background-color: white;
  opacity: 0.75;
  padding: 2px 8px;
  border-radius: 20px;
}


</style>
