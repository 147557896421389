// EXTERNAL
import {API_ENDPOINTS, BaleDropDetected, Baler, BalerLoadReport, flatten, isNullOrUndefined, parseBaleDropDetected, parseBalerLoadReport, pick, Team} from 'shared-frontend';
import TeamService from '@/team/team.service';
import httpService from '@services/http.service';
import {logger} from '@services/logger.service';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, concat, from, Observable, of} from 'rxjs';
import {inverse, Setoid} from '@utils/functional.util';

interface BaleDrop extends BaleDropDetected {
    teamId: string;
}

const toBaleDrop = (teamId: string, baleDropDetected: BaleDropDetected): BaleDrop => {
    return {teamId, ...baleDropDetected};
};

const toBalerLoadReport = (baleDrop: BaleDrop): BalerLoadReport => {
    const {teamId, ...baleDropDetected} = baleDrop;
    return parseBaleDropDetected(baleDropDetected);
};

class BalerLoadReportService {
    private baleDrops$: BehaviorSubject<BaleDrop> = new BehaviorSubject<BaleDrop>(null);

    public get balerLoadReports$(): Observable<BalerLoadReport | BalerLoadReport[]> {
        return TeamService.selectedTeam$.pipe(
            switchMap((team: Team) => concat(this.loadReportHistory(team), this.incomingLoadReports(team)))
        );
    }

    public onBaleDropDetected = (teamId: string, baleDropDetected: BaleDropDetected): void => {
        this.baleDrops$.next(toBaleDrop(teamId, baleDropDetected));
    };

    // Private methods
    // ===============

    private loadReportHistory = (team: Team): Observable<BalerLoadReport[]> => {
        return of(team.balers.getList()).pipe(
            map((balers: Baler[]) => balers.map(pick('id'))),
            switchMap(this.getLoadReportsForBalers(team)),
            map((reports: BalerLoadReport[]) => reports.filter((r) => r.finished))
        );
    };

    private incomingLoadReports = (team: Team): Observable<BalerLoadReport> => {
        return this.baleDrops$.pipe(
            filter(inverse(isNullOrUndefined)),
            filter((baleDrop: BaleDrop) => baleDrop.teamId === team.id),
            map(toBalerLoadReport)
        );
    };

    private getLoadReportsForBalers = (team: Team) => (balerIds: string[]): Observable<BalerLoadReport[]> => {
        if (balerIds?.length) {
            return from(
                Promise.all(
                    balerIds.map((id: string) => {
                        return this.getLoadReportsForFarmBaler(team.id, id);
                    })
                )
            ).pipe(
                tap((_) => logger.info('Fetching balerLoadReport history for ' + balerIds.join(', '))),
                map(flatten)
            );
        } else {
            return of([]);
        }
    };

    private getLoadReportsForFarmBaler = async (teamId: string, balerId: string): Promise<BalerLoadReport[]> => {
        try {
            const response = await httpService.get(API_ENDPOINTS.FARM_BALER_LOAD_REPORTS(teamId, balerId));
            return response?.data?.map(parseBalerLoadReport) ?? [];
        } catch (err) {
            logger.error(`[BalerLoadReportService] - Error Load Reports for farm baler ${balerId}`, err);
            return Promise.resolve([]);
        }
    };


}

export const eqBalerLoadReport: Setoid<BalerLoadReport> = {
    equals: (x: BalerLoadReport, y: BalerLoadReport) => x?.id === y?.id
};


export default new BalerLoadReportService();
