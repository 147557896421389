// EXTERNAL
import {
    API_ENDPOINTS,
    Baler,
    BalerLoadReport,
    Balers,
    FarmBalerJSON,
    parseBalerLoadReport,
    parseFarmBaler, Team
} from 'shared-frontend';
import httpService from '@services/http.service';
import { logger } from '@services/logger.service';
import {flatten} from 'shared-frontend/dist/utils/flatten.util';

class FarmBalersService {
    public async fetchFarmBalers(): Promise<Balers> {
        try {
            const response = await httpService.get(API_ENDPOINTS.FARM_BALERS());
            const farmBalersJSON: FarmBalerJSON[] = response.data;

            const balers: Baler[] = farmBalersJSON.map((farmBalerJSON: FarmBalerJSON) => parseFarmBaler(farmBalerJSON));

            logger.info('[FarmBalersService] - fetchFarmBalers', balers);

            return new Balers(balers);
        } catch (err) {
            logger.error(`[farmCombines.service] - fetchFarmCombines: Error in fetching farmBalers`, err);
        }
    }
}

export default new FarmBalersService();
