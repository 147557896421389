import 'core-js';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import KeycloakService from '@services/keycloak.service';
import SettingsService from '@services/settings.service';
import FarmManagerService from '@services/farmManager.service';
import WorkforceService from '@/workforce/workforce.service';
import DetectionService from '@services/detection.service';
import FarmBoundaryService from '@services/farmBoundary.service';
import EvoPortalService from '@services/evoPortal.service';
import {logger} from '@services/logger.service';
import vuetify from './plugins/vuetify.plugin';
import router from './router';
import store from './store';
import './plugins/leaflet.plugin'; // this line and others below: importing for effect
import './plugins/vueRx.plugin';
import './plugins/fragment.plugin';
import MessageService from '@/messaging/message.service'

Vue.config.productionTip = true;

const createNewVueInstance = () =>
    new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
    }).$mount('#app');

console.time('[TIMER]: keycloakLogin');
KeycloakService.getOrInit().then(() => {
    console.timeEnd('[TIMER]: keycloakLogin');
    createNewVueInstance();

    logger.debug('[main.ts] - Starting initializing services');
    console.time('[TIMER]: servicesInitialisation');

    Promise.all([
        FarmManagerService.init(),
        WorkforceService.init(),
        SettingsService.init(),
        DetectionService.init(),
        FarmBoundaryService.init(),
        EvoPortalService.init(),
        MessageService.init()
    ])
        .then(() => console.timeEnd('[TIMER]: servicesInitialisation'))
        .catch(err => logger.error('[main.ts] - services Initialisation failed!', err));
});
