<template>
  <div>
    <p class="advice-form__label">
      {{ currentFormItem.label }}
    </p>

    <v-text-field v-if="isTextField" v-model="currentFormItem.value" outlined :disabled="disabled" />

    <v-select
        v-if="isSelectField"
        v-model="currentFormItem.value"
        :items="currentFormItem.options"
        :rules="currentFormItem.rules"
        item-text="label"
        item-value="value"
        outlined
        :disabled="disabled"
    />

    <date-input v-if="isCalendarField" label="" v-model="currentFormItem.value" :disabled="disabled" />

    <p v-if="isSourceField" class="advice-form__label advice-form__label--help">
      {{ translations.APDT_ADVICE.FORMS.SOURCE_HELP }}
    </p>
    <advice-liming-input v-model="currentFormItem.value" v-if="isAdviceLimingInput" />
    <advice-sowing-input v-model="currentFormItem.value" v-if="isAdviceSowingInput" />
    <advice-fertilizing-n1-input v-model="currentFormItem.value" v-if="isAdviceFertilizingN1Input" />
    <advice-fertilizing-n2-input v-model="currentFormItem.value" v-if="isAdviceFertilizingN2Input" />
    <advice-fertilizing-n3-input v-model="currentFormItem.value" v-if="isAdviceFertilizingN3Input" />
    <advice-fusarium-input v-model="currentFormItem.value" v-if="isAdviceFusariumInput" />

    <advice-source v-if="isSourceField" v-model="currentFormItem.value" :farm-boundary-id="farmBoundaryId" :disabled="disabled" :advice-action="adviceAction" />
  </div>
</template>

<script>
import { FormItem, FormItemType } from "@/apdtAdvice/forms/forms.domain";
import DateInput from "@/components/DateInput.component.vue";
import AdviceSource from "@/apdtAdvice/forms/AdviceSource.form-item.vue";
import { mapState } from "vuex";
import AdviceLimingInput from "@/apdtAdvice/forms/AdviceLimingInput.form-item.vue";
import AdviceSowingInput from "@/apdtAdvice/forms/AdviceSowingInput.form-item.vue";
import AdviceFertilizingN1Input from "@/apdtAdvice/forms/AdviceFertilizingN1Input.form-item.vue";
import AdviceFertilizingN2Input from "@/apdtAdvice/forms/AdviceFertilizingN2Input.form-item.vue";
import AdviceFertilizingN3Input from "@/apdtAdvice/forms/AdviceFertilizingN3Input.form-item.vue";
import AdviceFusariumInput from "@/apdtAdvice/forms/AdviceFusariumInput.form-item.vue";

export default {
  name: "Advice.form-item",
  components: { AdviceLimingInput, AdviceSowingInput, AdviceFertilizingN1Input, AdviceFertilizingN3Input, AdviceFertilizingN2Input, AdviceFusariumInput, AdviceSource, DateInput },
  props: {
    currentFormItem: {
      type: FormItem
    },
    disabled: {
      type: Boolean,
      default: false
    },
    farmBoundaryId: String,
    adviceAction: String
  },
  data() {
    return {
      options: []
    };
  },
  async mounted() {
    this.handleApiServices();
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    }),
    isTextField() {
      return this.currentFormItem && [FormItemType.text, FormItemType.number].includes(this.currentFormItem.type);
    },
    isSelectField() {
      return this.currentFormItem && this.currentFormItem.type === FormItemType.select;
    },
    isCalendarField() {
      return this.currentFormItem && this.currentFormItem.type === FormItemType.calendar;
    },
    isSourceField() {
      return this.currentFormItem && this.currentFormItem.type === FormItemType.source;
    },
    isAdviceLimingInput() {
      return this.currentFormItem && this.currentFormItem.type === FormItemType.liming_input;
    },
    isAdviceSowingInput() {
      return this.currentFormItem && this.currentFormItem.type === FormItemType.sowing_input;
    },
    isAdviceFusariumInput() {
      return this.currentFormItem && this.currentFormItem.type === FormItemType.fusarium_input;
    },
    isAdviceFertilizingN1Input() {
      return this.currentFormItem && this.currentFormItem.type === FormItemType.fertilizing_n1_input;
    },
    isAdviceFertilizingN2Input() {
      return this.currentFormItem && this.currentFormItem.type === FormItemType.fertilizing_n2_input;
    },
    isAdviceFertilizingN3Input() {
      return this.currentFormItem && this.currentFormItem.type === FormItemType.fertilizing_n3_input;
    }
  },
  methods: {
    async handleApiServices() {
      if (this.currentFormItem.optionsApiService && !this.currentFormItem.options.length) {
        const options = await this.currentFormItem.optionsApiService();
        this.currentFormItem.updateOptionsFromApiService(options);
      }
    }
  },
  watch: {
    currentFormItem() {
      this.handleApiServices();
    }
  }
};
</script>

<style scoped></style>
