<template v-if="team$">
    <l-feature-group>
        <template v-if="combines.length">
            <FieldCoveragePerHarvester
                v-for="combine in combines"
                :key="combine.id"
                :teamId="teamId"
                :arableVehicleId="combine.id"
                :arableVehicleType="fieldEntity.COMBINE"
                :hasCanbus="combine.isCanBusCombine()"
                :zoom="zoom"
            />
        </template>
        <template v-if="spfhs.length">
            <FieldCoveragePerHarvester
                v-for="spfh in spfhs"
                :key="spfh.id"
                :teamId="teamId"
                :arableVehicleId="spfh.id"
                :arableVehicleType="fieldEntity.SPFH"
                :hasCanbus="spfh.isCanBusSpfh()"
                :zoom="zoom"
            />
        </template>
        <template v-if="balers.length">
          <FieldCoveragePerHarvester
              v-for="baler in balers"
              :key="baler.id"
              :teamId="teamId"
              :arableVehicleId="baler.id"
              :arableVehicleType="fieldEntity.BALER"
              :hasCanbus="baler.isCanBusBaler()"
              :zoom="zoom"
          />
        </template>
    </l-feature-group>
</template>

<script>
import TeamService from '@/team/team.service';
import FieldCoveragePerHarvester from './FieldCoveragePerHarvester.component';
import {FieldEntityEnum} from 'shared-frontend';

export default {
    name: 'FieldCoverage',
    components: {
        FieldCoveragePerHarvester
    },
    props: {
        zoom: Number
    },
    computed: {
        fieldEntity() {
            return FieldEntityEnum;
        },
        combines() {
            return this.team$?.combines?.getList();
        },
        spfhs() {
            return this.team$?.spfhs?.getList();
        },
        balers() {
          return this.team$?.balers?.getList();
        },
        teamId() {
            return this.team$?.id;
        }
    },
    subscriptions() {
        return {
            team$: TeamService.selectedTeamOrNull$
        };
    }
};
</script>
