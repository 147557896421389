<template>
    <v-app>
      <Toolbar
          :show-farm-team-picker="false"
          :show-settings="false" />
      <navigation
          :show-nav-items="false"
          :show-admin-items="true"
          :show-to-portal="true" />
        <v-main>
            <v-container class="layout" fluid>
                <aside v-if="hasAside" class="layout__aside">
                    <router-view name="aside"></router-view>
                </aside>
                <main class="layout__main">
                  <v-progress-circular
                      v-if="isAdmin === null"
                      color="primary"
                      indeterminate
                  />
                  <div v-if="!isAdmin">
                    {{ translations.ADMIN.FORBIDDEN }}
                  </div>
                  <div v-else>
                    <router-view></router-view>
                  </div>
                </main>
            </v-container>
        </v-main>
    </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import Toolbar from '@/layout/Toolbar.vue';
import Navigation from '@/layout/Navigation.vue';
import authenticationService from "@services/authentication.service";
import {mapState} from "vuex";
import {State} from "@/store";

export default Vue.extend({
    name: 'App',
  data() {
      return {
        isAdmin: null
      }
  },
  async created() {
    this.isAdmin = await authenticationService.isAdmin();
  },
  methods: {
  },
    components: { Toolbar, Navigation },
    computed: {
      ...mapState({
        translations: (state: State) => state.translation.translations
      }),
        hasAside(): boolean {
            // Check if "aside" route is used
            return Boolean(this.$route?.matched?.[1]?.components?.aside);
        }
    }
});
</script>

<style lang="scss">
@import './styles/styles';
</style>
