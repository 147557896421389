<template>
  <div class="mapView polygonMap">
    <l-map
        ref="mapView"
        :max-zoom="20"
        :min-zoom="3"
        style="z-index: 1"
    >
      <l-tile-layer :options="{ maxZoom: 20, maxNativeZoom: 19, tileSize: 512, zoomOffset: -1  }"  :url="tileServerUrl"/>
      <!--      This reports an error b/c top is not a valis position for controls. see https://github.com/Leaflet/Leaflet/pull/5554-->
      <l-control class="polygonMap__toggleDrawing" position="top">
        <template v-if="!drawing">
          <div v-if="showZoomInHelpText" class="help-text elevation-5">
            <p>{{ translations.POLYGON_MAP.SELECT_BOUNDARY }}</p>
            <p>{{ translations.POLYGON_MAP.DRAW_TEXT }}
              <v-btn small color="primary" @click="startDrawing">{{ translations.POLYGON_MAP.DRAW_ACTION }}</v-btn>
            </p>
          </div>
          <v-btn v-else color="primary" @click="startDrawing">{{ translations.POLYGON_MAP.START_DRAWING }}</v-btn>
        </template>
        <v-btn v-if="drawing" color="primary" @click="stopDrawing">{{ translations.POLYGON_MAP.STOP_DRAWING }}</v-btn>
        <v-btn v-if="drawing && this.coordinates.length>0" class="ml-3" color="error" @click="removeDrawing">{{ translations.POLYGON_MAP.REMOVE_DRAWING }}</v-btn>
      </l-control>
    </l-map>
  </div>
</template>
<style lang="scss">
.help-text {
  background-color: white;
  padding: 15px 30px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  align-items: center;

  & *:last-child {
    margin-bottom: 0;
  }

}
</style>
<script>
import { logger } from '@services/logger.service';
import { API_ENDPOINTS, calculateBounds } from "shared-frontend";
import { mapState } from "vuex";
import httpService from "@services/http.service";

export default {
  name: 'PolygonMap',
  components: {},
  props: {
    initialCoordinates: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      editableLayers: new window.L.FeatureGroup(),
      coordinates: [],
      intervalId: null,
      drawing: false,
      boundariesLayer: null
    }
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    }),
    tileServerUrl() {
      return process.env.VUE_APP_TILE_SERVER_URL;
    },
    drawControl() {
      // See: http://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html#control-draw
      return new L.Control.Draw({
        position: 'topright',
        draw: {
          polyline: false,
          rectangle: false,
          circle: false,
          marker: false,
          circlemarker: false
        },
        edit: {
          featureGroup: this.editableLayers
        }
      });
    },
    showZoomInHelpText() {
      return this.coordinates.length === 0
    },
  },
  mounted() {
    this.coordinates = this.initialCoordinates;

    this.editableLayers.addTo(this.$refs.mapView.mapObject);
    this.drawControl.addTo(this.$refs.mapView.mapObject);

    // Use other translation for this because the original mentions a cancel, which we do not support
    L.drawLocal.edit.handlers.edit.tooltip.subtext = this.translations.GENERAL.EDIT;

    this.startEmittingCoordinatesWhenEditting();
    this.startListeningToDrawEvents();
    this.startListeningToMapMoves();

    this.$nextTick(() => {
      const thereIsAnInitialPolygon = this.coordinates.length === 0;
      if (thereIsAnInitialPolygon) {
        this.centerMapOnBrowserLocation();
      } else {
        this.centerMapOnPolygon();
      }
    })

    this.syncLeafletWithCoordinates();
  },
  destroyed() {
    clearInterval(this.intervalId)
  },
  methods: {
    centerMapOnBrowserLocation() {
      if (!('geolocation' in navigator)) reject(new Error('Geolocation API not supported by browser!'));

      navigator.geolocation.getCurrentPosition(
          (position) => {
            const coords = position.coords;
            const {latitude, longitude} = coords || {};
            logger.info(
                `[BoundaryMap.component] - map centered on browserLocation - lat: ${latitude}, lon: ${longitude}`
            );
            this.setMapView(latitude, longitude, 18);
          },
          (error) => {
            logger.error('Geolocation API - getCurrentPosition - FAILED: ', error);
          }
      );
    },
    setMapView(latitude, longitude, zoom) {
      if (!latitude || !longitude) return;
      this.$refs.mapView.mapObject.setView([latitude, longitude], zoom);
    },
    centerMapOnPolygon() {
      const {maxLatitude, maxLongitude, minLatitude, minLongitude} = calculateBounds(this.coordinates);
      const bounds = [
        [minLatitude, minLongitude],
        [maxLatitude, maxLongitude]
      ];
      this.$refs.mapView.mapObject.fitBounds(bounds);
    },
    startListeningToDrawEvents() {
      const map = this.$refs.mapView.mapObject;
      map.on(window.L.Draw.Event.CREATED, (e) => {
        this.editableLayers.clearLayers();
        this.editableLayers.addLayer(e.layer);
        if (this.drawing) {
          this.startEditing();
        }
        const leafletCoordinates = e.layer._latlngs[0];
        this.coordinates = leafletCoordinates.map(coordinate => this.unTranslate(coordinate));
        this.$emit('input', this.coordinates);
      });
    },
    renderBoundaryDetail(fieldId) {
      this.fetchDetailsBoundary(fieldId).then(response => {
        this.boundariesLayer.clearLayers()
        this.editableLayers.clearLayers(); // A polygon might have been drawn before
        this.coordinates = response.data.geometry;
        const polygon = window.L.polygon(this.coordinates);
        polygon.addTo(this.editableLayers);
        this.startDrawing()
      })
    },
    fetchAndRenderBoundaries(map) {
      if (this.drawing || this.coordinates.length > 0) {
        return
      }
      if (this.boundariesLayer != null) {
        map.removeLayer(this.boundariesLayer)
      }
      if (map.getZoom() >= 15) {
        this.fetchBboxBoundaries(map.getBounds().toBBoxString()).then(response => {
          if (response.data.length > 0) {
            this.boundariesLayer = window.L.featureGroup()
            response.data.forEach((geom) => {
              const polygon = window.L.polygon(geom.geometry);
              polygon.fieldId = geom.id
              this.boundariesLayer.addLayer(polygon);
            })
            const self = this;
            this.boundariesLayer.on('click', function (event) {
              self.renderBoundaryDetail(event.layer.fieldId)
            })
            this.boundariesLayer.addTo(map);
          }
        })
      }
    }, startListeningToMapMoves() {
      const map = this.$refs.mapView.mapObject;
      map.on('moveend', (e) => {
        this.fetchAndRenderBoundaries(map);
      })
    },
    startEmittingCoordinatesWhenEditting() {
      this.intervalId = setInterval(() => {
        const layers = this.drawControl._toolbars.edit._modes.edit.handler._featureGroup._layers;
        if (Object.values(layers).length > 0) {
          const leafletCoordinates = Object.values(layers)[0]._latlngs[0];
          if (leafletCoordinates.length > 0) {
            this.coordinates = leafletCoordinates.map(coordinate => this.unTranslate(coordinate));
            this.$emit('input', this.coordinates)
          }
        }
      }, 100)
    },
    translate(coordinate) {
      return [coordinate.latitude, coordinate.longitude]
    },
    unTranslate(leafletCoordinate) {
      return {latitude: leafletCoordinate.lat, longitude: leafletCoordinate.lng}
    },
    startDrawing() {
      this.drawing = true;
      if (this.boundariesLayer != null) {
        this.$refs.mapView.mapObject.removeLayer(this.boundariesLayer)
      }
      const shouldCreate = this.coordinates.length === 0;
      if (shouldCreate) {
        this.startCreating();
      } else {
        this.startEditing();
      }
    },
    startCreating() {
      this.drawControl._toolbars.draw._modes.polygon.handler.enable()
    },
    startEditing() {
      this.drawControl._toolbars.edit._modes.edit.handler.enable()
    },
    stopDrawing() {
      this.drawing = false;
      const wasCreating = this.coordinates.length === 0;
      if (wasCreating) {
        this.stopCreating();
      } else {
        this.stopEditing();
      }
    },
    removeDrawing() {
      this.coordinates = []
      this.editableLayers.clearLayers();
      this.drawing = false;
      this.drawControl._toolbars.edit._modes.edit.handler.disable()
      this.fetchAndRenderBoundaries(this.$refs.mapView.mapObject);
    },
    stopCreating() {
      this.drawControl._toolbars.draw._modes.polygon.handler.completeShape()
      this.drawControl._toolbars.draw._modes.polygon.handler.disable()
    },
    stopEditing() {
      this.drawControl._toolbars.edit._modes.edit.handler.disable()
    },
    syncLeafletWithCoordinates() {
      this.editableLayers.clearLayers(); // A polygon might have been drawn before
      const polygon = L.polygon(this.coordinates.map(coordinate => this.translate(coordinate)));
      polygon.addTo(this.editableLayers);
    },
    fetchBboxBoundaries(bbox) {
      return httpService.get(API_ENDPOINTS.BOUNDARY_CREATOR_LIST_CANDIDATES(bbox))
    },
    fetchDetailsBoundary(id) {
      return httpService.get(API_ENDPOINTS.BOUNDARY_CREATOR_CANDIDATE(id))
    }
  }
}
</script>
