<template>
  <fragment>
    <div v-show="loading">
      <v-progress-circular class="mx-5" color="white" indeterminate/>
      <span class="white--text">Loading teams...</span>
    </div>

    <div v-show="noTeams">
            <span class="mx-5 white--text">
              <span class="font-weight-bold">{{ trans.TEAM_PICKER.ERROR.NO_TEAM }}</span> <span>{{ this.trans.TEAM_PICKER.ERROR.CREATE_TEAM }}</span>
            </span>
    </div>

    <v-col v-show="!loading && !noTeams" class="ml-5" md="1">
      <GrowerPicker v-model="selected.grower" :alphabetically="this.alphabetically" select-all :farm-teams="this.farmTeams$"></GrowerPicker>
    </v-col>

    <v-col v-show="!loading && !noTeams" md="1">
      <FarmPicker v-model="selected.farm" :alphabetically="this.alphabetically" select-all :farm-teams="this.farmTeams$" :grower="selected.grower"></FarmPicker>
    </v-col>

    <v-col v-show="!loading && !noTeams" md="1">
      <YearPicker v-model="selected.year" :alphabetically="this.alphabetically" select-all :farm-teams="this.farmTeams$" :grower="selected.grower" :farm="selected.farm"></YearPicker>
    </v-col>

    <v-col v-show="!loading && !noTeams" md="1">
      <FieldPicker v-model="selected.field" :alphabetically="this.alphabetically" select-all :farm-teams="this.farmTeams$" :grower="selected.grower" :farm="selected.farm" :year="selected.year"></FieldPicker>
    </v-col>

    <v-col v-show="!loading && !noTeams" md="1">
      <CropPicker v-model="selected.crop" :alphabetically="this.alphabetically" select-all :farm-teams="this.farmTeams$" :grower="selected.grower" :farm="selected.farm" :year="selected.year" :field="selected.field"></CropPicker>
    </v-col>

    <v-col v-show="!loading && !noTeams" md="1">
      <TeamNamePicker v-model="selected.teamName" :alphabetically="this.alphabetically" select-all :farm-teams="this.farmTeams$"
                      :grower="selected.grower" :farm="selected.farm" :year="selected.year" :field="selected.field" :crop="selected.crop"
                      @input="selectCurrentTeam"></TeamNamePicker>
    </v-col>

    <v-spacer/>

    <div class="sortSwitch">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span
              v-bind="attrs"
              v-on="on">
          <v-switch v-show="!loading && !noTeams"
                    v-model="alphabetically"
                    dark
                    :prepend-icon="`${ icons.clock }`"
                    :append-icon="`${ icons.alphabetic }`"
          ></v-switch>
            </span>
        </template>
        <span>{{ sortTooltipText }}</span>
      </v-tooltip>
    </div>

  </fragment>
</template>

<script lang="ts">
import Vue from "vue";
import {Fragment} from "vue-fragment";

import FarmTeamService from "@/farmTeam/farmTeam.service";
import TeamService from "@/team/team.service";
import {matchingTeams} from "./FarmTeamPicker.helper";
import {distinctUntilChanged, filter, first, map, takeUntil} from "rxjs/operators";
import {Team} from "shared-frontend";
import {mapState} from "vuex";
import {State} from "@/store";
import {FarmTeamSummary} from "@/farmTeam/farmTeam.domain";
import {icons} from "@utils/icons.utils";
import equal from "fast-deep-equal/es6";
import FilteredFarmTeamService from "@/farmTeam/filteredFarmTeam.service";
import {Subject} from "rxjs";
import {getMostRecentActiveTeam} from "@/farmTeam/farmTeam.helper";
import GrowerPicker from "@components/picker/GrowerPicker.component.vue";
import FarmPicker from "@components/picker/FarmPicker.component.vue";
import YearPicker from "@components/picker/YearPicker.component.vue";
import FieldPicker from "@components/picker/FieldPicker.component.vue";
import CropPicker from "@components/picker/CropPicker.component.vue";
import TeamNamePicker from "@components/picker/TeamNamePicker.component.vue";
import {AutoCompleteItem, toAutocompleteItems} from "@components/picker/Picker.helper";

export default Vue.extend({
  name: "FarmTeamPicker",
  components: {Fragment, GrowerPicker, FarmPicker, YearPicker, FieldPicker, CropPicker, TeamNamePicker},
  data() {
    return {
      selected: {
        grower: undefined as AutoCompleteItem | undefined,
        farm: undefined as AutoCompleteItem | undefined,
        year: undefined as AutoCompleteItem | undefined,
        field: undefined as AutoCompleteItem | undefined,
        crop: undefined as AutoCompleteItem | undefined,
        teamName: undefined as AutoCompleteItem | undefined
      },
      isInitializing: true,
      alphabetically: false,
      destroy$: new Subject()
    };
  },
  created() {
    FarmTeamService.farmTeams$
        .pipe(
            map((farmTeams) => getMostRecentActiveTeam(farmTeams)),
            filter(Boolean),
            first()
        )
        .subscribe((farmTeam: FarmTeamSummary) => {
          TeamService.selectTeam(farmTeam.teamId);
          FilteredFarmTeamService.setFilteredTeamIds([]);
        });

    TeamService.selectedTeam$
        .pipe(
            filter((team: Team) => Boolean(team?.id)),
            map(team => this.teamToSelected(team)),
            distinctUntilChanged(equal),
            takeUntil(this.destroy$)
        )
        .subscribe((selected) => {
          this.selected = selected;
          // We need a timeout because Vue's reactivity system takes a bit of time
          setTimeout(() => {
            this.isInitializing = false;
          }, 500);
        });
  },
  computed: {
    ...mapState({
      trans: (state: State) => state.translation.translations
    }),
    noTeams(): boolean {
      return (this as any).farmTeams$?.length === 0;
    },
    loading(): boolean {
      return (this as any).farmTeams$ === null;
    },
    icons() {
      return icons;
    },
    sortTooltipText(): string {
      return this.$data.alphabetically ? this.trans.TEAM_PICKER.SORT.ALPHABETICALLY : this.trans.TEAM_PICKER.SORT.BY_TIME;
    }

  },
  methods: {
    teamToSelected(team: Team) {
      return {
        grower: toAutocompleteItems(this.trans.TEAM.GROWER, this.trans)(team.grower, null),
        farm: toAutocompleteItems(this.trans.TEAM.FARM, this.trans)(team.farm, null),
        year: toAutocompleteItems(this.trans.TEAM.YEAR, this.trans)(team.year.toString(), team.year),
        field: toAutocompleteItems(this.trans.TEAM.FIELD, this.trans)(team.field, null),
        crop: toAutocompleteItems(this.trans.TEAM.CROP, this.trans)(team.crop, null),
        teamName: toAutocompleteItems(this.trans.TEAM.TEAM_NAME, this.trans)(team.name, null)
      };
    },
    selectCurrentTeam() {
      const foundTeams = matchingTeams(
          (this as any).farmTeams$,
          this.selected.grower,
          this.selected.farm,
          this.selected.year,
          this.selected.field,
          this.selected.crop,
          this.selected.teamName
      );
      console.debug(`[FarmTeamPicker.helper] - Found ${foundTeams.length} matching teams`);
      if (foundTeams.length === 1 && (this as any).selectedTeam$?.id !== foundTeams[0].teamId) {
        TeamService.selectTeam(foundTeams[0].teamId);
      } else if (foundTeams.length !== 1) {
        TeamService.selectTeam(null);
      }

      FilteredFarmTeamService.setFilteredTeamIds(foundTeams.map(t => t.teamId));
    }
  },
  beforeDestroy() {
    this.destroy$.next();
  },
  subscriptions() {
    return {
      farmTeams$: FarmTeamService.farmTeams$,
      selectedTeam$: TeamService.selectedTeamOrNull$
    };
  }
});
</script>

<style lang="scss">


.sortSwitch {

  .v-input__slot {
    margin-bottom: 0;
  }

  .v-input--selection-controls__input {
    margin-right: 0;
  }

  .v-messages {
    min-height: 0;
  }

  .v-input__prepend-outer {
    margin-right: 0;

    .v-icon {
      font-size: 16px;
    }
  }

  .v-input__append-outer {
    margin-left: 0;

    .v-icon {
      font-size: 16px;
    }
  }
}


</style>
