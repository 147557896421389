export class RecalibrationData {
    ph: number;
    nitrogen: number;
    phosphorus: number;
    potassium: number;
    carbon: number;
    humus: number;
    calcium: number;
    natrium: number;
    sulfur: number;

    constructor(item: any) {
        this.ph = item.ph;
        this.nitrogen = item.nitrogen;
        this.phosphorus = item.phosphorus;
        this.potassium = item.potassium;
        this.carbon = item.carbon;
        this.humus = item.humus;
        this.calcium = item.calcium;
        this.natrium = item.natrium;
        this.sulfur = item.sulfur;
    }

}
