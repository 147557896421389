<template>
  <h2><slot /></h2>
</template>

<script>
export default {
  name: "SubTitle"
}
</script>

<style scoped>
  h2 {
    margin-bottom: 1rem;
    font-weight: 400;
    color: var(--v-primary-base);
  }
</style>