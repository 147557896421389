<template>
  <div class="textWithLabel">
    <div class="textWithLabel__label">{{ label }}</div>
    <div class="textWithLabel__text" :style="{color: primaryColor}">{{ text }}</div>
  </div>
</template>

<script>
import {BrandingService, hexToRgba} from "shared-frontend";

export default {
  name: 'TextWithLabel',
  components: {},
  props: {
    label: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    primaryColor() {
      return BrandingService.getColors(process.env.VUE_APP_BRAND)?.PRIMARY
    },
  }
}
</script>