<template>
  <div class="advice-form pa-5" v-if="!loading">
    <div class="advice-form__no-form-item" v-if="!currentFormItem">NO CURRENT FORM ITEM</div>

    <div class="advice-form__root" v-else>
      <v-form v-model="isFormValid">
        <advice-form-item :currentFormItem="currentFormItem" :farm-boundary-id="farmBoundaryId" :advice-action="form.action"/>
      </v-form>
      <div class="advice-form__actions">
        <v-btn @click="goPrev">
          {{ translations.APDT_ADVICE.FORMS.ACTIONS.PREV }}
        </v-btn>
        <v-btn color="primary" @click="goNext" v-if="!isLastStep" :disabled="!isFormValid">
          {{ translations.APDT_ADVICE.FORMS.ACTIONS.NEXT }}
        </v-btn>
        <v-btn color="primary" @click="generateAdvice" v-else>
          {{ translations.APDT_ADVICE.FORMS.ACTIONS.SUBMIT }}
        </v-btn>
      </div>
    </div>
  </div>
  <v-overlay v-else>
    <v-progress-circular
        indeterminate
        size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
import { AdviceForm } from "@/apdtAdvice/forms/form.builder";
import { mapState } from "vuex";
import AdviceFormItem from "@/apdtAdvice/forms/Advice.form-item.vue";
import { generateAdvice } from "@/apdtAdvice/Advice.service";
import { ModalTypeEnum } from "@/modals/modalType.enum";
import ModalService from "@/modals/modal.service";

export default {
  name: "AdviceForm",
  components: { AdviceFormItem },
  props: {
    farmBoundaryId: String
  },
  data() {
    return {
      form: undefined,
      prevFormItems: [],
      currentFormItem: undefined,
      loading: false,
      isFormValid:false
    };
  },
  created() {
    this.form = new AdviceForm(this.translations);
    this.updateFormItems();
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    }),
    isLastStep() {
      return this.form.isLastStep;
    }
  },
  methods: {
    updateFormItems() {
      this.currentFormItem = this.form.currentFormItem;
      this.prevFormItems = this.form.prevFormItems;
    },
    goNext() {
      if (!this.currentFormItem.value || !this.form.canGoNext) {
        return;
      }
      this.form.goNext(this.currentFormItem.value);
    },
    async generateAdvice() {
      this.loading = true;
      generateAdvice(this.farmBoundaryId, this.form).then(value => {
            this.loading = false;
            this.$router.push({ name: "apdtAdviceDetail", params: { adviceId: value } });
          }
      ).catch(reason => {
        this.loading = false;
        ModalService.showModal(ModalTypeEnum.ERROR);
      });

    },
    goPrev() {
      if (this.form.isFirstStep) {
        this.$emit("goPrev");
      }

      this.form.goPrev();
    }
  },
  watch: {
    "form.step"() {
      this.updateFormItems();
    },
    "currentFormItem.value"() {
      this.$emit("updateForm", this.form);
    }
  }
};
</script>

<style scoped></style>
