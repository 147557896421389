// Vuetify comes with support to use Font Awesome icons
// Vuetify documentation: https://vuetifyjs.com/en/customization/icons#install-font-awesome-5-icons
// Font Awesome website: https://fontawesome.com/

export interface Icons {
    [iconName: string]: string;
}

export const icons: Icons = {
    back: 'fa-arrow-left',
    forward: 'fa-arrow-right',
    sendMessage: 'fa-paper-plane',
    settings: 'fa-cog',
    team: 'fa-users',
    addUser: 'fa-user-plus',
    delete: 'fa-trash-alt',
    user: 'fa-user',
    add: 'fa-plus',
    addCircle: 'fa-plus-circle',
    bin: 'fa-tractor',
    filePdf: 'fa-file-pdf',
    fileCsv: 'fa-file-csv',
    close: 'fa-times-circle',
    signOut: 'fa-sign-out-alt',
    editUser: 'fa-user-edit',
    recenterMap: 'fa-crosshairs',
    folder: 'fa-folder',
    folderOpen: 'fa-folder-open',
    stop: 'fa-ban',
    info: 'fa-info-circle',
    message: 'fa-envelope',
    boundary: 'fa-draw-polygon',
    upload: 'fa-upload',
    edit: 'fa-pencil-alt',
    infoCircle: 'fa-info-circle',
    report: 'fa-file-alt',
    users: 'fa-users',
    chevronUp: 'fa-chevron-up',
    chevronDown: 'fa-chevron-down',
    chevronRightCircle: 'fa-chevron-circle-right',
    exclamation: 'fa-exclamation-triangle',
    backTriangle: 'fa-caret-left',
    userWithoutAuthority: 'far fa-user',
    link: 'fa-link',
    unlink: 'fa-unlink',
    eye: 'fa-eye',
    eyeSlash: 'fa-eye-slash',
    retry: 'fa-undo',
    clock: 'fa-clock',
    alphabetic: 'fa-sort-alpha-down',
    vehicle: 'fa-car',
    handshake: 'fa-handshake',
    fileImport: 'fa-upload',
    download: 'fa-download',
    lock: 'fa-lock',
    temperature: 'fa-thermometer-half',
    precipitation: 'fa-tint',
    wind: 'fa-wind'
};
