import httpService from '@services/http.service';
import {API_ENDPOINTS} from 'shared-frontend';
import {Grower, LinkGrower} from '@/grower/grower.domain';
import {BehaviorSubject, Observable} from 'rxjs';

class GrowerService {
    private _growers$: BehaviorSubject<Grower[]> = new BehaviorSubject(null);

    public get growers$(): Observable<Grower[]> {
        return this._growers$.asObservable();
    }

    public fetchGrowers = async (): Promise<void> => {
        this._growers$.next(await this.getAllGrowers());
    }

    public linkGrower = async (linkGrower: LinkGrower): Promise<void> => {
        await httpService.post(API_ENDPOINTS.GROWERS(), linkGrower)
        await this.fetchGrowers();
    }

    public unLinkGrower = async (growerName: string): Promise<void> => {
        await httpService.delete(API_ENDPOINTS.GROWER(growerName))
        await this.fetchGrowers();
    }

    private getAllGrowers = async (): Promise<Grower[]> => {
        const response = await httpService.get(API_ENDPOINTS.GROWERS());
        return response.data;
    }
}

export default new GrowerService();