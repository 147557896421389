import {API_ENDPOINTS, EspCam, parseEspCam} from 'shared-frontend';
import httpService from '@services/http.service';
import {EspCamId} from 'shared-frontend/dist/espCam/espCam.domain';

class EspCamService {

    public async getEspCamsForCombine(farmMgrId: string, teamId: string, combineId: string): Promise<EspCam[]> {
        const response = await httpService.get(API_ENDPOINTS.ESP_CAMS_FOR_COMBINE(farmMgrId, teamId, combineId));
        return response.data.map(json => parseEspCam(json));
    }

    public async registerTunnelHeartbeatForCombine(farmMgrId: string, teamId: string, combineId: string, espCamId: EspCamId): Promise<string> {
        const response = await httpService.post(API_ENDPOINTS.REGISTER_ESP_CAM_TUNNEL_HEARTBEAT_FOR_COMBINE(farmMgrId, teamId, combineId, espCamId), {});
        return response.data;
    }

    public async getEspCamsForSpfh(farmMgrId: string, teamId: string, spfhId: string): Promise<EspCam[]> {
        const response = await httpService.get(API_ENDPOINTS.ESP_CAMS_FOR_SPFH(farmMgrId, teamId, spfhId));
        return response.data.map(json => parseEspCam(json));
    }

    public async registerTunnelHeartbeatForSpfh(farmMgrId: string, teamId: string, spfhId: string, espCamId: EspCamId): Promise<string> {
        const response = await httpService.post(API_ENDPOINTS.REGISTER_ESP_CAM_TUNNEL_HEARTBEAT_FOR_SPFH(farmMgrId, teamId, spfhId, espCamId), {});
        return response.data;
    }

    public getStreamUrl(espCam: EspCam): string {
        return `${espCam.tunnelBaseUrl}/mjpeg/1`
    }
}

export default new EspCamService();
