<template>
  <div v-if="isVisible" class="statistic">
    <span class="statistic__label">{{ translations.TEAM_PAGE.FIELD_FINISHED_AT }}</span>
    <span class="statistic__value" :style="{ color: primaryColor }">{{ formattedFieldFinishedAt }}</span>
  </div>
</template>

<script lang="ts">
import Vue, {PropType} from 'vue';
import {Brand, BrandingService, toTimeWithoutSeconds} from 'shared-frontend';
import SettingsService from '@services/settings.service';
import {mapState} from 'vuex';
import {State} from '@/store';

export default Vue.extend({
  name: 'FieldFinishedAt',
  props: {
    fieldFinishedAt: Date as PropType<Date>,
    primaryColor: String
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    isVisible(): boolean {
      return Boolean(this.formattedFieldFinishedAt);
    },
    formattedFieldFinishedAt(): string | null {
      const fieldFinishedAt = this.fieldFinishedAt;
      return fieldFinishedAt ? toTimeWithoutSeconds(fieldFinishedAt, SettingsService.userLocale) : null;
    }
  }
});
</script>
