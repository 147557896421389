export class UploadProgress {
    fileName: number;
    uploadedOn: Date | string | null;
    status: string;

    constructor(item:any, status: string) {
        this.fileName = item.fileName
        this.uploadedOn = new Date(item.createdOn).toLocaleString()
        this.status = status
        }
}
