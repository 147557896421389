var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('merge-dialog',{attrs:{"source-type":_vm.selectedMergeSourceType},on:{"merge":function($event){return _vm.handleMerge()}},model:{value:(_vm.showMergeDialog),callback:function ($$v) {_vm.showMergeDialog=$$v},expression:"showMergeDialog"}}),_c('div',{staticClass:"advice-sources mb-8"},[_vm._l((_vm.sources),function(source){return _c('v-card',{key:source.type,staticClass:"mx-auto mb-8",attrs:{"max-width":"500"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.translations.APDT_ADVICE.LAYERS.SOURCES[source.type])+" ")]),_c('v-col',{attrs:{"cols":"4"}},[( source.type==_vm.AdviceSourceType.AGDNA_CN1)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return (function (event) {
                     _vm.showMergeDialog=true;
                     _vm.selectedMergeSourceType=source.type;
                   }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.translations.APDT_ADVICE.LAYERS.SOURCES.ACTIONS.MERGE)+" ")]):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',[(!_vm.readOnly)?_c('advice-source-day-selector',{attrs:{"source":source},on:{"update":function (days) { return _vm.handleUpdateDays(source, days); }}}):_vm._e(),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.activeOptions),callback:function ($$v) {_vm.activeOptions=$$v},expression:"activeOptions"}},[_vm._l((source.options),function(sourceOption){return _c('v-list-item',{key:((source.type) + "---" + (sourceOption.name)),class:{'active-thumbnail': sourceOption.thumbnailSelected},attrs:{"value":((source.type) + "---" + (sourceOption.name)),"active-class":"primary--text"},on:{"click":function($event){return _vm.handleItemClick(sourceOption)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                   var active = ref.active;
return [_c('v-list-item-avatar',{attrs:{"tile":"","height":"100","width":"100","max-height":"100","max-width":"100"},on:{"click":function($event){$event.preventDefault();return (function (event) { return _vm.handleThumbnailClick(event, sourceOption); }).apply(null, arguments)}}},[(sourceOption.thumbnailUrl)?_c('v-img',{attrs:{"src":sourceOption.thumbnailUrl}}):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.translations.APDT_ADVICE.LAYERS.OPTIONS[sourceOption.name])}})],1),(!_vm.readOnly)?_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary accent-4"}})],1):_vm._e()]}}],null,true)})})],2)],1)],1)],1)}),_vm._l((_vm.noDataSources),function(noDataSource){return _c('v-card',{key:noDataSource.option,staticClass:"mx-auto mb-8 relativePosition",attrs:{"max-width":"500"}},[_c('div',{staticClass:"greyedLock"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                   var on = ref.on;
                   var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"white","large":""}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.icons().lock))])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.translations.APDT_ADVICE.HIDDEN_LAYERS)+" ")])],1),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.translations.APDT_ADVICE.LAYERS.SOURCES[noDataSource.sensor.toUpperCase()])+" ")]),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',_vm._l((noDataSource.options),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.translations.APDT_ADVICE.LAYERS.OPTIONS[item])}})],1)],1)}),1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }