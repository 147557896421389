import {
    BaleDropDetected,
    BalerTelemetriesWithIdJSON,
    BalerTelemetrySummariesWithIdJSON,
    BalerTelemetrySummary,
    BinTelemetriesWithIdJSON,
    BinTelemetry,
    CombineCoverageChangedJSON,
    CombineTelemetriesWithIdJSON,
    CombineTelemetrySummariesWithIdJSON,
    CombineTelemetrySummary,
    DetectionEvent,
    HarvestingProgress,
    HarvestingProgressJSON,
    LocationEvent,
    LocationsWithFieldEntityIdJSON,
    parseBalerTelemetrySummaries,
    parseBinTelemetries,
    parseCombineTelemetrySummaries,
    parseHarvestingProgress,
    parseLocationsWithFieldEntityId,
    parseSpfhTelemetrySummaries,
    parseTeamState,
    SpfhCoverageChangedJSON,
    SpfhTelemetrySummariesWithIdJSON,
    SpfhTelemetrySummary,
    SseEventsEnum,
    Team,
    TeamStateJSON,
    TeamStateObject,
    User,
    UserActivityService,
    UserActivityTypeEnum,
    UserJoinedTeamJSON
} from 'shared-frontend';
import MessageService from '@/messaging/message.service';
import MarkerService from '@services/marker.service';
import UserService from '@services/user.service';
import FieldCoverageService from '@services/fieldCoverage.service';
import DetectionService, {DetectionEnum} from '@services/detection.service';
import BalerLoadReportService from '@services/balerLoadReport.service';
import HarvestingProgressService from '@services/harvestingProgress.service';
import {SpfhTelemetriesWithIdJSON} from 'shared-frontend/dist/utils/jsonParsers/telemetry.jsonParsers.utils';
import {ActiveTeamEvent, UserActivityEvent, UserSentMessage} from '@/activeTeam/activeTeam.domain';
import {from, Observable, of} from "rxjs";
import {map} from "rxjs/operators";

export function enrichActiveTeamEvent(teamId: string, farmManagerId: string){
  return (event: ActiveTeamEvent): Observable<ActiveTeamEvent> => {
      const {eventType, eventTime, data} = event;

      // Only 1 User Activity event needs to be enriched at this moment
      if (eventType === SseEventsEnum.USER_ACTIVITY && (data as UserActivityEvent).type === UserActivityTypeEnum.JOINED_TEAM) {
          const {userId} = data as UserJoinedTeamJSON;

          return from(UserService.getUser(farmManagerId, teamId, userId)).pipe(map((user: User) => {
              return {eventType: SseEventsEnum.USER_ACTIVITY, eventTime, data: {type: UserActivityTypeEnum.ENRICHED_JOINED_TEAM, user}};
          }));
      } else {
          return of(event)
      }
  }
}

export const reduceActiveTeamEvent = (team: Team, {eventType, data}: ActiveTeamEvent): Team => {
    switch (eventType) {
        case SseEventsEnum.USER:
            return reduceUserLocationsEvent(team, data as LocationsWithFieldEntityIdJSON);

        case SseEventsEnum.COMBINE:
            return reduceCombineLocationsEvent(team, data as LocationsWithFieldEntityIdJSON);

        case SseEventsEnum.SPFH:
            return reduceSpfhLocationsEvent(team, data as LocationsWithFieldEntityIdJSON);

        case SseEventsEnum.BALER:
            return reduceBalerLocationsEvent(team, data as LocationsWithFieldEntityIdJSON);

        case SseEventsEnum.BIN:
            return reduceBinLocationsEvent(team, data as LocationsWithFieldEntityIdJSON);

        case SseEventsEnum.BALE_SERVICE_VEHICLE:
            return reduceBaleServiceVehicleLocationsEvent(team, data as LocationsWithFieldEntityIdJSON);

        case SseEventsEnum.DUMP:
            return reduceDumpLocationsEvent(team, data as LocationsWithFieldEntityIdJSON);

        case SseEventsEnum.COMBINE_TELEMETRY:
            return reduceCombineTelemetryEvent(team, data as CombineTelemetriesWithIdJSON);

        case SseEventsEnum.SPFH_TELEMETRY:
            return reduceSpfhTelemetryEvent(team, data as SpfhTelemetriesWithIdJSON);

        case SseEventsEnum.BALER_TELEMETRY:
            return reduceBalerTelemetryEvent(team, data as BalerTelemetriesWithIdJSON);

        case SseEventsEnum.BIN_TELEMETRY:
            return reduceBinTelemetryEvent(team, data as BinTelemetriesWithIdJSON);

        case SseEventsEnum.USER_ACTIVITY:
            return reduceUserActivityEvent(team, data as UserActivityEvent);

        case SseEventsEnum.TEAM_STATE:
            return reduceTeamStateEvent(team, data as TeamStateJSON);

        case SseEventsEnum.DETECTION:
            return reduceDetectionEvent(team, data as DetectionEvent);

        case SseEventsEnum.COMBINE_COVERAGE_CHANGED:
            return reduceCombineCoverageChangedEvent(team, data as CombineCoverageChangedJSON);

        case SseEventsEnum.SPFH_COVERAGE_CHANGED:
            return reduceSpfhCoverageChangedEvent(team, data as SpfhCoverageChangedJSON);

        case SseEventsEnum.HARVESTING_PROGRESS:
            return reduceHarvestingProgressEvent(team, data as HarvestingProgressJSON);
    }
    throw Error(`Could not find handler for event ${eventType}`)
};

const reduceTeamStateEvent = (team: Team, data: TeamStateJSON): Team => {
    const teamStateObject: TeamStateObject = parseTeamState(data);
    return team.updateTeamState(teamStateObject);
};

const reduceUserActivityEvent = (team: Team, data: UserActivityEvent): Team => {
    switch (data.type) {
        case UserActivityTypeEnum.USER_SENT_MESSAGE:
            const {messageId, user, message, eventTime} = data as UserSentMessage;
            MessageService.newIncomingMessage(messageId,  user,  message, eventTime);
            return team;

        case UserActivityTypeEnum.USER_CREATED_MARKER:
            MarkerService.onUserCreatedMarker();
            return team;

        case UserActivityTypeEnum.USER_DELETED_MARKER:
            MarkerService.onUserDeletedMarker();
            return team;

        default:
            return UserActivityService.handleEvent(data, team);
    }
};

const reduceUserLocationsEvent = (team: Team, data: LocationsWithFieldEntityIdJSON): Team => {
    const locationEvents: LocationEvent[] = parseLocationsWithFieldEntityId(data);
    return team.updateUserLocations(locationEvents);
};

const reduceCombineLocationsEvent = (team: Team, data: LocationsWithFieldEntityIdJSON): Team => {
    const locationEvents: LocationEvent[] = parseLocationsWithFieldEntityId(data);
    locationEvents.forEach((locationEvent: LocationEvent) => FieldCoverageService.addLocationEvent(locationEvent));
    return team.updateCombineLocations(locationEvents);
};

const reduceSpfhLocationsEvent = (team: Team, data: LocationsWithFieldEntityIdJSON): Team => {
    const locationEvents: LocationEvent[] = parseLocationsWithFieldEntityId(data);
    locationEvents.forEach((locationEvent: LocationEvent) => FieldCoverageService.addLocationEvent(locationEvent));
    return team.updateSpfhLocations(locationEvents);
};

const reduceBalerLocationsEvent = (team: Team, data: LocationsWithFieldEntityIdJSON): Team => {
    const locationEvents: LocationEvent[] = parseLocationsWithFieldEntityId(data);
    locationEvents.forEach((locationEvent: LocationEvent) => FieldCoverageService.addLocationEvent(locationEvent));
    return team.updateBalerLocations(locationEvents);
};

const reduceBinLocationsEvent = (team: Team, data: LocationsWithFieldEntityIdJSON): Team => {
    const locationEvents: LocationEvent[] = parseLocationsWithFieldEntityId(data);
    return team.updateBinLocations(locationEvents);
};

const reduceBaleServiceVehicleLocationsEvent = (team: Team, data: LocationsWithFieldEntityIdJSON): Team => {
    const locationEvents: LocationEvent[] = parseLocationsWithFieldEntityId(data);
    return team.updateBaleServiceVehicleLocations(locationEvents);
};

const reduceDumpLocationsEvent = (team: Team, data: LocationsWithFieldEntityIdJSON): Team => {
    const locationEvents: LocationEvent[] = parseLocationsWithFieldEntityId(data);
    return team.updateDumpLocations(locationEvents);
};

const reduceCombineTelemetryEvent = (team: Team, data: CombineTelemetrySummariesWithIdJSON): Team => {
    const combineTelemetrySummaries: CombineTelemetrySummary[] = parseCombineTelemetrySummaries(data);

    // Add harvesting to stream, to calculate fieldCoverage
    combineTelemetrySummaries.forEach((combineTelemetrySummary: CombineTelemetrySummary) => {
        FieldCoverageService.addHeaderEvent({
            arableVehicleId: combineTelemetrySummary.vehicleId,
            workWidth__m: combineTelemetrySummary.workWidth__m,
            harvesting: combineTelemetrySummary.harvesting
        });
    });

    return team.updateCombineTelemetrySummary(combineTelemetrySummaries);
};

const reduceSpfhTelemetryEvent = (team: Team, data: SpfhTelemetrySummariesWithIdJSON): Team => {
    const spfhTelemetrySummaries: SpfhTelemetrySummary[] = parseSpfhTelemetrySummaries(data);

    // Add harvesting to stream, to calculate fieldCoverage
    spfhTelemetrySummaries.forEach((spfhTelemetrySummary: SpfhTelemetrySummary) => {
        const {vehicleId: spfhId, workWidth__m, harvesting} = spfhTelemetrySummary;

        FieldCoverageService.addHeaderEvent({
            arableVehicleId: spfhId,
            workWidth__m: workWidth__m,
            harvesting
        });
    });

    return team.updateSpfhTelemetrySummary(spfhTelemetrySummaries);
};

const reduceBalerTelemetryEvent = (team: Team, data: BalerTelemetrySummariesWithIdJSON): Team => {
    const balerTelemetrySummaries: BalerTelemetrySummary[] = parseBalerTelemetrySummaries(data);

    // Add harvesting to stream, to calculate fieldCoverage
    balerTelemetrySummaries.forEach((balerTelemetrySummary: BalerTelemetrySummary) => {
        const {vehicleId: balerId, workWidth, harvesting} = balerTelemetrySummary;

        FieldCoverageService.addHeaderEvent({
            arableVehicleId: balerId,
            workWidth__m: workWidth.toNumber(),
            harvesting
        });
    });

    return team.updateBalerTelemetrySummary(balerTelemetrySummaries);
};

const reduceBinTelemetryEvent = (team: Team, data: BinTelemetriesWithIdJSON): Team => {
    const binTelemetries: BinTelemetry[] = parseBinTelemetries(data);
    return team.updateBinTelemetry(binTelemetries);
};

const reduceDetectionEvent = (team: Team, data: DetectionEvent): Team => {
    if (data.type === DetectionEnum.BALE_DROP_DETECTED) {
        BalerLoadReportService.onBaleDropDetected(team.id, data as BaleDropDetected);
    } else {
        DetectionService.addDetectionEvent(data);
    }

    return team;
};

const reduceCombineCoverageChangedEvent = (team: Team, data: CombineCoverageChangedJSON): Team => {
    FieldCoverageService.addHarvesterCoverageChangedEvent(data.combineId);
    return team;
};

const reduceSpfhCoverageChangedEvent = (team: Team, data: SpfhCoverageChangedJSON): Team => {
    FieldCoverageService.addHarvesterCoverageChangedEvent(data.spfhId);
    return team;
};

const reduceHarvestingProgressEvent = (team: Team, data: HarvestingProgressJSON): Team => {
    const harvestingProgress: HarvestingProgress = parseHarvestingProgress(data);
    HarvestingProgressService.updateHarvestingProgress(harvestingProgress);
    return team;
};
