<template>
  <div class="viewMarker">
    <v-simple-table dense>
      <template v-slot:default class="p-10">
        <tbody>
        <tr>
          <td>{{ translations.MODALS.MARKER.NAME.LABEL }}</td>
          <td>{{ marker.name }}</td>
        </tr>
        <tr>
          <td>{{ translations.MODALS.MARKER.LOCATION.LABEL }}</td>
          <td>Lon: {{ marker.location.longitude }} Lat: {{ marker.location.latitude }}</td>
        </tr>
        <tr>
          <td>{{ translations.MODALS.MARKER.CREATED_ON.LABEL }}</td>
          <td>{{ marker.createdOn.toLocaleString() }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="viewMarker__pictureWrapper">
      <template v-if="marker.hasPicture">
        <Picture
            img-class="viewMarker__picture"
            v-if="picture"
            :picture="picture"
        />
        <v-progress-circular v-else color="primary" indeterminate />
      </template>
      <template v-else>
        <div>{{translations.MODALS.MARKER.NO_PICTURE}}</div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import {Marker} from 'shared-frontend'
import Vue from 'vue';
import {mapState} from 'vuex'
import {State} from '@/store'
import MarkerService from '@services/marker.service'
import TeamService from '@/team/team.service'
import Picture from '@components/MagnifyPicture.component.vue'

export default Vue.extend({
  name: "ViewMarker",
  components: {Picture},
  props: {
    params: {
      type: Object,

    }
  },
  data() {
    return {
      picture: null
    }
  },
  async created() {
    if (this.marker.hasPicture) {
      this.picture = await MarkerService.getMarkerPicture((this as any).selectedTeamId$, this.marker.id)
    }
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    marker(): Marker {
      return this.params.marker;
    }
  },
  subscriptions() {
    return {
      selectedTeamId$: TeamService.selectedTeamIdOrNull$
    };
  }
})
</script>
