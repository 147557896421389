<template>
  <div class="temperature-bar">
    <div class="bar"></div>
    <div class="temperatures">
      <div class="max" title="Maximum temperature (°C)">{{ info.maxT }}</div>
      <div class="avg" title="Current temperature (°C)">{{ info.currentT }}</div>
      <div class="min" title="Minimum temperature (°C)">{{ info.minT }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {WeatherInfo} from '@/weather/weather-info.domain';

export default {
  name: 'TemperatureBar',
  props: {
    info: {
      type: Object as PropType<WeatherInfo>,
      required: true,
    }
  }
}

</script>

<style lang="scss" scoped>
.temperature-bar {
  display: flex;
  gap: 6px;
}

.temperatures {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.bar {
  height: 80px;
  width: 3px;
  border-radius: 2px;
  background-image: linear-gradient(
          to top,
          lighten(blue, 10%),
          white,
          lighten(red, 10%)
  );
}
</style>
