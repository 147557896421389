<template>
  <v-dialog v-model="show" scrollable width="500">
    <v-card>
      <v-toolbar :elevation="0" dark :color="modalShown.color || 'primary'">
        <v-card-title primary-title class="modal__title">
          {{ modalShown.title }}
        </v-card-title>
        <v-spacer/>
        <v-btn icon @click="closeModal" class="modal__close">
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="modal__content">
        <v-card-text class="modal__text">
          <component
              :is="modalShown.formComponent"
              v-if="modalShown.formComponent"
              @close="closeModal"
              :params="modalShown.params"
          />
          <p v-else>{{ modalShown.message }}</p>
        </v-card-text>

        <v-card-actions v-if="modalShown.hasCancelButton || modalShown.hasConfirmButton">
          <v-spacer/>
          <v-btn v-if="modalShown.hasCancelButton" @click="cancel" color="error" text>{{
              modalShown.cancelButtonText
            }}
          </v-btn>
          <v-btn
              v-if="modalShown.hasConfirmButton"
              @click="confirm"
              :color="modalShown.color || 'primary'"
              dark
          >
            {{ modalShown.confirmButtonText }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
// EXTERNAL
import { mapState } from 'vuex';
// INTERNAL
// Services
//
// Modals
import NewFarmBin from '@/farmBin/NewFarmBin.modal.vue';
import NewFarmBaleServiceVehicle from '@/farmBaleServiceVehicle/NewFarmBaleServiceVehicle.modal.vue';
import NewFarmServiceVehicle from '@/farmServiceVehicle/NewFarmServiceVehicle.modal.vue';
import NewWorkForceMember from '@/workforce/NewWorkForceMember.modal.vue';
import Settings from '@/modals/Settings.modal.vue';
import EditUser from '@/modals/EditUser.modal.vue';
import Telemetry from '@/telemetry/Telemetry.modal.vue';
import EspCams from '@/espCam/EspCams.modal.vue';
import EditTeamInfo from '@/modals/EditTeamInfo.modal.vue';
import ConfirmImportFromEvoPortal from '@/modals/ConfirmImportFromEvoPortal.modal.vue';
import ConfirmUploadToEvoPortal from '@/modals/ConfirmUploadToEvoPortal.modal.vue';
import ViewMarker from '@/modals/ViewMarker.modal.vue';
import LinkEditGrower from '@/grower/LinkEditGrower.modal.vue';

//
// Others
import { Mutations } from '@/store';
import { icons } from '@/utils/icons.utils';

export default {
  name: 'Modal',
  components: {
    NewWorkForceMember,
    NewFarmBin,
    NewFarmBaleServiceVehicle,
    NewFarmServiceVehicle,

    Settings,
    EditUser,
    Telemetry,
    EditTeamInfo,
    ConfirmImportFromEvoPortal,
    ConfirmUploadToEvoPortal,
    ViewMarker,
    LinkEditGrower,
    EspCams
  },
  computed: {
    ...mapState(['modalShown', 'branding']),
    icons() {
      return icons;
    }
  },
  data: () => ({
    show: true
  }),
  methods: {
    closeModal() {
      this.$store.commit(Mutations.UPDATE_MODAL_SHOWN, null);
    },
    confirm() {
      if (this.modalShown.onConfirm) this.modalShown.onConfirm();
      this.closeModal();
    },
    cancel() {
      this.closeModal();
    }
  },
  watch: {
    show(show) {
      if (!show) {
        this.closeModal();
      }
    }
  }
};
</script>
