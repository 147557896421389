<template>
    <div class="mapView__warning">
        <v-icon large dark class="mr-3">{{ icons.eye }}</v-icon>
        <span>{{ translations.MAP.VIEWING_AS_GROWER }}</span>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { icons, Icons } from '../../utils/icons.utils';
import {State} from '@/store';

export default Vue.extend({
    name: 'ViewingAsGrower',
    computed: {
        ...mapState({
            translations: (state: State) => state.translation.translations
        }),
        icons(): Icons {
            return icons;
        }
    }
});
</script>
