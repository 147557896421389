// EXTERNAL
import { BehaviorSubject } from 'rxjs';
//
// INTERNAL
// Shared
import { FieldEntity } from 'shared-frontend';

class FieldEntityService {
    private _fieldEntity$: BehaviorSubject<FieldEntity> = new BehaviorSubject<FieldEntity>(null);

    public get fieldEntity$() {
        return this._fieldEntity$.asObservable();
    }

    public setFieldEntity(fieldEntity: FieldEntity) {
        this._fieldEntity$.next(fieldEntity);
    }
}

export default new FieldEntityService();
