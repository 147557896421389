<template>
  <v-combobox v-if="comboboxMode" :value="value" :rules="rules" @input="handleInput" :items="farms" :search-input.sync="farmSearch" :label="trans.TEAM.FARM" clearable>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            No results matching "<strong>{{ farmSearch }}</strong>". Press <kbd>enter</kbd> to create a new one
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
  <v-autocomplete v-else :value="value" :rules="rules" @input="$emit('input', $event)" :items="farms" dark hide-details :label="trans.TEAM.FARM" return-object/>

</template>

<script lang="ts">

import {AutoCompleteItem, selectAllAutoCompleteItem, selectAllPredicate, toAutocompleteItems} from "./Picker.helper";
import {FarmTeamSummary} from "@/farmTeam/farmTeam.domain";
import Vue, {PropType} from "vue";
import {mapState} from "vuex";
import {State} from "@/store";
import {not} from "@utils/functional.util";
import {uniqueFarms} from "@components/picker/FarmPicker.helper";

const farmSelectAllAutoCompleteItem = selectAllAutoCompleteItem("All Farms");
export default Vue.extend({
  name: 'FarmPicker',
  props: {
    value: {},
    comboboxMode: {
      type: Boolean,
      default: false
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    alphabetically: {
      type: Boolean,
      default: false
    },
    farmTeams: {
      type: Array as PropType<FarmTeamSummary[]>
    },
    grower: {
      type: Object,
    },
    rules: []
  },
  data() {
    return {
      farmSearch: "",
      createdFarms:[]
    }
  },
  computed: {
    ...mapState({
      trans: (state: State) => state.translation.translations
    }),
    farms(): AutoCompleteItem[] {
      return this.findFarms(this.grower).concat(this.createdFarms);
    },
  },
  methods: {
    findFarms(grower: AutoCompleteItem): AutoCompleteItem[] {
      let farms = this.selectAll ? [farmSelectAllAutoCompleteItem] : [];
      return (
          farms.concat(
              uniqueFarms(this.farmTeams, this.alphabetically, grower)
                  ?.map(toAutocompleteItems(this.trans.TEAM.FARM, this.trans))
          )
      );
    },
    updateFarm(grower?: AutoCompleteItem): void {
      const growerUpdate = grower ?? this.grower
      if (growerUpdate?.selectAll) {
        this.$emit('input', farmSelectAllAutoCompleteItem)
      } else {
        this.$emit('input', this.firstFarm(growerUpdate))
      }
    },
    firstFarm(grower: AutoCompleteItem): AutoCompleteItem {
      return this.findFarms(grower).find(not(selectAllPredicate));
    },
    handleInput(entry): void {
      if (typeof entry === 'string') {
        let trimmedEntry = entry.trim();
        let foundEntry = this.farms.find(value => value.value === entry);
        if (foundEntry) {
          this.$emit('input', foundEntry)
        } else if (trimmedEntry.trim() !== "") {
          let newEntry = toAutocompleteItems(this.trans.TEAM.FARM, this.trans)(entry, null)
          this.createdFarms.push(newEntry)
          this.$emit('input', newEntry)
        }
      } else {
        this.$emit('input', entry)
      }
    }

  },
  watch: {
    'grower': function (newEntry: AutoCompleteItem, oldGrower: AutoCompleteItem): void {
      if (newEntry?.value === oldGrower?.value && newEntry?.selectAll === newEntry?.selectAll) return;
      this.updateFarm(newEntry);
    },
  }
});
</script>
