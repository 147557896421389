<template>
    <div class="teamMemberList__expanded" v-if="selectedTeam$">
        <v-expansion-panels class="teamMemberList__panel">
            <v-expansion-panel v-if="users.length">
                <v-expansion-panel-header
                    >{{ translations.TEAM_MEMBERS.USERS }} ({{ users.length }})</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                    <TeamMember v-for="user in users" :key="user.id" :field-entity="user" :icon-width="50" :nocolor="true" />
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="combines.length">
                <v-expansion-panel-header
                    >{{ translations.TEAM_MEMBERS.COMBINES }} ({{ combines.length }})
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div v-for="combine in combines" :key="combine.id" class="teamMemberList__subList">
                        <TeamMember :field-entity="combine" />
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="spfhs.length">
                <v-expansion-panel-header>{{ translations.TEAM_MEMBERS.SPFHS }} ({{ spfhs.length }})</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div v-for="spfh in spfhs" :key="spfh.id" class="teamMemberList__subList">
                        <TeamMember :field-entity="spfh" />
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="balers.length">
                <v-expansion-panel-header
                    >{{ translations.TEAM_MEMBERS.BALERS }} ({{ balers.length }})</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                    <div v-for="baler in balers" :key="baler.id" class="teamMemberList__subList">
                        <TeamMember :field-entity="baler" />
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="bins.length + baleServiceVehicles.length">
                <v-expansion-panel-header
                    >{{ translations.TEAM_MEMBERS.SERVICE_VEHICLES }} ({{ bins.length + baleServiceVehicles.length }})</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                    <div v-for="bin in bins" :key="bin.id" class="teamMemberList__subList">
                        <TeamMember :field-entity="bin" />
                    </div>
                    <div v-for="baleServiceVehicle in baleServiceVehicles" :key="baleServiceVehicle.id" class="teamMemberList__subList">
                      <TeamMember :field-entity="baleServiceVehicle" />
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {mapState} from 'vuex';

import {Baler, BaleServiceVehicle, Bin, Combine, Spfh, User} from 'shared-frontend';
import TeamService from '@/team/team.service';
import MapIcon from '@/map/icons/MapIcon.icon.vue';
import {Icons, icons} from '@utils/icons.utils';
import {State} from '@/store';
import TeamMember from '@/team/teamMemberList/TeamMember.component.vue';

export default Vue.extend({
    name: 'ExpandedTeamMemberList',
    components: { TeamMember, MapIcon },
    computed: {
        ...mapState({
            translations: (state: State) => state.translation.translations
        }),
        ...mapState({
            isFieldCoverageEnabled: (state: State) => state.fieldCoverageEnabled
        }),
        icons(): Icons {
            return icons;
        },
        combines(): Combine[] {
            return (this as any).selectedTeam$?.combines?.getList();
        },
        spfhs(): Spfh[] {
            return (this as any).selectedTeam$?.spfhs?.getList();
        },
        balers(): Baler[] {
            return (this as any).selectedTeam$?.balers?.getList();
        },
        bins(): Bin[] {
            return (this as any).selectedTeam$?.bins?.getList();
        },
        baleServiceVehicles(): BaleServiceVehicle[] {
          return (this as any).selectedTeam$?.baleServiceVehicles?.getList();
        },
        users(): User[] {
            return (this as any).selectedTeam$?.users?.getList();
        }
    },
    subscriptions() {
        return {
            selectedTeam$: TeamService.selectedTeamOrNull$
        };
    }
});
</script>
