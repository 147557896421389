import {Module} from 'vuex';
import {ApdtField} from '@/apdtAdvice/advice.domain';

export const enum Mutations {
    SET_CURRENT_FIELD = 'setCurrentField'
}


const apdtModule: Module<any, any> = {
    state: {
        current_field: null,
    },
    mutations:{
        [Mutations.SET_CURRENT_FIELD](state: any, field: ApdtField) {
            state.current_field = field;
        },
    }
}

export default apdtModule;
