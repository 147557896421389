<template>
    <Aside>
        <template v-if="!team$" v-slot:content>
            <Title :title="trans.MESSAGING.TITLE" />
            <div class="content">
                <div>
                    {{ trans.NO_TEAM }}
                </div>
            </div>
        </template>
        <template v-else v-slot:content>
            <Title :title="trans.MESSAGING.TITLE" />
            <div class="content">
                <template v-if="!isViewingAsGrower$">
                    <v-form ref="form" class="messaging__form" @submit.prevent="sendMessage">
                        <v-text-field
                            v-if="team$.isActive()"
                            :rules="formRules.name"
                            :label="trans.MESSAGING.FORM.MESSAGE_LABEL"
                            required
                            v-model="messageToSend"
                            autofocus
                        >
                            <template v-slot:append>
                                <v-btn icon @click="sendMessage">
                                    <v-icon color="primary" small> fas fa-paper-plane</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </v-form>
                    <p v-if="!team$.isActive()" class="font-weight-light body-2">{{ trans.MESSAGING.DISABLED }}</p>
                </template>

                <v-data-table
                    :custom-sort="customSort"
                    sort-by="createdOn"
                    sort-desc
                    :multi-sort="false"
                    must-sort
                    :headers="tableHeaders"
                    :items="tableItems"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:item.createdOn="{ item }">
                        <span>{{ formatDate(item.createdOn) }}</span>
                    </template>
                </v-data-table>
            </div>
        </template>
    </Aside>
</template>
<script lang="ts">
import Vue from 'vue';
import Aside from '../layout/Aside.vue';
import Title from '../components/Title.component.vue';
import Team from '../team/Team.page.vue';
import UnderscriptIcon from '../components/UnderscriptIcon.component.vue';
import SearchInput from '@components/SearchInput.component.vue';
import TextWithLabel from '../components/TextWithLabel.component.vue';
import TeamService from '@/team/team.service';
import {icons} from '@utils/icons.utils';
import {mapState} from 'vuex';
import {State} from '@/store';
import MessageService from '@/messaging/message.service';
import {Message, toShortDateTime} from 'shared-frontend';
import SettingsService from '@services/settings.service';

const COLUMN_CREATED_ON = 'createdOn';
const COLUMN_MESSAGE = 'message';
const COLUMN_CREATED_BY = 'createdBy';

export default Vue.extend({
    name: 'Messaging',
    components: { Aside, Title, Team, UnderscriptIcon, TextWithLabel, SearchInput },
    data() {
        return {
            messageToSend: '',
            formRules: {
                name: [
                    // Don't set it a required because it would keep spamming the user with message is required
                    (v) => !v || v.length <= 250 || (this as any).trans.MESSAGING.FORM.MESSAGE_ERROR_LENGTH
                ]
            }
        };
    },
    computed: {
        ...mapState({
            trans: (state: State) => state.translation.translations
        }),
        icons() {
            return icons;
        },
        tableHeaders() {
            return [
                {
                    value: COLUMN_CREATED_ON,
                    text: this.trans.MESSAGING.TABLE.HEADERS.CREATED_ON
                },
                {
                    value: COLUMN_MESSAGE,
                    text: this.trans.MESSAGING.TABLE.HEADERS.MESSAGE
                },
                {
                    value: COLUMN_CREATED_BY,
                    text: this.trans.MESSAGING.TABLE.HEADERS.CREATED_BY
                }
            ];
        },
        tableItems() {
            if (!(this as any).messages$) {
                return [];
            } else {
                return ((this as any).messages$ as Message[]).map((message: Message) => {
                    return {
                        [COLUMN_CREATED_ON]: message.createdOn,
                        [COLUMN_MESSAGE]: message.message,
                        [COLUMN_CREATED_BY]: message.createdBy?.getFullName() ?? this.trans.MESSAGING.UNKNOWN_USER
                    };
                });
            }
        }
    },
    methods: {
        customSort(items: Message[], sortBy: string[], isDesc: boolean[]): Message[] {
            // API: https://vuetifyjs.com/en/api/v-data-table/#props

            const copy = items.slice();
            const sortColumn = sortBy?.[0]; // because we don't allow multi-sort for now
            const sortDesc = isDesc?.[0]; // because we don't allow multi-sort for now

            if (sortColumn === 'createdOn') {
                // value is a date ('createdOn')
              // @ts-ignore
                copy.sort((a, b) => (sortDesc ? b[sortColumn] - a[sortColumn] : a[sortColumn] - b[sortColumn]));
            } else {
                // value is a string ('message', 'createdBy')
                copy.sort(function (a, b) {
                    if (!sortDesc) {
                        return a[sortColumn] < b[sortColumn] ? -1 : 1;
                    } else {
                        return b[sortColumn] < a[sortColumn] ? -1 : 1;
                    }
                });
            }

            return copy;
        },
        sendMessage() {
            const isValid = (this as any).$refs.form.validate();
            if (!isValid || !(this as any).messageToSend) {
                return;
            }

            MessageService.sendMessage(
                (this as any).messageToSend,
                (this as any).team$.farmManagerId,
                (this as any).team$.id
            );
            (this as any).messageToSend = '';
        },
        formatDate(date: Date): string {
            return toShortDateTime(date, SettingsService.userLocale);
        }
    },
    subscriptions() {
        return {
            team$: TeamService.selectedTeamOrNull$,
            messages$: MessageService.allMessages$(),
            isViewingAsGrower$: TeamService.isViewingAsGrower$
        };
    }
});
</script>

<style lang="scss" scoped>
.content {
    margin: 2rem;
}
</style>
