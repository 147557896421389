import {Credential, CredentialsSource, CredentialToken, CredentialUsernamePassword} from '@/credentials/credentials.domain';
import httpService from '@services/http.service';

export async function getCredentials(): Promise<Credential[]> {
    const baseUrl = `${process.env.VUE_APP_APDT_URL}/sensors/credentials`;
    const response = await httpService.get(baseUrl);
    return response.data.map(credential => {
        switch (credential.type) {
            case CredentialsSource.agroplanning:
                return new CredentialUsernamePassword(CredentialsSource.agroplanning, {
                    username: credential.credentials.username,
                    password: credential.credentials.password
                })
            case CredentialsSource.smartfarm:
                return new CredentialUsernamePassword(CredentialsSource.smartfarm, {
                    username: credential.credentials.username,
                    password: credential.credentials.password
                })

            case CredentialsSource.geoprospectors:
                return new CredentialUsernamePassword(CredentialsSource.geoprospectors, {
                    username: credential.credentials.username,
                    password: credential.credentials.password
                })

            case CredentialsSource.augmenta:
                return new CredentialToken(CredentialsSource.augmenta, {
                    token: credential.credentials.token,
                })

        }
    })
}

export async function deleteCredential(credentialSource: CredentialsSource): Promise<void> {
    const baseUrl = `${process.env.VUE_APP_APDT_URL}/sensors/credentials/` + credentialSource;
    await httpService.delete(baseUrl);
}

export async function updateCredential(credential: Credential): Promise<void> {
    const baseUrl = `${process.env.VUE_APP_APDT_URL}/sensors/credentials`;
    await httpService.put(baseUrl, credential.toApdtJSONBody());
}

export async function saveCredential(credential: Credential) : Promise<void> {
    const baseUrl = `${process.env.VUE_APP_APDT_URL}/sensors/credentials`;
     await httpService.post(baseUrl, credential.toApdtJSONBody());
}
