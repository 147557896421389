import {LogsUploadProgress, UploadStatus} from "@/admin/admin.domain";
import {parseDate} from "shared-frontend";

export interface LogsUploadProgressJSON {
    startedOn: string;
    status: UploadStatus;
    canStartNewUpload: boolean;
    failureMessage: string | null;
}


export function parseLogsUploadProgress(logsUploadProgress: LogsUploadProgressJSON): LogsUploadProgress {
    return new LogsUploadProgress(
        parseDate(logsUploadProgress.startedOn),
        logsUploadProgress.status,
        logsUploadProgress.canStartNewUpload,
        logsUploadProgress.failureMessage
    )
}
