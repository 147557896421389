<template>
  <div>
    <v-dialog
        v-bind:value="value"
        persistent
        max-width="600px"
        v-if="!loading"
    >

      <v-card>
        <v-card-title>
          <span class="text-h5">Please select 2 or more dates to merge</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  v-for="source in sources.data"
                  :key="source.day.toString()"
                  cols="12"
                  md="6"
              >
                <v-card
                    :class="{ 'selected-card': selectedDates.includes(source.day) }"
                    @click="toggleSelection(source.day)"
                >
                  <v-card-title class="justify-center">{{ source.day.format("YYYY-MM-DD") }}</v-card-title>
                  <v-img :src="createThumbnailUrl(source)" />

                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error" text
              @click="emitClose();"
          >
            Close
          </v-btn>
          <v-btn
              :disabled="!enableSubmit"
              color="primary"
              @click="handleMerge"
          >
            Merge
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar
          v-model="showSuccessSnackbar"
          color="success"
          absolute
          style="transform: translateY(-70vh)"
          timeout="3000"
      >
        {{ successMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="showSuccessSnackbar = false"
          >
            X
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
          v-model="showErrorSnackbar"
          color="error"
          absolute
          style="transform: translateY(-70vh)"
          timeout="3000"
      >
        {{ errorMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="showErrorSnackbar = false"
          >
            X
          </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
    <v-overlay v-else>
      <v-row>
        <v-col cols="12" class="text-center">
          Data is being merged, this could take a few seconds
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-progress-circular
              indeterminate
              size="64"></v-progress-circular>
        </v-col>
      </v-row>

    </v-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import { createThumbnailUrl, getAdviceSourcesForType, getMergePeriodThreshold, mergeLayers } from "@/apdtAdvice/Advice.service";
import moment from "moment";

export default Vue.extend({
  name: "MergeDialog",
  props: {
    sourceType: null,
    value: null
  },
  data() {
    return {
      showSuccessSnackbar: false,
      showErrorSnackbar: false,
      errorMessage: "",
      successMessage: "",
      sources: [],
      selectedDates: [],
      mergePeriodThreshold: null,
      enableSubmit: false,
      loading: false
    };
  },
  computed: {
    ...mapState({
      currentField: state => state.apdtStore.current_field,
      translations: state => state.translation.translations

    })
  },
  methods: {
    validateSelectedDates() {
      let minDate = moment.min(this.selectedDates);
      let maxDate = moment.max(this.selectedDates);
      var duration = moment.duration(maxDate.diff(minDate));
      if (duration.asMonths() > this.mergePeriodThreshold.asMonths()) {
        this.errorMessage = "The selected dates are too far apart to merge. Maximum period is " + this.mergePeriodThreshold.asMonths() + " months.";
        this.showErrorSnackbar = true;
        this.enableSubmit = false;
      }
      this.enableSubmit = true;
    },
    createThumbnailUrl(source) {
      return createThumbnailUrl(source.wmsLayer, source.wmsStyles[0].ref);
    },
    async init() {
      await this.fetchSources();
      this.mergePeriodThreshold = await getMergePeriodThreshold();
    },
    async fetchSources() {
      let sources = await getAdviceSourcesForType(this.currentField, this.sourceType).then(response => {
        return response;
      });
      if (sources.length > 0 && sources[0].options.length > 0) {
        this.sources = sources[0].options[0];
      }
    },
    toggleSelection(day) {
      const index = this.selectedDates.indexOf(day);
      if (index === -1) {
        this.selectedDates.push(day);
      } else {
        this.selectedDates.splice(index, 1);
      }
      this.validateSelectedDates();
    },
    emitClose() {
      this.$emit("input", false);
    },
    emitMerge() {
      this.$emit("merge");
    },
    handleMerge() {
      if (!this.showErrorSnackbar) {
        this.enableSubmit = false;
        this.loading = true;
        mergeLayers(this.currentField, this.selectedDates).then(response => {
          this.loading = false;
          this.emitMerge();
        }).catch(error => {
          this.loading = false;
          this.errorMessage = "An error occurred while merging the layers";
          this.showSuccessSnackbar = false;
          this.showErrorSnackbar = true;
        });
      }
    }
  },
  watch: {
    value(visible) {
      if (visible) {
        this.init();
      }
    },
    selectedDates(selectedDates) {
      if (selectedDates.length === 0) {
        this.enableSubmit = false;
      }
    }
  }
});

</script>

<style scoped>
.selected-card {
  border: 2px solid #1976D2;
}
</style>
