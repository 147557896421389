<template>
  <div class="fieldReport">
    <router-link class="fieldReport__title" :to="{ name: 'fieldReport' }" style="text-decoration: none">
      <PolygonImage :width="60" :height="60" v-if="boundary" :coordinates="boundary.coordinates"/>
      <span class="fieldReport__text">{{ teamName }}</span>
    </router-link>
    <div class="fieldReport__icons">
      <div class="fieldReport__icon" @click.stop="downloadFieldReport(responseType.PDF)">
        <v-progress-circular indeterminate v-if="isLoadingPdf"/>
        <v-icon v-else large>{{ icons.filePdf }}</v-icon>
      </div>
      <div class="fieldReport__icon" @click.stop="downloadFieldReport(responseType.CSV)">
        <v-progress-circular indeterminate v-if="isLoadingCsv"/>
        <v-icon v-else large>{{ icons.fileCsv }}</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import PolygonImage from '@/boundaries/PolygonImage.component.vue';
import ReportService from '@services/report.service';
import {ContentTypeEnum} from '@services/http.service';
import TeamService from '@/team/team.service';
import {icons} from '@utils/icons.utils';

export default {
  name: 'FieldReportBtn',
  components: {PolygonImage},
  props: {
    teamName: {
      type: String,
      required: true
    },
    boundary: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isLoading: {
        [ContentTypeEnum.PDF.contentType]: false,
        [ContentTypeEnum.CSV.contentType]: false,
      }
    }
  },
  computed: {
    responseType() {
      return ContentTypeEnum;
    },
    icons() {
      return icons;
    },
    isLoadingPdf() {
      return this.isLoading[ContentTypeEnum.PDF.contentType];
    },
    isLoadingCsv() {
      return this.isLoading[ContentTypeEnum.CSV.contentType];
    },
  },
  methods: {
    downloadFieldReport(responseType) {
      const contentType = responseType.contentType;

      if (this.isLoading[contentType]) return;

      this.isLoading[contentType] = true;

      ReportService.downloadFieldReport(this.teamId$, responseType).finally(() => {
        this.isLoading[contentType] = false;
      })
    }
  },
  subscriptions() {
    return {
      teamId$: TeamService.selectedTeamIdOrNull$
    };
  }
};
</script>
