<template>
  <div
      class="underscriptIcon"
      :class="{'underscriptIcon--clickable' : clickable}"
      @click="clickable && $emit('click')"
  >
    <v-icon :color="color" medium>{{ icon }}</v-icon>
    <div
        class="underscriptIcon__text"
        :style="`color: ${color}`"
    >
      {{ underscript }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'UnderscriptIcon',
  components: {},
  props: {
    icon: {
      type: String,
      required: true
    },
    underscript: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
}
</script>