export enum ActivityType {
    LIMING = 'LIMING',
    SOWING = 'SOWING',
    FERTILIZING_N1 = 'FERTILIZING_N1',
    FERTILIZING_N2 = 'FERTILIZING_N2',
    FERTILIZING_N3 = 'FERTILIZING_N3',
    FUSARIUM = 'FUSARIUM',
    APHID = 'APHID',
    OULEMA = 'OULEMA'
}

export enum CultivarType {
    STANDARD = 'STANDARD',
    HYBRID = 'HYBRID',
}

export enum AdviceSourceType {
    AGROPLANNING_YIELD = "AGROPLANNING_YIELD",
    AUGMENTA_INDEX = "AUGMENTA_INDEX",
    GEOPROSPECTOR_ECA = "GEOPROSPECTOR_ECA",
    SOIL_ANALYSIS = "SOIL_ANALYSIS",
    AGDNA_CN1 = "AGDNA_CN1",
    DIGIFARM_IMAGERY = "DIGIFARM_IMAGERY"
}
