// INTERNAL
// Shared
import { API_ENDPOINTS, parseUserInfo, User, UserInfoJSON } from 'shared-frontend';
//
// Services
import httpService from '@services/http.service';
import store, { Mutations } from '@/store';
import { logger } from '@services/logger.service';
import HttpService from '@services/http.service';

class UserService {
    public async getUser(farmManagerId: string, teamId: string, userId: string): Promise<User> {
        const response = await httpService.get(API_ENDPOINTS.USER(farmManagerId, teamId, userId));
        const userInfoJSON: UserInfoJSON = response.data;
        return parseUserInfo(userInfoJSON);
    }

    public async getCurrentUser(): Promise<User> {
        const response = await HttpService.get(API_ENDPOINTS.CURRENT_USER());
        const userInfoJSON: UserInfoJSON = response.data;
        return parseUserInfo(userInfoJSON);
    }
}

export default new UserService();
