import {FarmTeamSummary} from '@/farmTeam/farmTeam.domain';
import {AutoCompleteItem, filterApplies} from '@components/picker/Picker.helper';
import {pick, toUnique} from '@utils/functional.util';
import {sortAlphabeticallyBy, sortByMostRecent} from '@/farmTeam/farmTeam.helper';

export const uniqueYears = (farmTeams: FarmTeamSummary[], alphabetically: boolean, grower: AutoCompleteItem, farm: AutoCompleteItem): number[] => {
    if (!farmTeams?.length) return [];
    return toUnique(farmTeams.slice()
        .filter(filterApplies({growerName: grower?.value, farmName: farm?.value}))
        .sort(alphabetically ? sortAlphabeticallyBy('year', true) : sortByMostRecent)
        .map(pick('year')));
}
