import {FarmTeamSummary} from '@/farmTeam/farmTeam.domain';
import {AutoCompleteItem} from '@components/picker/Picker.helper';

export function matchingTeams(
    teams: FarmTeamSummary[] | null,
    selectedGrower: AutoCompleteItem,
    selectedFarm: AutoCompleteItem,
    selectedYear: AutoCompleteItem,
    selectedField: AutoCompleteItem,
    selectedCrop: AutoCompleteItem,
    selectedTeamName: AutoCompleteItem,
): FarmTeamSummary[] {
    return teams?.filter((farmTeam: FarmTeamSummary) => {
        const {growerName, farmName, year, fieldName, cropName, teamName} = farmTeam;
        return (
            (selectedGrower.selectAll || selectedGrower.value === growerName) &&
            (selectedFarm.selectAll || selectedFarm.value === farmName) &&
            (selectedYear.selectAll || selectedYear.valueAsNumber === year) &&
            (selectedField.selectAll || selectedField.value === fieldName) &&
            (selectedCrop.selectAll || selectedCrop.value === cropName) &&
            (selectedTeamName.selectAll || selectedTeamName.value === teamName)
        );
    }) || [];
}
