import {addItemToArray, deleteBy, updateArray} from '@utils/functional.util';
import {
    CreateFarmBaleServiceVehicle,
    eqFarmBaleServiceVehicle,
    FarmBaleServiceVehicle,
    FarmBaleServiceVehicleCreatedEvent,
    FarmBaleServiceVehicleEvent,
    FarmBaleServiceVehicleEventTypes,
    FarmBaleServiceVehicleRemovedEvent,
    FarmBaleServiceVehicleUpdatedEvent
} from '@/farmBaleServiceVehicle/farmBaleServiceVehicle.domain';
import {BaleServiceVehicle, parseBaleServiceVehicleIcon} from 'shared-frontend';

export const reduceFarmBaleServiceVehicleEvent = (farmBaleServiceVehicles: FarmBaleServiceVehicle[], event: FarmBaleServiceVehicleEvent): FarmBaleServiceVehicle[] => {
    switch (event.type) {
        case FarmBaleServiceVehicleEventTypes.FARM_BALE_SERVICE_VEHICLE_CREATED:
            return reduceFarmBaleServiceVehicleCreatedEvent(farmBaleServiceVehicles, event as FarmBaleServiceVehicleCreatedEvent);
        case FarmBaleServiceVehicleEventTypes.FARM_BALE_SERVICE_VEHICLE_UPDATED:
            return reduceFarmBaleServiceVehicleUpdatedEvent(farmBaleServiceVehicles, event as FarmBaleServiceVehicleUpdatedEvent);
        case FarmBaleServiceVehicleEventTypes.FARM_BALE_SERVICE_VEHICLE_REMOVED:
            return reduceFarmBaleServiceVehicleDeletedEvent(farmBaleServiceVehicles, event as FarmBaleServiceVehicleRemovedEvent);
    }
}


const reduceFarmBaleServiceVehicleCreatedEvent = (farmBaleServiceVehicles: FarmBaleServiceVehicle[], event: FarmBaleServiceVehicleCreatedEvent): FarmBaleServiceVehicle[] => {
    return addItemToArray<FarmBaleServiceVehicle>(eqFarmBaleServiceVehicle)(farmBaleServiceVehicles, event.farmBaleServiceVehicle);
}

const reduceFarmBaleServiceVehicleUpdatedEvent = (farmBaleServiceVehicles: FarmBaleServiceVehicle[], event: FarmBaleServiceVehicleUpdatedEvent): FarmBaleServiceVehicle[] => {
    return updateArray<FarmBaleServiceVehicle>(eqFarmBaleServiceVehicle)(farmBaleServiceVehicles, event.farmBaleServiceVehicle)
}

const reduceFarmBaleServiceVehicleDeletedEvent = (farmBaleServiceVehicles: FarmBaleServiceVehicle[], event: FarmBaleServiceVehicleRemovedEvent): FarmBaleServiceVehicle[] => {
    const selectFarmBaleServiceVehicle = (farmBaleServiceVehicle: FarmBaleServiceVehicle) => farmBaleServiceVehicle.baleServiceVehicleId === event.baleServiceVehicleId;
    return deleteBy<FarmBaleServiceVehicle>(farmBaleServiceVehicles, selectFarmBaleServiceVehicle);
}

// Convert FarmBaleServiceVehicle from/to other structs
// ======================================

export const farmBaleServiceVehicleToBaleServiceVehicle = ({baleServiceVehicleId, baleServiceVehicleName, icon}: FarmBaleServiceVehicle): BaleServiceVehicle => {
    return new BaleServiceVehicle(baleServiceVehicleId, null, baleServiceVehicleName, null, parseBaleServiceVehicleIcon(icon), false);
};

export const baleServiceVehicleToCreateFarmBaleServiceVehicle = (baleServiceVehicle: BaleServiceVehicle): CreateFarmBaleServiceVehicle => {
    return {
        baleServiceVehicleName: baleServiceVehicle.name,
        icon: JSON.stringify(baleServiceVehicle.icon)
    }
}
