<template>
  <fragment>
    <v-card class="dashboard__card dashboard__card--half">
      <v-card-title :style="{ backgroundColor: brandColors.SECONDARY }">
        <span>{{ translations.DASHBOARD.WORKFORCE_SERVICE_VEHICLES }}</span>
        <InfoIcon :text="translations.DASHBOARD.WORKFORCE_SERVICE_VEHICLES_INFO"/>
      </v-card-title>
      <v-card-text class="dashboard__textContainer">
        <ul v-if="(farmBins$ && farmBins$.length || farmBaleServiceVehicles$ && farmBaleServiceVehicles$.length)" class="dashboard__list">
          <li v-for="bin in farmBins$" class="dashboard__item">
            <MapIcon :custom-width="80" :field-entity="toBin(bin)"/>
            <span class="dashboard__text"
            ><span class="dashboard__text-title">{{ bin.binName }}</span> ({{
                bin.tankCapacity__m3
              }}{{ translations.DASHBOARD.BIN_CAPACITY_UNIT }})</span
            >
            <div class="dashboard__actions">
              <v-btn icon @click="removeBin(bin)">
                <v-icon color="red">{{ icons.delete }}</v-icon>
              </v-btn>
            </div>
          </li>
          <li v-for="baleServiceVehicle in farmBaleServiceVehicles$" class="dashboard__item">
            <MapIcon :custom-width="80" :field-entity="toBaleServiceVehicle(baleServiceVehicle)"/>
            <span class="dashboard__text"
            ><span class="dashboard__text-title">{{ baleServiceVehicle.baleServiceVehicleName }}</span></span
            >
            <div class="dashboard__actions">
              <v-btn icon @click="removeBaleServiceVehicle(baleServiceVehicle)">
                <v-icon color="red">{{ icons.delete }}</v-icon>
              </v-btn>
            </div>
          </li>
        </ul>
        <template v-else-if="!farmBins$">
          <v-progress-circular color="primary" indeterminate/>
        </template>
        <template v-else>
          <p style="font-weight: bold">
            {{ translations.DASHBOARD.NO_FARM_BINS.PARAGRAPH_1 }}
          </p>
          <p>{{ translations.DASHBOARD.NO_FARM_BINS.PARAGRAPH_2 }}</p>
        </template>
      </v-card-text>
      <v-btn absolute color="primary" dark fab large right top @click="addBin">
        <v-icon>{{ icons.add }}</v-icon>
      </v-btn>
    </v-card>
  </fragment>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import InfoIcon from '@components/InfoIcon.component.vue';
import MapIcon from '@/map/icons/MapIcon.icon.vue';
import { Icons, icons } from '@utils/icons.utils';
import ModalService from '@/modals/modal.service';
import { ModalTypeEnum } from '@/modals/modalType.enum';
import FarmBinService from '@/farmBin/farmBin.service';
import FarmBaleServiceVehicleService from '@/farmBaleServiceVehicle/farmBaleServiceVehicle.service';
import { BaleServiceVehicle, Bin, Brand, BrandingService } from 'shared-frontend';
import { State } from '@/store';
import { FarmBin } from '@/farmBin/farmBin.domain';
import { farmBinToBin } from '@/farmBin/farmBin.helper';
import { FarmBaleServiceVehicle } from '@/farmBaleServiceVehicle/farmBaleServiceVehicle.domain'
import { farmBaleServiceVehicleToBaleServiceVehicle } from '@/farmBaleServiceVehicle/farmBaleServiceVehicle.helper'

export default Vue.extend({
  name: 'FarmBins',
  components: {
    InfoIcon,
    MapIcon
  },
  computed: {
    ...mapState({
      translations: (state: State) => state.translation.translations
    }),
    icons(): Icons {
      return icons;
    },
    brandColors(): any {
      return BrandingService.getColors(process.env.VUE_APP_BRAND as Brand);
    }
  },
  methods: {
    addBin(): void {
      ModalService.showModal(ModalTypeEnum.ADD_SERVICE_VEHICLE);
    },
    removeBin(farmBin: FarmBin): void {
      if (farmBin) {
        ModalService.showModal(ModalTypeEnum.CONFIRM_DELETE_BIN, {farmBin});
      }
    },
    toBin(farmBin: FarmBin): Bin {
      const bin = farmBinToBin(farmBin);
      bin.assignedTo = 'dummy';
      return bin;
    },
    removeBaleServiceVehicle(farmBaleServiceVehicle: FarmBaleServiceVehicle): void {
      if (farmBaleServiceVehicle) {
        ModalService.showModal(ModalTypeEnum.CONFIRM_DELETE_BALE_SERVICE_VEHICLE, {farmBaleServiceVehicle});
      }
    },
    toBaleServiceVehicle(farmBaleServiceVehicle: FarmBaleServiceVehicle): BaleServiceVehicle {
      const baleServiceVehicle = farmBaleServiceVehicleToBaleServiceVehicle(farmBaleServiceVehicle);
      baleServiceVehicle.assignedTo = 'dummy';
      return baleServiceVehicle;
    }
  },
  subscriptions() {
    return {
      farmBins$: FarmBinService.farmBins$,
      farmBaleServiceVehicles$: FarmBaleServiceVehicleService.farmBaleServiceVehicles$,
    };
  }
});
</script>
