<template>
    <div class="message" v-if="show">
        <div class="message__content">
            <span class="message__content__time">{{ formattedTime }}</span>
            <span class="message__content__senderName">{{ formattedCreatedBy }}</span>
            <span class="message__content__messageText">{{ message.message }}</span>
        </div>

        <v-btn text @click="close">
            <v-icon color="white">
                {{ icons.close }}
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
import { Message, toShortDateTime } from 'shared-frontend';
import MessageService from '@/messaging/message.service';
import { icons } from '@/utils/icons.utils';
import SettingsService from "@services/settings.service";
import { mapState } from "vuex";

export default {
    name: 'Message',
    props: {
        message: Message
    },
    data: () => ({
        show: true
    }),
    computed: {
        ...mapState({
          trans: (state) => state.translation.translations
        }),
        formattedTime() {
            return toShortDateTime(this.message.createdOn, SettingsService.userLocale)
        },
        formattedCreatedBy() {
          return this.message.createdBy?.getFullName() ?? this.trans.MESSAGING.UNKNOWN_USER;
        },
        icons() {
            return icons;
        }
    },
    methods: {
        close() {
            MessageService.removeMessage$(this.message.id);
            this.show = false;
        }
    }
};
</script>
