<template>
  <v-list-item :disabled="isNotPresent" @click="onClick(fieldEntity)">
    <v-list-item-content>
      <div class="teamMember">
        <MapIcon :custom-width="iconWidth || 70" :field-entity="fieldEntity" :rotation="false" :no-color="nocolor"/>
        <span class="teamMember__label">
                    <span>{{ fieldEntity.getFullName() }}</span>
                    <span v-if="isNotPresent" class="teamMember__isRemoved">
                        ({{ trans.TEAM_MEMBERS.REMOVED }})</span
                    ></span
        >
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script lang="ts">
import Vue, {PropType} from 'vue';
import {mapState} from 'vuex';

import {FieldEntity, Person, Team, User} from 'shared-frontend';
import TeamService from '@/team/team.service';
import FieldEntityService from '@services/fieldEntity.service';
import MapIcon from '@/map/icons/MapIcon.icon.vue';
import {Icons, icons} from '@utils/icons.utils';
import {State} from '@/store';

export default Vue.extend({
  name: 'TeamMember',
  components: {MapIcon},
  props: {
    fieldEntity: Object as PropType<Person>,
    iconWidth: {
      required: false,
      type: Number
    },
    nocolor: Boolean
  },
  computed: {
    ...mapState({
      trans: (state: State) => state.translation.translations
    }),
    selectedTeam(): Team {
      return (this as any).selectedTeam$;
    },
    icons(): Icons {
      return icons;
    },
    isNotPresent(): boolean {
      if (this.selectedTeam?.isActive()) {
        const {users, spfhs, balers, combines, bins, baleServiceVehicles} = this.selectedTeam;
        return [...users, ...spfhs, ...balers, ...combines, ...bins, ...baleServiceVehicles].every(
            (fieldEntity: FieldEntity) => fieldEntity.id !== this.fieldEntity.id
        );
      } else {
        return false;
      }
    }
  },
  methods: {
    onClick(fieldEntity): void {
      const fieldEntitySelected =
          fieldEntity instanceof User
              ? this.selectedTeam?.getVehicleAssignedToUser(fieldEntity) || fieldEntity
              : fieldEntity;

      FieldEntityService.setFieldEntity(fieldEntitySelected);
    }
  },
  subscriptions() {
    return {
      selectedTeam$: TeamService.selectedTeamOrNull$,
    };
  }
});
</script>
