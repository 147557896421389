import {isValidNumber, parsePhoneNumberFromString} from 'libphonenumber-js';

export class PhoneNumber {

    public static createFromString(phoneNumber: string): PhoneNumber {
        if (!PhoneNumber.isValid(phoneNumber)) return null;

        const phoneNumberParsed = parsePhoneNumberFromString(phoneNumber);
        const areaCode = phoneNumberParsed ? phoneNumberParsed.countryCallingCode.toString() : '';
        const number = phoneNumberParsed ? phoneNumberParsed.nationalNumber.toString() : '';

        if (!areaCode || !number) return null;

        return new PhoneNumber(areaCode, number);
    }

    public static isValid(phoneNumber: string) {
        return isValidNumber(phoneNumber) && Boolean(parsePhoneNumberFromString(phoneNumber));
    }

    constructor(public areaCode: string, public number: string) {}

    public getFullNumber(): string {
        return `+${this.areaCode}${this.number}`;
    }
}
