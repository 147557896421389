// EXTERNAL
import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
//
// INTERNAL
// Shared
import { Brand, BrandingService } from 'shared-frontend';

// Branding
const colors = BrandingService.getColors(process.env.VUE_APP_BRAND as Brand);

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa'
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: colors.PRIMARY,
                primaryLight: colors.PRIMARY_LIGHT,
                secondary: colors.SECONDARY,
                toolbarColor: "#51586A"
            },
            dark: {
                primary: colors.PRIMARY,
                primaryLight: colors.PRIMARY_LIGHT,
                secondary: colors.SECONDARY,
                toolbarColor: "#51586A"
            }
        }
    }
});
