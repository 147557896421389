<template>

  <div class="d-flex align-center justify-space-between px-6 root-active-day-select" v-if="styles.length">

    <v-select
        :value="selectedDay"
        :items="selectableDays"
        item-text="label"
        item-value="value"
        background-color="white"
        outlined
        hide-details
        @change="handleUpdateDay"
        v-if="selectableDays.length"
    />

    <v-divider vertical class="mx-6"
               v-if="selectableDays.length" />

    <v-radio-group
        :value="selectedStyle"
        row
        hide-details
        @change="handleUpdateStyle"
    >
      <v-radio v-for="style in styles"
               :label="translations.APDT_ADVICE.LAYERS.STYLES[style.name]"
               :value="style"
      />
    </v-radio-group>

    <slot name="additionalActions">

    </slot>

  </div>

</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import RecalibrateAction from "@/apdtAdvice/soil-analysis/RecalibrateAction.vue";
import { AdviceSourceType } from "@/apdtAdvice/advice.enum";
import { Moment } from "moment";
import { AdviceWmsStyle } from "@/apdtAdvice/advice.domain";

type Day = {
  label: string;
  value: string;
}

type MomentWithLabel = {
  label: string;
  value: Moment;
}

export default Vue.extend({
  name: "AdviceDayStyle-selector",
  components: { RecalibrateAction },
  props: {
    selectedDay: {
      required: false,
      type: Object as PropType<Moment>
    },
    days: {
      required: false,
      type: Array as PropType<MomentWithLabel[]>
    },
    selectedStyle: {
      required: false,
      type: Object as PropType<AdviceWmsStyle>
    },
    styles: {
      required: true,
      type: Array as PropType<AdviceWmsStyle[]>
    }
  },
  computed: {
    AdviceSourceType() {
      return AdviceSourceType;
    },
    selectableDays() {
      return this.days === undefined || this.days === null ? [] : this.days.sort((a, b) => {
        return b.value.diff(a.value);
      });
    },
    ...mapState({
      translations: (state: any) => state.translation.translations
    }),
    hasAdditionalActions() {
      return !!this.$slots.additionalActions;
    }
  },
  methods: {
    handleUpdateDay(day: Day) {
      this.$emit("updateDay", day);
    },
    handleUpdateStyle(style: AdviceWmsStyle) {
      this.$emit("updateStyle", style);
    }
  }
});
</script>

<style scoped lang="scss">

.root-active-day-select {
  background-color: white;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 70px;
}

::v-deep .v-select__selections input {
  width: 20px;
}

::v-deep .v-radio {
  flex-direction: column;
  margin-right: 20px !important;

  .v-input--selection-controls__input {
    margin-right: 0;
    margin-bottom: 8px;
  }
}

::v-deep .v-input--selection-controls {
  padding: 12px;
  margin: 0;
}


</style>
