<template>
    <l-popup v-if="isVisible" :options="leafletPopupOptions">
      <div @click="onClick" class="mapView__balloonContainer">
        <p  v-for="value in balloon">{{ value }}</p>
      </div>
    </l-popup>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { PopupOptions } from 'leaflet';
import { FieldEntity, Vehicle } from 'shared-frontend';
import { mapState } from 'vuex';
import { State } from '@/store';

export default Vue.extend({
    name: 'Balloon',
    props: {
        fieldEntity: Object as PropType<FieldEntity>,
        showMenu: Function as PropType<(fieldEntity: FieldEntity, event: MouseEvent) => void>
    },
    computed: {
        ...mapState({
            translations: (state: State) => state.translation.translations
        }),
        isVisible(): boolean {
            return Boolean(this.fieldEntity instanceof Vehicle && this.balloon.length);
        },
        balloon(): string[] {
            return (this.fieldEntity as Vehicle).toBalloon(this.translations);
        },
        leafletPopupOptions(): PopupOptions {
            return {
              autoPan: false,
                keepInView: false,
                closeButton: false,
                closeOnClick: false,
                closeOnEscapeKey: false,
                autoClose: false,
                className: 'mapView__balloon'
            };
        }
    },
    methods: {
      onClick(event: MouseEvent): void {
        this.showMenu(this.fieldEntity, event);
      }
    }
});
</script>