export enum CredentialsType {
    usernamePassword,
    token
}

export enum CredentialsSource {
    agroplanning = 'agroplanning',
    augmenta = 'augmenta',
    geoprospectors = 'geoprospectors',
    smartfarm = 'smartfarm'
}

export abstract class Credential {
    private readonly type: CredentialsType;
    readonly source: CredentialsSource;

    protected constructor(type: CredentialsType, source: CredentialsSource) {
        this.type = type;
        this.source = source;
    }

    public abstract toApdtJSONBody(): any;
}

export class CredentialUsernamePassword extends Credential {
    public username: string;
    public password: string;

    constructor(source: CredentialsSource, data: Partial<CredentialUsernamePassword>) {
        super(CredentialsType.usernamePassword, source);

        this.username = data.username;
        this.password = data.password;
    }

    toApdtJSONBody(): any {
        return {
            sensor: this.source,
            credentials: {
                username: this.username,
                password: this.password
            }
        }
    }
}

export class CredentialToken extends Credential {
    public token: string;

    constructor(source: CredentialsSource, data: Partial<CredentialToken>) {
        super(CredentialsType.token, source);
        this.token = data.token;
    }

    toApdtJSONBody(): any {
        return {
            sensor: this.source,
            credentials: {
                token: this.token
            }
        }
    }
}

