// EXTERNAL
import { API_ENDPOINTS, Combine, Combines, FarmCombineJSON } from 'shared-frontend';
import httpService from '@services/http.service';
import { logger } from '@services/logger.service';
import { parseFarmCombine } from 'shared-frontend/dist/utils/jsonParsers/combine.jsonParsers.utils';

class FarmCombinesService {
    public async fetchFarmCombines(): Promise<Combines> {
        try {
            const response = await httpService.get(API_ENDPOINTS.FARM_COMBINES());
            const farmCombinesJSON: FarmCombineJSON[] = response.data;

            const combines: Combine[] = farmCombinesJSON.map((farmCombineJSON: FarmCombineJSON) =>
                parseFarmCombine(farmCombineJSON)
            );

            logger.info('[FarmBinsService] - fetchFarmCombines', combines);

            return new Combines(combines);
        } catch (err) {
            logger.error(`[farmCombines.service] - fetchFarmCombines: Error in fetching farmCombines`, err);
        }
    }
}

export default new FarmCombinesService();
